import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useMedias } from '../../redux/medias/hooks';
import { useSector } from '../../redux/sectors/hooks';
import { useFilter } from '../../redux/filters/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useManageOpenings } from '../../redux/openings/hooks';
import { useSelectedRoom, useSelectedSector, useSelectedWall } from '../../redux/rooms/hooks';
import { IonPage, IonContent, useIonViewWillLeave, IonLoading } from '@ionic/react';

import Icon from '../../atoms/Icon/Icon';
import Colors from './partials/Colors/Colors';
import Header from '../../routes/components/Header/Header';

import FiltresOuverture from './partials/FiltresOuverture/FiltresOuverture';

import './Ouverture.scss';
import wall from '../../utils/wall.interface';
import IconTypes from '../../types/IconTypes';
import { Camera, CameraResultType } from '@capacitor/camera';
import OptimizedImage from '../../atoms/OptimizedImage/OptimizedImage';

const STATUSOPENID = 1;

interface Location {
  pathname: string;
  state?: {
    mounted?: boolean;
    url?: string;
  };
}

const Ouvertures: React.FC = () => {
  const uploadRef = useRef<boolean>(false);
  const pageRef = useRef<HTMLIonContentElement>(null);

  const t = useLocalize();
  const { colors } = useFilter();
  const { room } = useSelectedRoom();
  const location: Location = useLocation();
  const { selectedWallId } = useSelectedWall();
  const { selectedSectorId } = useSelectedSector();
  const { sector, getSector, sectorReset } = useSector();
  const { dismantleSector, addSectorPicture, isPublishing } = useManageOpenings();
  const { uploadMedia, uploadedMedia, resetMedia, isLoading } = useMedias();

  const currentWall = useMemo(
    () => room?.walls.find((wall: wall) => wall.id === selectedWallId),
    [selectedWallId, room?.walls]
  );
  const currentSector = useMemo(
    () => currentWall?.sectors.find((sector: wall) => sector.id === selectedSectorId),
    [selectedSectorId, currentWall?.sectors]
  );

  const [status, setStatus] = useState<number>();
  const [adding, setAdding] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [selectedBoulder, setSelectedBoulder] = useState<any>();
  const [imgURL, setImgURL] = useState<string | undefined>(currentSector?.picture);

  const initialColor = useMemo(() => selectedBoulder?.color.id, [selectedBoulder]);
  const initialIntensity = useMemo(() => selectedBoulder?.intensity?.id, [selectedBoulder]);
  const initialTags = useMemo(
    () => selectedBoulder?.bouldertags.map((bouldertag: any) => bouldertag.tag.id),
    [selectedBoulder]
  );

  const planPicture = currentWall?.plan || room?.plan;

  const cannotAddBoulder = useMemo(
    () => (colors?.length || 0) <= (sector?.boulders?.length || 0),
    [colors?.length, sector?.boulders]
  );

  const handleBackgroundClick = useCallback((e) => {
    if (e.currentTarget === e.target) {
      setConfirm(false);
      setSuccess(false);
    }
  }, []);

  const hidePopup = useCallback(() => {
    setConfirm(false);
    setSuccess(false);
    // if(sector?.id) getSector(sector.id)
  }, []);

  const addBoulder = useCallback(() => {
    hidePopup();
    setAdding(true);
  }, [hidePopup]);

  const handleSectorPicture = useCallback(async () => {
    await Camera.requestPermissions();
    Camera.getPhoto({
      quality: 85,
      resultType: CameraResultType.DataUrl,
      correctOrientation: true,
      promptLabelPhoto: t('open_gallery'),
      promptLabelPicture: t('open_camera'),
    })
      .then((data) => {
        if (data?.dataUrl) {
          let { dataUrl } = data;
          uploadMedia(dataUrl);
          uploadRef.current = true;
        }
      })
      .catch((e) => {
        console.error('Error occurred while taking a picture', e);
      });
  }, [t, uploadMedia]);

  const handleDismantling = useCallback(() => {
    setConfirm(false);
    sectorReset();
    dismantleSector(currentSector?.id);
    setImgURL(undefined);
  }, [currentSector?.id, dismantleSector, sectorReset]);

  const showConfirm = useCallback(() => {
    setConfirm(true);
  }, []);

  // useEffect
  // For Photo change
  useEffect(() => {
    if (uploadedMedia && currentSector && uploadRef.current) {
      uploadRef.current = false;
      addSectorPicture(parseInt(currentSector.id), uploadedMedia);
      setImgURL(uploadedMedia);
      resetMedia();
      setAdding(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSector, uploadedMedia]);

  useEffect(() => {
    if (location?.state?.mounted) {
      setAdding(false);
      setSuccess(true);
      sectorReset();
      if (currentSector?.id) getSector(currentSector.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSector?.id, location]);

  useEffect(() => {
    sectorReset();
    if (currentSector?.id) getSector(currentSector.id);
    hidePopup();
    setAdding(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSector]);

  useEffect(() => {
    if (sector) {
      setImgURL(sector.picture);
      setStatus(sector.status);
    } else {
      setImgURL(undefined);
      setStatus(0);
    }
  }, [sector]);

  useEffect(() => {
    pageRef.current?.scrollToTop(0);
  }, [adding]);

  useIonViewWillLeave(() => {
    pageRef.current?.scrollToTop(0);
  });

  return (
    <IonPage id='ouverture'>
      <Header
        isCoach={false}
        autoSelect
        showRoom
        showProfile={false}
        showMonth={false}
        showWalls
        showSectors
      />
      <IonContent fullscreen scrollEvents ref={pageRef} className='ouvertureContent'>
        {!adding && (
          <div onClick={handleBackgroundClick}>
            <div className='plan' onClick={hidePopup}>
              <div className='title'>{t('plan')}</div>
              {!!room?.id && (
                <img crossOrigin='anonymous' className='planImg' src={planPicture} alt='plan' />
              )}
            </div>
            <div className='plan' onClick={hidePopup}>
              <div className='title'>{t('sector_boulders')}</div>
              <Colors
                sector={sector}
                selectBoulder={setSelectedBoulder}
                boulderAdded={success}
                dismantled={confirm}
              />
            </div>
            <div className='actionButtons' onClick={handleBackgroundClick}>
              {!selectedBoulder && (
                <button disabled={cannotAddBoulder || !imgURL} onClick={addBoulder}>
                  {t('add_boulder')}
                </button>
              )}
              {!!selectedBoulder && (
                <button disabled={!imgURL} onClick={addBoulder}>
                  {t('change_bloc')}
                </button>
              )}
              {!imgURL && !!currentSector?.id && (
                <div className='photoPlaceholder'>
                  <Icon icon={IconTypes.GALLERY_UNAVAILABLE} className='placeholderIcon' />
                </div>
              )}
              {!!imgURL && (
                <div className='sectorPicture'>
                  <OptimizedImage
                    format='card'
                    crossOrigin='anonymous'
                    src={imgURL}
                    alt='sector'
                    className='picture'
                  />
                </div>
              )}
              <button
                disabled={
                  currentSector?.id === 0 || !currentSector || status === STATUSOPENID
                    ? true
                    : false
                }
                onClick={handleSectorPicture}
              >
                {t('change_picture')}
              </button>
              <button disabled={status === STATUSOPENID ? false : true} onClick={showConfirm}>
                {t('dismantle_sector')}
              </button>
            </div>
          </div>
        )}
        {adding && !!imgURL && (
          <FiltresOuverture
            room={room}
            wall={currentWall}
            sector={currentSector}
            background={imgURL}
            initialColor={initialColor}
            initialIntensity={initialIntensity}
            initialTags={initialTags}
            boulder={selectedBoulder}
            dismiss={setAdding}
          />
        )}
        <IonLoading cssClass='custom-loader-class' isOpen={isLoading} message={''} />
      </IonContent>
      {success && !isPublishing && (
        <div className='successAlert'>
          <div className='successNob'>
            <Icon icon={IconTypes.TICK} className='successIcon' />
          </div>
          <div className='alertTitle'>{t('published_boulder')}</div>
          <button className='dissmissButton' onClick={hidePopup}>
            {t('OK')}
          </button>
          <div className='successTick' />
        </div>
      )}
      {confirm && (
        <div className='confirmAlert'>
          <Icon icon={IconTypes.DANGER} className='dangerIcon' />
          <div className='alertTitle'>{t('dismantle_confirmation')}</div>
          <button className='dissmissButton' onClick={handleDismantling}>
            {t('OK')}
          </button>
          <div className='successTick' />
        </div>
      )}
    </IonPage>
  );
};

export default Ouvertures;
