import QRCode from 'qrcode.react';
import './QrCodePreview.scss';

const QrCodePreview = ({
  qrCode,
  enabled,
  onClick,
}: {
  qrCode: string;
  enabled: boolean;
  onClick: () => void;
}) => {
  return (
    <div className='qrcodeMain'>
      <div className='qrcodeBorderPreview'>
        <div className='qrcodeImg' onClick={onClick}>
          <QRCode value={qrCode} size={125} fgColor={enabled ? '#000000' : '#e3e3e3'} />
        </div>
      </div>
    </div>
  );
};

export default QrCodePreview;
