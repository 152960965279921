import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import events from './events';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

const pEnv = (window as any)['env']?.REACT_APP_INSIGHTS ? (window as any)['env'] : process.env;

const appInsights = new ApplicationInsights({
  config: {
    connectionString: pEnv.REACT_APP_INSIGHTS,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export function appAnalyticsTrackPage(path: string) {
  console.info('TRACK PAGE', path);
  appInsights.trackPageView({ name: path });
}

export function appAnalyticsTrackUserLogged(userId: string) {
  console.info('TRACK LOGGED', userId);
  appInsights.setAuthenticatedUserContext(userId);
}

export function appAnalyticsTrackEvent(name: keyof typeof events, params: any = undefined) {
  console.info('TRACK EVENT', name, params);
  appInsights.trackEvent({ name: events[name] || name, properties: params });
}
