import React, { useCallback } from 'react';

import BoulderCard from '../BoulderCard/BoulderCard';

import './BoulderSelector.scss';

interface Props {
  boulderIds: string[];
  selectedBouldersTop: any[];
  selectedBouldersFlash: any[];
  bouldersToValidate: any[];
  onValidateBoulder: Function;
  bouldersToFlash: any[];
  onFlashBoulder: Function;
  date?: Date;
  isOverlayOpen: boolean;
  onOpenOverlay: Function;
}

const BoulderSelector: React.FC<Props> = ({
  boulderIds,
  selectedBouldersTop,
  selectedBouldersFlash,
  bouldersToValidate,
  onValidateBoulder,
  bouldersToFlash,
  onFlashBoulder,
  date,
  isOverlayOpen,
  onOpenOverlay,
}) => {
  const handleTop = useCallback(
    (id: number) => {
      if (selectedBouldersTop?.includes(id) || selectedBouldersFlash?.includes(id)) return;
      if (bouldersToValidate?.includes(id)) {
        const temp = [...bouldersToValidate];
        onValidateBoulder(temp.filter((el) => el !== id));
      } else {
        onValidateBoulder((prevState: number[]) => [...prevState, id]);
      }
    },
    [bouldersToValidate, selectedBouldersFlash, selectedBouldersTop, onValidateBoulder]
  );

  const handleFlash = useCallback(
    (id: number) => {
      if (selectedBouldersTop?.includes(id) || selectedBouldersFlash?.includes(id)) return;
      if (bouldersToFlash?.includes(id)) {
        const temp = [...bouldersToFlash];
        onFlashBoulder(temp.filter((el) => el !== id));
      } else {
        onFlashBoulder((prevState: number[]) => [...prevState, id]);
      }
    },
    [bouldersToFlash, onFlashBoulder, selectedBouldersFlash, selectedBouldersTop]
  );

  return (
    <div className='boulderSelector'>
      {boulderIds?.map((boulderId: any) => (
        <BoulderCard
          date={date}
          key={boulderId}
          boulderId={boulderId}
          selectedBouldersTop={selectedBouldersTop}
          selectedBouldersFlash={selectedBouldersFlash}
          bouldersToValidate={bouldersToValidate}
          onValidateBoulder={handleTop}
          bouldersToFlash={bouldersToFlash}
          onFlashBoulder={handleFlash}
          isOverlayOpen={isOverlayOpen}
          onOpenOverlay={onOpenOverlay}
        />
      ))}
    </div>
  );
};

export default BoulderSelector;
