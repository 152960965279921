import { useSelector } from 'react-redux';
import { getTranslateFunction } from 'redux-i18n';

export const useLocalize = () => {
  const translations = useSelector((state) => state.i18nState.translations);
  const lang = useSelector((state) => state.i18nState.lang);

  const t = getTranslateFunction(translations, lang);
  return t;
};
