import { useMemo } from 'react';
import { useLocalize } from '../../../../../redux/translation/localize';

import { IonContent } from '@ionic/react';
import FilterCard from '../../../../../components/FilterCard/FilterCard';
import YouTubeEmbed from '../../../../../components/YoutubeEmbed/YoutubeEmbed';
import OptimizedImage from '../../../../../atoms/OptimizedImage/OptimizedImage';

import { mediaType } from '../../../../../utils/fileUtils';
import BoulderType from '../../../../../utils/boulder.interface';

const BoulderInfo: React.FC<{ boulder: BoulderType }> = ({ boulder }) => {
  const t = useLocalize();
  const tags = useMemo(
    () => boulder?.bouldertags?.map((individualTag: any) => individualTag.tag),
    [boulder?.bouldertags]
  );

  return (
    <IonContent>
      <div className='divTitle'>{t('tutorial')}</div>
      {!!boulder?.mediaHelp && boulder?.mediaType === mediaType.PHOTO_TYPE && (
        <OptimizedImage
          src={boulder.mediaHelp}
          alt='help'
          className='photoDemo'
          format='fullScreen'
        />
      )}
      {!!boulder?.mediaHelp && boulder?.mediaType !== mediaType.PHOTO_TYPE && (
        <YouTubeEmbed
          className='videoDemo'
          src={boulder?.mediaHelp}
          width='320'
          height='166'
          title='boulder help'
        />
      )}
      {!!boulder?.textHelp && (
        <div className='textHelp'>
          <div>{boulder.textHelp}</div>
        </div>
      )}
      <div className='profilTags'>
        {!!tags?.length && <div className='tagTitle'>{t('tags')}</div>}
        <FilterCard filterName='' filterTab={tags} filterChange={() => {}} selectedFilter={[]} />
      </div>
    </IonContent>
  );
};

export default BoulderInfo;
