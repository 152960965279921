import { BrowserOptions, BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import packageInfo from '../../../package.json';

type BeforeBreadcrumbCallback = NonNullable<BrowserOptions['beforeBreadcrumb']>;

export const excludeGraphQLFetch: BeforeBreadcrumbCallback = (breadcrumb) => {
  if (breadcrumb.category === 'fetch') {
    const url: string = breadcrumb.data?.url ?? '';

    if (url.includes('/graphql')) {
      return null;
    }
  }

  return breadcrumb;
};

const environment = (window as any)?.env || process.env;

export const initSentry = () => {
  if (environment?.REACT_APP_SENTRY_URL) {
    if (environment?.REACT_APP_ENV) {
      Sentry.init({
        dsn: environment?.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing({ traceFetch: false })], // false to exclude redundant fetch breadcrumbs

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        normalizeDepth: 10,
        release: `climbing.district.v${packageInfo.version}`,
        environment: environment?.REACT_APP_ENV,
        beforeBreadcrumb: excludeGraphQLFetch,
      });
    } else {
      console.error('Missing REACT_APP_ENV');
    }
  }
};
