import { gql } from '@apollo/client';

export const CreateOrDeleteUsertag = gql`
  mutation CreateOrDeleteUsertag($tagId: Int!) {
    createOrDeleteUsertag(createOrDeleteUsertagData: { tagId: $tagId }) {
      userId
      tagId
    }
  }
`;
