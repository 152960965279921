import { client } from '../../App';
import { all, put, takeLatest } from 'redux-saga/effects';
import { actions as TagsActions } from './redux';
import { GetTags } from '../../GraphQL/GetFilters';
import * as Sentry from '@sentry/browser';

export default class TagsSagas {
  static *loadTags() {
    try {
      const res = yield client.query({
        fetchPolicy: 'network-only',
        query: GetTags,
      });

      yield put(TagsActions.updateTags(res?.data?.tags));
    } catch (error) {
      console.warn(error);
      Sentry.captureException(error);
    }
  }

  static *loop() {
    yield all([takeLatest(TagsActions.requestTags.getType(), TagsSagas.loadTags)]);
  }
}
