import React, { useCallback, useEffect, useState } from 'react';
import { IonRange } from '@ionic/react';

import './Range.scss';

interface Props {
  labelTitle: string;
  value: number;
  setValue: Function;
  toAdd: number;
  type?: number;
}

const Range: React.FC<Props> = ({ labelTitle, value, setValue, toAdd, type }) => {
  const [prevAdd, setPrevAdd] = useState<number>(0);

  const adaptValue = useCallback(
    (val: number) => {
      if (type) {
        setValue(type, val);
      }
    },
    [setValue, type]
  );

  const changeValue = useCallback(
    (e) => {
      if (type) {
        setValue(type, e.detail.value / 10);
      } else setValue(e.detail.value / 10);
    },
    [setValue, type]
  );

  useEffect(() => {
    if (toAdd > prevAdd) {
      const add = Math.round((toAdd - prevAdd + value + Number.EPSILON) * 100) / 100;
      if (add <= 10) adaptValue(add);
    }
    if (toAdd < prevAdd) {
      const remove = Math.round((value - (prevAdd - toAdd) + Number.EPSILON) * 100) / 100;
      if (remove >= 0) adaptValue(remove);
    }
    setPrevAdd(toAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevAdd, toAdd]);

  return (
    <div className='goalsRanges'>
      <div className='rangeLabels'>
        <label className='rangeTitle'>{labelTitle}</label>
        <label className='rangeValue'>{value}</label>
      </div>
      <IonRange value={value * 10} pin={false} onIonInput={changeValue}></IonRange>
    </div>
  );
};

export default Range;
