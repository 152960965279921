import { getPadTwoDigits } from './dateTime';
import { toFixed } from './toFixed';

export const getVideoTime = (value: number, noMs: boolean = false) => {
  const minutes = Number(toFixed(value / 60, 0));
  const seconds = value / (minutes + 1);
  const ms = (value % 1) * 10;

  const params = [
    noMs ? undefined : getPadTwoDigits(Number(ms) * 10),
    getPadTwoDigits(seconds),
    getPadTwoDigits(Number(minutes)),
  ].filter((_) => !!_);

  return params.reverse().join(':');
};
