import { FFMpeg } from '@awesome-cordova-plugins/ffmpeg';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { File } from '@ionic-native/file';
import { StickerType } from '../../templates/VideoEditor/partial/type';
import getExtension from '../getExtension';
import { trimVideoMobile } from './utils/trimVideo';
import { CreateVideoProps, CreatorStatusEnum } from './utils/utils';

export const createMobileVideo = ({
  video,
  onFinish,
  onLoading,
  onProgress,
  params = {},
}: CreateVideoProps) => {
  (async () => {
    onLoading(true);

    // Getting basic information
    const extension = getExtension(video.type);
    const uniqueName = `${Number(new Date())}_video.${extension}`;
    const uniqueOutName = `${Number(new Date())}_outVideo.mp4`;

    try {
      onProgress(CreatorStatusEnum.ONGOING, 0);

      // Creating the video file
      const CACHE_URI = (await Filesystem.getUri({ directory: Directory.Cache, path: '' })).uri;
      const videoFile = await File.writeFile(CACHE_URI, uniqueName, video);
      let fileName = videoFile.nativeURL;

      // If there's no treatment to be done, we can use the basic file
      if (!params.trim && !params.stickers?.length) {
        return onFinish(video, fileName);
      }

      const output: any = fileName.split('/').reverse();
      output[0] = uniqueOutName;
      const outputPath = output.reverse().join('/');

      // Trim the video if there's trimming to do
      if (params.trim) {
        await trimVideoMobile(
          fileName,
          params.trim.startTime,
          params.trim.endTime,
          fileName.replace('video.', 'video_trim.')
        );
        fileName = fileName.replace('video.', 'video_trim.');

        // If there's no other treatment to do other than trimming
        // We can use the trimming version
        if (!params.stickers?.length) {
          const result = await Filesystem.readFile({ path: fileName });
          return fetch(`data:video/mp4;base64,${result.data}`)
            .then((value) => value.blob())
            .then((data: Blob) => onFinish(data, fileName));
        }
      }

      // If you get here, it gets complicated, stickers are around
      // We set the basic runner for FFmpeg
      let runner = ['-r', '30', '-threads', '10', '-i', fileName];

      // Then we create a filter complex to add all the stickers as overlays
      let filterComplex = '';
      const paths: string[] = [];
      for (let i = 0; i < params.stickers.length; i++) {
        const sticker: StickerType = params.stickers[i];

        // Load Image to get image original information and blob
        const filterBlob = await (await fetch(sticker.path))?.blob();

        // Image Info
        const img = new Image();
        img.src = URL.createObjectURL(filterBlob);
        await new Promise((resolve) => {
          img.onload = resolve;
        });

        // With the information we can create a safe size dividable by 2, otherwise FFmpeg won't be happy
        const safeWidth = Number((sticker.width / 2).toFixed(0)) * 2;
        const height = (sticker.height * img.naturalHeight) / (img.naturalWidth || 1);
        const safeHeight = Number((height / 2).toFixed(0)) * 2;

        // We create the file to add it
        const uniqueFilter = `${Number(Date.now())}_filter_${i}.png`;
        const filterFile = await File.writeFile(CACHE_URI, uniqueFilter, filterBlob);
        const name = filterFile.nativeURL;

        // This is too complex I don't even know how to fully explain
        // But it's scaling and adding the sticker to the video creating a new image
        // And stacking the images because that's how it works

        const scaleFilter = `[${
          i + 1
        }:v]scale=${safeWidth}:${safeHeight}:force_original_aspect_ratio=decrease[img${i + 1}];`;
        const overlayFilter = `[out${i}][img${i + 1}]overlay=${sticker.x}:${sticker.y}[out${
          i + 1
        }]`;
        filterComplex += `${scaleFilter}${overlayFilter}${
          i === params.stickers.length - 1 ? '' : ';'
        }`;

        paths.push('-i');
        paths.push(name);
      }

      // Once filter complex done we add it to the runner
      const safeWidth = Number((window.innerWidth / 2).toFixed(0)) * 2;
      const safeHeight = Number((window.innerHeight / 2).toFixed(0)) * 2;

      runner = runner.concat([
        ...paths,
        '-filter_complex',
        `[0:v]scale=${safeWidth}:${safeHeight}[out0];${filterComplex}`,
        '-map',
        `[out${params.stickers.length}]`,
        '-map',
        '0:a',
      ]);

      // We add last parameters to prevent useless encoding + the output path
      runner = runner.concat([
        '-c:v',
        'libx264',
        '-c:a',
        'aac',
        '-movflags',
        'faststart',
        '-crf',
        '20',
        '-pix_fmt',
        'yuv420p',
        outputPath,
      ]);

      // We run the runner
      await FFMpeg.exec(runner.join(' '));

      // We get the file to use it
      const result = await Filesystem.readFile({ path: outputPath });
      return fetch(`data:video/mp4;base64,${result.data}`)
        .then((value) => value.blob())
        .then((data: Blob) => onFinish(data, outputPath));
    } catch (err) {
      console.error(err);
      onFinish(null, '');
    }
    onLoading(false);
  })();
};
