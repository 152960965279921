// React
import React, { useState, useRef, useEffect, useCallback } from 'react';

// Libs
import * as PIXI from 'pixi.js-legacy';
// import { Graphics } from 'pixi.js-legacy';

// Hooks
import { useCropEditor, useEditor } from '../../redux/editor/hooks';

// Components
import PixiApp from './utils/PixiApp';
import ContextToolbar from './partials/ContextToolbar/ContextToolbar';

// Utils
import './ImageEditor.scss';
import defaultImage from './utils/defaultImage';

interface ImageEditorInterface {
  source?: string;
  additionalColors?: string[];
  onEditClose: () => void;
  onEditCompleted: (value: string) => void;
}

const force = (v) => Math.floor(Math.max(0, v));

const ImageEditor: React.FC<ImageEditorInterface> = ({
  additionalColors,
  source = defaultImage,
  onEditClose,
  onEditCompleted,
}) => {
  // Hooks
  const {
    initialBound,
    isInCropEditor,
    cropBound: currentCropBound,
    setInitialBound,
  } = useCropEditor();
  const { clearEditor } = useEditor();

  // Refs
  const cropGraphics = useRef<any>(null);

  // States
  const [imageSet, setImageSet] = useState<boolean>(false);

  // Methods
  const renderCrop = useCallback(() => {
    if (!PixiApp.cropContainer || !PixiApp.backgroundContainer) {
      return null;
    }

    if (!cropGraphics.current) {
      cropGraphics.current = new PIXI.Graphics();
      PixiApp.maskContainer?.addChild(cropGraphics.current);
    }

    cropGraphics.current.clear();
    PixiApp.backgroundContainer?.scale.set(1);

    const ww = window.innerWidth;
    const wh = window.innerHeight;

    const cropBound = isInCropEditor
      ? {
          x: 0,
          y: 0,
          width: initialBound.width,
          height: initialBound.height,
        }
      : currentCropBound;
    const viewport = isInCropEditor ? PixiApp.CROP_VIEWPORT : PixiApp.FULL_VIEWPORT;

    const ratio = Math.min(viewport.width / cropBound.width, viewport.height / cropBound.height);
    const dx = cropBound.x * -ratio;
    const dy = cropBound.y * -ratio;

    PixiApp.cropContainer.scale.set(ratio);
    PixiApp.cropContainer.x = viewport.width / 2 - (cropBound.width * ratio) / 2 + dx + viewport.x;
    PixiApp.cropContainer.y =
      viewport.height / 2 - (cropBound.height * ratio) / 2 + dy + viewport.y;

    const top = viewport.height / 2 - (cropBound.height * ratio) / 2 + viewport.y;
    const bottom = viewport.height / 2 + (cropBound.height * ratio) / 2 + viewport.y;
    const left = viewport.width / 2 - (cropBound.width * ratio) / 2 + viewport.x;
    const right = viewport.width / 2 + (cropBound.width * ratio) / 2 + viewport.x;

    cropGraphics.current.beginFill(0);
    cropGraphics.current.drawRect(-1, -1, ww + 1, top + 1);
    cropGraphics.current.drawRect(0, bottom, ww, wh - bottom);
    cropGraphics.current.drawRect(-1, -1, left + 1, wh + 1);
    cropGraphics.current.drawRect(right, 0, ww - right, wh);
  }, [isInCropEditor, initialBound, currentCropBound]);

  const setBackgroundImage = useCallback(() => {
    const canvas: any = document.getElementById('ImageEditorCanvas');
    if (!source || !canvas) return null;

    PIXI.settings.RESOLUTION = 2;
    const thisApp: any = new PIXI.Application({
      width: window.innerWidth,
      height: window.innerHeight,
      view: canvas,
      antialias: true,
      resolution: PIXI.settings.RESOLUTION,
      autoDensity: true,
      forceCanvas: false,
    });
    thisApp.renderer.background.color = 0x000000;

    thisApp.renderer.autoResize = true;
    thisApp.renderer.view.style.display = 'block';
    thisApp.renderer.resize(window.innerWidth, window.innerHeight);
    PixiApp.init(thisApp);

    const img = new Image();
    img.onload = function (this: any) {
      const ratioW = 2048 / img.width;
      const ratioH = 2048 / img.height;
      const ratio = Math.min(Math.min(ratioW, ratioH), 1);
      const finalWidth = img.width * ratio;
      const finalHeight = img.height * ratio;

      const canvas = document.createElement('canvas');
      canvas.width = finalWidth;
      canvas.height = finalHeight;
      const ctx = canvas.getContext('2d');

      if (!ctx) return null;
      ctx.drawImage(img, 0, 0, finalWidth, finalHeight);

      const resources = {
        background: {
          texture: PIXI.Texture.from(canvas, { resolution: 1 }),
        },
      };

      const background: any = new PIXI.Sprite(resources.background.texture);
      PixiApp.backgroundContainer?.addChild(background);
      PixiApp.bound = {
        top: background.y,
        right: background.x + background.width,
        bottom: background.y + background.height,
        left: background.x,
        width: background.width,
        height: background.height,
      };

      setInitialBound(
        0,
        0,
        resources.background.texture.width,
        resources.background.texture.height
      );

      // renderCrop();
      setImageSet(true);
    };
    img.src = source;
    img.crossOrigin = 'anonymous';
  }, [source, setInitialBound]);

  const handleDownload = useCallback(
    (canvas: any, filename: string) => {
      const lnk = document.createElement('a');
      const data = canvas.toDataURL('image/jpeg', 0.7);

      lnk.download = filename;
      lnk.href = data;

      onEditCompleted(data);
    },
    [onEditCompleted]
  );

  const handleSave = useCallback(() => {
    if (!PixiApp.zoomContainer || !PixiApp.app) return null;
    PixiApp.zoomContainer.scale.set(1);
    PixiApp.zoomContainer.x = 0;
    PixiApp.zoomContainer.y = 0;

    PixiApp.app.renderer.on('postrender', () => {
      PixiApp.app.renderer.off('postrender');
      const canvas: any = document.createElement('canvas');

      canvas.width = currentCropBound.width * PIXI.settings.RESOLUTION;
      canvas.height = currentCropBound.height * PIXI.settings.RESOLUTION;
      const ctx = canvas.getContext('2d');
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const canvasSource: any = document.getElementById('ImageEditorCanvas');

      const viewport = PixiApp.FULL_VIEWPORT;
      const ratio = Math.min(
        viewport.width / currentCropBound.width,
        viewport.height / currentCropBound.height
      );

      const top = viewport.height / 2 - (currentCropBound.height * ratio) / 2 + viewport.y;
      const left = viewport.width / 2 - (currentCropBound.width * ratio) / 2 + viewport.x;

      ctx.drawImage(
        canvasSource,
        force(left * PIXI.settings.RESOLUTION),
        force(top * PIXI.settings.RESOLUTION),
        force(currentCropBound.width * ratio * PIXI.settings.RESOLUTION),
        force(currentCropBound.height * ratio * PIXI.settings.RESOLUTION),

        0,
        0,
        force(currentCropBound.width * PIXI.settings.RESOLUTION),
        force(currentCropBound.height * PIXI.settings.RESOLUTION)
      );

      canvas.style.position = 'fixed';
      canvas.style.top = 0;
      canvas.style.left = 0;
      handleDownload(canvas, 'download.png');
    });
  }, [currentCropBound.height, currentCropBound.width, handleDownload]);

  const handleQuit = useCallback(onEditClose, [onEditClose]);

  // Did Mount
  useEffect(() => {
    setBackgroundImage();
    return () => {
      clearEditor();
    };
  }, [setBackgroundImage, source, clearEditor]);

  // Did Update
  useEffect(() => {
    if (imageSet) {
      renderCrop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSet]);

  return (
    <div id='ImageEditor'>
      <canvas id='ImageEditorCanvas' />
      {!!source && imageSet && (
        <div className='ImageEditorContainer'>
          {isInCropEditor && <div />}
          <ContextToolbar
            additionalColors={additionalColors}
            onSave={handleSave}
            onQuit={handleQuit}
          />
        </div>
      )}
    </div>
  );
};

export default ImageEditor;
