import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { useLocalize } from '../../../../redux/translation/localize';
import { InAppBrowser, InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser';
import { useToken } from '../../../../redux/auth/hooks';

import './HomeShop.scss';
import { trackEvent } from '../../../../providers/tracker';
import { useLiberfitShop } from '../../../../redux/user/hooks';
import { homeShopData, LiberfitUrl } from '../../../../utils/shop.utils';

const srcEvents: { [name: string]: any } = {
  seances: 'shop_sessions',
  abonnements: 'shop_subscription',
  cours: 'shop_class',
  stages: 'shop_internship',
  yoga: 'shop_yoga',
  renforcement: 'shop_reinforcement',
};

const ShopItem: React.FC<{
  label: string;
  icon: string;
  link: string;
  src: string;
}> = ({ label, icon, link, src }) => {
  const appRef = useRef<InAppBrowserObject | null>(null);
  const timer: any = useRef(null);

  const { token } = useToken();
  const { getIframeToken, clearIframe, liberfitToken } = useLiberfitShop(src);

  const [buttonStyle, setButtonStyle] = useState<string>('');

  const iconStyle = useMemo(() => ({ fontSize: icon === '🎟' ? '3rem' : '2rem' }), [icon]);

  const handleClick = useCallback(() => {
    trackEvent('shop', {
      event_category: 'shop',
      event_action: srcEvents[src],
    });
    getIframeToken();
    setButtonStyle('pressed');
  }, [src, getIframeToken]);

  useEffect(() => {
    if (appRef.current) {
      appRef.current.close();
      appRef.current = null;
    }

    if (liberfitToken) {
      const addParams = !link.includes('?');
      appRef.current = InAppBrowser.create(
        token
          ? `${LiberfitUrl}${link}${addParams ? '?token=' : '&token='}${liberfitToken}`
          : LiberfitUrl
      );
      clearIframe();
    }
  }, [clearIframe, liberfitToken, link, src, token]);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      setButtonStyle('');
    }, 1000);
    return () => timer.current && clearTimeout(timer.current);
  }, [buttonStyle]);

  return (
    <div className={`shopItem ${buttonStyle}`} onClick={handleClick}>
      <div className='shopIcon' style={iconStyle}>
        {icon}
      </div>
      <div className='shopLabel'>{label}</div>
    </div>
  );
};

const HomeShop: React.FC = () => {
  const t = useLocalize();

  return (
    <div className='homeShopContainer'>
      <div className='sectionName'>
        <div className='title'>{t('maCarteButtonBoutique')}</div>
      </div>
      <div className='shopItems'>
        {homeShopData.map(
          (item: { label: string; icon: string; link: string; src: string }, index: number) => (
            <ShopItem
              label={t(item.label)}
              icon={item.icon}
              link={item.link}
              src={item.src}
              key={index}
            />
          )
        )}
      </div>
    </div>
  );
};

export default HomeShop;
