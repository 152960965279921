import { gql } from '@apollo/client';

export const GetPeriodicSessions = gql`
  query GetPeriodicSessions($roomId: Int, $dateStart: DateTime, $dateEnd: DateTime) {
    sessions(roomId: $roomId, dateStart: $dateStart, dateEnd: $dateEnd) {
      id
      startDate
      sessionspecialities {
        specialityId
        value
      }
      sessionboulders {
        boulder {
          color {
            id
            name
          }
          sector {
            plan
            name
          }
        }
        status
      }
    }
  }
`;

export const GetDailySessions = gql`
  query GetDailySessions($roomId: Int, $dateStart: DateTime, $dateEnd: DateTime) {
    sessions(roomId: $roomId, dateStart: $dateStart, dateEnd: $dateEnd) {
      id
      startDate
    }
  }
`;

export const GetUserVisit = gql`
  query GetUserVisit($startDate: DateTime!, $endDate: DateTime!, $roomId: Int) {
    qrCodeFlashHistoryByDate(startDate: $startDate, endDate: $endDate, roomId: $roomId) {
      id
      userId
      roomId
      date
    }
  }
`;

export const GetPassedSessionBoulder = gql`
  query getSessionsBouldersBySessions($sessionId: Int!) {
    sessionbouldersbysession(sessionId: $sessionId) {
      boulderId
    }
  }
`;

export const GetActiveSession = gql`
  query GetActiveSession($roomId: Int!) {
    activesession(roomId: $roomId) {
      id
      startDate
      userId
      sessionboulders {
        id
        status
        iscoaching
        boulderId
      }
    }
  }
`;

export const CreateSession = gql`
  mutation CreateSession($roomId: Int!, $startDate: DateTime) {
    createSession(createSessionData: { roomId: $roomId, startDate: $startDate }) {
      id
      startDate
      sessionboulders {
        id
        status
        iscoaching
        boulderId
      }
    }
  }
`;

export const GetSessionById = gql`
  query GetSessionById($id: Int!) {
    session(id: $id) {
      id
      roomId
      startDate
      sessionboulders {
        id
        status
        iscoaching
        boulderId
      }
    }
  }
`;
