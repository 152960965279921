import { useLogged } from '../../redux/auth/hooks';
import { useCoaching } from '../../redux/coaching/hooks';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useAudits, useBoulderValidation } from '../../redux/audits/hooks';
import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useBoulder, useSectorBoulders, useSectors } from '../../redux/boulders/hooks';

import Icon from '../../atoms/Icon/Icon';
import { IonPage, IonButton, IonLoading } from '@ionic/react';
import BoulderNob from '../../components/BoulderNob/BoulderNob';
import MovableImage from '../../atoms/MovableImage/MovableImage';
import BlocDetailsModal from '../Bloc/partials/BlocDetailsModal/BlocDetailsModal';

import { isMobile } from 'react-device-detect';
import { Vibration } from '@awesome-cordova-plugins/vibration';

import '../Bloc/Bloc.scss';
import ROUTES from '../../routes/constants';
import IconTypes from '../../types/IconTypes';
import AuditTypes from '../../types/AuditTypes';
import getBoulderColor from '../../utils/getBoulderColor';
import BoulderValue from './BoulderValue';
import { trackEvent } from '../../providers/tracker';

export interface ModalContent {
  blocInfo: boolean;
  blocLocation: boolean;
  blocDefi: boolean;
  blocValidation: boolean;
  blocColor: boolean;
}

const BRAVO = require('../../assets/img/bravo.png');

const Sector: React.FC<any> = () => {
  // Refs
  const timer = useRef<NodeJS.Timeout | null>(null);
  const bravoRef = useRef<NodeJS.Timeout | null>(null);

  // Hooks
  const { logged } = useLogged();
  const { sectorId } = useParams();
  const { room } = useSelectedRoom();
  const { clearSession } = useCoaching();
  const [auditTemp, setAuditTemp] = useState<string>();
  const { requestGetSectors, sectorList } = useSectors();

  const [isColorDpOpen, setColorDpStatus] = useState(false);

  // Props States
  const navigate = useNavigate();
  const location = useLocation();

  // Props Memos
  const {
    wall,
    boulderId: startingBoulderId,
    coach,
    colorFilters,
    tagFilters,
    intensityFilters,
    prev,
  } = useMemo(
    () => ({
      boulderId: location.state?.boulderId,
      wall: location.state?.wall,
      coach: location.state?.coach,
      colorFilters: location.state?.colorFilters || [],
      tagFilters: location.state?.tagFilters || [],
      intensityFilters: location.state?.intensityFilters || [],
      prev: location.state?.prev || false,
    }),
    [location.state]
  );

  // Props Hooks
  const sector = useSectorBoulders(sectorId);

  // Boulder management
  const boulderToUse = useMemo(() => {
    return sector?.boulders.filter((boulder) => {
      if (
        (!colorFilters.length ||
          (colorFilters.length && colorFilters.includes(boulder.color.id))) &&
        (!intensityFilters.length ||
          (intensityFilters.length && intensityFilters.includes(boulder.intensity.id)))
      ) {
        const boulderTags = boulder.bouldertags.map((tag) => tag.tag.id);
        const isHere = boulderTags.some((tagId) => {
          return tagFilters.includes(tagId);
        });

        if (tagFilters.length && !isHere) return null;
        return boulder;
      }
      return undefined;
    });
  }, [colorFilters, intensityFilters, sector?.boulders, tagFilters]);

  const boulders = useMemo(() => {
    return [...boulderToUse].sort((a, b) => (a.color.id < b.color.id ? -1 : 1));
  }, [boulderToUse]);

  const startingBoulderIndex = useMemo(
    () =>
      startingBoulderId ? boulders.findIndex((boulder) => boulder.id === startingBoulderId) : -1,
    [boulders, startingBoulderId]
  );

  const startingIndex = useMemo(() => {
    if (startingBoulderIndex >= 0) return startingBoulderIndex;
    return prev ? boulders.length - 1 : 0;
  }, [boulders.length, prev, startingBoulderIndex]);

  const [index, setIndex] = useState(startingIndex);
  const boulder: any = useBoulder(boulders?.[index]?.id);

  // Hooks
  const t = useLocalize();
  const { audits, sendClap, sendIncrementation } = useAudits(boulder?.id);
  const { sendSingleValidation, unvalidateBoulder } = useBoulderValidation();

  // State
  const [clap, setClap] = useState<number>(0);
  const [classNm, setClassNm] = useState<string>();
  const [_, setShowFail] = useState<boolean>(false);
  const [showTop, setShowTop] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [breakpoint, setBreakpoints] = useState<number>(0.25);
  const [isPanelOpen, setOpenPanel] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<ModalContent>({
    blocInfo: false,
    blocLocation: false,
    blocDefi: false,
    blocValidation: false,
    blocColor: false,
  });

  // Memos
  const { color, colorRgba } = useMemo(
    () => getBoulderColor(boulder?.color?.name),
    [boulder?.color?.name]
  );
  const dateTimeFormat = useMemo(() => {
    const date = new Date(boulder?.createDate);
    if (date && boulder?.createDate) {
      return new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: 'short',
      }).format(date);
    } else {
      return null;
    }
  }, [boulder]);

  // Handlers
  const filterBoulders = useCallback(
    (selectedTags, selectedColors, selectedIntensities, reset) => {
      const tagIds = selectedTags.length ? selectedTags.length : null;
      const colorIds = selectedColors.length ? selectedColors : null;
      const intensityIds = selectedIntensities.length ? selectedIntensities : null;
      requestGetSectors(room?.id, wall?.id, colorIds, tagIds, intensityIds, reset);
    },
    [requestGetSectors, room?.id, wall?.id]
  );

  const handleAuditTemp = useCallback((value: string) => {
    setAuditTemp(value);
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      setAuditTemp(undefined);
    }, 20000);
    return () => timer.current && clearTimeout(timer.current);
  }, []);

  const handleModal = useCallback(
    (key: string, initBreakpoint: number | null) => {
      trackEvent('block_modal_open', { modal: key });
      if (key === 'blocColor' && coach) return;
      if (initBreakpoint) setBreakpoints(initBreakpoint);
      else if (key === 'blocColor') setBreakpoints(0.5);
      else setBreakpoints(0.25);
      setShowModal(true);
      setModalContent((prevState: any) => ({
        ...prevState,
        [key]: true,
      }));
    },
    [coach]
  );

  const handleTop = useCallback(
    (flash: boolean) => {
      if (isMobile) Vibration.vibrate(1000);
      setShowFail(false);
      if (logged && boulder?.id && boulder?.room?.id) {
        if (flash) {
          trackEvent('block_flash', { blockId: boulder.id });
          sendSingleValidation(boulder.id, boulder.room.id, false, 'FLASH');
          handleAuditTemp('FLASH');
        } else {
          trackEvent('block_validate', { blockId: boulder.id });
          sendSingleValidation(boulder.id, boulder.room.id, false, 'TOP');
          handleAuditTemp('TOP');
        }
      }
      setShowTop(true);
      if (bravoRef.current) clearTimeout(bravoRef.current);
      if (logged) {
        bravoRef.current = setTimeout(() => setShowTop(false), 2000);
      }
    },
    [boulder?.id, boulder?.room?.id, handleAuditTemp, sendSingleValidation, logged]
  );

  const handleUnvalidate = useCallback(() => {
    if (logged && boulder?.id && boulder?.room?.id) {
      trackEvent('block_unvalidate', { blockId: boulder.id });
      unvalidateBoulder(boulder.id, boulder.room.id);
      setAuditTemp(undefined);
    }
    setShowTop(false);
    setShowFail(false);
  }, [boulder?.id, boulder?.room?.id, logged, unvalidateBoulder]);

  const handleFail = useCallback(() => {
    handleAuditTemp('FAIL');
    if (logged && boulder?.id && boulder?.room?.id)
      sendSingleValidation(boulder.id, boulder.room.id, false, 'FAIL');
  }, [boulder?.id, boulder?.room?.id, handleAuditTemp, sendSingleValidation, logged]);

  const handlePanel = useCallback(() => {
    setOpenPanel(!isPanelOpen);
  }, [isPanelOpen]);

  const hideTop = useCallback(() => {
    setShowTop(false);
    setShowFail(false);
    setOpenPanel(false);
  }, []);

  const hideModal = useCallback(() => {
    setShowModal(false);
    setModalContent({
      blocInfo: false,
      blocLocation: false,
      blocDefi: false,
      blocValidation: false,
      blocColor: false,
    });
  }, []);

  const handleClap = useCallback(
    (value) => {
      if (value > 0 && logged) {
        sendClap(boulder?.id, value);
      }
    },
    [boulder?.id, sendClap, logged]
  );

  const handleClose = useCallback(() => {
    hideTop();
    if (location?.state?.goBackTo) {
      navigate(location.state.goBackTo, {
        state: {
          room: boulder?.room,
          wall: wall || undefined,
          colorFilters: colorFilters,
          tagFilters: tagFilters,
          intensityFilters: intensityFilters,
        },
      });
    } else if (location?.pathname?.includes('/sector/')) {
      navigate(ROUTES.ROOM);
    } else if (location?.pathname?.includes('/bloc/')) {
      navigate(ROUTES.TRAINING);
    } else {
      navigate(-1);
    }
    setAuditTemp(undefined);
    clearSession();
  }, [
    hideTop,
    location?.state?.goBackTo,
    location?.pathname,
    clearSession,
    navigate,
    boulder?.room,
    wall,
    colorFilters,
    tagFilters,
    intensityFilters,
  ]);

  const handleShowColors = useCallback(() => setColorDpStatus(true), []);
  const handleCloseDpColors = useCallback(() => setColorDpStatus(false), []);

  const handleColorSelect = useCallback(
    (id: number) => {
      if (id !== boulder?.id) {
        let i = boulders.findIndex((b) => b.id === id);
        if (i > -1) {
          let newBoulder = boulders.find((b) => b.id === id);
          trackEvent('block_color', {
            sectorId: boulder?.sectorId,
            block: { id: boulder?.id, color: boulder?.color },
            newBlock: { id: newBoulder.id, color: newBoulder.color },
          });
          setIndex(i);
        }
      }
    },
    [boulder, boulders]
  );

  // Effects
  useEffect(() => {
    if (boulder?.id && logged) {
      sendIncrementation(boulder.id, AuditTypes.READ);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boulder?.id, logged]);

  useEffect(() => {
    if (audits) {
      if (audits[AuditTypes.CLAP]) {
        setClap(audits[AuditTypes.CLAP]);
      } else setClap(0);
    }
  }, [audits]);

  useEffect(() => {
    setIndex(startingIndex);
  }, [colorFilters, startingIndex]);

  useEffect(() => {
    hideTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const goToSector = useCallback(
    (id: number, prev: boolean) => {
      navigate(`${ROUTES.SECTOR}/${id}`, {
        state: {
          goBackTo: location?.state?.goBackTo,
          wall,
          coach: false,
          colorFilters: colorFilters,
          tagFilters: tagFilters,
          intensityFilters: intensityFilters,
          sectorIds: sectorList,
          prev,
        },
      });
    },
    [navigate, location, wall, colorFilters, tagFilters, intensityFilters, sectorList]
  );

  const nextBloc = useCallback(() => {
    hideTop();
    if (index < boulders.length - 1) {
      setClap(0);
      setIndex(index + 1);
      trackEvent('block_next', { sectorId: boulder?.sectorId, blockId: boulder?.id });
    } else if (
      index === boulders.length - 1 &&
      sectorList.indexOf(Number(sectorId)) < sectorList.length - 1
    ) {
      trackEvent('block_next', { sectorId: sectorList[sectorList.indexOf(Number(sectorId)) + 1] });
      setIndex(0);
      goToSector(sectorList[sectorList.indexOf(Number(sectorId)) + 1], false);
    }
    if (sectorList.indexOf(Number(sectorId)) === sectorList.length - 2) {
      filterBoulders(tagFilters, colorFilters, intensityFilters, true);
    }
    setAuditTemp(undefined);
  }, [
    hideTop,
    index,
    boulders.length,
    sectorList,
    sectorId,
    boulder?.sectorId,
    boulder?.id,
    goToSector,
    filterBoulders,
    tagFilters,
    colorFilters,
    intensityFilters,
  ]);

  const prevBloc = useCallback(() => {
    hideTop();
    if (index > 0) {
      setClap(0);
      setIndex(index - 1);
      trackEvent('block_next', { sectorId: boulder?.sectorId, blockId: boulder?.id });
    } else if (index === 0 && sectorList.indexOf(Number(sectorId)) > 0) {
      goToSector(sectorList[sectorList.indexOf(Number(sectorId)) - 1], true);
      trackEvent('block_previous', {
        sectorId: sectorList[sectorList.indexOf(Number(sectorId)) - 1],
      });
      setIndex(0);
    }
    setAuditTemp(undefined);
  }, [boulder?.id, boulder?.sectorId, goToSector, hideTop, index, sectorId, sectorList]);

  const handleRegister = useCallback(() => {
    trackEvent('block_login');
    hideTop();
    localStorage.removeItem('isOnboarded');
    localStorage.removeItem('skippedOnboarding');
    window.location.href = '/';
  }, [hideTop]);

  useEffect(() => {
    if (showTop) setClassNm('animation-bounce');
    else setClassNm('');
  }, [showTop]);

  return (
    <IonPage>
      <IonLoading isOpen={!boulder?.validationConditions} spinner='crescent' />
      {isColorDpOpen && (
        <div className='ShowDpColors' onClick={handleCloseDpColors}>
          <div className='box'>
            <BoulderValue boulder={boulder} onClick={handleColorSelect} key={boulder.id} />
            {boulders.map((b) =>
              b.id === boulder.id ? null : (
                <BoulderValue boulder={b} onClick={handleColorSelect} key={b.id} />
              )
            )}
          </div>
        </div>
      )}
      {!!boulder && (
        <div className='bloc_content'>
          <div className='boulder_header'>
            {boulder.value !== undefined && (
              <div
                style={{ backgroundColor: color }}
                className='boulder_value'
                onClick={boulders.length > 1 ? handleShowColors : undefined}
              >
                {t('boulder_value', { value: boulder.value?.toFixed() })}
              </div>
            )}
            <div className='boulder_title'>
              <span className='daily_date'>{dateTimeFormat}</span>
              <div className='dot'></div>
              <span>{boulder?.room?.name}</span>
              <div className='dot'></div>
              <span>{boulder?.sector?.name}</span>
            </div>
            <Icon icon={IconTypes.CLOSE_CIRCLE} className='close_icon' onClick={handleClose} />
          </div>
          {!!boulder.validationConditions && (
            <BoulderNob
              showModal={showModal}
              modalContent={modalContent}
              setModal={handleModal}
              onTop={handleTop}
              onFail={handleFail}
              invalidateBloc={handleUnvalidate}
              setPanel={handlePanel}
              isPanelOpen={isPanelOpen}
              addClap={handleClap}
              clap={clap}
              totalClaps={boulder?.claps}
              color={color}
              colorRgba={colorRgba}
              boulder={boulder}
              hideTop={hideTop}
              nextBloc={nextBloc}
              prevBloc={prevBloc}
              disableNext={
                index === boulders.length - 1 &&
                (!sectorList.length ||
                  sectorList.indexOf(Number(sectorId)) === sectorList.length - 1)
              }
              disablePrev={
                (!sectorList.length || sectorList.indexOf(Number(sectorId)) === 0) && index === 0
              }
              auditTemp={auditTemp}
            />
          )}
          <BlocDetailsModal
            isOpen={showModal}
            onDismiss={hideModal}
            isCoach={coach}
            boulders={boulders}
            boulder={boulder}
            modalContent={modalContent}
            onBlocChange={setIndex}
            breakpoint={breakpoint}
          />
          {showTop && (
            <div className={`topContainer sector ${classNm}`} id='topContainer'>
              {logged && <img src={BRAVO} alt='bravo' className='bravoIcon' />}
              {!logged && (
                <div className='topContent'>
                  <div className='NoTokenTitle'>{t('register_alert_top')}</div>
                  <div className='NoTokenDescription'>{t('register_alert_description')}</div>
                  <IonButton onClick={handleRegister}>{t('register')}</IonButton>
                  <div className='topTick' />
                </div>
              )}
            </div>
          )}
          <MovableImage
            url={boulder?.picture}
            alt='background'
            className='bloc_picture'
            handleClick={hideTop}
          />
          {!boulder?.picture && <div className='bloc_picture empty' />}
        </div>
      )}
    </IonPage>
  );
};

export default Sector;
