import room from './room.interface';
import sector from './sector.interface';
import wall from './wall.interface';

export const months = [
  { description: 'Janvier' },
  { description: 'Février' },
  { description: 'Mars' },
  { description: 'Avril' },
  { description: 'Mai' },
  { description: 'Juin' },
  { description: 'Juillet' },
  { description: 'Aout' },
  { description: 'Septembre' },
  { description: 'Octobre' },
  { description: 'Novembre' },
  { description: 'Décembre' },
];

export const lastYears = (num: number) => {
  const yearsTab: number[] = [];
  const startYear = new Date().getFullYear();
  for (let i = startYear; i > startYear - num; i--) {
    yearsTab.push(i);
  }
  return yearsTab.map((year: number) => ({ description: year.toString() }));
};

const monthMap = {
  '1': 'Janv',
  '2': 'Févr',
  '3': 'Mars',
  '4': 'Avr',
  '5': 'Mai',
  '6': 'Juin',
  '7': 'Juill',
  '8': 'Août',
  '9': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Déc',
};

export const dateConfig = {
  date: {
    format: 'DD',
    caption: 'Day',
    step: 1,
  },
  month: {
    format: (value: any) => monthMap[value.getMonth() + 1],
    caption: 'Mon',
    step: 1,
  },
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
};

export const resetRoom: room = {
  id: 0,
  name: 'Toutes les salles',
  plan: '',
  walls: [],
};

export const resetWall: wall = {
  id: 0,
  name: 'Toutes les zones',
  roomId: 0,
  sectors: [],
};

export const resetSector: sector = {
  id: 0,
  status: 2,
  name: 'Choisir',
  picture: '',
  wallId: 0,
  plan: '',
  boulders: [],
  creationDate: new Date(),
};
