export const addAudits = (state, { audits, _boulderId }) => {
  let toAdd = {};
  if (!!audits) {
    audits.forEach((audit) => {
      if (!toAdd[audit.typeId]) toAdd[audit.typeId] = audit.value;
    });
  }
  state = state.setIn(['audits'], toAdd);
  return state;
};
