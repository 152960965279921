import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as AccountSelectors, actions as AccountActions } from './redux';

export const useAccountAction = () => {
  const dispatch = useDispatch();

  const setUserName = useCallback(
    (userName) => dispatch(AccountActions.setUserName(userName)),
    [dispatch]
  );

  const requestClubs = useCallback(() => dispatch(AccountActions.requestClubs()), [dispatch]);

  const setPassword = useCallback(
    (password) => dispatch(AccountActions.setPassword(password)),
    [dispatch]
  );

  return {
    setUserName,
    requestClubs,
    setPassword,
  };
};

export const useAccountSelector = () => {
  const userName = useSelector((state) => AccountSelectors.userName(state));
  const password = useSelector((state) => AccountSelectors.password(state));

  return {
    userName,
    password,
  };
};

export const useCreateAccount = () => {
  const dispatch = useDispatch();
  const level = useSelector((state) => AccountSelectors.level(state));
  const goals = useSelector((state) => AccountSelectors.goals(state));

  const setLevel = useCallback((level) => dispatch(AccountActions.setLevel(level)), [dispatch]);
  const setGoals = useCallback(
    (goalsType, value) => dispatch(AccountActions.setGoals(goalsType, value)),
    [dispatch]
  );

  const requestCreateAccount = useCallback(
    (accountInfo, callback) =>
      dispatch(AccountActions.requestCreateAccount(accountInfo, callback, level, goals)),
    [dispatch, level, goals]
  );

  return {
    level,
    goals,
    setLevel,
    setGoals,
    requestCreateAccount,
  };
};

export const useOnboardingInfo = () => {
  const dispatch = useDispatch();
  const level = useSelector((state) => AccountSelectors.level(state));
  const goals = useSelector((state) => AccountSelectors.goals(state));

  const addOnboarding = useCallback(
    (callback) => dispatch(AccountActions.addOnboarding(goals, level, callback)),
    [dispatch, goals, level]
  );

  return { addOnboarding };
};

export const useResetPassword = () => {
  const dispatch = useDispatch();

  const resetPassword = useCallback(
    (email, cb) => dispatch(AccountActions.requestResetPassword(email, cb)),
    [dispatch]
  );

  return { resetPassword };
};
