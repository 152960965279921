import { useLocalize } from '../../redux/translation/localize';
import { useCreateBoulderVideo } from '../../redux/boulderVideo/hooks';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { IonButton, IonSpinner } from '@ionic/react';
import Icon from '../../atoms/Icon/Icon';
import Header from '../../components/VideoHeader/VideoHeader';

import shareToApp from '../../utils/shareToApp';
import { ellipsisHorizontal, pauseCircle, playCircle } from 'ionicons/icons';

import './VideoSharing.scss';
import { isIOS } from 'react-device-detect';

interface Props {
  video: Blob;
  path?: string | null;
  boulderId: number;
  onBack: () => void;
  onClose: () => void;
  onLoading?: (val: boolean) => void;
}

enum ShareType {
  SOCIALS = 'socials',
  CD = 'climbing_district',
}

const SocialButton: React.FC<{
  icon?: string;
  onClick: (value?: string) => void;
  value?: string;
}> = ({ value, icon, onClick }) => {
  const handleClick = useCallback(() => onClick(icon ? value : undefined), [onClick, icon, value]);

  if (!icon) return <IonButton onClick={handleClick}>{value}</IonButton>;
  return <Icon className={value} icon={icon} onClick={handleClick} />;
};

const VideoSharing: React.FC<Props> = ({ video, path, boulderId, onBack, onClose, onLoading }) => {
  const t = useLocalize();
  const { createVideo } = useCreateBoulderVideo(boulderId);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const statusIcon = useRef<HTMLDivElement | null>(null);

  const [playing, setPlaying] = useState(false);
  const [shareType, setShareType] = useState<ShareType>(ShareType.CD);
  const [currentVideo, setCurrentVideo] = useState<string | undefined>();
  const [videoState, setVideoState] = useState<HTMLVideoElement | null>(null);

  // Button Handlers
  const handleShareSocial = useCallback(
    (appName?: string) => shareToApp({ appName }, path || video, onLoading),
    [path, video, onLoading]
  );

  const handleBack = useCallback(
    () => (shareType === ShareType.CD ? onBack() : setShareType(ShareType.CD)),
    [onBack, shareType]
  );

  const handleDone = useCallback(() => {
    videoState?.pause();
    createVideo(video, JSON.parse(localStorage.getItem('video_info_temp') || ''), onClose);
    localStorage.removeItem('video_info_temp');
  }, [createVideo, onClose, video, videoState]);

  // Video Handlers
  const handleClickScreen = useCallback(() => {
    if (videoState) {
      setPlaying((prev) => {
        if (statusIcon.current) {
          statusIcon.current.style.display = 'flex';
          statusIcon.current.style.opacity = '1';

          if (timerRef.current) clearTimeout(timerRef.current);
          timerRef.current = setTimeout(() => {
            if (statusIcon.current) statusIcon.current.style.opacity = '0';
            timerRef.current = setTimeout(
              () => statusIcon.current && (statusIcon.current.style.display = 'none'),
              500
            );
          }, 1000);
        }
        prev ? videoState?.pause() : videoState?.play();
        return !prev;
      });
    }
  }, [videoState]);

  const handleDurationChanged = useCallback(
    (ev: any) => {
      if (!!ev.target?.duration && ev.target.duration !== Infinity) {
        onLoading?.(false);
        ev.target.loop = true;
        ev.target.play();

        setVideoState(ev.target);
        setPlaying(true);
      } else if (ev.target.duration === Infinity || isNaN(Number(ev.target.duration))) {
        ev.target.currentTime = 1e101;
      }
    },
    [onLoading]
  );

  useLayoutEffect(() => {
    const objectUrl: string = URL.createObjectURL(video);
    setCurrentVideo(objectUrl);
  }, [video]);

  if (isIOS) {
    return (
      <div id='VideoSharing' className='loading'>
        <IonSpinner name='crescent' color='primary' />
      </div>
    );
  }
  return (
    <div id='VideoSharing'>
      <div className='videoBox'>
        <video
          playsInline
          controls={false}
          preload='metadata'
          src={currentVideo}
          onClick={handleClickScreen}
          onDurationChange={handleDurationChanged}
        />
        <div className='contents socialSide'>
          <span className='title'>{t('share_socials')}</span>
          <div className='socials'>
            <SocialButton icon={ellipsisHorizontal} onClick={handleShareSocial} />
          </div>
        </div>
      </div>

      <Header playing={playing} onBack={handleBack} onClose={handleDone} />

      <div className='videoStatusIcon' ref={statusIcon}>
        <Icon icon={playing ? playCircle : pauseCircle} />
      </div>
    </div>
  );
};

export default VideoSharing;
