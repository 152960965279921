import { client } from '../../App';
import { all, takeLatest, put, race, take, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { actions as AppActions } from '../app/redux';
import { actions as OpeningsActions } from './redux';
import { actions as BouldersActions } from '../boulders/redux';
import { actions as MediasActions, selectors as MediasSelector } from '../medias/redux';

import { getMimeType } from '../../utils/fileUtils';
import { MountBoulder, UpdateBoulder } from '../../GraphQL/GetBoulders';
import { DismantelSector, AddSectorPicture } from '../../GraphQL/GetSector';

export default class OpeningsSagas {
  static *dismantleSector({ payload }) {
    try {
      const { sectorId } = payload;
      const result = yield client.mutate({
        fetchPolicy: 'network-only',
        mutation: DismantelSector,
        variables: { sectorId },
      });
      if (result?.data) {
        console.info('dismantled', result.data);
        yield put(BouldersActions.getSectorById(sectorId));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *addSectorPicture({ payload }) {
    try {
      const { id, picture } = payload;
      const result = yield client.mutate({
        fetchPolicy: 'network-only',
        mutation: AddSectorPicture,
        variables: { id, picture },
      });
      if (result?.data) {
        console.info('Picture added', result.data);
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *mountBoulder({ payload }) {
    const {
      onDate,
      status,
      roomId,
      wallId,
      sectorId,
      colorId,
      intensityId,
      tagIds,
      media,
      textHelp,
    } = payload.params;
    try {
      let { picture } = payload.params;
      yield put(AppActions.setAppLoader(true));
      yield put(OpeningsActions.setIsPublishing(true));

      const extension = media ? media.split('.').reverse()[0] : null;
      const { type } = extension ? getMimeType(extension.toLowerCase()) : { type: undefined };

      if (!picture.startsWith('https://')) {
        yield put(MediasActions.requestUploadMedia(picture));
        const [success] = yield race([
          take(MediasActions.setUploadSuccess.getType()),
          take(MediasActions.setUploadFail.getType()),
        ]);
        if (success) picture = yield select(MediasSelector.uploadedMedia);
      }

      const variables = {
        onDate,
        status,
        roomId,
        wallId,
        sectorId,
        colorId,
        intensityId,
        tagIds,
        picture,
        mediaHelp: media,
        mediaType: type,
        textHelp,
      };

      const result = yield client.mutate({
        fetchPolicy: 'network-only',
        mutation: MountBoulder,
        variables,
      });
      yield put(AppActions.setAppLoader(false));

      if (!!result?.data) {
        console.info('boulder mounted', result.data);
        yield put(BouldersActions.getSectorById(sectorId));
      }

      yield put(OpeningsActions.setIsPublishing(false));
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
      yield put(AppActions.setAppLoader(false));
    }
  }

  static *updateBoulder({ payload }) {
    try {
      const { sectorId, boulderId, boulderColorId, intensityId, tagIds, media, textHelp } =
        payload.params;
      let { picture } = payload.params;

      yield put(OpeningsActions.setIsPublishing(true));
      const extension = media ? media.split('.').reverse()[0] : null;
      const { type } = extension ? getMimeType(extension.toLowerCase()) : { type: undefined };

      if (!picture.startsWith('https://')) {
        yield put(MediasActions.requestUploadMedia(picture));
        const [success] = yield race([
          take(MediasActions.setUploadSuccess.getType()),
          take(MediasActions.setUploadFail.getType()),
        ]);
        if (success) picture = yield select(MediasSelector.uploadedMedia);
      }

      const variables = {
        id: boulderId,
        colorId: boulderColorId,
        intensityId,
        tagIds,
        picture,
        mediaHelp: media,
        mediaType: type,
        textHelp,
      };

      const result = yield client.mutate({
        fetchPolicy: 'network-only',
        mutation: UpdateBoulder,
        variables,
      });
      if (!!result?.data) {
        console.info('boulder updated', result.data);
        yield put(BouldersActions.getSectorById(sectorId));
      }
      yield put(OpeningsActions.setIsPublishing(false));
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(OpeningsActions.dismantleSector.getType(), OpeningsSagas.dismantleSector),
      takeLatest(OpeningsActions.mountBoulder.getType(), OpeningsSagas.mountBoulder),
      takeLatest(OpeningsActions.updateBoulder.getType(), OpeningsSagas.updateBoulder),
      takeLatest(OpeningsActions.addSectorPicture.getType(), OpeningsSagas.addSectorPicture),
    ]);
  }
}
