import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

import { createSelector } from 'reselect';
import * as reducers from './reducers';

const initialState = Immutable({
  roomIds: [],
  roomById: {},

  selectedRoomId: null,
  selectedWallId: 0,
  selectedSectorId: 0,
});

export const actions = {
  requestRooms: createAction('request all room'),
  requestRoomById: createAction('request room by id', (roomId) => ({ roomId })),
  setSelectedRoomId: createAction('setSelected a room', (id) => ({ id })),
  setSelectedWallId: createAction('setSelected a wall', (id) => ({ id })),
  setSelectedSectorId: createAction('setSelected a sector', (id) => ({ id })),
  addRooms: createAction('add rooms', (rooms) => ({ rooms })),
  addRoom: createAction('add rooms', (room) => ({ room })),
};

export const reducer = createReducer(
  {
    [actions.setSelectedRoomId]: (state, { id }) => state.setIn(['selectedRoomId'], id),
    [actions.setSelectedWallId]: (state, { id }) => state.setIn(['selectedWallId'], id),
    [actions.setSelectedSectorId]: (state, { id }) => state.setIn(['selectedSectorId'], id),
    [actions.addRooms]: reducers.addRooms,
    [actions.addRoom]: reducers.addRoom,
  },
  initialState
);

export const createSelectorRoomById = () =>
  createSelector(
    (state, roomId) => state.rooms.getIn(['roomById', roomId]),
    (_) => _
  );

export const selectors = {
  roomIds: (state) => state.rooms.roomIds,
  roomByIds: (state) => state.rooms.roomById,
  roomById: (state, id) => state.rooms.roomById[id],
  selectedRoomId: (state) => state.rooms.selectedRoomId,
  selectedWallId: (state) => state.rooms.selectedWallId,
  selectedSectorId: (state) => state.rooms.selectedSectorId,
};
