import React, { useMemo, useState } from 'react';
import moment from 'moment';

import DayCard from '../DayCard/DayCard';

import './Calendar.scss';
import Alert from '../Alert/Alert';
export interface CalendarDay {
  day: number;
  date: number;
  isThisMonth: boolean;
  hasSession: boolean;
  hasVisit: boolean;
  updateDate: Date;
  isPassed: boolean;
  isToday: boolean;
  sessionId: number;
}

interface Props {
  startDate: Date;
  endDate: Date;
  sessions: any;
  visits: any;
}

const Calendar: React.FC<Props> = ({ startDate, endDate, sessions, visits }) => {
  const sessionsDates = useMemo(
    () =>
      sessions
        .filter((session) => session.sessionboulders.length)
        .map((session: any) => {
          return new Date(session.startDate);
        }),
    [sessions]
  );

  const start = useMemo(() => {
    const day = startDate.getDay();
    const startDateCopy = new Date(startDate);
    if (day === 0) return new Date(startDateCopy.setDate(startDateCopy.getDate() - 6));
    if (day === 1) return startDate;
    return new Date(startDateCopy.setDate(startDateCopy.getDate() - (day - 1)));
  }, [startDate]);

  const end = useMemo(() => {
    const day = endDate.getDay();
    const endDateCopy = new Date(endDate);
    if (day === 0) return endDate;
    return new Date(endDateCopy.setDate(endDateCopy.getDate() + (7 - day)));
  }, [endDate]);

  const currentMonth = startDate.getMonth();

  const visitesDates = useMemo(() => visits?.map((visit: any) => new Date(visit.date)), [visits]);

  const dateArray = useMemo(() => {
    const arr: any[] = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = new Date(start);
    const endDate = new Date(end);
    while (date <= endDate) {
      arr.push({
        updateDate:
          sessionsDates.find(
            (sessionDate) =>
              date.getFullYear() === sessionDate.getFullYear() &&
              date.getMonth() === sessionDate.getMonth() &&
              date.getDate() === sessionDate.getDate()
          ) || new Date(date),
        day: date.getDay(),
        date: date.getDate(),
        isThisMonth: date.getMonth() === currentMonth,
        hasSession: sessionsDates.some(
          (sessionDate) =>
            date.getFullYear() === sessionDate.getFullYear() &&
            date.getMonth() === sessionDate.getMonth() &&
            date.getDate() === sessionDate.getDate()
        ),
        hasVisit: visitesDates.some(
          (visitesDate) =>
            date.getFullYear() === visitesDate.getFullYear() &&
            date.getMonth() === visitesDate.getMonth() &&
            date.getDate() === visitesDate.getDate()
        ),
        isPassed: date <= today,
        isToday: moment(today).format('MMM Do YY') === moment(date).format('MMM Do YY'),
        sessionId: sessions.find(
          (session: any) =>
            moment(session?.startDate).format('MMM Do YY') === moment(date)?.format('MMM Do YY')
        )?.id,
      });
      date.setDate(date.getDate() + 1);
    }
    return arr;
  }, [currentMonth, end, sessions, sessionsDates, start, visitesDates]);

  const [showAlert, setShowAlert] = useState<boolean>(false);

  return (
    <div className='calendarContainer'>
      {dateArray.map((item: CalendarDay, index: number) => (
        <DayCard key={index} day={item} setShowAlert={setShowAlert} />
      ))}
      <Alert
        onAlertChange={setShowAlert}
        message='selectionne une salle avant de faire cette action'
        title=''
        actionName='ok'
        action={() => {
          setShowAlert(false);
        }}
        position=' 40%'
        display={showAlert}
      />
    </div>
  );
};

export default Calendar;
