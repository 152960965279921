import { DatetimeCustomEvent, DatetimePresentation } from '@ionic/core';
import { IonButton, IonDatetime, IonModal } from '@ionic/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './DateSelector.scss';
import { parseISO, formatISO } from 'date-fns';
import { useLocalize } from '../../redux/translation/localize';

type PropsDateSelector = {
  date: Date;
  setDate: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  title?: string;
  presentation: DatetimePresentation;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const DateSelector = ({
  date,
  setDate,
  minDate,
  maxDate,
  presentation,
  isOpen,
  setIsOpen,
}: PropsDateSelector) => {
  const t = useLocalize();

  const tempId = useRef(`temp_date_${Number(Date.now())}`);

  const [dateIntern, setDateIntern] = useState<Date>(date);

  const [min, max] = useMemo(() => {
    const min = minDate ? formatISO(minDate) : undefined;
    const max = maxDate ? formatISO(maxDate) : undefined;
    return [min, max];
  }, [minDate, maxDate]);

  const value = useMemo(() => formatISO(dateIntern), [dateIntern]);

  const handleDateChange = useCallback((e: DatetimeCustomEvent) => {
    const value = parseISO(e.detail.value as string);
    const date = new Date(value.getFullYear(), value.getMonth(), 1);
    if (date.toString() === 'Invalid Date') return;
    setDateIntern(date);
  }, []);

  const handleExit = useCallback(() => {
    setDate(dateIntern);
    setIsOpen(false);
  }, [setIsOpen, dateIntern, setDate]);

  const handleControl = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  }, []);

  // Secret style because shadow roots
  useEffect(() => {
    setTimeout(() => {
      const parent = document.getElementById(tempId.current);

      if (parent) {
        const children = parent.shadowRoot
          ?.querySelector('ion-picker-internal')
          ?.getElementsByTagName('ion-picker-column-internal');

        for (let i = 0; i < (children?.length || 0); i++) {
          children?.[i]?.shadowRoot?.prepend(
            Object.assign(document.createElement('style'), {
              innerText: `
                   .picker-item-active {
                      color: rgb(49, 114, 255) !important;
                    }
                  `,
            })
          );
        }
      }
    }, 250);
  }, [isOpen]);

  return (
    <IonModal
      isOpen={isOpen}
      className='dateSelectorModal'
      backdropDismiss={false}
      onClick={handleControl}
    >
      <div className='dateSelectorContainer'>
        <IonButton className='dateSelectorOkButton' onClick={handleExit}>
          {t('OK')}
        </IonButton>
        <IonDatetime
          id={tempId.current}
          min={min}
          max={max}
          preferWheel
          value={value}
          className='dateSelector'
          presentation={presentation}
          onAbort={handleExit}
          onIonCancel={handleExit}
          onIonChange={handleDateChange}
        ></IonDatetime>
      </div>
    </IonModal>
  );
};

export default DateSelector;
