import { IonContent, IonLoading, IonPage, IonSpinner, isPlatform } from '@ionic/react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useLocalize } from '../../redux/translation/localize';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCreateBoulderVideo } from '../../redux/boulderVideo/hooks';

import Camera from '../../templates/Camera/Camera';
import VideoTrim from '../../templates/VideoTrim/VideoTrim';
import VideoEditor from '../../templates/VideoEditor/VideoEditor';
import MobileCamera from '../../templates/MobileCamera/MobileCamera';
import VideoSharing from '../../templates/VideoSharing/VideoSharing';

import './VideoCreator.scss';
import { isIOS } from 'react-device-detect';
import ROUTES from '../../routes/constants';
import createFileVideo from '../../utils/createVideoFile';
import { StickerType } from '../../templates/VideoEditor/partial/type';
import { CreatorStatusEnum } from '../../utils/videoCreator/utils/utils';
import { trackEvent } from '../../providers/tracker';

enum STATUS_TYPE {
  VIDEO = 'video',
  SHARING = 'sharing',
}

type ParamsType = { boulderId?: string; sectorId?: string };

const defaultVideoTimers = {
  ready: false,
  startTime: 0,
  endTime: 100,
  duration: 0,
};

const VideoCreator: React.FC = () => {
  const isNavigating = useRef<boolean>(false);

  const { boulderId: bId, sectorId }: ParamsType = useParams();
  const boulderId = useMemo(() => (bId ? parseInt(bId) : undefined), [bId]);

  const t = useLocalize();
  const navigate = useNavigate();
  const location = useLocation();
  const { isFetching, createVideo } = useCreateBoulderVideo(boulderId);

  const [trim, setTrim] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Blob | null>(null);
  const [video, setVideo] = useState<Blob | null>(null);
  const [path, setPath] = useState<string | null>(null);
  const [status, setStatus] = useState(STATUS_TYPE.VIDEO);
  const [stickers, setStickers] = useState<StickerType[]>([]);
  const [videoTimers, setVideoTimers] = useState(defaultVideoTimers);
  const [videoCacheName, setVideoCacheName] = useState<string | null>(null);
  const [progress, setProgress] = useState<{ progress?: number; status: CreatorStatusEnum } | null>(
    null
  );

  const isDesktop = useMemo(() => isPlatform('desktop') || isPlatform('mobileweb'), []);

  const handleChange = useCallback((video: Blob) => {
    setData(video);
    setVideoCacheName(`video_${Number(Date.now())}`);
  }, []);

  const handleTrim = useCallback((timers: any) => {
    setTrim(false);
    setVideoTimers(timers);
  }, []);

  const handleBack = useCallback(() => {
    if (!isNavigating.current) {
      trackEvent('back', { path: 'video' });
      navigate(`${ROUTES.SECTOR}/${sectorId}`, { state: { boulderId } });
      isNavigating.current = true;
    }
    setTimeout(() => {
      setIsLoading(false);
      setTrim(false);
      setIsLoading(false);
      setData(null);
      setVideo(null);
      setVideoCacheName(null);
      setPath(null);
      setStickers([]);
      setStatus(STATUS_TYPE.VIDEO);
      setVideoTimers(defaultVideoTimers);
      isNavigating.current = false;
    }, 500);
  }, [boulderId, navigate, sectorId]);

  const handleVideoDone = useCallback(
    (params: any) => {
      if (!!data)
        createFileVideo({
          video: data,
          onProgress: (status, progress) => setProgress({ progress, status }),
          onFinish: (video: Blob | null, path: string) => {
            setVideo(video || null);
            setPath(path || null);
            setProgress(null);
            setIsLoading(false);
            if (isIOS) {
              createVideo(
                video,
                JSON.parse(localStorage.getItem('video_info_temp') || ''),
                handleBack
              );
            }
          },
          onLoading: setIsLoading,
          params: {
            ...(params || {}),
            stickers,
          },
        });
      setStatus(STATUS_TYPE.SHARING);
    },
    [createVideo, data, handleBack, stickers]
  );

  const handleClose = useCallback(() => {
    if (status !== STATUS_TYPE.VIDEO) {
      setStatus(STATUS_TYPE.VIDEO);
      setVideo(null);
      setPath(null);
      setVideoCacheName(null);
    } else {
      setData(null);
      setVideoCacheName(null);
      setPath(null);
      setStickers([]);
      setVideoTimers(defaultVideoTimers);
    }
  }, [status]);

  useEffect(() => {
    setTimeout(() => {
      if (!((isLoading || isFetching) && !progress)) {
        const elem: any = document.getElementById('VideoCreatorLoading');
        if (elem) {
          elem.dismiss();
        }
      }
    }, 500);
  }, [isFetching, isLoading, progress]);

  if (!location?.pathname?.includes(ROUTES.RECORD)) {
    return null;
  }
  return (
    <IonPage id='VideoCreator'>
      <IonContent fullscreen scrollY={false}>
        <IonLoading
          id='VideoCreatorLoading'
          isOpen={(isLoading || isFetching) && !progress}
          spinner='bubbles'
        />
        {!!progress && (
          <div className='progressModal'>
            <IonSpinner name='dots' />
            <div>{t('assembling_video')}</div>
          </div>
        )}
        {(isLoading || isFetching) && <div className='fakeBackground' />}
        {status === STATUS_TYPE.VIDEO && (
          <>
            {!data && isDesktop && <Camera onBack={handleBack} onChange={handleChange} />}
            {!data && !isDesktop && (
              <MobileCamera onBack={handleBack} onChange={handleChange} onLoading={setIsLoading} />
            )}
            {!!data && !trim && (
              <VideoEditor
                video={data}
                stickers={stickers}
                onStickersChange={setStickers}
                videoTimers={videoTimers}
                onDone={handleVideoDone}
                onTrim={setTrim}
                onClose={handleClose}
                onLoading={setIsLoading}
                onTimersChanged={setVideoTimers}
              />
            )}
            {!!data && !!trim && (
              <VideoTrim
                video={data}
                name={videoCacheName}
                videoTimers={videoTimers}
                onClose={setTrim}
                onTrimAccept={handleTrim}
                onLoading={setIsLoading}
              />
            )}
          </>
        )}
        {!!video && status === STATUS_TYPE.SHARING && (
          <VideoSharing
            video={video}
            path={path}
            boulderId={Number(boulderId)}
            onBack={handleClose}
            onClose={handleBack}
            onLoading={setIsLoading}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default VideoCreator;
