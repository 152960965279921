const texts = [
  {
    name: 'Basic',
    style: {
      fontFamily: 'Arial',
      fontSize: 36,
      fill: '$colorStyle',
    },
    background: {
      radius: 0,
      opacity: 0,
      fill: 0xffffff,
    },
  },

  {
    name: 'whiteBackground',
    colorIndexReplace: [0, 1],
    style: {
      fontFamily: 'Arial',
      fontSize: 36,
      fill: '$colorStyle',
    },
    background: {
      radius: 10,
      opacity: 1,
      fill: 0xffffff,
    },
  },

  {
    name: 'blackBackground',
    colorIndexReplace: [1, 0],
    style: {
      fontFamily: 'Arial',
      fontSize: 36,
      fill: '$colorStyle',
    },
    background: {
      radius: 10,
      opacity: 1,
      fill: 0x000000,
    },
  },

  {
    name: 'coloredBackground',
    style: {
      fontFamily: 'Arial',
      fontSize: 36,
      fill: 0x000000,
    },
    background: {
      radius: 10,
      opacity: 1,
      fill: '$colorStyle',
    },
  },

  {
    name: 'Neon',
    style: {
      fontFamily: 'Times New Roman',
      fontSize: 36,
      fill: '$colorStyle',
      dropShadow: true,
      dropShadowColor: '$colorStyle',
      dropShadowBlur: 20,
      dropShadowAngle: 0,
      dropShadowDistance: 2,
      fontStyle: 'italic',
    },
    background: {
      radius: 0,
      opacity: 0,
      fill: 0x000000,
    },
  },
  {
    name: 'Sunrise',
    style: {
      fontFamily: 'Arial',
      fontSize: 36,
      fill: ['#ffab00', '#d44b00'],
      fontWeight: 'bold',
    },
    background: {
      radius: 10,
      opacity: 1,
      fill: 0xffffff,
    },
  },
];

export default texts;
