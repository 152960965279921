import React from 'react';

import './ProfilInfo.scss';

interface Props {
  title: string;
  text: string;
}

const ProfilInfo: React.FC<Props> = ({ title, text }) => {
  return (
    <div className='profil_info_main'>
      <div className='profil_info_title'>{title}</div>
      <label className='profil_info_text'>{text || ''}</label>
    </div>
  );
};

export default ProfilInfo;
