import React from 'react';
import { IonSpinner } from '@ionic/react';
import './Loader.scss';

interface Props {
  fullscreen?: boolean;
  style?: any;
}

const Loader: React.FC<Props> = React.memo(({ fullscreen = false, style }) => (
  <div style={style} className={`loaderBox ${fullscreen ? 'fullscreen' : ''}`}>
    <IonSpinner name='crescent' color='primary' />
  </div>
));

export default Loader;
