import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

const initialState = Immutable({
  published: false,
  isPublishing: false,
});

export const actions = {
  dismantleSector: createAction('dismantle sector', (sectorId) => ({ sectorId })),
  addSectorPicture: createAction('add sectors picture', (id, picture) => ({ id, picture })),
  updateBoulder: createAction('update boulder', (params) => ({ params })),
  mountBoulder: createAction('mount boulder', (params) => ({ params })),
  setIsPublishing: createAction('setIsPublishing', (value) => ({ value })),
};

export const reducer = createReducer(
  {
    [actions.setIsPublishing]: (state, { value }) => state.setIn(['isPublishing'], value),
  },
  initialState
);

export const selectors = {
  published: (state) => state.openings.published,
  isPublishing: (state) => state.openings.isPublishing,
};
