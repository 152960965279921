import { all, put, call, delay, takeLatest, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import Response from './Response';
import { actions as TranslationActions } from './redux';
import { selectors as TranslationSelectors } from './redux';

import axios from 'axios';
import moment from 'moment';
require('moment/min/locales.min');

let LoadedLanguages = {};
export default class TranslationSagas {
  static *localizedStaticAsset(forceLang = undefined, retry = 0) {
    try {
      if (retry > 10) {
        return Response.fail(`Too many atemps`);
      }

      const userLang = yield select(TranslationSelectors.lang);
      const lang = forceLang || userLang;
      const path = '/assets/locales/' + lang + '.json?i=' + new Date();

      let result = null;
      try {
        result = yield axios.get(path, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': window.location.origin,
          },
        });
      } catch (err) {
        console.warn(err);
        return Response.fail(`Axios Problem ` + err);
      }
      const status = result && result.status;

      switch (status) {
        case 200:
          try {
            const parsedResult =
              typeof result.data === 'string' ? JSON.parse(result.data) : result.data;

            return Response.success(parsedResult);
          } catch (e) {
            console.warn(`can't parse json ` + e);
            return Response.fail(`can't parse json ` + e);
          }
        case 404:
          return Response.fail('Static asset not found at ' + path);
        default:
          yield delay(1000);
          return yield call(TranslationSagas.localizedStaticAsset, forceLang, retry + 1);
      }
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  }

  static *requestLoadLanguage({ payload }) {
    try {
      let language = payload.lang;
      let loadedLanguage = yield select((state) => state.i18nState.translations);

      if (
        loadedLanguage &&
        Object.keys(loadedLanguage).length > 0 &&
        LoadedLanguages[language] === true
      ) {
        yield put(TranslationActions.loadLanguagesSuccess());
      }

      const result = yield call(TranslationSagas.localizedStaticAsset, language);

      LoadedLanguages[language] = true;

      const translation = result.data;
      moment.locale(translation.moment_locale);
      const translations = yield select((state) => state.i18nState.translations);

      yield put({
        type: 'REDUX_I18N_SET_TRANSLATIONS',
        translations: { ...translations, [language]: translation },
      });
      yield put({ type: 'REDUX_I18N_SET_LANGUAGE', lang: language });
      yield put({ type: 'REDUX_I18N_SET_FORCE_REFRESH', force: true });
      yield put(TranslationActions.loadLanguagesSuccess());
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  }

  static *setLanguage({ payload }) {
    try {
      const { lang } = payload;
      const translations = yield select((state) => state.i18nState.translations);

      if (!translations[lang]) {
        yield put(TranslationActions.requestLoadLanguages(lang));
      } else {
        yield put({ type: 'REDUX_I18N_SET_LANGUAGE', lang });
        yield put({ type: 'REDUX_I18N_SET_FORCE_REFRESH', force: true });
      }
      return null;
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(
        TranslationActions.requestLoadLanguages.getType(),
        TranslationSagas.requestLoadLanguage
      ),
      takeLatest(TranslationActions.setLanguage.getType(), TranslationSagas.setLanguage),
    ]);
  }
}
