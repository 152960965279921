import React, { IframeHTMLAttributes } from 'react';

const YoutubeEmbed: React.FC<IframeHTMLAttributes<HTMLIFrameElement>> = ({
  src,
  title = 'YoutubeEmbed',
  className = '',
  ...props
}) => <iframe {...props} src={src} title={title} className={`youtubeIframe ${className}`} />;

export default YoutubeEmbed;
