import React, { useCallback, useEffect, useState } from 'react';
import { useFilter } from '../../../../redux/filters/hooks';
import { IonButton, IonModal } from '@ionic/react';
import { useLocalize } from '../../../../redux/translation/localize';

import Icon from '../../../../atoms/Icon/Icon';
import FilterCard from '../../../../components/FilterCard/FilterCard';

import './RoomFilters.scss';
import IconTypes from '../../../../types/IconTypes';
import { trackEvent, trackPage } from '../../../../providers/tracker';

const MAXTAG = 3;

interface Props {
  showModal: boolean;
  handleDismiss: Function;
  initialTags: number[];
  sendSelectedTags: Function;
  initialIntensities: number[];
  sendSelectedIntensities: Function;
}

const RoomFilters: React.FC<Props> = ({
  showModal,
  handleDismiss,
  initialTags,
  sendSelectedTags,
  initialIntensities,
  sendSelectedIntensities,
}) => {
  const t = useLocalize();
  const { tags, intensities, getFilters } = useFilter();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<number[]>(initialTags);
  const [selectedIntensities, setSelectedIntensities] = useState<number[]>(initialIntensities);

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTag = useCallback(
    (id: any, name?: string) => {
      trackEvent('room_additional_filter_choose', {
        event_category: 'room',
        event_action: 'filters_choose',
        event_label: name,
      });

      if (selectedTags.includes(id)) {
        const temp = [...selectedTags];
        setSelectedTags(temp.filter((el) => el !== id));
      } else {
        if (selectedTags.length >= MAXTAG) return;
        setSelectedTags((prevState: number[]) => [...prevState, id]);
      }
    },
    [setSelectedTags, selectedTags]
  );

  const addIntensity = useCallback(
    (id: any, name?: string) => {
      trackEvent('room_additional_filter_choose', {
        event_category: 'room',
        event_action: 'filters_choose',
        event_label: name,
      });
      if (selectedIntensities.includes(id)) {
        const temp = [...selectedIntensities];
        trackEvent('filters_choose', { intensity: temp.filter((el) => el !== id) });
        setSelectedIntensities(temp.filter((el) => el !== id));
      } else {
        trackEvent('filters_choose', { intensities: [...selectedIntensities, id] });
        setSelectedIntensities((prevState: number[]) => [...prevState, id]);
      }
    },
    [setSelectedIntensities, selectedIntensities]
  );

  const sendFilters = useCallback(
    (selectedTags, selectedIntensities) => {
      sendSelectedTags(selectedTags);
      sendSelectedIntensities(selectedIntensities);
    },
    [sendSelectedTags, sendSelectedIntensities]
  );

  const resetSelectedFilters = useCallback(() => {
    trackEvent('filters_reset', { tags: [], intensities: [] });
    setSelectedTags([]);
    setSelectedIntensities([]);
  }, []);

  const saveFilter = useCallback(() => {
    sendFilters(selectedTags, selectedIntensities);
    trackEvent('filters_apply', { tags: selectedTags, intensities: selectedIntensities });
    setIsSaved(true);
    handleDismiss();
  }, [handleDismiss, sendFilters, selectedTags, selectedIntensities]);

  const resetFilter = useCallback(() => {
    resetSelectedFilters();
    sendFilters([], []);
    setIsSaved(false);
  }, [sendFilters, setIsSaved, resetSelectedFilters]);

  const handleClose = useCallback(() => {
    if (!isSaved) {
      resetFilter();
    }
    handleDismiss();
  }, [isSaved, handleDismiss, resetFilter]);

  useEffect(() => {
    if (showModal) {
      trackPage('additional_filter');
    }
  }, [showModal]);

  return (
    <IonModal className='filtre_modal' isOpen={showModal}>
      <div className='filterContainer'>
        <div className='filter_header'>
          <div className='filter_title'>
            <span>{t('filter_supp')}</span>
          </div>
          <Icon icon={IconTypes.CLOSE_CIRCLE} className='close_icon' onClick={handleClose} />
        </div>
        <div className='filter_types'>
          <FilterCard
            filterName='Intensité'
            filterTab={intensities}
            filterChange={addIntensity}
            selectedFilter={selectedIntensities}
          />
          <FilterCard
            filterName='Tags'
            filterTab={tags}
            filterChange={addTag}
            selectedFilter={selectedTags}
          />
        </div>
      </div>
      <div className='applyContainer'>
        <IonButton expand='block' className='save_button' onClick={saveFilter}>
          {t('apply')}
        </IonButton>
        <div className='reset_filtre' onClick={resetFilter}>
          <Icon icon={IconTypes.CLOSE_CIRCLE} className='reset_icon' />
          <span>{t('reset')}</span>
        </div>
      </div>
    </IonModal>
  );
};

export default RoomFilters;
