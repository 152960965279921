import React from 'react';
import Icon from '../../../../atoms/Icon/Icon';

// Styles
import './IconButton.scss';

const IconButton: React.FC<any> = ({ icon, iconSelected, isSelected, isDisabled, onClick }) => {
  const cName = `button icon-button ${isSelected ? 'selected' : ''} ${
    isDisabled ? 'disabled' : ''
  }`;

  return (
    <div className={cName} onClick={onClick}>
      <Icon icon={isSelected ? iconSelected || icon : icon} />
    </div>
  );
};

export default IconButton;
