import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import './SectorCard.scss';
import getBoulderColor from '../../utils/getBoulderColor';
import wall from '../../utils/wall.interface';
import { useSector } from '../../redux/boulders/hooks';
import OptimizedImage from '../../atoms/OptimizedImage/OptimizedImage';
import ROUTES from '../../routes/constants';
import { trackEvent } from '../../providers/tracker';

interface Props {
  sectorId: string;
  wall: wall;
  colors: string[];
  colorFilters?: number[];
  tagFilters?: number[];
  intensityFilters?: number[];
  sectorIds?: number[];
  isFetching: boolean;
  prevRoute: string;
}

const ColorItem: React.FC<{ item: string }> = ({ item }) => {
  const { color, borderColor } = useMemo(() => getBoulderColor(item), [item]);

  if (!!color) {
    return (
      <div
        className='individual_color'
        style={{ backgroundColor: color, borderColor: borderColor }}
      />
    );
  }
  return null;
};

const SectorCard: React.FC<Props> = ({
  sectorId,
  colors,
  wall,
  colorFilters,
  tagFilters,
  intensityFilters,
  sectorIds,
  prevRoute,
}) => {
  const navigate = useNavigate();
  const sector = useSector(sectorId);

  const { requestGetSector } = useSector(sectorId);
  const { updateDate } = useMemo(() => sector, [sector]);

  const dateFomatted = useMemo(
    () =>
      updateDate
        ? new Intl.DateTimeFormat('fr-FR', {
            day: '2-digit',
            month: 'short',
            year: '2-digit',
          }).format(new Date(updateDate))
        : null,
    [updateDate]
  );
  const names = useMemo(() => {
    const tab = sector.name?.split(' ') || [];
    if (isNaN(parseInt(tab[tab.length - 1]))) {
      return {
        name: sector.name,
        number: undefined,
      };
    } else {
      const name = tab.splice(0, tab.length - 1).join(' ');
      return {
        name,
        number: tab[tab.length - 1],
      };
    }
  }, [sector]);

  useEffect(() => {
    if (sector?.id && !sector?.picture) requestGetSector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sector?.id]);

  const goToSector = useCallback(() => {
    trackEvent('bloc_click', {
      event_category: 'room',
      event_action: 'bloc_click',
    });

    navigate(`${ROUTES.SECTOR}/${sectorId}`, {
      state: {
        goBackTo: prevRoute,
        wall,
        coach: false,
        colorFilters,
        tagFilters,
        intensityFilters,
        sectorIds,
      },
    });
  }, [navigate, sectorId, prevRoute, wall, colorFilters, tagFilters, intensityFilters, sectorIds]);

  if (!sector || sector.status === 2) return null;
  return (
    <div className='sector_item' onClick={goToSector}>
      {!!sector?.picture && (
        <>
          <div className='gradient' />
          <div className='headerPart'>
            <span className='daily_date'>{dateFomatted}</span>
            <div className='sectorNames'>
              <div className='sectorName'>{names?.name}</div>
              {!!names?.number && <div className='sectorNumber'>{names?.number}</div>}
            </div>
          </div>
          <OptimizedImage className='sector_img' format='thumb' src={sector.picture} />
          <div className='boulderColor'>
            {!!colors?.length &&
              colors.map((item: string, index: number) => <ColorItem item={item} key={index} />)}
          </div>
        </>
      )}
    </div>
  );
};

export default SectorCard;
