import { IonContent, IonPage } from '@ionic/react';
import React, { useCallback, useEffect } from 'react';
import QRCode from 'qrcode.react';

import './Qrcode.scss';
import { useCurrentUser } from '../../redux/user/hooks';
import { useQrcode } from '../../redux/user/hooks';
import Icon from '../../atoms/Icon/Icon';
import IconTypes from '../../types/IconTypes';
import { useNavigate } from 'react-router';
import OptimizedImage from '../../atoms/OptimizedImage/OptimizedImage';

const Qrcode: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { selectedQrcode } = useQrcode();

  useEffect(() => {
    if (selectedQrcode == null) {
      navigate('maCarte');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQrcode]);

  const handleClose = useCallback(() => {
    navigate('maCarte');
  }, [navigate]);

  return (
    <IonPage>
      <IonContent>
        <Icon className='qrcodeClose' icon={IconTypes.CLOSE_CIRCLE} onClick={handleClose} />
        <div className='qrcodeUser'>
          <OptimizedImage
            format='card'
            crossOrigin='anonymous'
            src={currentUser?.picture}
            alt='QRCode PdP'
            className='qrcodePicture'
          />
          {currentUser?.mindFlurryUser?.firstName} {currentUser?.mindFlurryUser?.lastName}
        </div>
        <div className='qrcodeMain'>
          <div className='qrcodeBorder'>
            <div className='qrcodeImg'>
              <QRCode value={selectedQrcode} size={200} />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Qrcode;
