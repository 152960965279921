import React from 'react';

import Filter from './Filter';

import './FilterCard.scss';

interface Props {
  filterName: string;
  filterTab: any;
  selectedFilter: number[];
  filterChange: any;
}

const FilterCard: React.FC<Props> = ({ filterName, filterTab, selectedFilter, filterChange }) => {
  return (
    <div className='individual_type'>
      <div className='filtre_title'>
        <span>{filterName}</span>
      </div>
      <div className='tags'>
        {!!filterTab?.length &&
          filterTab.map((filter: any, index: number) => (
            <Filter
              key={index}
              filter={filter}
              selectedFilter={selectedFilter}
              filterChange={filterChange}
            />
          ))}
        <div />
      </div>
    </div>
  );
};

export default FilterCard;
