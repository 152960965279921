import { useTags } from '../../redux/tags/hooks';
import { useCurrentUser, useUpdateUser } from '../../redux/user/hooks';
import { useLocation, useNavigate } from 'react-router';
import { useMyRank } from '../../redux/leaderBoard/hooks';
import { usePreferredRoom, useSelectedRoom } from '../../redux/rooms/hooks';
import { useAuth, useLogged } from '../../redux/auth/hooks';
import { useLocalize } from '../../redux/translation/localize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Icon from '../../atoms/Icon/Icon';
import Loader from '../../atoms/Loader/Loader';
import { Camera, CameraResultType } from '@capacitor/camera';
import { IonContent, IonPage, isPlatform } from '@ionic/react';
import ProfilInfo from '../../components/ProfilInfo/ProfilInfo';
import FilterCard from '../../components/FilterCard/FilterCard';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';

import './Profil.scss';
import ROUTES from '../../routes/constants';
import IconTypes from '../../types/IconTypes';
import { APP_VERSION } from '../../utils/env';
import { trackEvent } from '../../providers/tracker';
import ProfilNotifications from './Notifications/ProfilNotifications';

const Profil: React.FC<{ location: { search: string } }> = () => {
  // Hooks
  const t = useLocalize();
  const navigate = useNavigate();
  const location = useLocation();
  const { logged } = useLogged();
  const { room } = useSelectedRoom();
  const { requestLogout } = useAuth();
  const { getTags, tags } = useTags();
  const { room: preferredRoom } = usePreferredRoom();
  const { requestCurrentUser, usertags } = useCurrentUser();
  const { clickUsertag, requestUpdatePicture } = useUpdateUser();
  const { userColor, userRank, currentUser } = useMyRank(room?.id);

  // States
  const [darkMode, setDarkMode] = useState<boolean>();
  const [updatedProfil, setUpdatedProfil] = useState<any>(currentUser);

  // Memos
  const userTags = useMemo(() => {
    const tab = usertags || currentUser?.usertags;
    return tab?.map((tag) => tag.tagId);
  }, [currentUser?.usertags, usertags]);

  // Handlers
  const handleTag = useCallback(
    (id: number) => {
      if (logged) {
        trackEvent('user_tags_update', {
          event_category: 'profile',
          event_action: 'user_tags_update',
        });
        clickUsertag(id);
      }
    },
    [clickUsertag, logged]
  );

  const handleBackButton = useCallback(() => {
    trackEvent('back', {
      path: 'profile',
      nextPath: location?.state?.goBackTo || ROUTES.MY_CARD,
    });
    navigate(location?.state?.goBackTo || ROUTES.MY_CARD);
  }, [location, navigate]);

  const handleChangeProfil = useCallback(
    (field: string, data?: string) => {
      if (!data) return;
      const imageURI = data.replace(/^file:\/\//, '');
      const myURL = window['Ionic'].WebView.convertFileSrc(imageURI);
      let profilUpdated = { ...updatedProfil };
      profilUpdated[field] = data;
      profilUpdated.picture = myURL;

      trackEvent('user_update', {
        event_category: 'profile',
        event_action: 'user_update_photo',
      });
      setUpdatedProfil(profilUpdated);
      requestUpdatePicture(profilUpdated.pictureUrl);
      return;
    },
    [updatedProfil, requestUpdatePicture]
  );

  const takePicture = useCallback(
    async (ev: any) => {
      ev.preventDefault();
      ev.stopPropagation();
      await Camera.requestPermissions();
      Camera.getPhoto({
        quality: 85,
        resultType: CameraResultType.DataUrl,
        correctOrientation: true,
        promptLabelPhoto: t('open_gallery'),
        promptLabelPicture: t('open_camera'),
      })
        .then((data) => {
          handleChangeProfil('pictureUrl', data.dataUrl);
        })
        .catch((e) => console.error('Error occurred while taking a picture', e));
    },
    [handleChangeProfil, t]
  );

  useEffect(() => {
    if (isPlatform('ios')) setDarkMode(true);
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logged && !currentUser) {
      requestCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged, room?.id, currentUser]);

  useEffect(() => {
    setUpdatedProfil(currentUser);
  }, [currentUser]);

  return (
    <IonPage id='Profile'>
      {darkMode && (
        <div className='darkMode'>
          <div />
        </div>
      )}

      <IonContent className='profilContent'>
        <div className='profil_back_button' onClick={handleBackButton}>
          <Icon className='back_button' icon={IconTypes.LEFT_OPEN} />
        </div>
        <div className='pictureAndEdition'>
          <div className='picture'>
            <ProfilePicture
              logged={true}
              border='none'
              user={updatedProfil}
              classProps='largeProfile'
              crowned={userRank > 0 && userRank < 10}
              link={false}
              userColor={userColor}
            />
          </div>
          <Icon className='editPicture' icon={IconTypes.CAMERA} onClick={takePicture} />
        </div>
        {!!currentUser?.mindFlurryUser?.firstName ? (
          <div className='profil_main'>
            <div className='profil_name'>
              {currentUser.mindFlurryUser.firstName + ' ' + currentUser.mindFlurryUser.lastName}
            </div>
            {!!currentUser.login && (
              <ProfilInfo title={t('user_pseudo')} text={currentUser.login} />
            )}
            <ProfilInfo title={t('email')} text={currentUser.mindFlurryUser.email} />
            {!!preferredRoom && (
              <ProfilInfo title={t('preferred_room')} text={preferredRoom?.name} />
            )}
            <div className='tagsTitle'>{t('tagsProfil')}</div>
            <div className='tagLegend'>
              <div className='legendTitle'>{t('define_style')}</div>
            </div>
            <div className='profilTags'>
              <FilterCard
                filterName=''
                filterTab={tags.tags}
                filterChange={handleTag}
                selectedFilter={userTags || []}
              />
            </div>
            <ProfilNotifications />
            <div className='profilButtons'>
              <button className='profilButton' onClick={requestLogout}>
                {t('maCarteButtonDisconnect')}
              </button>
            </div>
            {!!APP_VERSION && <div className='appVersion'>v{APP_VERSION}</div>}
          </div>
        ) : (
          <Loader />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Profil;
