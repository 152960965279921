import { IonSpinner } from '@ionic/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slider';
import { getPadTwoDigits } from '../../utils/dateTime';
import { toFixed } from '../../utils/toFixed';

import './VideoTrimer.scss';

const MAX_IMAGES = 4;
const CACHE: { [key: string]: string[] } = {};

interface VideoTrimerProps {
  name?: string | null;
  videoRef: HTMLVideoElement | null;
  startTime: number;
  endTime: number;
  duration: number;
  playing: boolean;
  onChange: ({ startTime, endTime }: { startTime: number; endTime: number }) => void;
}

const VideoTrimer: React.FC<VideoTrimerProps> = ({
  name = 'cache',
  videoRef,
  startTime,
  endTime,
  duration,
  playing,
  onChange,
}) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [values, setValues] = useState([0, 100]);
  const [images, setImages] = useState<string[]>(CACHE[name || 'cache'] || []);

  // Min distance
  const minDistance = useMemo(() => {
    if (!duration || duration < 2) return 100;

    // Duration === 100%; 2s === X; Find X;
    return (2 * 100) / duration;
  }, [duration]);

  // Move methods
  const handleStartMove = useCallback(() => videoRef?.pause(), [videoRef]);
  const handleMove = useCallback(
    (ev: number[]) => {
      const newValues = {
        startTime: ((duration || 1) * (ev[0] || 0)) / 100,
        endTime: ((duration || 1) * (ev[1] || 0)) / 100,
      };

      if (videoRef && videoRef.currentTime < newValues.startTime) {
        videoRef.currentTime = newValues.startTime;
      } else if (videoRef && videoRef.currentTime > newValues.endTime) {
        videoRef.currentTime = newValues.endTime;
      }

      onChange(newValues);
    },
    [duration, onChange, videoRef]
  );
  const handleEndMove = useCallback(() => playing && videoRef?.play(), [playing, videoRef]);

  const handleThumbRender = useCallback(
    (props, { valueNow }) => {
      const time = (valueNow * duration) / 100;
      const minutes = Number(toFixed(time / 60, 0));
      const seconds = time / (minutes + 1);
      const ms = toFixed((time % 1) * 10, 0);

      const params = [ms, getPadTwoDigits(seconds)];

      if (!!Number(toFixed(duration / 60, 0))) params.push(getPadTwoDigits(Number(minutes)));

      const value = params.reverse().join(':');
      return (
        <div {...props}>
          <div className='littlePop'>{value}</div>
        </div>
      );
    },
    [duration]
  );

  useEffect(() => {
    setValues([(startTime * 100) / (duration || 1), (endTime * 100) / (duration || 1)]);
  }, [duration, endTime, startTime]);

  useEffect(() => {
    if (!!images.length) return;

    const canvas = document.createElement('canvas');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      const context = canvas.getContext('2d');
      const numImages = 4; // Number of images to extract
      const images: string[] = [];

      if (!context || !videoRef?.videoWidth || videoRef.duration === Infinity) return;
      const intervalTime = videoRef.duration / numImages;

      for (let i = 0; i < numImages; i++) {
        const time = intervalTime * i;
        videoRef.currentTime = time;
        context.drawImage(videoRef, 0, 0, window.innerWidth, window.innerHeight);
        const imageUrl = canvas.toDataURL('image/jpeg');
        images.push(imageUrl);
      }
      CACHE[name || 'cache'] = images;
      setImages(images);
    }, 1000);
  }, [images.length, name, videoRef]);

  return (
    <div id='VideoTrimer'>
      <div className={`imagesBox ${images.length < MAX_IMAGES}`}>
        {!images.length && <IonSpinner />}
        {!!images.length &&
          images.map((img: string, i: number) =>
            i >= 4 ? null : <img crossOrigin='anonymous' src={img} key={i} alt={`image_${i}`} />
          )}
      </div>
      <Slider
        renderThumb={handleThumbRender}
        value={values}
        className='VideoTrimmerRange'
        thumbClassName='thumb'
        trackClassName='track'
        pearling
        minDistance={minDistance}
        onBeforeChange={handleStartMove}
        onChange={handleMove}
        onAfterChange={handleEndMove}
      />
    </div>
  );
};

export default VideoTrimer;
