import React from 'react';

// Hooks
import { useEditorTools } from '../../../../redux/editor/hooks';

// Components
import DrawCircleToolbar from './partials/DrawCircleToolbar/DrawCircleToolbar';
import ManipulationToolbar from './partials/ManipulationToolbar/ManipulationToolbar';

// Styles
import './ContextToolbar.scss';

interface ContextToolbarProps {
  additionalColors?: string[];
  onSave: () => void;
  onQuit: () => void;
}

const ContextToolbar: React.FC<ContextToolbarProps> = ({ additionalColors, onSave, onQuit }) => {
  const { primaryTool } = useEditorTools();
  return (
    <div className='context-toolbar'>
      {primaryTool === 'draw' && (
        <DrawCircleToolbar additionalColors={additionalColors} onSave={onSave} onClose={onQuit} />
      )}
      {primaryTool === 'manipulation' && <ManipulationToolbar onSave={onSave} onClose={onQuit} />}
    </div>
  );
};

export default ContextToolbar;
