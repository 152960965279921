import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

import { createSelector } from 'reselect';
import * as reducers from './reducers';

const initialState = Immutable({
  // Sectors
  sectorIds: [],
  sectorById: {},

  sectorList: [],

  // Boulders
  boulderIds: [],
  boulderById: {},

  boulderList: [],
  validatedBoulder: [],

  // Setttings
  isFetching: false,
  isFullLoaded: false,

  isFetchingValidatedBoulder: false,
  isFullLoadedValidatedBoulder: false,
});

export const actions = {
  requestFilteredBoulders: createAction(
    'request filtered boulders',
    (roomId, wallId, colorIds, tagIds, intensityIds, skip) => ({
      roomId,
      wallId,
      colorIds,
      tagIds,
      intensityIds,
      skip,
    })
  ),
  getSectorById: createAction('request sector by Id', (sectorId) => ({ sectorId })),
  getBoulderById: createAction('request boulder by Id', (boulderId) => ({ boulderId })),
  getBouldersBySector: createAction('request sector boulders', (sectorIds) => ({ sectorIds })),
  getBouldersByWall: createAction('request wall boulders', (payload) => ({ payload })),
  getMyValidatedBoulders: createAction('request my validated boulder', (payload) => ({ payload })),
  requestValidationConditions: createAction(
    'request boulder validation conditions',
    (boulderId, date) => ({
      boulderId,
      date,
    })
  ),

  addSector: createAction('add sector', (sector) => ({ sector })),
  addSectors: createAction('add sectors', (sectors, clean = false) => ({ sectors, clean })),
  addBoulder: createAction('add boulder', (boulder) => ({ boulder })),
  addBoulders: createAction('add boulders', (boulders, clean = false) => ({ boulders, clean })),
  updateBoulderValidationConditions: createAction(
    'updateBoulderValidationConditions',
    (boulderId, validationConditions) => ({ boulderId, validationConditions })
  ),

  updateClap: createAction('update clap on boulder', (boulderId, value) => ({ boulderId, value })),

  requestBouldersDetails: createAction('requestBouldersDetails', (boulderIds, date) => ({
    boulderIds,
    date,
  })),
  setFetching: createAction('setFetching', (value) => ({ value })),
  setFullLoaded: createAction('setFullLoaded', (value) => ({ value })),
  setFetchingValidatedBoulder: createAction('setFetching', (value) => ({ value })),
  setFullLoadedValidatedBoulder: createAction('setFullLoaded', (value) => ({ value })),
  addSectorList: createAction('addSectorList', (ids, reset) => ({ ids, reset })),
  addBoulderList: createAction('addBoulderList', (ids, reset) => ({ ids, reset })),
  addValidatedBoulder: createAction('addValidatedBoulder', (ids, reset) => ({ ids, reset })),
  cleanBoulders: createAction('cleanBoulders'),
};

export const reducer = createReducer(
  {
    [actions.addSector]: reducers.addSector,
    [actions.addSectors]: reducers.addSectors,
    [actions.addBoulder]: reducers.addBoulder,
    [actions.addBoulders]: reducers.addBoulders,
    [actions.addSectorList]: reducers.addSectorList,
    [actions.addBoulderList]: reducers.addBoulderList,
    [actions.addValidatedBoulder]: reducers.addValidatedBoulder,
    [actions.setFetching]: (state, { value }) => state.setIn(['isFetching'], value),
    [actions.setFullLoaded]: (state, { value }) => state.setIn(['isFullLoaded'], value),
    [actions.setFetchingValidatedBoulder]: (state, { value }) =>
      state.setIn(['isFetchingValidatedBoulder'], value),
    [actions.setFullLoadedValidatedBoulder]: (state, { value }) =>
      state.setIn(['isFullLoadedValidatedBoulder'], value),
    [actions.updateBoulderValidationConditions]: reducers.updateBoulderValidationConditions,

    [actions.cleanBoulders]: (state) => state.merge({ ...initialState }),
  },
  initialState
);

export const createSelectorSectorBouldersById = () =>
  createSelector(
    (state, sectorId) => state.boulders.getIn(['sectorById', sectorId]),
    (state) => state.boulders.getIn(['boulderById']),
    (sector, boulderById) => sector?.boulders?.map?.((boulderId) => boulderById[boulderId]) || []
  );

export const selectors = {
  sectorIds: (state) => state.boulders.sectorIds,
  sectorById: (state, id) => state.boulders.sectorById[id],
  boulderIds: (state) => state.boulders.boulderIds,
  boulderById: (state, id) => state.boulders.boulderById[id],
  isFetching: (state) => state.boulders.isFetching,
  isFullLoaded: (state) => state.boulders.isFullLoaded,
  isFetchingValidatedBoulder: (state) => state.boulders.isFetchingValidatedBoulder,
  isFullLoadedValidatedBoulder: (state) => state.boulders.isFullLoadedValidatedBoulder,
  sectorList: (state) => state.boulders.sectorList,
  boulderList: (state) => state.boulders.boulderList,
  validatedBoulder: (state) => state.boulders.validatedBoulder,
};
