import React from 'react';
import { IonButton, IonItemGroup, IonPage, IonText } from '@ionic/react';

import './Actualite.scss';

const Actualite: React.FC = () => {
  return (
    <IonPage id='Actualite'>
      <IonItemGroup>
        <IonButton fill='clear' color='tertiary'>
          Test
        </IonButton>
      </IonItemGroup>
      <IonItemGroup>
        <IonText className='test'>Textssss</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
        <IonText className='test'>Text</IonText>
      </IonItemGroup>
    </IonPage>
  );
};

export default Actualite;
