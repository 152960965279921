import { useNavigate } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { useLocalize } from '../../redux/translation/localize';

import Icon from '../../atoms/Icon/Icon';

import './DayCard.scss';
import type { CalendarDay } from '../Calendar/Calendar';
import IconTypes from '../../types/IconTypes';
import ROUTES from '../../routes/constants';
import { trackEvent } from '../../providers/tracker';

interface Props {
  day: CalendarDay;
  setShowAlert: Function;
}

const DayCard: React.FC<Props> = ({ day, setShowAlert }) => {
  const t = useLocalize();
  const navigate = useNavigate();
  const { room } = useSelectedRoom();
  const dayStyle = useMemo(
    () => ({
      opacity: day.isThisMonth ? '1' : '0.48',
    }),
    [day.isThisMonth]
  );

  const weekDayStyle = useMemo(
    () => ({
      opacity: day.isToday ? '1' : '0.48',
      background: day.isToday
        ? 'rgba(14, 70, 177, 0.3)'
        : day.isPassed
        ? 'rgba(225, 229, 238, 1)'
        : 'rgba(14, 70, 177, 0)',
      color: day.isToday ? 'white' : 'black',
    }),
    [day.isPassed, day.isToday]
  );

  const handleAddSession = useCallback(() => {
    if (day.isPassed && room?.id) {
      trackEvent('click_calendar_day', {
        event_category: 'coach',
        event_action: 'click_calendar_day',
      });
      navigate(ROUTES.TRAINING_EDIT, {
        state: {
          sessionDate: day.updateDate,
          sessionId: day.sessionId,
        },
      });
    } else if (!room?.id) setShowAlert(true);
  }, [day.isPassed, day.sessionId, day.updateDate, navigate, room?.id, setShowAlert]);

  return (
    <div className='singleDay' onClick={handleAddSession}>
      {day.hasSession && <Icon icon={IconTypes.TICK_CIRCLE_FULL} className='dayIcon' />}
      {!day.hasSession && day.hasVisit && <Icon icon={IconTypes.ADD_CIRCLE} className='dayIcon' />}
      {!day.hasSession && !day.hasVisit && (
        <div className='dayName' style={weekDayStyle}>
          <div className='weekDay'>{t(`week_day_${day.day}`)}</div>
        </div>
      )}
      {!day.isToday && (
        <div className='dayDate' style={dayStyle}>
          {day.date}
        </div>
      )}
      {day.isToday && (
        <div className='dayDateToday' style={dayStyle}>
          {day.date}
        </div>
      )}
    </div>
  );
};

export default DayCard;
