const audits = {
  TOP: 1,
  CLAP: 2,
  ZONE: 3,
  FLASH: 4,
  ESSAI: 5,
  LIKE: 6,
  DISLIKE: 7,
  READ: 8,
  FAIL: 9,
};

export default audits;
