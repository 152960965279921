import React, { useCallback, useEffect, useMemo } from 'react';
import { useColors } from '../../../../redux/filters/hooks';

import ProfilePicture from '../../../ProfilePicture/ProfilePicture';
import UserName from '../UserName/UserName';
import { color } from '../../../../utils/filter.interface';
import getBoulderColor from '../../../../utils/getBoulderColor';

import './UserCard.scss';

interface Props {
  user: {
    id: number;
    rank: number;
    colorId: number;
    firstName?: string;
    lastName?: string;
    login?: string;
    picture: string;
    points: number;
    isAdmin?: boolean;
  };
  boardLength: number;
  isMyUser?: boolean;
  displayUserRanking?: (userId: number) => void;
  forcedColorId?: number;
}

const UserCard: React.FC<Props> = React.memo(
  ({ user, boardLength, isMyUser, displayUserRanking, forcedColorId }) => {
    const { colors, getColors } = useColors();
    const { color } = useMemo(() => {
      const currentColor = colors.find(({ id }: color) => id === (forcedColorId || user.colorId));
      return getBoulderColor(currentColor?.name);
    }, [colors, forcedColorId, user.colorId]);

    const cardStyle = useMemo(
      () => ({
        backgroundColor: isMyUser ? color : '',
      }),
      [isMyUser, color]
    );

    const fontStyle = useMemo(() => ({ color: isMyUser ? 'white' : '' }), [isMyUser]);

    useEffect(() => {
      if (!colors.length) {
        getColors();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colors.length]);

    const handleClickUserCard = useCallback(() => {
      displayUserRanking && displayUserRanking(user.id);
    }, [displayUserRanking, user.id]);

    return (
      <div
        className={`userCard ${isMyUser ? 'myUserCard' : ''}`}
        style={cardStyle}
        onClick={handleClickUserCard}
      >
        <div className='userRank' style={fontStyle}>
          {user.rank}
        </div>
        <div className='pictures'>
          <ProfilePicture
            user={user}
            border='solid'
            link={false}
            logged={false}
            crowned={false}
            classProps='mediumProfile'
            borderCol={color}
            leaderBoard
          />
        </div>
        <UserName
          user={user}
          color={color}
          lastRow={user.rank === boardLength || !boardLength}
          onClick={handleClickUserCard}
          isMyUser={isMyUser}
        />
      </div>
    );
  }
);

export default UserCard;
