import { IonApp, setupIonicReact } from '@ionic/react';
import { Provider as ReduxProvider } from 'react-redux';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
  ApolloLink,
} from '@apollo/client';
import { SentryLink } from './config/sentry/ApolloLink';

import { setContext } from '@apollo/client/link/context';
import { store } from './redux/index';

import initApp from './init/init';
import Router from './routes/Router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/_mainClasses.scss';

import * as Sentry from '@sentry/react';
import { createClient } from 'graphql-ws';
import getHeader from './utils/getHeader';
import { GQL_URL, WS_URL } from './utils/env';
import { register } from 'swiper/element/bundle';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { QRCodeFlashProvider } from './providers/qr-code-flash.provider';
import { BrowserRouter } from 'react-router-dom';

// register Swiper custom elements
register();

const httpLink = createHttpLink({
  uri: GQL_URL,
});

const authLink = setContext((_) => {
  const headers = getHeader();
  return {
    headers,
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_URL,
  })
);

const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeVariables: true,
    includeFetchResult: true,
    includeError: true,
  },
});

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${GQL_URL}?${operation.operationName}`,
  }));
  return forward ? forward(operation) : null;
});

setupIonicReact({
  //  mode: 'ios', // for ios style everywhere, md for material design everywhere, leave blank for automatic
  // animated: true
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  ApolloLink.from([namedLink, sentryLink, httpLink])
);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(splitLink),
});

initApp();

const App: React.FC = () => (
  <ReduxProvider store={store}>
    <ApolloProvider client={client}>
      <QRCodeFlashProvider>
        <IonApp>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </IonApp>
      </QRCodeFlashProvider>
    </ApolloProvider>
  </ReduxProvider>
);

export default Sentry.withErrorBoundary(App, { fallback: <p>an error has occurred</p> });
