import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import {
  IonContent,
  IonPage,
  IonBackdrop,
  IonRefresher,
  IonRefresherContent,
  IonModal,
} from '@ionic/react';
import { useLogged } from '../../redux/auth/hooks';

import Header from '../../routes/components/Header/Header';
import UsersRanking from '../../components/UsersRanking/UsersRanking';

import './Community.scss';
import MyRank from '../../components/MyRank/MyRank';
import UserBoulderRanking from '../../components/UserBoulderRanking/UserBoulderRanking';
import { useRefreshLeaderboard } from '../../redux/leaderBoard/hooks';
import { trackEvent } from '../../providers/tracker';

//TODO: display my rank only when logged
const Community: React.FC = () => {
  const refreshRef: any = useRef();
  // Hooks
  const { room } = useSelectedRoom();
  const { logged } = useLogged();
  const { refreshLeaderboard, isFetching } = useRefreshLeaderboard();
  const [displayInfo, setDisplayInfo] = useState<boolean>(false);
  const [showUserRanking, setShowUserRanking] = useState<number | undefined>();

  // Memos
  const date = useMemo(() => new Date(), []);

  const handleHideInfo = useCallback(() => {
    setDisplayInfo(false);
  }, []);

  const handleDisplayUserRanking = useCallback((userId?) => {
    trackEvent('click_leaderboard_user', {
      event_category: 'leaderboard',
      event_action: 'click_leaderboard_user',
    });
    setShowUserRanking(userId);
  }, []);

  const handleHideUserRanking = useCallback(() => {
    setShowUserRanking(undefined);
  }, []);

  const handleStopLoading = useCallback(() => {
    refreshRef.current.complete();
  }, []);

  useEffect(() => {
    if (!isFetching) handleStopLoading();
  }, [handleStopLoading, isFetching]);

  const handleRefresh = useCallback(() => {
    trackEvent('leaderboard_refresh');
    handleHideUserRanking();
    refreshLeaderboard(room?.id, handleStopLoading);
  }, [handleHideUserRanking, handleStopLoading, refreshLeaderboard, room?.id]);

  return (
    <IonPage className='home'>
      {displayInfo && <IonBackdrop visible tappable onIonBackdropTap={handleHideInfo} />}
      <Header
        isCoach={false}
        showProfile={false}
        showRoom
        showMonth={false}
        showWalls={false}
        showSectors={false}
      />
      <IonContent fullscreen className='homeContent'>
        <IonRefresher ref={refreshRef} slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {logged && (
          <MyRank
            date={date}
            room={room}
            displayInfo={displayInfo}
            setDisplayInfo={setDisplayInfo}
            isDetailed={!!showUserRanking}
          />
        )}
        <UsersRanking room={room} displayUserRanking={handleDisplayUserRanking} />
        <IonModal
          id='UserBestBouldersModal'
          isOpen={!!showUserRanking}
          backdropDismiss
          onDidDismiss={handleHideUserRanking}
        >
          {showUserRanking && (
            <UserBoulderRanking
              room={room}
              userId={showUserRanking}
              hideUserRanking={handleHideUserRanking}
            />
          )}
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Community;
