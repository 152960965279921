import React, { useEffect, useMemo, useState } from 'react';

//Hooks
import { useLogged } from '../../../../redux/auth/hooks';
import { useColors } from '../../../../redux/filters/hooks';
import { useLocalize } from '../../../../redux/translation/localize';
import { useCoachingUpdate } from '../../../../redux/coaching/hooks';
import { useSemesterPerf, useSessionsPerf } from '../../../../redux/sessions/hooks';

// Components
import Loader from '../../../../atoms/Loader/Loader';
import Point from '../../../../components/Point/Point';
import Difficulties from '../../../../components/Difficulties/Difficulties';

// Style
import './LastMonths.scss';
import { countValidatedSessionBouldersByColor } from '../../../../utils/sessionCountUtils';

interface Props {
  roomId?: number;
  dateStart: Date;
  dateEnd: Date;
}

const LastMonths: React.FC<Props> = ({ dateStart, dateEnd, roomId }) => {
  // Hooks
  const t = useLocalize();
  const { logged } = useLogged();
  const { activity } = useSessionsPerf();
  const { colors, getColors } = useColors();
  const { nbOfSession } = useCoachingUpdate();
  const { semesterActivity, isFetching, requestSemesterActivity } = useSemesterPerf();

  // States
  const [selectedColor, setSelectedColor] = useState<number>(1);

  // Memos
  const nbSessions = useMemo(() => {
    const arr = semesterActivity.filter((activity) => activity.sessionboulders.length);
    return arr.length;
  }, [semesterActivity]);

  const countColor = useMemo(
    () => countValidatedSessionBouldersByColor(semesterActivity),
    [semesterActivity]
  );
  const nbBlocs = useMemo(
    () =>
      Object.values<number>(countColor).reduce<number>(
        (sum: number, sessionsNb: number) => sum + sessionsNb,
        0
      ),
    [countColor]
  );

  const colorsCounted = useMemo(
    () =>
      colors.map(({ id, name }) => ({
        id,
        name,
        count: countColor[name] || 0,
      })),
    [colors, countColor]
  );

  useEffect(() => {
    if (logged && dateEnd && dateStart) {
      requestSemesterActivity(dateStart.toDateString(), dateEnd.toDateString(), roomId);
    }
    getColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateEnd, dateStart, activity, nbOfSession, logged, roomId]);

  return (
    <div className='lastMonthsRecap'>
      <div className='recapTitle'>{t('last_six_months')}</div>
      {isFetching && <Loader />}
      {!isFetching && (
        <>
          <div className='monthsNumbers'>
            <div className='title'>
              <Point />
              <div>{t('sessions')}</div>
              <div className='number'>{nbSessions}</div>
            </div>
            <div className='title'>
              <Point />
              <div>{t('boulder_done')}</div>
              <div className='number'>{nbBlocs}</div>
            </div>
          </div>
          <div className='colorRecap'>
            <div className='title'>
              <Point />
              <div>{t('completion_rate')}</div>
            </div>
            <Difficulties
              colors={colorsCounted}
              selectedColor={selectedColor}
              changeColor={setSelectedColor}
            />
          </div>
          <div className='plugsNumbers'>
            <div className='title'>
              <Point />
              <div>{t('number_of_plugs')}</div>
              <div className='number'>{nbBlocs * 6}</div>
            </div>
            <div className='title'>
              <Point />
              <div>{t('vertical_distance')}</div>
              <div className='number'>
                {Math.floor(nbBlocs * 4.35)}
                <span>m</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LastMonths;
