import { gql } from '@apollo/client';

export const GetUser = gql`
  query GetUser {
    user {
      id
      login
      isAdmin
      picture
      isOnboarded
      instagram
      bio
      usertags {
        tagId
      }
      rgpd_sms
      rgpd_notifications_byemail
      rgpd_directemails
      mindFlurryUser {
        id
        login
        firstName
        lastName
        roomId
        phoneNumber
        email
      }
    }
  }
`;

export const GetUserRank = gql`
  query GetUserRank($roomId: Int) {
    user {
      id
      usercolors {
        colorId
        roomId
      }
      rank(roomId: $roomId)
      colorRank(roomId: $roomId) {
        rank
        colorId
      }
    }
  }
`;

export const GetUserPerf = gql`
  query GetUserPerf($dateStart: DateTime!, $dateEnd: DateTime!, $roomId: Int) {
    userperformancesbydate(dateStart: $dateStart, dateEnd: $dateEnd, roomId: $roomId) {
      specialityId
      value
    }
  }
`;

export const GetUserLastSession = gql`
  query GetUserLastSession($roomId: Int) {
    user {
      id
      lastSession(roomId: $roomId) {
        id
        startDate
        sessionspecialities {
          specialityId
          value
        }
      }
    }
  }
`;
export const AddOnboarding = gql`
  mutation AddOnboarding(
    $userGoals: [GoalInput!]!
    $xpYears: Int
    $picture: String
    $startLevel: String
    $colorId: Int
    $cgvaccepted: Boolean!
  ) {
    addOnboarding(
      userGoals: $userGoals
      xpYears: $xpYears
      picture: $picture
      startLevel: $startLevel
      colorId: $colorId
      cgvaccepted: $cgvaccepted
    ) {
      id
      isAdmin
      picture
      isOnboarded
      mindFlurryUser {
        id
        firstName
        lastName
        login
        phoneNumber
      }
    }
  }
`;
