import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Hooks
import { useFormattedDate } from '../../redux/common/hooks';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { useSessionsPerf } from '../../redux/sessions/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useCoachingUpdate } from '../../redux/coaching/hooks';

// Components
import Loader from '../../atoms/Loader/Loader';

// Styles & Utils
import './Activity.scss';
import { Line } from 'react-chartjs-2';
import { speciality, options, specialitiesConfig } from '../../utils/chartConfig';

// Types
import SpeTypes from '../../types/SpecialityTypes';
import { Performance, Session } from '../../utils/coach.interface';
import { useLogged } from '../../redux/auth/hooks';

interface Props {
  dateStart: Date;
  dateEnd: Date;
  isHome?: boolean;
}

interface Accumulator {
  [key: string]: number;
}
interface AccumulatorParent {
  [key: string]: Accumulator;
}

const Activity: React.FC<Props> = ({ dateStart, dateEnd, isHome }) => {
  // Hooks
  const t = useLocalize();
  const { logged } = useLogged();
  const { nbOfSession } = useCoachingUpdate();
  const { roomId: selectedRoomId } = useSelectedRoom();
  const { activity, coachActivity, isFetching, requestPeriodicActivity } = useSessionsPerf();
  const startHome = useFormattedDate(new Date(dateStart), { day: 'numeric', month: 'short' }, [
    dateStart,
  ]);
  const endHome = useFormattedDate(new Date(dateEnd), { day: 'numeric', month: 'short' }, [
    dateStart,
  ]);
  const date = useFormattedDate(new Date(dateStart), { month: 'long' }, [dateStart]);

  // States
  const [labels, setLabels] = useState<string[]>([]);
  const [specialities, setSpecialities] = useState({
    prehensions: [],
    physiques: [],
    techniques: [],
    deplacements: [],
  });

  // Memos
  // Add user's score in each speciality to the dataset
  const dataSet = useMemo(
    () =>
      specialitiesConfig.map((item: speciality) => ({
        ...item,
        label: item.name ? t(item.name) : item.label,
        data: specialities[item.label],
      })),
    [specialities, t]
  );

  const calculActivity = useCallback(
    (tabValue: Session[]) => {
      const tab = tabValue.filter((session) => session.sessionboulders.length);
      let prehensionsToSet: (undefined | number)[] = [];
      let physiquesToSet: (undefined | number)[] = [];
      let techniquesToSet: (undefined | number)[] = [];
      let deplacementsToSet: (undefined | number)[] = [];
      let labelToSet: string[] = [];
      const end = dateEnd.toDateString();
      const start = dateStart.toDateString();
      let count = new Date(dateStart);
      let countDay = start;

      // in activity extract all the coaching sessions with the date of the session and user's perfomances during the session
      const activityByDate = tab.reduce((acc: AccumulatorParent, session: Session) => {
        const startDay =
          new Date(session.startDate).getDate().toString() +
          new Date(session.startDate).getMonth().toString();
        const performances = session.sessionspecialities.reduce(
          (acc: Accumulator, perf: Performance) => {
            acc[perf.specialityId] = (acc[perf.specialityId] || 0) + perf.value;
            acc.total += perf.value;
            return acc;
          },
          acc[startDay] ? { ...acc[startDay] } : { total: 0 } //if the key already existe in the acc, copy the key value (in case of multiple session in one day)
        );
        acc[startDay] = performances;
        return acc;
      }, {});

      //for each day save user's score in each speciality.
      //The days with no session add undefined value to have a continus chart
      while (!(countDay === end)) {
        let day = count.getDate().toString() + count.getMonth().toString();
        labelToSet.push(count.getDate().toString());
        if (!activityByDate[day]) {
          prehensionsToSet.push(undefined);
          physiquesToSet.push(undefined);
          techniquesToSet.push(undefined);
          deplacementsToSet.push(undefined);
        } else {
          const session = activityByDate[day];
          prehensionsToSet.push(session[SpeTypes.PREHENSION]);
          physiquesToSet.push(session[SpeTypes.PHYSIQUE]);
          techniquesToSet.push(session[SpeTypes.TECHNIQUE]);
          deplacementsToSet.push(session[SpeTypes.DEPLACEMENT]);
        }
        count.setDate(count.getDate() + 1);
        countDay = count.toDateString();
      }
      ///last iteration
      let day = count.getDate().toString() + count.getMonth().toString();
      labelToSet.push(count.getDate().toString());
      if (!activityByDate[day]) {
        prehensionsToSet.push(undefined);
        physiquesToSet.push(undefined);
        techniquesToSet.push(undefined);
        deplacementsToSet.push(undefined);
      } else {
        const session = activityByDate[day];
        prehensionsToSet.push(session[SpeTypes.PREHENSION]);
        physiquesToSet.push(session[SpeTypes.PHYSIQUE]);
        techniquesToSet.push(session[SpeTypes.TECHNIQUE]);
        deplacementsToSet.push(session[SpeTypes.DEPLACEMENT]);
      }
      count.setDate(count.getDate() + 1);
      countDay = count.toDateString();
      ///
      setLabels(labelToSet);
      setSpecialities((prevState: any) => ({
        ...prevState,
        prehensions: prehensionsToSet,
        physiques: physiquesToSet,
        techniques: techniquesToSet,
        deplacements: deplacementsToSet,
      }));
    },
    [dateEnd, dateStart]
  );

  const data = useMemo(() => ({ labels: labels, datasets: dataSet }), [dataSet, labels]);

  // Effects
  useEffect(() => {
    if (logged && dateEnd && dateStart) {
      const endDateCopy = new Date(new Date(dateEnd).setHours(23, 59, 59, 999));
      requestPeriodicActivity(dateStart, endDateCopy, selectedRoomId, !!isHome);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateEnd, dateStart, selectedRoomId, nbOfSession, logged, isHome]);

  useEffect(() => {
    calculActivity(isHome ? activity : coachActivity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, coachActivity, isHome]);

  return (
    <div className='activityContent'>
      <div className='sectionName'>
        {!!isHome && <div className='activityTitle'>{t('activity')}</div>}
        {!isHome && <div className='activityTitle'>{t('activity_monthly')}</div>}
        {!isHome && <div className='activityDate'>{date}</div>}
        {!!isHome && <div className='activityDate'>{startHome + ' - ' + endHome}</div>}
      </div>
      {isFetching && !dataSet && <Loader />}
      <div className='chart'>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default Activity;
