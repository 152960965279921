import { all, takeLatest, put, select } from 'redux-saga/effects';
import { client } from '../../App';
import ROUTES from '../../routes/constants';
import * as Sentry from '@sentry/browser';

// Redux
import { actions as AppActions } from '../app/redux';
import { actions as AuthActions } from '../auth/redux';
import { actions as UserActions } from '../user/redux';
import { actions as AccountActions, selectors as AccountSelectors } from './redux';

// Libraries
import axios from 'axios';

// Utils
import {
  CLUBS,
  SYNC_USER,
  ASK_EMAIL_VERIF_URL,
  EMAIL_VERIF_URL,
  UPDATE_USERNAME,
  USER_CREATION_URL,
  ASK_RESET_PASSWORD_URL,
  RESET_PASSWORD_URL,
  UPDATE_ACCOUNT,
} from '../../utils/env';
import { AddOnboarding } from '../../GraphQL/GetUser';
import getHeader from '../../utils/getHeader';
import GoalTypes from '../../types/GoalTypes';

export default class AccountSagas {
  static *syncUserDeciplus({ payload }) {
    const { email, firstName, lastName, birthDate, club, callback, emailToken } = payload.payload;
    let syncPayload = {};
    try {
      yield put(AppActions.setAppLoader(true));
      if (club)
        syncPayload = { email, firstName, lastName, birthdate: birthDate, club, emailToken };
      else if (birthDate && !club)
        syncPayload = { email, firstName, lastName, birthdate: birthDate, emailToken };
      else if (!birthDate && !club) syncPayload = { email, emailToken };

      const res = yield axios.post(SYNC_USER, syncPayload, {
        headers: getHeader(),
      });

      if (res.status === 200 || res.status === 201) {
        if (res?.data?.login) yield put(AccountActions.setUserName(res?.data?.login));
        if (res?.data?.id) {
          yield put(AccountActions.setUserId(res?.data?.id));
        }

        yield put(AppActions.setAppLoader(false));
      }
      callback(res.status, ROUTES.ACCOUNT_EDIT_USERNAME);
      yield put(AppActions.setAppLoader(false));
    } catch (e) {
      if (e?.response?.status === 409) {
        yield put(AccountActions.requestClubs());
        const userClubIds = e.response.data.clubs.map((club) => club.club_id);
        yield put(AccountActions.setUserClubs(userClubIds));
      }
      Sentry.captureException(e);
      yield put(AccountActions.setUserNotFound(true));
      callback(e.response.status, undefined, e.response?.data.message);
      console.warn(e);
      yield put(AppActions.setAppLoader(false));
      return;
    }
  }

  static *requestClubs() {
    try {
      const res = yield axios.get(CLUBS, {
        headers: getHeader(),
      });

      if (res.data.data.length) {
        const clubs = res.data.data
          .map((club) => ({ id: club.id, name: club.name }))
          .filter((club) => club.id !== 184);
        yield put(AccountActions.setClubs(clubs));
      }
    } catch (e) {
      Sentry.captureException(e);
      console.warn(e);
    }
  }

  static *updateUsername({ payload }) {
    const { username, userId, emailToken, callback } = payload;
    try {
      yield put(AppActions.setAppLoader(true));
      const res = yield axios.post(
        UPDATE_USERNAME,
        {
          username,
          userId,
          emailToken,
        },
        {
          headers: getHeader(),
        }
      );
      if (res.status === 201) {
        callback(res.status);
        yield put(AccountActions.setUserName(username));
      }
      yield put(AppActions.setAppLoader(false));
    } catch (e) {
      console.warn(e);
      callback(e.response.status);
      yield put(AppActions.setAppLoader(false));
      Sentry.captureException(e);
      return;
    }
  }

  static *emailVerification({ payload }) {
    const { email, callback } = payload;
    try {
      yield put(AppActions.setAppLoader(true));
      const res = yield axios.post(
        ASK_EMAIL_VERIF_URL,
        {
          email,
        },
        {
          headers: getHeader(),
        }
      );
      if (res && callback) callback(res.status);
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
      callback(err.response.status);
    }
    yield put(AppActions.setAppLoader(false));
  }

  static *emailCodeValidation({ payload }) {
    const { email, emailCode, callback } = payload;
    try {
      const isSync = yield select(AccountSelectors.isSync);
      yield put(AppActions.setAppLoader(true));
      const res = yield axios.post(
        EMAIL_VERIF_URL,
        {
          email,
          emailCode,
        },
        {
          headers: getHeader(),
        }
      );

      if (res) {
        yield put(AccountActions.setEmailToken(res?.data?.token));
        if (isSync) {
          yield put(
            AccountActions.requestSyncUserDeciplus({
              email,
              callback,
              emailToken: res?.data?.token,
            })
          );
        } else {
          callback(res?.status, ROUTES.ACCOUNT_RESET_PASSWORD);
          yield put(AppActions.setAppLoader(false));
        }
      }
    } catch (e) {
      console.warn(e);
      callback(e.response?.data?.statusCode, undefined, e.response?.data?.message);
      yield put(AppActions.setAppLoader(false));
      Sentry.captureException(e);
    }
  }

  static *createAccount({ payload }) {
    yield put(AppActions.setAppLoader(true));
    const { accountInfos, callback, level, goals } = payload;
    try {
      const res = yield axios.post(
        USER_CREATION_URL,
        {
          username: accountInfos.username,
          password: accountInfos.password,
          email_address: accountInfos.email_address,
          first_name: accountInfos.first_name,
          last_name: accountInfos.last_name,
          birth_date: accountInfos.birth_date,
          startLevel: level,
          goals,
          facility: 776,
          cgvaccepted: accountInfos.cgvaccepted,
          rgpd_sms: accountInfos.rgpd_sms,
          rgpd_directemails: accountInfos.rgpd_directemails,
          rgpd_notifications_byemail: accountInfos.rgpd_notifications_byemail,
        },
        {
          headers: getHeader(),
        }
      );
      if (res?.data) {
        if (!res.data.success) {
          callback(res.data.message);
        } else {
          yield put(AuthActions.setToken(res.data?.token));
          yield put(AuthActions.setIsOnboarded(res.data?.user?.isOnboarded));
          yield put(UserActions.requestCurrentUser());
          callback();
        }
      }
    } catch (err) {
      yield put(AppActions.setAppLoader(false));
      console.warn(err);
      callback(err.response.status);
      Sentry.captureException(err);
    }
    yield put(AppActions.setAppLoader(false));
  }

  static *askResetPassword({ payload }) {
    const { email, callback } = payload;
    try {
      yield put(AppActions.setAppLoader(true));

      const res = yield axios.post(
        ASK_RESET_PASSWORD_URL,
        {
          email,
        },
        {
          headers: getHeader(),
        }
      );

      if (res?.data?.success) {
        yield put(AccountActions.setEmail(email));
        callback(res.status);
      } else {
        callback(404);
      }
    } catch (e) {
      console.warn(e);
      yield put(AccountActions.setUserNotFound(true));
      callback(e.response.status);
      Sentry.captureException(e);
    }
    yield put(AppActions.setAppLoader(false));
  }

  static *resetPassword({ payload }) {
    const { password, userId, callback } = payload;
    try {
      yield put(AppActions.setAppLoader(true));
      const emailToken = yield select(AccountSelectors.emailToken);
      const res = yield axios.post(
        RESET_PASSWORD_URL,
        {
          password,
          emailToken,
          userId,
        },
        {
          headers: getHeader(),
        }
      );
      if (res) {
        yield put(AuthActions.setToken(res.data?.mindflurryToken));
        yield put(AuthActions.setRefreshToken(res.data?.mindFlurryRefreshToken));
        yield put(AuthActions.setIsOnboarded(res.data?.isOnboarded));
        yield put(UserActions.requestCurrentUser());
        callback(res.status, res.data?.isOnboarded);
      }
    } catch (err) {
      console.warn(err);
      callback(err.status, false);
      Sentry.captureException(err);
    }
    yield put(AppActions.setAppLoader(false));
  }

  static *addOnboarding({ payload }) {
    const { goals, picture, level, callback } = payload;
    try {
      yield put(AppActions.setAppLoader(true));
      const userGoals = [
        { goalId: GoalTypes.INTENSITY, value: goals[GoalTypes.INTENSITY] },
        { goalId: GoalTypes.PREHENSION, value: goals[GoalTypes.PREHENSION] },
        { goalId: GoalTypes.PHYSIQUE, value: goals[GoalTypes.PHYSIQUE] },
        { goalId: GoalTypes.TECHNIQUE, value: goals[GoalTypes.TECHNIQUE] },
        { goalId: GoalTypes.DEPLACEMENT, value: goals[GoalTypes.DEPLACEMENT] },
      ];

      const result = yield client.mutate({
        fetchPolicy: 'network-only',
        mutation: AddOnboarding,
        variables: {
          userGoals,
          picture,
          startLevel: level,
          cgvaccepted: true,
        },
      });

      if (result) {
        yield put(UserActions.addCurrentUser(result.data.addOnboarding));
        yield put(AuthActions.setIsOnboarded(true));
        yield put(AuthActions.skipOnboarding(false));
        callback(200); //query succes
        yield put(AppActions.setAppLoader(false));
      }
    } catch (err) {
      console.warn(err);
      callback();
      yield put(AppActions.setAppLoader(false));
      Sentry.captureException(err);
    }
  }

  static *updateAccount({ payload }) {
    const { info } = payload;

    yield put(AppActions.setAppLoader(true));
    try {
      const res = yield axios.put(UPDATE_ACCOUNT, info, {
        headers: getHeader(),
      });
      if (res) {
        yield put(UserActions.requestCurrentUser());
      }
      yield put(AppActions.setAppLoader(false));
    } catch (err) {
      console.warn(err);
      // Sentry.captureException(err);
      yield put(AppActions.setAppLoader(false));
    }
  }

  static *loop() {
    yield all([
      takeLatest(AccountActions.requestUpdateUsername.getType(), AccountSagas.updateUsername),
      takeLatest(AccountActions.requestEmailVerification.getType(), AccountSagas.emailVerification),
      takeLatest(
        AccountActions.requestEmailCodeValidation.getType(),
        AccountSagas.emailCodeValidation
      ),
      takeLatest(AccountActions.requestClubs.getType(), AccountSagas.requestClubs),
      takeLatest(AccountActions.requestCreateAccount.getType(), AccountSagas.createAccount),
      takeLatest(AccountActions.requestSyncUserDeciplus.getType(), AccountSagas.syncUserDeciplus),
      takeLatest(AccountActions.sendResetPassword.getType(), AccountSagas.resetPassword),
      takeLatest(AccountActions.requestResetPassword.getType(), AccountSagas.askResetPassword),
      takeLatest(AccountActions.addOnboarding.getType(), AccountSagas.addOnboarding),
      takeLatest(AccountActions.updateAccount.getType(), AccountSagas.updateAccount),
    ]);
  }
}
