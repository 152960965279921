import IconTypes from '../../../types/IconTypes';
import ROUTES from '../../constants';

export interface FooterItemProps {
  name: string;
  path: string;
  icon: string;
  disabled?: boolean;
}

const FooterTypes = {
  main: 'MainFooter',
  news: 'NewsFooter',
};

export const MainFooter = [
  {
    name: 'Accueil',
    path: ROUTES.HOME,
    icon: IconTypes.HOME,
  },
  {
    name: 'Ma carte',
    path: ROUTES.MY_CARD,
    icon: IconTypes.TICKET,
  },
  {
    name: 'Boutique',
    path: ROUTES.STORE,
    icon: IconTypes.BOUTIQUE,
  },
  {
    name: 'Mon profil',
    path: ROUTES.PROFILE,
    icon: IconTypes.PROFILE,
    needsAuth: true,
  },
];

export const NewsFooter = [
  {
    name: 'Accueil',
    path: ROUTES.HOME,
    icon: IconTypes.HOME,
  },
  {
    name: 'Ma carte',
    path: ROUTES.MY_CARD,
    icon: IconTypes.TICKET_VERTICAL,
  },
  {
    name: 'Blocs',
    path: ROUTES.ROOM,
    icon: IconTypes.BLOCS,
  },
  {
    name: 'Entraînement',
    path: ROUTES.COACH,
    icon: IconTypes.ENTRAINEMENT,
    needsAuth: true,
  },
  {
    name: 'Communauté',
    path: ROUTES.COMMUNITY,
    icon: IconTypes.COMMUNAUTE,
    disabled: false,
  },
];

export default FooterTypes;
