import { useCallback, useMemo } from 'react';
import { useLocalize } from '../../redux/translation/localize';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { AccessDto } from '../../types/AccessTypes';

import Ticket from '../Ticket/Ticket';
import { trackEvent } from '../../providers/tracker';
import { useLiberfitShop } from '../../redux/user/hooks';
import { LiberfitUrl } from '../../utils/shop.utils';

const Access = ({ access }: { access?: AccessDto }) => {
  const t = useLocalize();
  const { liberfitToken } = useLiberfitShop('accessList');

  const [accessClassName, rechargeClassName] = useMemo(() => {
    const classNameList = ['access', 'recharge'];
    if (access) {
      return classNameList;
    }
    return classNameList.map((className) => `${className} loading-animation`);
  }, [access]);
  const typeOfAccess = useMemo(() => {
    if (!!access?.end_date) return 'renew';
    return 'recharge';
  }, [access?.end_date]);

  const handleRenew = useCallback(() => {
    trackEvent('ticket_renew');

    InAppBrowser.create(
      liberfitToken
        ? `${LiberfitUrl}/shop?token=${liberfitToken}`
        : `https://moncompte.climbingdistrict.com/boutique-mobile`
    );
  }, [liberfitToken]);

  return (
    <div className={accessClassName}>
      <Ticket access={access} isBig={true} />
      <div className='infos'>
        <div className='title'>{access?.subscription_name ?? ''}</div>
        {/* <div className='priceType'>{access?.priceType ? t(access.priceType) : ''}</div> */}
      </div>
      <button onClick={handleRenew} className={rechargeClassName}>
        {t(typeOfAccess)}
      </button>
    </div>
  );
};

export default Access;
