import React, { useEffect } from 'react';
import { IonList } from '@ionic/react';

import room from '../../utils/room.interface';
import { useLocalize } from '../../redux/translation/localize';
import UserCard from '../../components/LeaderBoard/partials/UserCard/UserCard';

import './UsersRanking.scss';
import { useLeaderboard, useMyRank, useRefreshLeaderboard } from '../../redux/leaderBoard/hooks';
import Loader from '../../atoms/Loader/Loader';

interface Props {
  room?: room;
  displayUserRanking: (userId: number) => void;
}

const UsersRanking: React.FC<Props> = ({ room, displayUserRanking }) => {
  const t = useLocalize();
  const { isByUserColor, userColor, userRank, currentUser, userScore, userColorRank } = useMyRank(
    room?.id
  );
  const { loadMore, leaderBoardsGlobal, isFetching, isFullLoaded } = useLeaderboard(room?.id);

  const { refreshLeaderboard } = useRefreshLeaderboard();

  useEffect(() => {
    refreshLeaderboard(room?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room?.id, isByUserColor, userColor]);

  return (
    <div className='usersRanking'>
      <div className='ranking'>
        <div className='sectionName'>{t('ranking_community')}</div>

        <IonList className='userList'>
          {leaderBoardsGlobal.map((item, index) => (
            <UserCard
              key={index}
              user={item}
              boardLength={leaderBoardsGlobal.length}
              displayUserRanking={displayUserRanking}
              forcedColorId={isByUserColor ? userColor : undefined}
            />
          ))}
        </IonList>

        {isFetching && <Loader />}
        {!isFullLoaded && !isFetching && (
          <div className='loadMoreButton' onClick={loadMore}>
            {t('load_more')}
          </div>
        )}
        {!!currentUser && !!userRank && (
          <UserCard
            user={{
              id: currentUser.id,
              rank: isByUserColor ? userColorRank : userRank,
              colorId: userColor,
              firstName: currentUser.mindFlurryUser.firstName,
              lastName: currentUser.mindFlurryUser.lastName,
              login: currentUser.login,
              picture: currentUser.picture,
              points: userScore,
            }}
            boardLength={0}
            isMyUser
            displayUserRanking={displayUserRanking}
          />
        )}
      </div>
    </div>
  );
};

export default UsersRanking;
