// Components
import Home from '../pages/Home/Home';
import Actualite from '../pages/Actualite/Actualite';
import Entrainement from '../pages/Entrainement/Entrainement';
import PlanEntrainement from '../pages/PlanEntrainement/PlanEntrainement';
import Community from '../pages/Community/Community';
import Room from '../pages/Room/Room';
import Bloc from '../pages/Bloc/Bloc';
import Sector from '../pages/Sector/Sector';
import Profil from '../pages/Profil/Profil';
import MaCarte from '../pages/MaCarte/MaCarte';
import Ouverture from '../pages/Ouverture/Ouverture';
import CoachHome from '../pages/CoachHome/CoachHome';
import Login from '../pages/Login/Login';
import BlocOpening from '../pages/BlocOpening/BlocOpening';
import CoachEditing from '../pages/CoachEditing/CoachEditing';
import Qrcode from '../pages/Qrcode/Qrcode';
import Boutique from '../pages/Boutique/Boutique';
import VideoCreator from '../pages/VideoCreator/VideoCreator';
import OnboardingGoals from '../pages/OnboardingGoals/OnboardingGoals';

// On Standby
// import Boutique from '../pages/Boutique/Boutique';

// Footer
import footerTypes from './components/MainFooter/footerTypes';
import ROUTES from './constants';
import CreateAccount from '../pages/CreateAccount/CreateAccount';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';

interface RoutesProps {
  path: string;
  component: any;
  footer?: string;
  needOnboarding?: boolean;
}

const routes: RoutesProps[] = [
  {
    path: ROUTES.ACTUALITY,
    component: Actualite,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: `${ROUTES.BLOC}/:blocId`,
    component: Bloc,
    needOnboarding: true,
  },
  {
    path: ROUTES.STORE,
    component: Boutique,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: ROUTES.COMMUNITY,
    component: Community,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: ROUTES.TRAINING,
    component: Entrainement,
    needOnboarding: true,
  },
  {
    path: ROUTES.TRAINING_EDIT,
    component: CoachEditing,
    needOnboarding: true,
  },
  {
    path: ROUTES.TRAINING_PLAN,
    component: PlanEntrainement,
    needOnboarding: true,
  },
  {
    path: ROUTES.HOME,
    component: Home,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: ROUTES.COACH,
    component: CoachHome,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: ROUTES.LOGIN,
    component: Login,
  },
  {
    path: ROUTES.CREATE_ACCOUNT,
    component: CreateAccount,
  },
  {
    path: ROUTES.MY_CARD,
    component: MaCarte,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: ROUTES.OPENING,
    component: Ouverture,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: ROUTES.OPENING_BLOC,
    component: BlocOpening,
    needOnboarding: true,
  },
  {
    path: ROUTES.PROFILE,
    component: Profil,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: `${ROUTES.PROFILE}/:userId`,
    component: Profil,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: ROUTES.ROOM,
    component: Room,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: `${ROUTES.SECTOR}/:sectorId`,
    component: Sector,
    needOnboarding: true,
  },
  {
    path: `${ROUTES.RECORD}/:sectorId/:boulderId`,
    component: VideoCreator,
    needOnboarding: true,
  },
  {
    path: ROUTES.QR_CODE,
    component: Qrcode,
    footer: footerTypes.news,
    needOnboarding: true,
  },
  {
    path: ROUTES.ONBOARDING_GOALS,
    component: OnboardingGoals,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
];

export default routes;
