import React, { useCallback } from 'react';

import './FilterCard.scss';

interface Props {
  selectedFilter: number[];
  filterChange: (id: number | string, name?: string) => void;
  filter: any;
}

const Filter: React.FC<Props> = ({ filter, selectedFilter, filterChange }) => {
  const handleSelect = useCallback(() => {
    filterChange(filter.id, filter.name);
  }, [filter.id, filter.name, filterChange]);

  return (
    <div
      className='individualTag'
      style={{
        backgroundColor: selectedFilter.includes(filter.id) ? 'black' : '',
        color: selectedFilter.includes(filter.id) ? 'white' : 'black',
      }}
      onClick={handleSelect}
    >
      {filter.name}
    </div>
  );
};

export default Filter;
