import { createAction, createReducer } from 'redux-act';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  availableLanguages: [],
  debugKeys: false,
});

export const actions = {
  requestLoadDefaultLanguage: createAction('request load default language'),
  requestLoadLanguages: createAction('request load languages', (lang) => ({ lang })),
  loadLanguagesSuccess: createAction('loadLanguagesSuccess'),
  setAvailableLanguages: createAction('setAvailableLanguages', (availableLanguages) => ({
    availableLanguages,
  })),
  setLanguage: createAction('set language', (lang) => ({ lang })),
  setDebugKeys: createAction('setDebugKeys'),
};

export const reducer = createReducer(
  {
    [actions.setAvailableLanguages.toString()]: (state, { availableLanguages }) =>
      state.merge({ availableLanguages }),
    [actions.setDebugKeys.toString()]: (state) => state.merge({ debugKeys: !state.debugKeys }),
  },
  initialState
);

export const selectors = {
  availableLanguages: (state) => state.translation.availableLanguages,
  lang: (state) => state.i18nState.lang,
  currentTranslation: (state) => state.i18nState.translations[state.i18nState.lang],
  debugKeys: (state) => state.translation.debugKeys || false,
};
