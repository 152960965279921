import { gql } from '@apollo/client';

export const BoulderVideoFragment = gql`
  fragment BoulderVideoFragment on BoulderVideo {
    id
    type
    video {
      id
      url
      jobName
      previewImage
      date
      userId
      user {
        id
        login
      }
    }
    boulderId
  }
`;

export const getBoulderVideos = gql`
  query getBoulderVideos(
    $boulderId: Int!
    $type: BoulderVideoType
    $skip: Int
    $limit: Int
    $orderBy: SortOrder
    $userId: Int
  ) {
    boulder(id: $boulderId) {
      boulderVideos(
        skip: $skip
        limit: $limit
        type: $type
        orderByVideoDate: $orderBy
        userId: $userId
      ) {
        ...BoulderVideoFragment
      }
    }
  }
  ${BoulderVideoFragment}
`;

export const createBoulderVideo = gql`
  mutation createBoulderVideo(
    $boulderId: Int!
    $jobName: String!
    $url: String!
    $previewImage: String!
  ) {
    createBoulderVideo(
      createBoulderArgs: {
        boulderId: $boulderId
        url: $url
        jobName: $jobName
        previewImage: $previewImage
      }
    ) {
      ...BoulderVideoFragment
    }
  }
  ${BoulderVideoFragment}
`;

export const deleteBoulderVideo = gql`
  mutation deleteBoulderVideo($videoId: Int!) {
    deleteBoulderVideo(deleteBoulderVideo: { id: $videoId }) {
      id
    }
  }
`;
