export const addBoulder = (state, { boulder, sectorId }) => {
  if (!boulder) return state;

  const path =
    (sectorId || sectorId === 0 ? ['sectorById', sectorId, 'boulders'] : ['boulderIds']) || [];
  let boulderIds = state.getIn(path) || [];
  if (boulderIds.asMutable) {
    boulderIds = boulderIds.asMutable();
  }
  if (!boulderIds.includes(boulder.id)) {
    boulderIds.push(boulder.id);
  }

  state = state.setIn(['boulderById', boulder.id], {
    ...state.boulderById[boulder.id],
    ...boulder,
  });
  return state.setIn(path, boulderIds);
};

export const addBoulders = (state, { boulders, sectorId, clean }) => {
  if (!boulders) return state;
  if (clean) {
    state = state.setIn(['boulderIds'], []);
  }
  boulders.forEach((boulder) => (state = addBoulder(state, { boulder, sectorId })));
  return state;
};

export const addSector = (state, { sector }) => {
  if (!sector) return state;

  let sectorIds = state.getIn(['sectorIds']) || [];
  if (sectorIds.asMutable) {
    sectorIds = sectorIds.asMutable();
  }
  if (!sectorIds.includes(sector.id)) {
    sectorIds.push(sector.id);
  }

  state = state.setIn(['sectorById', sector.id], sector);
  state = state.setIn(['sectorById', sector.id, 'boulders'], []);

  if (sector.boulders) {
    state = addBoulders(state, { boulders: sector.boulders, sectorId: sector.id });
  }

  return state.setIn(['sectorIds'], sectorIds);
};

export const addSectors = (state, { sectors, clean }) => {
  if (clean) {
    state = state.setIn(['sectorIds'], []);
  }
  if (!sectors) return state;

  sectors.forEach((sector) => (state = addSector(state, { sector, clean })));
  return state;
};

export const updateClap = (state, { boulderId, value }) => {
  const claps = state.getIn(['boulderById', boulderId, 'claps']) || 0;
  return state.setIn(['boulderById', boulderId, 'claps'], claps + value);
};

export const addSectorList = (state, { ids, reset }) => {
  if (reset) {
    state = state.setIn(['sectorList'], []);
  }
  const list = state.sectorList.asMutable();

  if (!!ids) {
    ids?.forEach((id) => {
      if (!list.includes(id)) {
        list.push(id);
      }
    });
    state = state.setIn(['sectorList'], list);
  }
  return state;
};

export const addBoulderList = (state, { ids, reset }) => {
  if (reset) {
    state = state.setIn(['boulderList'], []);
  }
  const list = state.boulderList.asMutable();

  if (!!ids) {
    ids?.forEach((id) => {
      if (!list.includes(id)) {
        list.push(id);
      }
    });
    state = state.setIn(['boulderList'], list);
  }
  return state;
};

export const addValidatedBoulder = (state, { ids, reset }) => {
  if (reset) {
    state = state.setIn(['validatedBoulder'], []);
  }
  const list = state.validatedBoulder.asMutable();

  if (!!ids) {
    ids?.forEach((id) => {
      if (!list.includes(id)) {
        list.push(id);
      }
    });
    state = state.setIn(['validatedBoulder'], list);
  }
  return state;
};

export const updateBoulderValidationConditions = (state, { boulderId, validationConditions }) => {
  return state.setIn(['boulderById', boulderId, 'validationConditions'], validationConditions);
};
