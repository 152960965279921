import { client } from '../../App';
import { all, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { actions as AppActions } from '../app/redux';
import { actions as CoachingActions } from './redux';
import { actions as UserActions } from '../user/redux';
import { actions as LeaderBoardActions } from '../leaderBoard/redux';

import {
  GetCoaching,
  CreateSession,
  getSession,
  AddSessionsBoulder,
} from '../../GraphQL/GetCoaching';
import { GetPassedSessionBoulder, GetActiveSession } from '../../GraphQL/GetSessions';

export default class CoachingSagas {
  static *loadSessionBoulders({ payload }) {
    try {
      yield put(CoachingActions.setFetching(true));
      const { volume, time, roomId } = payload;
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetCoaching,
        variables: { volume, time, roomId },
      });
      if (result?.data) {
        yield put(CoachingActions.addSessionBoulders(result.data.coaching));
        yield put(CoachingActions.clearSession());
        yield put(CoachingActions.setFullLoaded(true));
      }
      yield put(CoachingActions.setFetching(false));
    } catch (err) {
      console.warn(err);
      yield put(CoachingActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *createSession({ payload }) {
    try {
      const { volume, roomId, boulderIds } = payload;
      yield client.mutate({
        fetchPolicy: 'network-only',
        mutation: CreateSession,
        variables: { volume, roomId, boulderIds },
      });
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *terminateSession() {
    try {
      yield put(UserActions.requestLastSession());
      yield put(CoachingActions.incrementNb());
      yield put(CoachingActions.addSessionAudits([]));
      yield put(LeaderBoardActions.requestUserRankUpdate());
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadSession({ payload }) {
    try {
      const { id } = payload;
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: getSession,
        variables: { id },
      });
      if (result?.data) {
        yield put(CoachingActions.addSession(result.data.session));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadSessionAudits({ payload }) {
    try {
      const { sessionId } = payload;
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetPassedSessionBoulder,
        variables: {
          sessionId,
        },
      });
      if (result?.data) {
        yield put(CoachingActions.addSessionAudits(result.data.sessionbouldersbysession));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadCoachSession({ payload }) {
    try {
      const { roomId } = payload;
      if (roomId) {
        const result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetActiveSession,
          variables: {
            roomId,
          },
        });
        if (result?.data) {
          yield put(CoachingActions.addCoachSession(result.data.activesession));
        }
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *updateSession({ payload }) {
    try {
      const { sessionId, boulderId, iscoaching, isvalidated } = payload;
      const result = yield client.mutate({
        fetchPolicy: 'network-only',
        mutation: AddSessionsBoulder,
        variables: { sessionId, boulderId, iscoaching, isvalidated },
      });
      if (result?.data) {
        console.info('session boulder added ', result.data);
        yield put(CoachingActions.requestSession(sessionId));
        yield put(UserActions.requestLastSession());
        yield put(CoachingActions.incrementNb());
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *logout() {
    try {
      yield put(CoachingActions.cleanCoaching());
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(
        CoachingActions.requestSessionBoulders.getType(),
        CoachingSagas.loadSessionBoulders
      ),
      takeLatest(CoachingActions.createSession.getType(), CoachingSagas.createSession),
      takeLatest(CoachingActions.terminateSession.getType(), CoachingSagas.terminateSession),
      takeLatest(CoachingActions.requestSession.getType(), CoachingSagas.loadSession),
      takeLatest(CoachingActions.requestSessionAudits.getType(), CoachingSagas.loadSessionAudits),
      takeLatest(CoachingActions.requestCoachSession.getType(), CoachingSagas.loadCoachSession),
      takeLatest(CoachingActions.updateSession.getType(), CoachingSagas.updateSession),
      takeLatest(AppActions.requestAppLogout.getType(), CoachingSagas.logout),
    ]);
  }
}
