import ROUTES from '../../routes/constants';

const screenOverrides = {
  '/': 'landing_page',
  [ROUTES.LOGIN]: 'landing_page',
  '/login2': 'login',
  [ROUTES.ONBOARDING_GOALS]: 'onboarding_specs',
  [ROUTES.CREATE_ACCOUNT]: 'onboarding_details',
  [ROUTES.HOME]: 'home',
  [ROUTES.ROOM]: 'room',
  [ROUTES.BLOC]: 'bloc_modal',
  [ROUTES.SECTOR]: 'bloc_modal',
  [ROUTES.MY_CARD]: 'ma_carte',
  [ROUTES.PROFILE]: 'profile',
  [ROUTES.COMMUNITY]: 'community',
  [ROUTES.COACH]: 'coach',
  [ROUTES.TRAINING]: 'entrainement',
  [ROUTES.TRAINING_PLAN]: 'entrainement_plan',
  [ROUTES.TRAINING_EDIT]: 'entrainement_edit',
};

export default screenOverrides;
