import React, { useEffect, useMemo } from 'react';

// Hooks
import { useLogged } from '../../../redux/auth/hooks';
import { useColors } from '../../../redux/filters/hooks';
import { useCurrentUser, useLiberfitShop } from '../../../redux/user/hooks';
import { useMyRank } from '../../../redux/leaderBoard/hooks';
import { useSelectedRoom } from '../../../redux/rooms/hooks';
import { useQrcode, useUserColor } from '../../../redux/user/hooks';

// Components
import Icon from '../../../atoms/Icon/Icon';
import { IonSpinner } from '@ionic/react';
import ProfilePicture from '../../../components/ProfilePicture/ProfilePicture';

// Styles & Utils
import './HomeCarte.scss';
import IconTypes from '../../../types/IconTypes';
import getBoulderColor from '../../../utils/getBoulderColor';
import QrCodePreview from '../../../components/QrCodePreview/QrCodePreview';

const DEFAULT_QRCODE = 'https://youtu.be/dQw4w9WgXcQ';

const HomeCarte: React.FC<unknown> = () => {
  const { logged } = useLogged();
  const { room } = useSelectedRoom();
  const { userColor } = useMyRank(room?.id);
  const { getColors, colors } = useColors();
  const { currentUser, requestCurrentUser } = useCurrentUser();
  const { requestUserGlobalColor, userGlobalColor } = useUserColor();
  const { getIframeToken } = useLiberfitShop('ma_carte');
  const { requestQrcodes, isFetchingQrCode, qrCode, resetSelectedQrcode } = useQrcode();

  const name = useMemo(() => {
    if (!currentUser) return '';
    if (!currentUser?.mindFlurryUser?.firstName && !currentUser?.mindFlurryUser?.lastName)
      return '';
    if (!currentUser?.mindFlurryUser?.firstName) return currentUser?.mindFlurryUser?.lastName;
    if (!currentUser?.mindFlurryUser?.lastName) return currentUser?.mindFlurryUser?.firstName;
    return `${currentUser?.mindFlurryUser?.firstName} ${currentUser?.mindFlurryUser?.lastName}`;
  }, [currentUser]);

  const { color } = useMemo(
    () => getBoulderColor(colors.find((col) => col.id === userGlobalColor)?.name),
    [colors, userGlobalColor]
  );

  useEffect(() => {
    getColors();
    getIframeToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logged) {
      if (!currentUser) requestCurrentUser();
      requestUserGlobalColor();
      requestQrcodes();
      resetSelectedQrcode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged]);

  const leftBorderStyle = useMemo(() => ({ borderLeftColor: color }), [color]);
  const borderStyle = useMemo(() => ({ borderColor: color }), [color]);
  const backgroundStyle = useMemo(
    () => ({ backgroundColor: color, color: !color?.length ? '#000' : '#FFF' }),
    [color]
  );

  return (
    <div className='homeCartePage'>
      <div className='idContainer' style={borderStyle}>
        <div className='pictureAndQrc'>
          <div className='picture'>
            {logged && (
              <ProfilePicture
                logged={true}
                border='none'
                user={currentUser}
                classProps='largeProfile'
                crowned={false}
                link={false}
                userColor={userColor}
              />
            )}
            {!logged && <Icon className='iconProfil' icon={IconTypes.ICON_PROFILE} />}
          </div>
          <div className='qrcContainer' style={leftBorderStyle}>
            {!!isFetchingQrCode && (
              <div className='spinnerLoader'>
                <IonSpinner name='crescent' />
              </div>
            )}
            <QrCodePreview
              qrCode={qrCode || DEFAULT_QRCODE}
              enabled={logged && !!currentUser && !!qrCode}
              onClick={requestQrcodes}
            />
          </div>
        </div>
        <div className='maCarteName' style={backgroundStyle}>
          {logged ? name : 'Hello !'}
        </div>
      </div>
    </div>
  );
};

export default HomeCarte;
