import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

const initialState = Immutable({
  sectors: [],
  sector: {},

  isFetching: false,
  isFullLoaded: false,
});

export const actions = {
  requestFilteredSectors: createAction('request filtered sectors', (roomId = null, orderBy) => ({
    roomId,
    orderBy,
  })),
  requestSector: createAction('request single sector', (id) => ({ id })),

  addSectors: createAction('add sectors', (sectors) => ({ sectors })),
  addSector: createAction('add sector', (sector) => ({ sector })),
  sectorReset: createAction('reset sector'),

  setFetching: createAction('setFetching', (value) => ({ value })),
  setFullLoaded: createAction('setFullLoaded', (value) => ({ value })),
};

export const reducer = createReducer(
  {
    [actions.addSectors]: (state, { sectors }) => state.setIn(['sectors'], sectors),
    [actions.addSector]: (state, { sector }) => state.setIn(['sector'], sector),
    [actions.sectorReset]: (state) => state.setIn(['sector'], initialState.sector),

    [actions.setFetching.toString()]: (state, { value }) => state.setIn(['isFetching'], value),
    [actions.setFullLoaded.toString()]: (state, { value }) => state.setIn(['isFullLoaded'], value),
  },
  initialState
);

export const selectors = {
  sector: (state) => state.sectors.sector,
  sectors: (state) => state.sectors.sectors,
  isFetching: (state) => state.sectors.isFetching,
  isFullLoaded: (state) => state.sectors.isFullLoaded,
};
