import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as MediasActions, selectors as MediasSelector } from './redux';

const uploadType = {
  MEDIA: 1,
  DEMO: 2,
  EDITING: 3,
};

export const useMedias = () => {
  const dispatch = useDispatch();

  const uploadedMedia = useSelector((state) => MediasSelector.uploadedMedia(state));
  const isLoading = useSelector((state) => MediasSelector.isLoading(state));
  const uploadFail = useSelector((state) => MediasSelector.uploadFail(state));
  const uploadSuccess = useSelector((state) => MediasSelector.uploadSuccess(state));
  const resetMedia = useCallback(() => dispatch(MediasActions.resetMedia()), [dispatch]);
  const uploadMedia = useCallback(
    (path, type = uploadType.MEDIA) => dispatch(MediasActions.requestUploadMedia(path, type)),
    [dispatch]
  );

  return {
    uploadedMedia,
    isLoading,
    uploadFail,
    uploadSuccess,
    resetMedia,
    uploadMedia,
  };
};

export const useDemoMedias = () => {
  const dispatch = useDispatch();

  const uploadedDemo = useSelector((state) => MediasSelector.uploadedDemo(state));
  const isLoading = useSelector((state) => MediasSelector.isLoading(state));
  const uploadFail = useSelector((state) => MediasSelector.uploadFail(state));
  const uploadSuccess = useSelector((state) => MediasSelector.uploadSuccess(state));
  const resetDemo = useCallback(() => dispatch(MediasActions.resetDemo()), [dispatch]);
  const uploadDemo = useCallback(
    (path, type = uploadType.DEMO) => dispatch(MediasActions.requestUploadMedia(path, type)),
    [dispatch]
  );

  return {
    uploadedDemo,
    isLoading,
    uploadFail,
    uploadSuccess,
    resetDemo,
    uploadDemo,
  };
};

export const useMediaEditing = () => {
  const dispatch = useDispatch();

  const uploadedEdition = useSelector((state) => MediasSelector.uploadedEdition(state));
  const isLoading = useSelector((state) => MediasSelector.isLoading(state));
  const uploadFail = useSelector((state) => MediasSelector.uploadFail(state));
  const uploadSuccess = useSelector((state) => MediasSelector.uploadSuccess(state));
  const resetEdition = useCallback(() => dispatch(MediasActions.resetEdition()), [dispatch]);
  const uploadEdition = useCallback(
    (path, type = uploadType.EDITING) => dispatch(MediasActions.requestUploadMedia(path, type)),
    [dispatch]
  );

  return {
    uploadedEdition,
    isLoading,
    uploadFail,
    uploadSuccess,
    resetEdition,
    uploadEdition,
  };
};
