import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormattedDate } from '../../redux/common/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useColors } from '../../redux/filters/hooks';

import Icon from '../../atoms/Icon/Icon';

import './MyRank.scss';
import IconTypes from '../../types/IconTypes';
import room from '../../utils/room.interface';
import { color } from '../../utils/filter.interface';
import getBoulderColor from '../../utils/getBoulderColor';
import { useLogged } from '../../redux/auth/hooks';

import { useMyRank, useSetByColor } from '../../redux/leaderBoard/hooks';
import { IonToggle } from '@ionic/react';

const TOPRANK = 10; //to be decided

interface Props {
  date: Date;
  room: room;
  displayInfo: boolean;
  setDisplayInfo: (value: boolean) => void;
  isDetailed: boolean;
  onClick?: () => void;
}

const MyRank: React.FC<Props> = ({ date, room, displayInfo, setDisplayInfo, onClick }) => {
  const t = useLocalize();
  const { userColor, userColorRank, userRank, isByUserColor } = useMyRank(room?.id);
  const { logged } = useLogged();
  const { colors, getColors } = useColors();
  const { setIsByUserColor } = useSetByColor();
  const formatedDate = useFormattedDate(
    date,
    {
      month: 'long',
      year: 'numeric',
    },
    [date]
  );

  useEffect(() => {
    if (!colors.length) {
      getColors();
    }
  }, [colors.length, getColors, room]);

  const color = useMemo(() => {
    const name = colors.find((color: color) => color.id === userColor)?.name;
    return getBoulderColor(name).color;
  }, [colors, userColor]);

  const handleInfoModal = useCallback(() => {
    setDisplayInfo(true);
  }, [setDisplayInfo]);

  return (
    <div className='myRankContainer' onClick={onClick}>
      <div className='myRankTitle'>
        <div className='title'>{t('my_ranking')}</div>
        <div className='myRankDate'>{formatedDate}</div>
        <Icon icon={IconTypes.QUESTION} className='questionIcon' onClick={handleInfoModal} />
      </div>
      {displayInfo && (
        <div className='infoModal'>
          <div className='infoRank'>
            <div className='infoRankTitle'>{t('info_rank_title')}</div>
            <div className='infoRankDescription'>{t('info_rank_general')}</div>
            <div className='infoRankDescription'>{t('info_rank_color')}</div>
          </div>
          <div className='infoNotch' />
        </div>
      )}
      <div className='infoBackDrop' />
      {logged && (
        <div className='myRank'>
          {/* {isDetailed && <MyRankDetails myUser={currentUser} color={color} score={userScore} />} */}
          <div className='myRankContent'>
            <div className='myRankPosition'>
              <div className='rankArrows'>
                <Icon icon={IconTypes.RANK_ARROW} className='upArrow' />
                <Icon icon={IconTypes.RANK_ARROW} className='downArrow' />
              </div>
              <div className='rankNumber'>
                <div className='rank'>{userRank || '-'}</div>
                <div className='rankSorting'>{t('my_rank_sorting_global')}</div>
              </div>
            </div>
            <div className='myRankPositionColor'>
              <div className='rankArrows'>
                {!!userColorRank && userColorRank < TOPRANK ? (
                  <div className='myRankCrown'>
                    <Icon icon={IconTypes.CROWN_FULL} className='crownIcon' style={{ color }} />
                  </div>
                ) : (
                  <div className='myRankSquare' style={{ backgroundColor: color }}></div>
                )}
              </div>
              <div className='rankNumber'>
                <div className='rank'>{userColorRank || '-'}</div>
                <div className='rankSorting'>{t('my_rank_sorting_color')}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {logged && userColor && (
        <div className='toggleByColorLeaderboard'>
          {t('display_by_user_best_color')}
          <IonToggle checked={isByUserColor} onClick={setIsByUserColor}></IonToggle>
        </div>
      )}
    </div>
  );
};

export default MyRank;
