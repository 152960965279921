const ROUTES = {
  ACTUALITY: '/actualite',
  BLOC: '/bloc',
  CAMERA: '/camera',
  CODE_CHECKER: '/check-code',
  COMMUNITY: '/community',
  HOME: '/home',
  COACH: '/coach',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/reset-password',
  CREATE_ACCOUNT: '/create-account',
  MY_CARD: '/ma_carte',
  ONBOARDING_GOALS: '/onboarding/goals',
  OPENING: '/ouverture',
  OPENING_BLOC: '/ouverture/bloc',
  PROFILE: '/profile',
  QR_CODE: '/qr_code',
  RECORD: '/record',
  ROOM: '/room',
  SECTOR: '/sector',
  STORE: '/boutique',
  TRAINING: '/entrainement',
  TRAINING_EDIT: '/entrainement/edition',
  TRAINING_PLAN: '/entrainement/plan',
};

export default ROUTES;
