import { SocialSharing } from '@awesome-cordova-plugins/social-sharing';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { File } from '@ionic-native/file';

type ShareType = {
  // Not supported for some apps (Facebook, Instagram)
  message?: string;
  // Email subject
  subject?: string;
  // Filenames (locally or remotely)
  files?: string[];
  // Url to share
  url?: string;
  // App to open (Android Only)
  appPackageName?: string;
  // Share Sheet Title (Android Only)
  chooserTitle?: string;
  // IOS only iPadCoordinates for where the popover should be point.
  // Format with x,y,width,height
  iPadCoordinates?: `${number},${number},${number},${number}`;
  // For Particular made Share
  appName?: string;
};

const defaultInfo: ShareType = {
  files: [],
  message: 'Check out how good I am 💪 🧗‍♀️',
  iPadCoordinates: '0,0,0,0',
};

const shareToApp = (
  options: ShareType = {},
  video?: Blob | string,
  onFileLoad?: (val: boolean) => void,
  onSuccess?: (result: any) => void,
  onError?: (message: string) => void
) => {
  const handleSuccess = (result: any) => {
    // On Android apps mostly return false even while it's true
    console.info('Share completed? ' + result.completed);
    // On Android result.app since plugin version 5.4.0 this is no longer empty.
    // On iOS it's empty when sharing is cancelled (result.completed=false)
    console.info('Shared to app: ' + result.app);
    onSuccess?.(result);
  };

  const handleError = (msg) => {
    console.error('Sharing failed with message: ' + msg);
    onError?.(msg);
  };

  // Create file if not existant
  onFileLoad?.(true);
  (async () => {
    const files = [...(options.files || [])];
    if (video && typeof video !== 'string') {
      const videoUri = await Filesystem.getUri({ directory: Directory.Cache, path: '' });
      const videoFile = await File.writeFile(
        videoUri.uri,
        `shareable_${Number(Date.now())}.mp4`,
        video
      );
      files.push(videoFile.nativeURL);
    } else if (!!video) {
      files.push(video);
    }
    onFileLoad?.(false);

    // Share
    const { appName } = options;
    if (appName) {
      SocialSharing.shareVia(
        appName,
        options.message || '',
        options.subject,
        files[0],
        options.url
      );
    } else {
      SocialSharing.shareWithOptions({ ...defaultInfo, ...options, files })
        .then(handleSuccess)
        .catch(handleError);
    }
  })();
};

// const shareToApp = (dd: any) => {
//   Share.share({
//     title: 'sharing this',
//     text: 'so random text',
//     url: 'https://youtube.com',
//     dialogTitle: 'idk',
//   })
// };
export default shareToApp;
