import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as TagsActions, selectors as TagsSelector } from './redux';

export const useTags = () => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => TagsSelector.tags(state));
  const getTags = useCallback(() => dispatch(TagsActions.requestTags()), [dispatch]);

  return {
    // Selectors
    tags,

    // Actions
    getTags,
  };
};
