import { createAction, createReducer } from 'redux-act';
import Immutable from 'seamless-immutable';

// Reduxs
import * as cropEditor from './cropEditor/redux';
import * as display from './display/redux';
import * as drawEditor from './drawEditor/redux';
import * as tagEditor from './tagEditor/redux';
import * as textEditor from './textEditor/redux';
import * as tools from './tools/redux';

const initialState = Immutable({
  ...cropEditor.initialState,
  ...display.initialState,
  ...drawEditor.initialState,
  ...tagEditor.initialState,
  ...textEditor.initialState,
  ...tools.initialState,
});

export const actions = {
  ...cropEditor.actions,
  ...display.actions,
  ...drawEditor.actions,
  ...tagEditor.actions,
  ...textEditor.actions,
  ...tools.actions,
  clearEditor: createAction('clear editor'),
};

export const reducer = createReducer(
  {
    ...cropEditor.reducers,
    ...display.reducers,
    ...drawEditor.reducers,
    ...tagEditor.reducers,
    ...textEditor.reducers,
    ...tools.reducers,
    [actions.clearEditor]: (state) => state.merge({ ...initialState }),
  },
  initialState
);

export const selectors = {
  ...cropEditor.selectors,
  ...display.selectors,
  ...drawEditor.selectors,
  ...tagEditor.selectors,
  ...textEditor.selectors,
  ...tools.selectors,
};
