import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as LeaderBoardActions, selectors as LeaderBoardSelectors } from './redux.js';
import { actions as UserActions, selectors as UserSelectors } from '../user/redux.js';
import { trackEvent } from '../../providers/tracker';

export const useLeaderBoardGlobal = () => {
  const dispatch = useDispatch();

  const leaderBoardsGlobal = useSelector((state) => LeaderBoardSelectors.leaderBoardsGlobal(state));

  const isFetching = useSelector((state) => LeaderBoardSelectors.isFetching(state));

  const isFullLoaded = useSelector((state) => LeaderBoardSelectors.isFullLoaded(state));

  const getLeaderBoardsGlobal = useCallback(
    () => dispatch(LeaderBoardActions.requestLeaderBoardsGlobal()),
    [dispatch]
  );

  return {
    leaderBoardsGlobal,
    isFetching,
    isFullLoaded,
    getLeaderBoardsGlobal,
  };
};

export const useRoomLeaderBoard = () => {
  const dispatch = useDispatch();

  const roomLeaderBoard = useSelector((state) => LeaderBoardSelectors.roomLeaderBoard(state));

  const isFetching = useSelector((state) => LeaderBoardSelectors.isFetching(state));

  const isFullLoaded = useSelector((state) => LeaderBoardSelectors.isFullLoaded(state));

  const getRoomLeaderBoard = useCallback(
    (roomId, id) => dispatch(LeaderBoardActions.requestRoomLeaderBoard(roomId, id)),
    [dispatch]
  );

  return {
    roomLeaderBoard,
    isFetching,
    isFullLoaded,
    getRoomLeaderBoard,
  };
};

export const useColorsCount = () => {
  const dispatch = useDispatch();

  const colorsCount = useSelector((state) => LeaderBoardSelectors.colorsCount(state));

  const getColorsCount = useCallback(
    (roomId) => dispatch(LeaderBoardActions.requestColorsCount(roomId)),
    [dispatch]
  );

  return {
    colorsCount,
    getColorsCount,
  };
};

export const useMyRank = (roomId) => {
  const dispatch = useDispatch();
  const getUserBestColor = useCallback(
    (callback) => dispatch(UserActions.requestUserBestColor(roomId, callback)),
    [dispatch, roomId]
  );

  const userColor = useSelector((state) => UserSelectors.userColor(state));
  const userColorRank = useSelector((state) => UserSelectors.userColorRank(state));
  const userRank = useSelector((state) => UserSelectors.userRank(state));
  const currentUser = useSelector((state) => UserSelectors.currentUser(state));
  const userScore = useSelector((state) => UserSelectors.userScore(state));
  const isByUserColor = useSelector((state) => LeaderBoardSelectors.isByUserColor(state));

  const getUserRanks = useCallback(() => {
    dispatch(UserActions.requestUserScore(roomId, isByUserColor));
    dispatch(UserActions.requestUserColorRank(roomId, userColor));
    dispatch(UserActions.requestUserGlobalRank(roomId));
  }, [dispatch, roomId, isByUserColor, userColor]);

  const getUserRankUpdate = useCallback(() => {
    dispatch(LeaderBoardActions.requestUserRankUpdate());
  }, [dispatch]);

  return {
    getUserBestColor,
    getUserRanks,
    getUserRankUpdate,
    userColor,
    userColorRank,
    userRank,
    currentUser,
    userScore,
    isByUserColor,
  };
};

// export const useRankOnly = () => {
//   const userRank = useSelector((state) => UserSelectors.userRank(state));
//   const userColor = useSelector((state) => UserSelectors.userColor(state));

//   return {
//     userRank,
//     userColor,
//   };
// };

export const useSetByColor = () => {
  const dispatch = useDispatch();
  const isByUserColor = useSelector((state) => LeaderBoardSelectors.isByUserColor(state));

  const setIsByUserColor = useCallback(() => {
    trackEvent('leaderboard_color', { isByUserColor: !isByUserColor });
    dispatch(LeaderBoardActions.setIsByUserColor());
  }, [dispatch, isByUserColor]);

  return {
    setIsByUserColor,
  };
};

export const useLeaderboard = (roomId) => {
  const dispatch = useDispatch();

  const leaderBoardsGlobal = useSelector((state) => LeaderBoardSelectors.leaderBoardsGlobal(state));

  const isFetching = useSelector((state) => LeaderBoardSelectors.isFetching(state));

  const isFullLoaded = useSelector((state) => LeaderBoardSelectors.isFullLoaded(state));

  const getLeaderBoardsWithParams = useCallback(
    (callback) => dispatch(LeaderBoardActions.requestLeaderBoardsWithParams(roomId, callback)),
    [dispatch, roomId]
  );

  const loadMore = useCallback(
    () => dispatch(LeaderBoardActions.requestLoadMoreLeaderBoardsWithParams(roomId)),
    [dispatch, roomId]
  );
  return { getLeaderBoardsWithParams, loadMore, leaderBoardsGlobal, isFetching, isFullLoaded };
};

export const useUserBestBoulders = (roomId, userId) => {
  const dispatch = useDispatch();

  const getUserBestBoulders = useCallback(
    () => dispatch(LeaderBoardActions.requestUserBestBoulders(roomId, userId)),
    [dispatch, roomId, userId]
  );

  const bestBoulders = useSelector((state) => LeaderBoardSelectors.bestBoulders(state));
  const isFetching = useSelector((state) => LeaderBoardSelectors.isFetching(state));
  const isByUserColor = useSelector((state) => LeaderBoardSelectors.isByUserColor(state));

  return {
    getUserBestBoulders,
    bestBoulders,
    isFetching,
    isByUserColor,
  };
};

export const useRefreshLeaderboard = () => {
  const dispatch = useDispatch();

  const isByUserColor = useSelector((state) => LeaderBoardSelectors.isByUserColor(state));
  const isFetching = useSelector((state) => LeaderBoardSelectors.isFetching(state));

  const getUserBestColor = useCallback(
    (roomId, callback) => dispatch(UserActions.requestUserBestColor(roomId, callback)),
    [dispatch]
  );

  const getLeaderBoardsWithParams = useCallback(
    (roomId, callback) =>
      dispatch(LeaderBoardActions.requestLeaderBoardsWithParams(roomId, callback)),
    [dispatch]
  );
  const getUserRanks = useCallback(
    (roomId, userColor) => {
      dispatch(UserActions.requestUserScore(roomId, isByUserColor));
      dispatch(UserActions.requestUserColorRank(roomId, userColor));
      dispatch(UserActions.requestUserGlobalRank(roomId));
    },
    [dispatch, isByUserColor]
  );

  const refreshLeaderboard = useCallback(
    (roomId, callback) => {
      getUserBestColor(roomId, (userColor) => {
        dispatch(UserActions.requestUserScore(roomId, isByUserColor));
        dispatch(UserActions.requestUserColorRank(roomId, userColor));
        dispatch(UserActions.requestUserGlobalRank(roomId));
        getUserRanks(roomId, userColor);
        getLeaderBoardsWithParams(roomId, callback);
      });
    },
    [dispatch, getLeaderBoardsWithParams, getUserBestColor, getUserRanks, isByUserColor]
  );

  return {
    isFetching,
    refreshLeaderboard,
  };
};
