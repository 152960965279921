import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

const initialState = Immutable({
  uploadFail: false,
  uploadSuccess: false,
  isLoading: false,

  uploadedMedia: undefined,
  uploadedDemo: undefined,
  uploadedEdition: undefined,
});

export const actions = {
  requestUploadMedia: createAction('upload media', (path, type) => ({ path, type })),
  resetMedia: createAction('reset media'),
  resetDemo: createAction('reset demo media'),
  resetEdition: createAction('reset edition media'),

  addUploadedMedia: createAction('addUploadedMedia', (value) => ({ value })),
  addUploadedDemo: createAction('addUploadedDemo', (value) => ({ value })),
  addUploadEdition: createAction('addUploadedEditing', (value) => ({ value })),
  setIsLoading: createAction('isLoading', (value) => ({ value })),
  setUploadFail: createAction('setUploadFail', (value) => ({ value })),
  setUploadSuccess: createAction('setUploadSuccess', (value) => ({ value })),
};

export const reducer = createReducer(
  {
    [actions.addUploadedMedia]: (state, { value }) => state.setIn(['uploadedMedia'], value),
    [actions.addUploadedDemo]: (state, { value }) => state.setIn(['uploadedDemo'], value),
    [actions.addUploadEdition]: (state, { value }) => state.setIn(['uploadedEdition'], value),
    [actions.resetMedia]: (state) => state.setIn(['uploadedMedia'], initialState.uploadedMedia),
    [actions.resetDemo]: (state) => state.setIn(['uploadedDemo'], initialState.uploadedDemo),
    [actions.resetEdition]: (state) =>
      state.setIn(['uploadedEdition'], initialState.uploadedEdition),
    [actions.setIsLoading]: (state, { value }) => state.setIn(['isLoading'], value),
    [actions.setUploadFail]: (state, { value }) => state.setIn(['uploadFail'], value),
    [actions.setUploadSuccess]: (state, { value }) => state.setIn(['uploadSuccess'], value),
  },
  initialState
);

export const selectors = {
  uploadedMedia: (state) => state.medias.uploadedMedia,
  uploadedDemo: (state) => state.medias.uploadedDemo,
  uploadedEdition: (state) => state.medias.uploadedEdition,
  uploadFail: (state) => state.medias.uploadFail,
  isLoading: (state) => state.medias.isLoading,
  uploadSuccess: (state) => state.medias.uploadSuccess,
};
