import { useCallback, useMemo } from 'react';
import { useToken } from '../redux/auth/hooks';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { trackEvent } from '../providers/tracker';

export const LiberfitUrl = 'https://membres-climbing.lepitch.fr/customportal';

export const homeShopData = [
  {
    label: 'sessions_shop',
    icon: '🎟',
    link: '/',
    src: 'seances',
  },
  {
    label: 'sub',
    icon: '🧲',
    link: '/shop?category=391&item=',
    src: 'abonnements',
  },
  {
    label: 'classes',
    icon: '🤩',
    link: '/shop?category=438&item=',
    src: 'cours',
  },
  {
    label: 'camp',
    icon: '😎',
    link: '/shop',
    src: 'stages',
  },
  {
    label: 'yoga',
    icon: '🧘‍♀️',
    link: '/shop',
    src: 'yoga',
  },
  {
    label: 'strengthening',
    icon: '💪',
    link: '/shop',
    src: 'renforcement',
  },
];

export const SHOP_PAGES = {
  MOBILE_SHOP: 'boutique-mobile',
  BOOK_A_TRIAL: 'reserver-essai',
  ORDER_A_CALENDAR_LOL: 'commander-planning',
  BOOK_A_SESSION: 'boutique-mobile/#seances',
};

export const SHOP_EVENTS: { [name: string]: any } = {
  'boutique-mobile': 'shop',
  'reserver-essai': 'shop_class_plan',
  'commander-planning': 'shop_class_search',
  'boutique-mobile/#seances': 'shop',
};

const SHOP_LINK = {
  'boutique-mobile': '/shop',
  'reserver-essai': '/schedule',
  'commander-planning': '/schedule',
  'boutique-mobile/#seances': '/schedule',
};

export const useWebSiteShop = (page, liberfitToken) => {
  const { token } = useToken();
  const link = useMemo(() => SHOP_LINK[page], [page]);

  const handle = useCallback(() => {
    trackEvent('click_ma_carte_button', {
      event_category: 'ma_carte',
      event_action: SHOP_EVENTS[page],
    });
    InAppBrowser.create(token ? `${LiberfitUrl}${link}?token=${liberfitToken}` : LiberfitUrl);
  }, [page, token, link, liberfitToken]);

  return handle;
};
