import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as SessionsActions, selectors as SessionsSelector } from './redux';

export const useSessionsPerf = () => {
  const dispatch = useDispatch();

  const activity = useSelector((state) => SessionsSelector.activity(state));
  const coachActivity = useSelector((state) => SessionsSelector.coachActivity(state));
  const isFetching = useSelector((state) => SessionsSelector.isFetching(state));
  const isFullLoaded = useSelector((state) => SessionsSelector.isFullLoaded(state));

  const requestPeriodicActivity = useCallback(
    (dateStart, dateEnd, roomId, isHome) =>
      dispatch(SessionsActions.requestPeriodicActivity(dateStart, dateEnd, roomId, isHome)),
    [dispatch]
  );

  const resetPeriodicActivity = useCallback(
    () => dispatch(SessionsActions.resetPeriodicActivity()),
    [dispatch]
  );

  return {
    activity,
    coachActivity,
    isFetching,
    isFullLoaded,
    requestPeriodicActivity,
    resetPeriodicActivity,
  };
};

export const useSemesterPerf = () => {
  const dispatch = useDispatch();

  const semesterActivity = useSelector((state) => SessionsSelector.semesterActivity(state));
  const isFetching = useSelector((state) => SessionsSelector.isFetchingSemester(state));
  const isFullLoaded = useSelector((state) => SessionsSelector.isFullLoadedSemester(state));

  const requestSemesterActivity = useCallback(
    (dateStart, dateEnd, roomId) =>
      dispatch(SessionsActions.requestSemesterActivity(dateStart, dateEnd, roomId)),
    [dispatch]
  );

  return {
    semesterActivity,
    isFetching,
    isFullLoaded,
    requestSemesterActivity,
  };
};

export const useUserVisit = () => {
  const dispatch = useDispatch();

  const userVisites = useSelector((state) => SessionsSelector.userVisites(state));

  const requestUserVisites = useCallback(
    (startDate, endDate, roomId) =>
      dispatch(SessionsActions.requestUserVisites(startDate, endDate, roomId)),
    [dispatch]
  );

  return {
    userVisites,
    requestUserVisites,
  };
};

export const useActiveSession = () => {
  const dispatch = useDispatch();

  const activeSession = useSelector((state) => SessionsSelector.activeSession(state));

  const requestActiveSession = useCallback(
    (roomId) => dispatch(SessionsActions.requestActiveSession(roomId)),
    [dispatch]
  );

  return {
    activeSession,
    requestActiveSession,
  };
};

export const useSessions = () => {
  const dispatch = useDispatch();

  const sessions = useSelector((state) => SessionsSelector.sessions(state));
  const dailySessionId = useSelector((state) => SessionsSelector.dailySession(state));
  const createSession = useCallback(
    (roomId, load = undefined, startDate = undefined) =>
      dispatch(SessionsActions.createSession(roomId, load, startDate)),
    [dispatch]
  );
  const requestDailySession = useCallback(
    (roomId, startDate, dateEnd) =>
      dispatch(SessionsActions.requestDailySession(roomId, startDate, dateEnd)),
    [dispatch]
  );

  return {
    createSession,
    requestDailySession,
    sessions,
    dailySessionId,
  };
};

export const useSessionById = (id) => {
  const dispatch = useDispatch();

  const session = useSelector((state) => SessionsSelector.sessionById(state, id));

  const requestGetSession = useCallback(
    () => dispatch(SessionsActions.getSessionById(id)),
    [dispatch, id]
  );

  useEffect(() => {
    if (id && !session) {
      requestGetSession(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, session]);

  return {
    session: session || {},
  };
};
