import React, { useEffect, useCallback } from 'react';

// Hooks
import { useNews } from '../../../../redux/news/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useLocalize } from '../../../../redux/translation/localize';

// Components
import { IonContent } from '@ionic/react';
import NewsSlide from '../../../../components/NewsSlide/NewsSlide';
import Loader from '../../../../atoms/Loader/Loader';

// Styles & Utils
import './HomeNews.scss';
import 'swiper/scss';
import room from '../../../../utils/room.interface';

interface Props {
  room?: room;
}

const HomeNews: React.FC<Props> = ({ room }) => {
  const t = useLocalize();
  const { news, getNews, isFetching, isFullLoaded } = useNews(room?.id || 'homeIds');

  useEffect(() => {
    getNews(room?.id || 'homeIds', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room?.id]);

  const handleSwipe = useCallback(() => {
    if (isFetching || isFullLoaded) return;
    getNews(room?.id || 'homeIds', true);
  }, [getNews, isFetching, isFullLoaded, room?.id]);

  if (!isFetching && !(news as Array<any>)?.length) return null;
  return (
    <IonContent className='newsContainer'>
      <div className='sectionName'>
        <span id='title'>{t('news_intro')}</span>
      </div>
      {!(news as Array<any>)?.length && isFetching && <Loader />}
      {!!(news as Array<any>)?.length && (
        <Swiper
          slidesPerView='auto'
          spaceBetween={0}
          className='NewsSlides'
          onReachEnd={handleSwipe}
        >
          {(news as Array<any>)?.map((id: number, index: number) => (
            <SwiperSlide key={index} className='newsSwiper'>
              {!!id && <NewsSlide id={id} />}
            </SwiperSlide>
          ))}
          {isFetching && <Loader />}
        </Swiper>
      )}
    </IonContent>
  );
};

export default HomeNews;
