import { IonLoading } from '@ionic/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Footer from '../../components/VideoFooter/VideoFooter';
import Header from '../../components/VideoHeader/VideoHeader';

import './Camera.scss';

const PRECISION = 0.05;
const MAX_LENGTH = 60 * 3;
const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

interface CameraProps {
  onChange: (data: Blob) => void;
  onBack: () => void;
}

const Camera: React.FC<CameraProps> = ({ onChange, onBack }) => {
  // Refs
  const cameraRef: any = useRef(null);
  const recorderRef: any = useRef(null);
  const timerRef = useRef<any>(null);
  const cameraLoading: any = useRef<NodeJS.Timer | null>(null);

  // States
  const [time, setTime] = useState(0);
  const [permission, setPermission] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [idealSize, setIdealSize] = useState({ r: false, w: 1080, h: 1830 });
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);

  const videoConstraints: any = useMemo(
    () => ({
      facingMode,
      ready: idealSize.r,
      video: {
        width: idealSize.w + (idealSize.w % 2),
        height: idealSize.h + (idealSize.h % 2),
      },
    }),
    [facingMode, idealSize.h, idealSize.r, idealSize.w]
  );

  // Callbacks
  const handleSwitchView = useCallback(() => {
    setFacingMode((prev) =>
      prev === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
    );
  }, []);

  // Capturing
  const handleDataAvailable = useCallback(
    ({ data }: BlobEvent) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    recorderRef.current.stop();
    setTime(0);
    setCapturing(false);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, []);

  const handleStartCaptureClick = useCallback(() => {
    if (cameraRef.current) {
      try {
        recorderRef.current = new MediaRecorder(cameraRef.current.srcObject, {
          mimeType: 'video/webm;codecs=vp9',
        });
        recorderRef.current.ondataavailable = handleDataAvailable;
        recorderRef.current.start();
        setCapturing(true);
      } catch (err) {
        console.error(err);
      }
    }
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    setTime(0);
    timerRef.current = setInterval(() => {
      setTime((prev) => {
        if (prev + PRECISION >= MAX_LENGTH) handleStopCaptureClick();
        return prev + PRECISION;
      });
    }, PRECISION * 1000);
  }, [handleDataAvailable, handleStopCaptureClick]);

  // Loaders
  const handleLoadStart = useCallback(
    (ev: any) => setIdealSize({ r: true, w: ev.target.clientWidth, h: ev.target.clientHeight }),
    []
  );

  const handleLoadEnd = useCallback(() => setIsLoading(false), []);

  // Effects
  useEffect(() => {
    if (recordedChunks.length && !capturing) {
      onChange(recordedChunks[0]);
      setRecordedChunks([]);
    }
  }, [capturing, onChange, recordedChunks]);

  useEffect(() => {
    if (!videoConstraints.ready) setIsLoading(true);
    try {
      (navigator.permissions as any).query({ name: 'camera' }).then((permissionObj: any) => {
        setPermission(permissionObj.state);
      });
      (navigator.permissions as any).query({ name: 'microphone' }).then((permissionObj: any) => {
        setPermission(permissionObj.state);
      });
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true, ...videoConstraints })
        .then((stream) => {
          if (cameraRef.current) {
            cameraRef.current.srcObject = stream;
            if (cameraLoading.current) {
              clearTimeout(cameraLoading.current);
            }
            cameraLoading.current = setTimeout(() => {
              cameraRef.current.play();
              setIsLoading(false);
            }, 500);
          }
        })
        .catch(() => {});
    } catch (err) {
      console.warn(err);
    }
  }, [videoConstraints]);

  const handleBack = useCallback(() => {
    setTime(0);
    setCapturing(false);

    try {
      recorderRef.current.stop();
      cameraRef.current.srcObject = null;
    } catch {}
    onBack();
  }, [onBack]);

  useEffect(
    () => () => {
      try {
        setTime(0);
        setCapturing(false);
        const stream = cameraRef.current.srcObject;
        stream.getTracks().forEach((track) => {
          track.stop();
        });
        cameraRef.current.srcObject = null;
      } catch {}
    },
    []
  );

  return (
    <div id='Camera'>
      <IonLoading isOpen={isLoading} spinner='bubbles' />
      <div className='CameraBox'>
        {permission === 'denied' && (
          <div className='cameraDenied'>Please authorize your camera. Thank you.</div>
        )}
        {permission !== 'denied' && (
          <Header
            isCamera
            videoTime={time}
            videoConstraints={videoConstraints}
            onSwitchView={handleSwitchView}
            onBack={handleBack}
          />
        )}
        {permission !== 'denied' && (
          <video
            id='VideoCamera'
            onLoadStart={handleLoadStart}
            onLoadedData={handleLoadEnd}
            onLoadedMetadata={handleLoadEnd}
            ref={cameraRef}
            muted
          />
        )}
      </div>

      <Footer
        capturing={capturing}
        permission={permission}
        onStart={handleStartCaptureClick}
        onStop={handleStopCaptureClick}
      />
    </div>
  );
};

export default Camera;
