const config = [
  '/assets/stickers/bloc.png',
  '/assets/stickers/blocword.png',
  '/assets/stickers/bottle.png',
  '/assets/stickers/cake.png',
  '/assets/stickers/cd_style.png',
  '/assets/stickers/cd.png',
  '/assets/stickers/grimpeur.png',
  '/assets/stickers/grip.png',
  '/assets/stickers/holy.png',
  '/assets/stickers/knot.png',
  '/assets/stickers/mountain.png',
  '/assets/stickers/skate.png',
  '/assets/stickers/top.png',

  // Room names
  '/assets/stickers/bastille.png',
  '/assets/stickers/buttes_chaumont.png',
  '/assets/stickers/pont_de_neuilly.png ',
  '/assets/stickers/saint_lazare.png',
  '/assets/stickers/batignolles.png',
];

export default config;
