// import redux store
import * as account from '../account/redux';
import * as app from '../app/redux';
import * as auth from '../auth/redux';
import * as audits from '../audits/redux';
import * as boulders from '../boulders/redux';
import * as boulderVideo from '../boulderVideo/redux';
import * as coaching from '../coaching/redux';
import * as filters from '../filters/redux';
import * as editor from '../editor/redux';
import * as leaderBoard from '../leaderBoard/redux';
import * as login from '../user/redux';
import * as medias from '../medias/redux';
import * as news from '../news/redux';
import * as rooms from '../rooms/redux';
import * as sessions from '../sessions/redux';
import * as sectors from '../sectors/redux';
import * as tags from '../tags/redux';
import * as user from '../user/redux';
import * as openings from '../openings/redux';

import StoreValues from '../../utils/storeValues.interface';

const entities: StoreValues = {
  /* En ordre Alphabetique <3 */
  account,
  app,
  auth,
  audits,
  boulders,
  boulderVideo,
  coaching,
  filters,
  editor,
  leaderBoard,
  login,
  medias,
  news,
  openings,
  rooms,
  sectors,
  sessions,
  tags,
  user,
  /* En ordre Alphabetique <3 */
};

export default entities;
