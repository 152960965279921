import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

const initialState = Immutable({
  tags: [],
  colors: [],
  intensities: [],
  specialities: [],
});

export const actions = {
  requestFilters: createAction('request all filters'),
  requestTags: createAction('request all tag'),
  requestColors: createAction('request all color', (roomId = null) => ({ roomId })),
  requestIntensities: createAction('request all intensity'),
  requestSpecialities: createAction('request all speciality'),

  addTags: createAction('add tags', (tag) => ({ tag })),
  addColors: createAction('add tags', (color) => ({ color })),
  addIntensities: createAction('add tags', (intensity) => ({ intensity })),
  addSpecialities: createAction('add tags', (speciality) => ({ speciality })),

  cleanFilters: createAction('cleanFilters'),
};

export const reducer = createReducer(
  {
    [actions.addTags.toString()]: (state, { tag }) => state.setIn(['tags'], tag),
    [actions.addColors.toString()]: (state, { color }) => state.setIn(['colors'], color),
    [actions.addIntensities.toString()]: (state, { intensity }) =>
      state.setIn(['intensities'], intensity),
    [actions.addSpecialities.toString()]: (state, { speciality }) =>
      state.setIn(['specialities'], speciality),

    [actions.cleanFilters]: (state) => state.merge({ ...initialState }),
  },
  initialState
);

export const selectors = {
  tags: (state) => state.filters.tags,
  colors: (state) => state.filters.colors,
  intensities: (state) => state.filters.intensities,
  specialities: (state) => state.filters.specialities,
};
