import React, { useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocalize } from '../../redux/translation/localize';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { IonPage, IonRange } from '@ionic/react';

import Header from '../../routes/components/Header/Header';

import './Entrainement.scss';
import ROUTES from '../../routes/constants';
import { useCoaching } from '../../redux/coaching/hooks';
import { trackEvent } from '../../providers/tracker';

const Entrainement: React.FC = () => {
  const t = useLocalize();
  const navigate = useNavigate();

  const { room, roomId: selectedRoomId } = useSelectedRoom();
  const { getSessionBoulders } = useCoaching();
  const [volume, setVolume] = useState<number>(3);
  const [time, setTime] = useState<number>(1);

  const timeToDisplay = useMemo(() => (time * 60).toString() + 'mn', [time]);

  const changeTime = useCallback((e) => {
    setTime(e.detail.value / 60);
  }, []);

  const changeVolume = useCallback((e) => {
    setVolume(e.detail.value);
  }, []);

  const handleBackHome = useCallback(() => navigate(ROUTES.COACH), [navigate]);

  const viewCircuit = useCallback(() => {
    if (volume && time && room) {
      getSessionBoulders(volume, time, room.id);
    }
    trackEvent('coach_select', {
      event_category: 'coach',
      event_action: 'coach_select',
    });
    navigate(ROUTES.TRAINING_PLAN, {
      state: { room: room, volume, time },
    });
  }, [volume, time, room, navigate, getSessionBoulders]);

  return (
    <IonPage>
      <div className='coachPage'>
        <div className='coachContent'>
          <Header
            isCoach
            coachStart
            goBack={handleBackHome}
            showRoom
            showProfile={false}
            showMonth={false}
            showWalls={false}
            showSectors={false}
            disableRoom
          />
          <div className='coachParams'>
            <div className='coachParamsContent'>
              <div id='secondaryTitle'>{t('adjust_coach')}</div>
              <div className='paramsRanges'>
                <div className='rangeLabels'>
                  <label htmlFor=''>{t('duration')}</label>
                  <label htmlFor=''>{timeToDisplay}</label>
                </div>
                <IonRange
                  min={60}
                  max={120}
                  ticks={false}
                  snaps
                  step={30}
                  onIonChange={changeTime}
                />
              </div>
              <div className='paramsRanges'>
                <div className='rangeLabels'>
                  <label htmlFor=''>{t('intensity')}</label>
                  <label htmlFor=''>{t('volume')}</label>
                </div>
                <IonRange
                  className='volumeRange'
                  ticks={false}
                  value={volume}
                  min={1}
                  max={5}
                  snaps
                  onIonChange={changeVolume}
                />
              </div>
            </div>
          </div>
          <div className='echauffement'>
            <p id='coachParagraph'>{t('coaching_paragraph')}</p>
            <span id='tertiaryTitle'>{t('coaching_guide')}</span>
            <span id='tertiaryTitle'>{t('coaching_cheering')}</span>
          </div>
        </div>
        <div className='actionButtons'>
          <button onClick={viewCircuit} disabled={selectedRoomId ? false : true}>
            {t('circuit_plan')}
          </button>
        </div>
      </div>
    </IonPage>
  );
};

export default Entrainement;
