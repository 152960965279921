import { all } from 'redux-saga/effects';
//import sagas
import NewsSagas from '../news/sagas';
import FiltersSagas from '../filters/sagas';
import UserSagas from '../user/sagas';
import RoomsSagas from '../rooms/sagas';
import AuditsSagas from '../audits/sagas';
import SectorsSagas from '../sectors/sagas';
import BouldersSagas from '../boulders/sagas';
import SessionsSagas from '../sessions/sagas';
import TranslationSagas from '../translation/sagas';
import AuthSagas from '../auth/sagas';
import TagsSagas from '../tags/sagas';
import LeaderBoardSagas from '../leaderBoard/sagas';
import BoulderVideoSagas from '../boulderVideo/sagas';
import CoachingSagas from '../coaching/sagas';
import MediaSagas from '../medias/sagas';
import OpeningsSagas from '../openings/sagas';
import AccountSagas from '../account/sagas';

function* bootLoop() {
  yield all([
    RoomsSagas.loop(),
    SectorsSagas.loop(),
    AuditsSagas.loop(),
    NewsSagas.loop(),
    FiltersSagas.loop(),
    BouldersSagas.loop(),
    BoulderVideoSagas.loop(),
    UserSagas.loop(),
    SessionsSagas.loop(),
    TranslationSagas.loop(),
    AuthSagas.loop(),
    TagsSagas.loop(),
    LeaderBoardSagas.loop(),
    CoachingSagas.loop(),
    MediaSagas.loop(),
    OpeningsSagas.loop(),
    AccountSagas.loop(),
  ]);
  return null;
}

export default bootLoop;
