import { createAction } from 'redux-act';

export const initialState = {
  primaryTool: 'manipulation',
  editingDisplayId: null,
};

export const actions = {
  edit: createAction('set toolbar', (toolbar, editingDisplayId) => ({
    toolbar,
    editingDisplayId,
  })),
  clearPrimaryTool: createAction('clear primary tool'),
};

const setEditor = (state, { toolbar, editingDisplayId = null }) => {
  state = state.setIn(['primaryTool'], toolbar);
  return state.setIn(['editingDisplayId'], editingDisplayId);
};

export const reducers = {
  [actions.edit]: setEditor,
};

export const selectors = {
  primaryTool: (state) => state.editor.primaryTool,
  editingDisplayId: (state) => state.editor.editingDisplayId,
};
