export const updateActiveSession = (state, { sessionBoulderToUpdate }) => {
  if (!sessionBoulderToUpdate) return state;
  let session = JSON.parse(JSON.stringify(state.getIn(['activeSession'])));

  const index = session.sessionboulders.findIndex(
    (sessionBoulder) => sessionBoulder.boulderId === sessionBoulderToUpdate.boulderId
  );
  if (index === -1) session.sessionboulders.push(sessionBoulderToUpdate);
  else session.sessionboulders[index].status = sessionBoulderToUpdate.status;
  return state.setIn(['activeSession'], session);
};

export const removeBoulderOnActiveSession = (state, { sessionBoulderId }) => {
  if (!sessionBoulderId) return state;
  let session = JSON.parse(JSON.stringify(state.getIn(['activeSession'])));
  const index = session.sessionboulders.findIndex((boulder) => boulder.id === sessionBoulderId);
  if (index !== -1) session.sessionboulders.splice(index, 1);
  return state.setIn(['activeSession'], session);
};

export const addSession = (state, { session }) => {
  if (!session) return state;

  let ids = state.getIn(['sessionIds']) || [];
  if (ids.asMutable) {
    ids = ids.asMutable();
  }
  if (!ids.includes(session.id)) {
    ids.push(session.id);
  }

  state = state.setIn(['sessionById', session.id], session);
  return state.setIn(['sessionIds'], ids);
};

export const updateSession = (state, { boulders, sessionId }) => {
  if (!boulders) return state;
  let session = JSON.parse(JSON.stringify(state.getIn(['sessionById', sessionId])));

  boulders?.forEach(({ sessionBoulder, boulderId }) => {
    const index = session.sessionboulders.findIndex((boul) => boul.boulderId === boulderId);
    if (index === -1) session.sessionboulders.push(sessionBoulder);
    else session.sessionboulders[index].status = sessionBoulder.status;
  });
  return state.setIn(['sessionById', sessionId], session);
};
