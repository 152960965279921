const events = {
  // General
  back: 'back_click',
  footer: 'footer_click',
  room_change: 'room_change',
  zone_change: 'zone_change',
  sector_change: 'sector_change',
  header_ticket: 'header_ticket_click',
  header_profile: 'header_profile_click',
  header_login: 'header_login_click',

  // Onboarding | Login
  onboarding_details: 'onboarding_details',
  onboarding_info: 'onboarding_info',
  onboarding_specs: 'onboarding_specs',
  onboarding_send_code: 'onboarding_send_code',
  onboarding_request_code: 'onboarding_request_code',
  user_tags: 'user_tags_update',
  user_update_start: 'user_update_start',
  user_update_photo: 'user_update_photo',
  user_update_cancel: 'user_update_cancel',
  delete_account: 'delete_account_click',
  delete_account_cancel: 'delete_account_click',
  delete_account_apply: 'delete_account_apply',
  logout: 'logout_click',

  // Home only
  news: 'news_click',
  activity: 'activity_click',

  // Block events
  filters: 'filters_click',
  filters_choose: 'filters_choose',
  filters_color: 'filters_color_click',
  filters_apply: 'filters_apply_click',
  filters_reset: 'filters_reset',
  block_login: 'block_login_click',
  block_clap: 'block_clap',
  block_click: 'block_click',
  block_close: 'block_close',
  block_color: 'block_color_change',
  block_flash: 'block_flash',
  block_modal_open: 'block_modal_open',
  block_previous: 'block_previous',
  block_next: 'block_next',
  block_validate: 'block_validate',
  block_unvalidate: 'block_unvalidate',
  block_video_close: 'block_video_close',
  block_video_visualize: 'block_video_visualize',
  block_video_delete: 'block_video_delete',
  block_video_click: 'block_video_click',
  block_video_gallery: 'block_video_galery',
  block_video_record: 'block_video_record',
  block_video_send: 'block_video_send',

  // Coach
  coach_add_more: 'coach_add_more_click',
  coach_added_more: 'coach_add_more_amount',
  coach_calendar_day: 'coach_calendar_day',
  coach_calendar_month: 'coach_calendar_month',
  coach_cancel: 'coach_cancel_click',
  coach_finish: 'coach_finish_click',
  coach_complete: 'coach_complete',
  coach_start: 'coach_start_click',
  coach_select: 'coach_select_click',

  // Leader board
  leaderboard: 'leaderboard_click',
  leaderboard_refresh: 'leaderboard_refresh',
  leaderboard_color: 'leaderboard_color_change',
  leaderboard_user: 'leaderboard_user_click',

  // Shop Events
  ticket_renew: 'ticket_renew_click',
  shop: 'shop_click',
  shop_class: 'shop_class_click',
  shop_class_plan: 'shop_class_plan_click',
  shop_class_search: 'shop_class_search_click',
  shop_internship: 'shop_internship_click',
  shop_reinforcement: 'shop_reinforcement_click',
  shop_sessions: 'shop_sessions_click',
  shop_subscription: 'shop_subscription_click',
  shop_yoga: 'shop_yoga_click',

  // Admin
  openning: 'openning_click',
};

export default events;
