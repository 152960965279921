import { client } from '../../App';
import { actions as LeaderBoardActions, selectors as LeaderBoardSelectors } from './redux';
import { actions as UserActions, selectors as UserSelectors } from '../user/redux';
import { selectors as RoomsSelectors } from '../rooms/redux';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { GetLeaderboard, GetUserBestBoulders } from '../../GraphQL/GetLeaderBoard';
const LEADER_BOARD_LIMIT = 10;

const formatLeaderboards = (leaderboards, preLength) =>
  leaderboards.map((leaderboard, index) => ({
    id: leaderboard.user.id,
    rank: preLength + index + 1,
    colorId: leaderboard.user.dominantColorId,
    firstName: leaderboard.user.mindFlurryUser?.firstName,
    lastName: leaderboard.user.mindFlurryUser?.lastName,
    login: leaderboard.user.login,
    picture: leaderboard.user.picture,
    points: leaderboard.score,
    isAdmin: leaderboard.user.isAdmin,
  }));
export default class LeaderBoardSagas {
  static *requestLeaderBoardsWithParams({ payload }) {
    try {
      const { roomId, callback } = payload;

      let colorId = undefined;
      const isByUserColor = yield select(LeaderBoardSelectors.isByUserColor);
      if (isByUserColor) colorId = yield select(UserSelectors.userColor);

      // Reset board
      yield put(LeaderBoardActions.setBoardsGlobal([]));

      yield put(LeaderBoardActions.setFetching(true));

      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetLeaderboard,
        variables: {
          skip: 0,
          limit: LEADER_BOARD_LIMIT,
          colorId,
          roomId,
        },
      });

      if (!!result?.data) {
        yield put(
          LeaderBoardActions.setBoardsGlobal(formatLeaderboards(result.data.leaderboard, 0))
        );
        yield put(
          LeaderBoardActions.setFullLoaded(result.data.leaderboard.length < LEADER_BOARD_LIMIT)
        );
      }
      !!callback && callback();
      yield put(LeaderBoardActions.setFetching(false));
    } catch (err) {
      console.error(err);
      yield put(LeaderBoardActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *requestLoadMoreLeaderBoardsWithParams({ payload }) {
    try {
      const { roomId } = payload;

      let colorId = undefined;
      const isByUserColor = yield select(LeaderBoardSelectors.isByUserColor);
      if (isByUserColor) colorId = yield select(UserSelectors.userColor);

      const leaderboard = yield select(LeaderBoardSelectors.leaderBoardsGlobal);

      yield put(LeaderBoardActions.setFetching(true));

      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetLeaderboard,
        variables: {
          skip: leaderboard.length,
          limit: LEADER_BOARD_LIMIT,
          colorId,
          roomId,
        },
      });

      if (!!result?.data) {
        yield put(
          LeaderBoardActions.addBoardsGlobal(
            formatLeaderboards(result.data.leaderboard, leaderboard.length)
          )
        );
        yield put(LeaderBoardActions.requestUserRankUpdate());
        yield put(
          LeaderBoardActions.setFullLoaded(result.data.leaderboard.length < LEADER_BOARD_LIMIT)
        );
      }
      yield put(LeaderBoardActions.setFetching(false));
    } catch (err) {
      console.error(err);
      yield put(LeaderBoardActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *requestUserBestBoulders({ payload }) {
    try {
      yield put(LeaderBoardActions.setBestBoulders([]));

      const { roomId, userId } = payload;

      let colorId = undefined;
      const isByUserColor = yield select(LeaderBoardSelectors.isByUserColor);
      if (isByUserColor) colorId = yield select(UserSelectors.userColor);

      yield put(LeaderBoardActions.setFetching(true));
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetUserBestBoulders,
        variables: {
          colorId,
          roomId,
          userId,
        },
      });

      if (!!result?.data) {
        yield put(LeaderBoardActions.setBestBoulders(result.data.userBestBoulders));
      }
      yield put(LeaderBoardActions.setFetching(false));
    } catch (err) {
      console.error(err);
      yield put(LeaderBoardActions.setFetching(false));
      Sentry.captureException(err);
    }
  }
  static *loadLeaderBoardsGlobal() {
    try {
      yield put(LeaderBoardActions.setFetching(true));
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetLeaderboard,
      });

      if (!!result?.data) {
        yield put(LeaderBoardActions.addBoardsGlobal(result.data.colors));
        // yield put(LeaderBoardActions.setFullLoaded(true));
      }
      yield put(LeaderBoardActions.setFetching(false));
    } catch (err) {
      console.error(err);
      yield put(LeaderBoardActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *loadRoomLeaderBoard({ payload }) {
    try {
      yield put(LeaderBoardActions.setFetching(true));
      const { roomId, id } = payload;
      const result = yield client.query({
        query: GetLeaderboard,
        fetchPolicy: 'network-only',
        variables: {
          skip: 0,
          limit: LEADER_BOARD_LIMIT,
          colorId: id,
          roomId: roomId,
        },
      });
      if (result?.data) {
        yield put(LeaderBoardActions.addRoomBoard(result.data.leaderboards, id));
        // yield put(LeaderBoardActions.setFullLoaded(true));
      }
      yield put(LeaderBoardActions.setFetching(false));
    } catch (err) {
      console.error(err);
      yield put(LeaderBoardActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *requestUserRankUpdate() {
    try {
      const roomId = yield select(RoomsSelectors.selectedRoomId);
      const isByUserColor = yield select(LeaderBoardSelectors.isByUserColor);
      const userColor = yield select(UserSelectors.userColor);

      yield put(UserActions.requestUserScore(roomId, isByUserColor));
      yield put(UserActions.requestUserColorRank(roomId, userColor));
      yield put(UserActions.requestUserBestColor(roomId));
      yield put(UserActions.requestUserGlobalRank(roomId));
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(
        LeaderBoardActions.requestLeaderBoardsWithParams.getType(),
        LeaderBoardSagas.requestLeaderBoardsWithParams
      ),
      takeLatest(
        LeaderBoardActions.requestLoadMoreLeaderBoardsWithParams.getType(),
        LeaderBoardSagas.requestLoadMoreLeaderBoardsWithParams
      ),
      takeLatest(
        LeaderBoardActions.requestLeaderBoardsGlobal.getType(),
        LeaderBoardSagas.loadLeaderBoardsGlobal
      ),
      takeLatest(
        LeaderBoardActions.requestRoomLeaderBoard.getType(),
        LeaderBoardSagas.loadRoomLeaderBoard
      ),
      takeLatest(
        LeaderBoardActions.requestUserBestBoulders.getType(),
        LeaderBoardSagas.requestUserBestBoulders
      ),
      takeLatest(
        LeaderBoardActions.requestUserRankUpdate.getType(),
        LeaderBoardSagas.requestUserRankUpdate
      ),
    ]);
  }
}
