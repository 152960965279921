import { useCallback, useMemo, useState } from 'react';
import icons from '../../types/IconTypes';
import Icon from '../../atoms/Icon/Icon';
import DateSelector from '../DateSelector/DateSelector';

import '../SelectWheel/SelectWheel.scss';

interface Props {
  isCoach: boolean;
  selectedItem: Date;
  hint: string;
  onSelect: (date: Date) => void;
}

const SelectDate = ({ selectedItem, onSelect, hint, isCoach }: Props) => {
  const [openSelectDate, setOpenSelectDate] = useState(false);

  const today = useMemo(() => new Date(), []);
  const dateText = useMemo(() => {
    return `${selectedItem.toLocaleString('default', {
      month: 'short',
    })} ${selectedItem.getFullYear()}`;
  }, [selectedItem]);

  const handleClickDate = useCallback(() => {
    setOpenSelectDate(!openSelectDate);
  }, [openSelectDate]);

  return (
    <div className={isCoach ? 'selectWheelContainerCoach' : 'selectWheelContainer'}>
      <div className='hint'>{hint}</div>
      <div className='boxDevider' onClick={handleClickDate}>
        <div className='buttonSelect'>{dateText}</div>
        <Icon src={icons.DOWN_OPEN} className={`arrowIcon ${!isCoach ? 'blue' : ''}`} />

        <DateSelector
          date={selectedItem}
          setDate={onSelect}
          maxDate={today}
          presentation='month-year'
          isOpen={openSelectDate}
          setIsOpen={setOpenSelectDate}
        />
      </div>
    </div>
  );
};

export default SelectDate;
