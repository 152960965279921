import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as EditorActions, selectors as EditorSelectors } from '../redux.js';

export const useCropEditor = () => {
  const dispatch = useDispatch();

  const cropBound = useSelector(EditorSelectors.bound);
  const initialBound = useSelector(EditorSelectors.initialBound);
  const isInCropEditor = useSelector(EditorSelectors.isInEditMode);

  const setInitialBound = useCallback(
    (x, y, width, height, scale) =>
      dispatch(EditorActions.setInitialBound(x, y, width, height, scale)),
    [dispatch]
  );
  const setBound = useCallback(
    (x, y, width, height, scale) => dispatch(EditorActions.setBound(x, y, width, height, scale)),
    [dispatch]
  );

  return {
    cropBound,
    initialBound,
    isInCropEditor,
    setBound,
    setInitialBound,
  };
};
