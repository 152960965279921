import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
export interface speciality {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
  name: string;
}

export const options: ChartOptions<'line'> = {
  spanGaps: true,

  maintainAspectRatio: false,

  plugins: { legend: { display: false } },

  responsive: true,

  animation: {
    duration: 0,
  },

  scales: {
    x: {
      display: true,

      grid: {
        display: false,
      },

      ticks: {
        display: true,

        padding: 1,
      },
    },

    yAxes: {
      display: false,

      grid: {
        display: false,
      },

      ticks: {
        display: false,

        padding: 10,
      },
    },
  },
};

export const specialitiesConfig = [
  {
    label: 'prehensions',
    data: [],
    borderColor: '#b50006',
    backgroundColor: '#b50006',
    name: 'spe_prehension',
  },
  {
    label: 'physiques',
    data: [],
    borderColor: '#ff2700',
    backgroundColor: '#ff2700',
    name: 'spe_physical',
  },
  {
    label: 'techniques',
    data: [],
    borderColor: '#a68f49',
    backgroundColor: '#a68f49',
    name: 'spe_technical',
  },
  {
    label: 'deplacements',
    data: [],
    borderColor: '#117dbe',
    backgroundColor: '#117dbe',
    name: 'spe_movement',
  },
];
