import { IonIcon } from '@ionic/react';
import { chevronBack, pauseCircle, playCircle } from 'ionicons/icons';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import Header from '../../components/VideoHeader/VideoHeader';

import VideoTrimer from '../../components/VideoTrimer/VideoTrimer';

import './VideoTrim.scss';

interface Props {
  video: Blob;
  name?: string | null;
  videoTimers: any;
  onClose: (val: boolean) => void;
  onTrimAccept: (val: any) => void;
  onLoading: (val: boolean) => void;
}

const VideoTrim: React.FC<Props> = ({
  video,
  name = 'cache',
  videoTimers: mainTimers,
  onTrimAccept,
  onLoading,
  onClose,
}) => {
  const videoTimersRef = useRef(mainTimers);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const statusIcon = useRef<HTMLDivElement | null>(null);

  const [_, setCurrentTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [videoTimers, setVideoTimers] = useState(mainTimers);
  const [videoState, setVideoState] = useState<HTMLVideoElement | null>(null);
  const [currentVideo, setCurrentVideo] = useState<string | undefined>();

  const handleSetTimers = useCallback((params: any) => {
    setVideoTimers((prev: any) => {
      const values = { ...prev, ...params };
      videoTimersRef.current = values;
      return values;
    });
  }, []);

  const handleTrimming = useCallback(
    ({ startTime, endTime }: { startTime: number; endTime: number }) => {
      handleSetTimers({
        ready: true,
        startTime,
        endTime,
        duration: endTime - startTime,
      });
    },
    [handleSetTimers]
  );

  // Video Handlers
  const handleTimeUpdate = useCallback(() => {
    let actualTime = videoState?.currentTime || 0;

    if (videoState && actualTime > videoTimersRef.current.endTime) {
      actualTime = videoTimersRef.current.startTime;
      videoState.currentTime = actualTime;
    } else if (videoState && actualTime < videoTimersRef.current.startTime) {
      actualTime = videoTimersRef.current.startTime;
      videoState.currentTime = actualTime;
    }
    setCurrentTime(actualTime);
  }, [videoState]);

  const handleClickScreen = useCallback(() => {
    if (videoState) {
      setPlaying((prev) => {
        if (statusIcon.current) {
          statusIcon.current.style.display = 'flex';
          statusIcon.current.style.opacity = '1';

          if (timerRef.current) clearTimeout(timerRef.current);
          timerRef.current = setTimeout(() => {
            if (statusIcon.current) statusIcon.current.style.opacity = '0';
            timerRef.current = setTimeout(
              () => statusIcon.current && (statusIcon.current.style.display = 'none'),
              500
            );
          }, 1000);
        }
        prev ? videoState?.pause() : videoState?.play();
        return !prev;
      });
    }
  }, [videoState]);

  const handleDurationChanged = useCallback(
    (ev: any) => {
      try {
        if (!!ev.target?.duration && ev.target.duration !== Infinity) {
          onLoading?.(false);
          ev.target.loop = true;
          ev.target.pause();
          ev.target.play();
          setPlaying(true);
          setVideoState(ev.target);
        } else if (ev.target.duration === Infinity || isNaN(Number(ev.target.duration))) {
          ev.target.currentTime = 1e101;
        }
      } catch (err) {
        console.error(err);
      }
    },
    [onLoading]
  );

  const handleAcceptTrim = useCallback(() => {
    onTrimAccept(videoTimers);
  }, [onTrimAccept, videoTimers]);
  const handleClose = useCallback(() => onClose(false), [onClose]);

  useLayoutEffect(() => {
    const objectUrl: string = URL.createObjectURL(video);
    setCurrentVideo(objectUrl);
  }, [video]);

  return (
    <div id='VideoTrim'>
      <div className='videoBox' onClick={handleClickScreen}>
        <video
          playsInline
          controls={false}
          preload='metadata'
          src={currentVideo}
          onTimeUpdate={handleTimeUpdate}
          onDurationChange={handleDurationChanged}
        />
      </div>
      <Header playing={playing} />
      <div className='videoStatusIcon' ref={statusIcon}>
        <IonIcon icon={playing ? playCircle : pauseCircle} />
      </div>
      <div id='VideoFooter'>
        <div className='footerButton' onClick={handleClose}>
          <IonIcon icon={chevronBack} />
        </div>
        <VideoTrimer
          name={name}
          playing={playing}
          videoRef={videoState}
          duration={videoState?.duration || 0}
          startTime={videoTimers.startTime}
          endTime={videoTimers.endTime}
          onChange={handleTrimming}
        />
        <div className='footerButton' onClick={handleAcceptTrim}>
          <IonIcon icon={playCircle} />
        </div>
      </div>
    </div>
  );
};

export default VideoTrim;
