import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

const initialState = Immutable({
  appStarted: false,
  isLoading: false,
});

export const actions = {
  setAppLoader: createAction('set App Loader', (value = false) => ({ value })),
  setAppStarted: createAction('set App Started', (value = true) => ({ value })),

  requestAppLogout: createAction('request app logout'),
};

export const reducer = createReducer(
  {
    [actions.setAppLoader]: (state, { value }) => state.setIn(['isLoading'], value),
    [actions.setAppStarted]: (state, { value }) => state.setIn(['appStarted'], value),
  },
  initialState
);

export const selectors = {
  appStarted: (state) => state.app.appStarted,
  isLoading: (state) => state.app.isLoading,
};
