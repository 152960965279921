import { gql } from '@apollo/client';

export const GetTags = gql`
  query GetTags {
    tags {
      id
      name
    }
  }
`;

export const GetColors = gql`
  query GetColors {
    colors {
      id
      name
    }
  }
`;

export const GetIntensities = gql`
  query GetIntensities {
    intensities {
      id
      name
    }
  }
`;

export const GetSpecialities = gql`
  query GetSpecialities {
    specialities {
      id
      name
    }
  }
`;
