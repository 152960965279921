import React, { useEffect, useMemo } from 'react';

// Hooks
import { useFormattedDate } from '../../../../redux/common/hooks';
import { useColors } from '../../../../redux/filters/hooks';
import { useSessionsPerf } from '../../../../redux/sessions/hooks';
import { useLocalize } from '../../../../redux/translation/localize';
import { useCoachingUpdate } from '../../../../redux/coaching/hooks';
import { useUserVisit } from '../../../../redux/sessions/hooks';

// Components
import Loader from '../../../../atoms/Loader/Loader';
import Point from '../../../../components/Point/Point';
import Calendar from '../../../../components/Calendar/Calendar';

// Style & Utils
import './Sessions.scss';
import getBoulderColor from '../../../../utils/getBoulderColor';
import { countValidatedSessionBouldersByColor } from '../../../../utils/sessionCountUtils';
import { useLogged } from '../../../../redux/auth/hooks';

interface Props {
  roomId?: number;
  startDate: Date;
  endDate: Date;
}

const Sessions: React.FC<Props> = ({ startDate, endDate, roomId }) => {
  // Hooks
  const t = useLocalize();
  const { logged } = useLogged();
  const { nbOfSession } = useCoachingUpdate();
  const { colors, getColors } = useColors();
  const { coachActivity, isFetching, requestPeriodicActivity } = useSessionsPerf();
  const { requestUserVisites, userVisites } = useUserVisit();
  const date = useFormattedDate(
    new Date(startDate),
    {
      month: 'long',
      year: 'numeric',
    },
    [startDate]
  );
  // Memos
  const nbSessions = useMemo(() => {
    const arr = coachActivity.filter((activity) => activity.sessionboulders.length);
    return arr.length;
  }, [coachActivity]);

  const countColor = useMemo(
    () => countValidatedSessionBouldersByColor(coachActivity),
    [coachActivity]
  );

  const nbBlocs = useMemo(
    () =>
      Object.values<number>(countColor).reduce<number>(
        (sum: number, sessionsNb: number) => sum + sessionsNb,
        0
      ),
    [countColor]
  );

  useEffect(() => {
    getColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logged && endDate && startDate) {
      const endDateCopy = new Date(new Date(endDate).setHours(23, 59, 59, 999));
      requestPeriodicActivity(startDate, endDateCopy, roomId);
      requestUserVisites(startDate.toDateString(), endDate.toDateString(), roomId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate, nbOfSession, logged, roomId]);

  return (
    <div>
      <div className='introMySessions'>
        <div className='sectionName'>
          <div className='activityTitle'>{t('my_sessions')}</div>
          <span className='activityDate'>{date}</span>
        </div>
        <p>{t('my_sessions_paragraph')}</p>
      </div>
      {isFetching && <Loader />}
      {!isFetching && (
        <div className='sessionsRecap'>
          <div className='calendarRecap'>
            <div className='title'>
              <Point />
              <div>{t('number_of_sessions')}</div>
              <div className='number'>{nbSessions}</div>
            </div>
            <div className='calendar'>
              <Calendar
                startDate={startDate}
                endDate={endDate}
                sessions={coachActivity}
                visits={userVisites}
              />
            </div>
            <div className='addSessionHint'>{t('add_session')}</div>
          </div>
          <div className='blocRecap'>
            <div className='title'>
              <Point />
              <div>{t('number_of_bloc')}</div>
              <div className='number'>{nbBlocs}</div>
            </div>
            <div className='colorSession'>
              {!!colors &&
                colors.map((col: any, index: number) => {
                  const { color } = getBoulderColor(col.name);
                  return (
                    <div className='individualColor' key={index} style={{ backgroundColor: color }}>
                      {!!countColor[col.name] ? countColor[col.name] : 0}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sessions;
