import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as BoulderVideoActions, selectors as BoulderVideoSelector } from './redux.js';
import { trackEvent } from '../../providers/tracker';

const BoulderVideoType = {
  opener: 'OPENER',
  user: 'USER',
};

export const useBoulderVideo = (videoId) => {
  const dispatch = useDispatch();

  const video = useSelector((state) => BoulderVideoSelector.videoById(state, videoId));

  const requestGetVideo = useCallback(
    () => dispatch(BoulderVideoActions.requestBoulderVideo(videoId)),
    [dispatch, videoId]
  );

  const requestDeleteVideo = useCallback(
    () => dispatch(BoulderVideoActions.requestDeleteVideo(video.boulderId, videoId, video.type)),
    [dispatch, video?.boulderId, video?.type, videoId]
  );

  useEffect(() => {
    if (!video && videoId) {
      requestGetVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video, videoId]);

  return {
    video,
    requestDeleteVideo,
    getVideo: requestGetVideo,
  };
};

export const useBoulderVideos = (boulderId) => {
  const dispatch = useDispatch();

  const isFetching = useSelector((state) => BoulderVideoSelector.isFetching(state));
  const isFullLoaded = useSelector((state) => BoulderVideoSelector.isFullLoaded(state));

  const videoIds = useSelector((state) =>
    BoulderVideoSelector.videoIds(state, boulderId, BoulderVideoType.user)
  );
  const videoOpenerIds = useSelector((state) =>
    BoulderVideoSelector.videoIds(state, boulderId, BoulderVideoType.opener)
  );

  const requestGetVideos = useCallback(
    (filters) => dispatch(BoulderVideoActions.requestBoulderVideos(boulderId, filters)),
    [boulderId, dispatch]
  );

  return {
    videoIds,
    videoOpenerIds,
    isFetching,
    isFullLoaded,
    getVideos: requestGetVideos,
  };
};

export const useCreateBoulderVideo = (boulderId) => {
  const dispatch = useDispatch();

  const isFetching = useSelector((state) => BoulderVideoSelector.isFetching(state));

  const requestCreateVideo = useCallback(
    (videoBlob, params = {}, callback) => {
      trackEvent('block_video_send');
      dispatch(BoulderVideoActions.createBoulderVideo(boulderId, videoBlob, params, callback));
    },
    [boulderId, dispatch]
  );

  return {
    isFetching,
    createVideo: requestCreateVideo,
  };
};
