import { createAction } from 'redux-act';

export const initialState = {
  pencilWidth: 5,
  pencilColorIndex: 0,
  history: [],
};

export const actions = {
  setPencilWidth: createAction('Editor: set width', (width) => ({ width })),
  setPencilColorIndex: createAction('Editor: set color index', (colorIndex) => ({
    colorIndex,
  })),
  addToHistory: createAction('Editor: add to history', (serializedPath) => ({
    serializedPath,
  })),
  updateHistory: createAction('Editor: update history item', (name, params) => ({
    name,
    params,
  })),
  removeHistory: createAction('Editor: remove item from history', (name) => ({
    name,
  })),
  historyBack: createAction('Editor: history back'),
};

export const reducers = {
  [actions.setPencilWidth]: (state, { width }) => state.setIn(['pencilWidth'], width),
  [actions.setPencilColorIndex]: (state, { colorIndex }) =>
    state.setIn(['pencilColorIndex'], colorIndex),
  [actions.addToHistory]: (state, { serializedPath }) => {
    const history = state.history.asMutable();
    history.push(serializedPath);
    return state.setIn(['history'], history);
  },
  [actions.updateHistory]: (state, { name, params = {} }) => {
    if (state.history.length === 0) return state;
    const history = state.history.asMutable();
    const index = history.findIndex((_) => _.data.name === name);

    if (index >= 0) {
      history[index] = { ...history[index], ...params };
      return state.setIn(['history'], history);
    }
    return state;
  },
  [actions.removeHistory]: (state, { name }) => {
    if (state.history.length === 0) return state;
    const history = state.history.asMutable();
    return state.setIn(
      ['history'],
      history.filter((_) => _.data.name !== name)
    );
  },
  [actions.historyBack]: (state) => {
    if (state.history.length === 0) return state;
    const history = state.history.asMutable();
    history.pop();
    return state.setIn(['history'], history);
  },
};

export const selectors = {
  pencilWidth: (state) => state.editor.pencilWidth,
  pencilColorIndex: (state) => state.editor.pencilColorIndex,
  lastInHistory: (state) => {
    const lastItem =
      state.editor.history.length === 0
        ? null
        : state.editor.history[state.editor.history.length - 1];
    return lastItem?.asMutable ? lastItem.asMutable() : lastItem;
  },
  history: (state) => state.editor.history,
};
