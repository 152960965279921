import React, { useCallback, useMemo, useState } from 'react';
import { useCurrentUser } from '../../redux/user/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { IonContent, IonPage, IonBackdrop } from '@ionic/react';

import Levels from '../../components/Levels/Levels';
import HomeNews from './partials/HomeNews/HomeNews';
import Header from '../../routes/components/Header/Header';
import Activity from '../../components/Activity/Activity';
import HomeOpenings from './partials/HomeOpenings/HomeOpenings';
import Icon from '../../atoms/Icon/Icon';
import MyRank from '../../components/MyRank/MyRank';

import './Home.scss';
import { useLogged } from '../../redux/auth/hooks';
import HomeShop from './partials/HomeShop/HomeShop';
import ROUTES from '../../routes/constants';
import { useNavigate } from 'react-router';
import { trackEvent } from '../../providers/tracker';
import HomeCarte from '../MaCarte/HomeCarte/HomeCarte';

const HomeItem: React.FC<{
  label: string;
  valueKey: string;
  className: string;
  onClick: (value: string) => void;
  isDisable: boolean;
  itemIcon?: string;
}> = ({ label, valueKey, className, onClick, isDisable, itemIcon }) => {
  const handleClick = useCallback(() => onClick(valueKey), [onClick, valueKey]);
  return (
    <button className={className} onClick={handleClick} disabled={isDisable}>
      {itemIcon && <Icon icon={itemIcon} className='buttonIcon' />}
      {label}
    </button>
  );
};

const Home: React.FC = () => {
  // Hooks
  const { logged } = useLogged();
  const t = useLocalize();
  const navigate = useNavigate();
  const { room } = useSelectedRoom();
  const { currentUser } = useCurrentUser();

  // Memos
  const dateEnd = useMemo(() => new Date(), []);
  const dateStart = useMemo(
    () => new Date(dateEnd.getFullYear(), dateEnd.getMonth() - 1, dateEnd.getDate()),
    [dateEnd]
  );

  const handleClick = useCallback((value: any) => navigate(value), [navigate]);

  const [displayInfo, setDisplayInfo] = useState<boolean>(false);
  const handleHideInfo = useCallback(() => {
    setDisplayInfo(false);
  }, []);

  const handleClickProgress = useCallback(() => {
    trackEvent('activity');
    navigate(ROUTES.COACH);
  }, [navigate]);
  const handleClickMyRank = useCallback(() => {
    trackEvent('leaderboard');
    navigate(ROUTES.COMMUNITY);
  }, [navigate]);

  return (
    <IonPage className='home'>
      {displayInfo && <IonBackdrop visible tappable onIonBackdropTap={handleHideInfo} />}
      <Header
        isCoach={false}
        showProfile
        showRoom
        showMonth={false}
        showWalls={false}
        showSectors={false}
        allowAccess
      />
      <IonContent fullscreen className='homeContent'>
        {logged && <HomeCarte />}
        <HomeNews room={room} />
        {logged && (
          <div className='progress' onClick={handleClickProgress}>
            <div className='progressTitle'>{t('my_progress')}</div>
            <Activity dateStart={dateStart} dateEnd={dateEnd} isHome />
            <Levels
              title={t('my_level')}
              dateStart={dateStart}
              dateEnd={dateEnd}
              isHome
              isLastSession={false}
            />
          </div>
        )}
        <HomeShop />
        {logged && (
          <MyRank
            onClick={handleClickMyRank}
            date={dateEnd}
            room={room}
            displayInfo={displayInfo}
            setDisplayInfo={setDisplayInfo}
            isDetailed={false}
          />
        )}
        <HomeOpenings room={room} />
      </IonContent>
      {currentUser?.isAdmin && (
        <div className='buttonOverlay'>
          <HomeItem
            valueKey={ROUTES.OPENING}
            label={t('opening')}
            onClick={handleClick}
            className='openingButton'
            isDisable={false}
          />
        </div>
      )}
    </IonPage>
  );
};

export default Home;
