import { gql } from '@apollo/client';

export const SendUpdate = gql`
  mutation SendUpdate($boulderId: Int!, $typeId: Int!, $value: Int) {
    updateAudit(
      updateAuditData: { boulderId: $boulderId, typeId: $typeId, languageId: 1, value: $value }
    ) {
      boulderId
      updateDate
      typeId
      value
    }
  }
`;

export const SendIncrementation = gql`
  mutation SendIncrementation($boulderId: Int!, $typeId: Int!, $date: DateTime) {
    incrementAudit(
      incrementAuditData: { boulderId: $boulderId, typeId: $typeId, languageId: 1, date: $date }
    ) {
      value
      typeId
      updateDate
    }
  }
`;
export const SendIncrementations = gql`
  mutation SendIncrementations($incrementAuditsData: IncrementAuditsInput!) {
    incrementAudits(incrementAuditsData: $incrementAuditsData) {
      userId
      value
      typeId
      boulderId
      languageId
    }
  }
`;

export const SendClap = gql`
  mutation SendClap($boulderId: Int!, $value: Int!) {
    updateAuditClap(updateClapData: { boulderId: $boulderId, languageId: 1, value: $value }) {
      value
      boulder {
        id
      }
    }
  }
`;
export const SendSingleValidation = gql`
  mutation SendSingleValidation(
    $sessionId: Int!
    $boulderId: Int!
    $roomId: Int!
    $isCoaching: Boolean!
    $status: String!
  ) {
    validateBoulder(
      validateBoulderData: {
        sessionId: $sessionId
        boulderId: $boulderId
        roomId: $roomId
        isCoaching: $isCoaching
        status: $status
      }
    ) {
      sessionBoulder {
        id
        boulderId
        sessionId
        status
        iscoaching
      }
    }
  }
`;

export const SendMultipleValidation = gql`
  mutation SendMultipleValidation(
    $sessionId: Int!
    $roomId: Int!
    $boulders: [ValidateBouldersElementInput!]!
  ) {
    validateBoulders(
      validateBouldersData: { sessionId: $sessionId, roomId: $roomId, boulders: $boulders }
    ) {
      userId
      boulderId
      sessionBoulder {
        id
        sessionId
        status
        iscoaching
        boulderId
      }
    }
  }
`;

export const UnvalidateBoulder = gql`
  mutation UnvalidateBoulder($sessionBoulderId: Int!, $roomId: Int!) {
    unvalidateBoulder(
      unvalidateBoulderData: { sessionBoulderId: $sessionBoulderId, roomId: $roomId }
    ) {
      boulder {
        validationConditions {
          isFlashable
          isValidable
          session {
            startDate
          }
          status
        }
      }
    }
  }
`;
