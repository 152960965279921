import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useCoaching } from '../../redux/coaching/hooks';
import { IonPage, IonLoading } from '@ionic/react';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useActiveSession } from '../../redux/sessions/hooks';

import Icon from '../../atoms/Icon/Icon';
import Alert from '../../components/Alert/Alert';
import Header from '../../routes/components/Header/Header';
import EndCoach from '../../components/EndCoach/EndCoach';

import './PlanEntrainement.scss';
import IconTypes from '../../types/IconTypes';
import room from '../../utils/room.interface';
import getBoulderColor from '../../utils/getBoulderColor';
import { CoachBoulder } from '../../utils/coach.interface';
import OptimizedImage from '../../atoms/OptimizedImage/OptimizedImage';
import ROUTES from '../../routes/constants';
import { trackEvent } from '../../providers/tracker';

interface Location {
  pathname: string;
  state?: {
    room?: room;
    volume?: number;
    time?: number;
  };
}

interface ItemProps {
  boulder: CoachBoulder;
  index: number;
  launch: (index: number) => void;
  validatedBloc: number[];
}

const PlanItem: React.FC<ItemProps> = ({ boulder, index, launch, validatedBloc }) => {
  const { color } = getBoulderColor(boulder.color.name);
  const handleLaunching = useCallback(() => {
    launch(index);
  }, [index, launch]);

  return (
    <div className='wallItem' key={boulder.id} onClick={handleLaunching}>
      <span className='boulderName'>{boulder.sector.name}</span>
      <div className='boulderIndex'>{index + 1}</div>
      <OptimizedImage className='wallImg' src={boulder?.picture} alt='boulder' format='thumb' />
      {validatedBloc?.includes(boulder.id) && (
        <div className='doneBackground'>
          <Icon icon={IconTypes.TICK_CIRCLE_FULL} className='done' />
        </div>
      )}
      <div className='boulderColor' style={{ backgroundColor: color }}></div>
    </div>
  );
};

const PlanEntrainement: React.FC = () => {
  const t = useLocalize();
  const navigate = useNavigate();
  const { sessionBoulders, isFetching, cleanCoaching, terminateSession, getSessionBoulders } =
    useCoaching();
  const { activeSession } = useActiveSession();
  const location: Location = useLocation();
  const { room } = useSelectedRoom();
  const { volume, time } = useMemo(
    () => ({
      volume: location.state?.volume,
      time: location.state?.time,
    }),
    [location.state]
  );
  const [_index, setIndex] = useState<number>(0);
  const [end, setEnd] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [numberOfBlocs, setNumberOfBlocs] = useState<number>(0);
  const [_boulderClick, setBoulderClick] = useState<boolean>(false);
  const validatedBloc = useMemo(() => {
    return activeSession?.sessionboulders?.map((boulder) => {
      if (boulder.status !== 'FAIL') return boulder.boulderId;
      return undefined;
    });
  }, [activeSession?.sessionboulders]);

  useEffect(() => {
    if (volume && time && room) {
      getSessionBoulders(volume, time, room.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, time, volume]);

  useEffect(() => {
    if (sessionBoulders) {
      setNumberOfBlocs(sessionBoulders.length);
    }
  }, [sessionBoulders]);

  const launchCoach = useCallback(
    (index: number) => {
      setIndex(index);
      if (!!sessionBoulders?.length) {
        setBoulderClick(false);
        navigate(`${ROUTES.BLOC}/${sessionBoulders[index].id}`, {
          state: {
            room,
            boulders: sessionBoulders,
            index,
            coach: true,
          },
        });
      }
    },
    [navigate, room, sessionBoulders]
  );

  const handleDone = useCallback(() => {
    trackEvent('coach_finish', {
      event_category: 'coach',
      event_action: 'coach_finish',
    });
    setIndex(0);
    setBoulderClick(false);
    terminateSession();
    setEnd(true);
  }, [terminateSession]);

  const handleBack = useCallback(() => {
    trackEvent('back', { path: 'coach' });
    cleanCoaching();
    terminateSession();
    setShowAlert(false);
    setIndex(0);
    setBoulderClick(false);
    navigate(ROUTES.TRAINING, {
      state: { room },
    });
  }, [cleanCoaching, navigate, room, terminateSession]);

  const displayAlert = useCallback(() => {
    if (!showAlert) setShowAlert(true);
  }, [showAlert]);

  return (
    <IonPage>
      <div className='coachCircuitPage'>
        <Header
          isCoach
          coachStart
          goBack={displayAlert}
          showProfile={false}
          showMonth={false}
          showWalls={false}
          showSectors={false}
          showRoom={false}
        />
        <div className='cicuitDescription'>
          <span className='secondaryTitle'>{t('circuit_plan')}</span>
          {!!sessionBoulders && (
            <span className='circuitContent'>
              {t('plan_comment', { numberOfBlocs: numberOfBlocs.toString() })}
            </span>
          )}
        </div>
        <div className='flexList'>
          {!!sessionBoulders &&
            sessionBoulders.map((boulder: CoachBoulder, index: number) => (
              <PlanItem
                boulder={boulder}
                key={index}
                index={index}
                launch={launchCoach}
                validatedBloc={validatedBloc}
              />
            ))}
        </div>
        <div className='circuitActionButtons'>
          <button onClick={handleDone} disabled={!validatedBloc?.length}>
            {t('coach_finish')}
          </button>
        </div>
        <IonLoading cssClass='custom-loader-class' isOpen={isFetching} message={''} />
        {end && <EndCoach room={room} onDismiss={setEnd} />}
        {end && <div className='endBackground' />}
        <Alert
          onAlertChange={setShowAlert}
          title={t('leave_session_alert')}
          message={t('leave_session_confirmation')}
          actionName={t('leave')}
          action={handleBack}
          position=' 80%'
          display={showAlert}
        />
      </div>
    </IonPage>
  );
};

export default PlanEntrainement;
