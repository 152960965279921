import { gql } from '@apollo/client';

export const updateUser = gql`
  mutation updateUser($instagram: String, $bio: String, $picture: String) {
    updateUser(updateUserData: { instagram: $instagram, bio: $bio, picture: $picture }) {
      instagram
      bio
      picture
    }
  }
`;
