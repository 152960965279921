import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

const initialState = Immutable({
  leaderBoardsGlobal: [],
  roomLeaderBoard: {},
  colorsCount: [],

  isFetching: false,
  isFullLoaded: false,
  isByUserColor: false,
  bestBoulders: [],
});

export const actions = {
  requestLeaderBoardsWithParams: createAction(
    'request leader boards by roomId and/or colorId',
    (roomId, callback) => ({
      roomId,
      callback,
    })
  ),
  requestLoadMoreLeaderBoardsWithParams: createAction(
    'load more leader boards by roomId and/or colorId',
    (roomId) => ({
      roomId,
    })
  ),
  requestRoomLeaderBoard: createAction('request leader boards by room', (roomId, id) => ({
    roomId,
    id,
  })),

  requestLeaderBoardsGlobal: createAction('request leader boards global'),

  requestColorsCount: createAction('request color count', (roomId) => ({
    roomId,
  })),
  requestUserRankUpdate: createAction('request userRank update'),

  setBoardsGlobal: createAction('set leaderBoard', (boards) => ({ boards })),
  addBoardsGlobal: createAction('add leaderBoard', (boards) => ({ boards })),
  addRoomBoard: createAction('add roomBoard', (board, id) => ({ board, id })),

  addColorsCount: createAction('add ColorsCount', (count) => ({ count })),
  setIsByUserColor: createAction('set isByUserColor'),

  requestUserBestBoulders: createAction('requestUserBestBoulders', (roomId, userId) => ({
    roomId,
    userId,
  })),

  setBestBoulders: createAction('set bestBoulders', (bestBoulders) => ({ bestBoulders })),

  setFetching: createAction('setFetching', (value) => ({ value })),
  setFullLoaded: createAction('setFullLoaded', (value) => ({ value })),
};

export const reducer = createReducer(
  {
    [actions.setBoardsGlobal.toString()]: (state, { boards }) =>
      state.setIn(['leaderBoardsGlobal'], boards),
    [actions.addBoardsGlobal.toString()]: (state, { boards }) =>
      state.setIn(['leaderBoardsGlobal'], [...state.leaderBoardsGlobal, ...boards]),
    [actions.addRoomBoard.toString()]: (state, { board, id }) =>
      state.setIn(['roomLeaderBoard', id], board),
    [actions.setIsByUserColor.toString()]: (state) =>
      state.setIn(['isByUserColor'], !state.isByUserColor),
    [actions.setBestBoulders.toString()]: (state, { bestBoulders }) =>
      state.setIn(['bestBoulders'], bestBoulders),
    [actions.addColorsCount.toString()]: (state, { count }) => state.setIn(['colorsCount'], count),

    [actions.setFetching.toString()]: (state, { value }) => state.setIn(['isFetching'], value),
    [actions.setFullLoaded.toString()]: (state, { value }) => state.setIn(['isFullLoaded'], value),
  },
  initialState
);

export const selectors = {
  leaderBoardsGlobal: (state) => state.leaderBoard.leaderBoardsGlobal,
  roomLeaderBoard: (state) => state.leaderBoard.roomLeaderBoard,
  colorsCount: (state) => state.leaderBoard.colorsCount,
  isFetching: (state) => state.leaderBoard.isFetching,
  isFullLoaded: (state) => state.leaderBoard.isFullLoaded,
  isByUserColor: (state) => state.leaderBoard.isByUserColor,
  bestBoulders: (state) => state.leaderBoard.bestBoulders,
};
