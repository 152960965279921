const Response = {
  success(data) {
    return { ok: true, data };
  },

  fail(error) {
    return { ok: false, error };
  },
};

export default Response;
