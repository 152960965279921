import React, { useEffect, useMemo, useState } from 'react';
import { useSectorBoulders } from '../../../../redux/boulders/hooks';

import ColorCard from '../../../../components/ColorCard/ColorCard';

import './Colors.scss';

interface Props {
  sector?: any;
  selectBoulder: Function;
  boulderAdded: boolean;
  dismantled: boolean;
}

const empty = ['', '', '', '', '', '', '', ''];

const Colors: React.FC<Props> = ({ sector, selectBoulder }) => {
  const [selectedIndex, setSelectedIndex] = useState();
  const { boulders } = useSectorBoulders(sector?.id);

  const tab = useMemo(() => {
    let ret: string[] = [];
    if (boulders) {
      ret = boulders.map((boulder: any) => {
        if (!boulder.offDate) return boulder.color.name;
        return undefined;
      });
    }
    let tmp: string[] = [];
    while ((tmp.length + ret.length) % 8) {
      tmp.push('');
    }
    return ret.concat(tmp);
  }, [boulders]);

  useEffect(() => {
    if (selectedIndex && boulders[selectedIndex]) {
      selectBoulder(boulders[selectedIndex]);
    } else if (selectedIndex === 0 && boulders[0]) {
      selectBoulder(boulders[0]);
    } else selectBoulder(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boulders, selectedIndex]);

  return (
    <div className='colors'>
      {!!tab.length &&
        tab.map((color: string, index: number) => (
          <ColorCard
            colorName={color}
            index={index}
            key={index}
            selectIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
          />
        ))}
      {!tab.length &&
        empty.map((color: string, index: number) => (
          <ColorCard
            colorName={color}
            index={index}
            key={index}
            selectIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
          />
        ))}
    </div>
  );
};

export default Colors;
