import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { useDemoMedias } from '../../redux/medias/hooks';
import { useLocalize } from '../../redux/translation/localize';

import Icon from '../../atoms/Icon/Icon';
import { IonInput, IonButton } from '@ionic/react';
import YoutubeEmbed from '../YoutubeEmbed/YoutubeEmbed';

import './DemoPopup.scss';
import IconTypes from '../../types/IconTypes';
import { mediaType, getMimeType } from '../../utils/fileUtils';
import OptimizedImage from '../../atoms/OptimizedImage/OptimizedImage';
import { isAndroid, osVersion } from 'react-device-detect';
import { getAndroidPermissions } from '../../utils/MobilePermissions/getAndroidPermissions';
import { AndroidPermissionList } from '../../utils/MobilePermissions/AndroidPermissionList';

import {
  DestinationType,
  Camera as AwesomeCamera,
  PictureSourceType,
  MediaType,
} from '@awesome-cordova-plugins/camera';
import { Camera as IonCamera } from '@capacitor/camera';

interface Props {
  display: Function;
  setMedia: Function;
  setText: Function;
  media?: string;
  text?: string;
  top?: boolean;
}

const DemoPopup: React.FC<Props> = ({ display, setMedia, setText, media, text, top }) => {
  const t = useLocalize();
  const textRef = useRef<any>();

  const [demoMedia, setDemoMedia] = useState<string>(media || '');
  const { uploadDemo, uploadedDemo, resetDemo, isLoading } = useDemoMedias();
  const type = useMemo(() => {
    const extension = demoMedia?.split('.').reverse()[0];
    const { type } = getMimeType(extension?.toLowerCase());
    return type;
  }, [demoMedia]);

  const position = useMemo(() => {
    if (top)
      return {
        margin: 'auto auto 0 auto',
      };
    else return {};
  }, [top]);

  useEffect(() => {
    if (demoMedia.startsWith('https://www.youtube')) {
      const el: any = document.getElementById('YouTube_URL');
      if (el) {
        el.value = demoMedia;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uploadedDemo) {
      setDemoMedia(uploadedDemo);
    }
  }, [uploadedDemo]);

  const handleDismiss = useCallback(() => {
    display(false);
    resetDemo();
  }, [display, resetDemo]);

  const handleEmbed = useCallback((e) => {
    const [basicUrl, clean] = e.detail.value.split('?');

    if (!clean?.length && !basicUrl?.length) {
      setDemoMedia('');
    } else {
      try {
        if (basicUrl.includes('youtu.be') || basicUrl.includes('/shorts/')) {
          const v = basicUrl.split('/').reverse()[0];
          setDemoMedia(v.length ? 'https://www.youtube.com/embed/' + v : e.detail.value);
        } else {
          const params = clean.split('&').reduce((acc: any, item) => {
            const [key, ...values] = item.split('=');
            const value = values.join('=');

            acc[decodeURIComponent(key) as string] = decodeURIComponent(value);
            return acc;
          }, {});

          setDemoMedia(!!params.v ? 'https://www.youtube.com/embed/' + params.v : e.detail.value);
        }
      } catch (err) {
        setDemoMedia(e.detail.value);
        console.error(err);
      }
    }
  }, []);

  const handleMedia = useCallback(async () => {
    let granted = true;
    if (isAndroid) {
      if (parseInt(osVersion) <= 12) {
        granted = await getAndroidPermissions([
          AndroidPermissionList.WRITE_EXTERNAL_STORAGE,
          AndroidPermissionList.READ_EXTERNAL_STORAGE,
        ]);
      } else {
        granted = await getAndroidPermissions([
          AndroidPermissionList.READ_MEDIA_IMAGES,
          AndroidPermissionList.READ_MEDIA_VIDEO,
        ]);
      }
    } else {
      const permissions = await IonCamera.requestPermissions({
        permissions: ['photos'],
      });
      if (permissions.photos !== 'granted') {
        granted = false;
      }
    }
    if (granted) {
      AwesomeCamera.getPicture({
        quality: 50,
        destinationType: DestinationType.DATA_URL,
        sourceType: PictureSourceType.PHOTOLIBRARY,
        mediaType: MediaType.VIDEO,
      })
        .then((data) => {
          let path = data;
          if (!data.startsWith('file:/')) {
            path = 'file://' + data;
          }
          uploadDemo(path);
        })
        .catch((err) => console.error(err));
    }
  }, [uploadDemo]);

  const handleValidation = useCallback(() => {
    setMedia(demoMedia);
    setText(textRef.current.value || '');
    display(false);
    resetDemo();
  }, [demoMedia, display, resetDemo, setMedia, setText]);
  const handleClearMedia = useCallback(() => setDemoMedia(''), []);

  return (
    <>
      <div className='demoPopupBackdrop' style={position} onClick={handleDismiss}></div>
      <div className='demoPopup' style={position}>
        <div className='demoPopupContent'>
          <div className='demoTitle'>{t('add_media')}</div>
          <IonInput ref={textRef} className='demoInput' value={text} placeholder='Description' />
          {!demoMedia && (
            <IonInput
              id='YouTube_URL'
              className='demoInput'
              placeholder='URL youtube'
              onIonChange={handleEmbed}
              clearInput
            />
          )}
          {!!demoMedia && !isLoading && (
            <div className='demoDisplay'>
              {type === mediaType.DEFAULT_TYPE && (
                <YoutubeEmbed
                  className='youtubeIframe'
                  src={demoMedia}
                  width='96'
                  height='166'
                  title='ma demo'
                />
              )}
              {type === mediaType.PHOTO_TYPE && (
                <OptimizedImage
                  src={demoMedia}
                  className='demoMediaDiplay'
                  alt='demo du bloc'
                  format='fullScreen'
                />
              )}
              {type === mediaType.VIDEO_TYPE && (
                <video src={demoMedia} className='demoMediaDiplay' controls autoPlay={false} />
              )}
            </div>
          )}
          {!demoMedia && (
            <div className='demoIcon'>
              <Icon icon={IconTypes.GALLERY} className='galleryIcon' onClick={handleMedia} />
            </div>
          )}
          {!!demoMedia && (
            <div className='demoIcon'>
              <Icon icon={IconTypes.TRASH} className='galleryIcon' onClick={handleClearMedia} />
            </div>
          )}
          <IonButton className='demoButton' onClick={handleValidation}>
            {t('ok')}
          </IonButton>
          <div className='alertTick' />
        </div>
      </div>
    </>
  );
};

export default DemoPopup;
