import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';
import GoalTypes from '../../types/GoalTypes';
import * as reducers from './reducers';

const initialState = Immutable({
  error: null,
  email: '',
  emailToken: '',
  userId: '',
  firstName: '',
  lastName: '',
  birthDate: '',
  userName: '',
  phoneNumber: '',
  userClub: {},
  userClubs: [],
  level: '',
  goals: {
    [GoalTypes.INTENSITY]: 0,
    [GoalTypes.PREHENSION]: 0,
    [GoalTypes.PHYSIQUE]: 0,
    [GoalTypes.TECHNIQUE]: 0,
    [GoalTypes.DEPLACEMENT]: 0,
  },
  picture: '',
  localPicture: '',
  password: '',
  clubs: [],
  userNotFound: false,
  isSync: false,
});

export const actions = {
  setSyncError: createAction('request sync user failed', (value) => ({ value })),

  setEmailToken: createAction('set email token', (token) => ({ token })),
  setUserId: createAction('set user id', (userId) => ({ userId })),
  setEmail: createAction('set email', (email) => ({ email })),
  setFirstName: createAction('set firstName', (firstName) => ({ firstName })),
  setLastName: createAction('set lastName', (lastName) => ({ lastName })),
  setPhoneNumber: createAction('set phone number', (number) => ({ number })),
  setBirthDate: createAction('set birthDate', (birthDate) => ({ birthDate })),
  setUserInfo: createAction('set user info', (firstName, lastName, birthDate) => ({
    firstName,
    lastName,
    birthDate,
  })),
  setUserName: createAction('set userName', (userName) => ({ userName })),
  setUserClub: createAction('set user club', (club) => ({ club })),
  setUserClubs: createAction('set user clubs', (clubIds) => ({ clubIds })),
  setLevel: createAction('set level', (level) => ({ level })),
  setPicture: createAction('set picture', (picture) => ({ picture })),
  setLocalPicture: createAction('set picture while the user is not logged', (picture) => ({
    picture,
  })),
  setGoals: createAction('set goals', (goalsType, value) => ({ goalsType, value })),
  setPassword: createAction('set password', (password) => ({ password })),
  setClubs: createAction('set clubs associated to user', (clubs) => ({ clubs })),
  setUserNotFound: createAction('set if user is not found', (value) => ({ value })),
  setIsSync: createAction('set if user is being synchronised', (value) => ({ value })),
  requestClubs: createAction('request clubs'),
  requestSyncUser: createAction('request sync user', (email, callback) => ({ email, callback })),
  requestSyncUserInfo: createAction(
    'request sync user info',
    (email, firstName, lastName, birthDate, callback) => ({
      email,
      firstName,
      lastName,
      birthDate,
      callback,
    })
  ),
  requestFullSyncUser: createAction(
    'request full sync user info',
    (email, firstName, lastName, birthDate, club, callback) => ({
      email,
      firstName,
      lastName,
      birthDate,
      club,
      callback,
    })
  ),
  requestSyncUserDeciplus: createAction('request deciplus sync user info', (payload) => ({
    payload,
  })),
  requestEmailVerification: createAction('request email verification', (email, callback) => ({
    email,
    callback,
  })),
  requestEmailCodeValidation: createAction(
    'request email code validation',
    (email, emailCode, callback) => ({
      email,
      emailCode,
      callback,
    })
  ),
  requestCreateAccount: createAction(
    'request account creation',
    (accountInfos, callback, level, goals) => ({
      accountInfos,
      callback,
      level,
      goals,
    })
  ),
  requestUpdateUsername: createAction(
    'request update username',
    (username, userId, emailToken, callback) => ({ username, userId, emailToken, callback })
  ),
  requestResetPassword: createAction('request reset password', (email, callback) => ({
    email,
    callback,
  })),
  sendResetPassword: createAction('send reset password', (password, userId, callback) => ({
    password,
    userId,
    callback,
  })),
  addOnboarding: createAction('onboard logged user', (goals, level, callback) => ({
    goals,
    level,
    callback,
  })),
  updateAccount: createAction('update account info', (info) => ({ info })),
};

export const reducer = createReducer(
  {
    [actions.setSyncError]: (state, { value }) => state.setIn(['error'], value),
    [actions.setEmailToken]: (state, { token }) => state.setIn(['emailToken'], token),
    [actions.setUserId]: (state, { userId }) => state.setIn(['userId'], userId),
    [actions.setEmail]: (state, { email }) => state.setIn(['email'], email),
    [actions.setFirstName]: (state, { firstName }) => state.setIn(['firstName'], firstName),
    [actions.setLastName]: (state, { lastName }) => state.setIn(['lastName'], lastName),
    [actions.setPhoneNumber]: (state, { number }) => state.setIn(['phoneNumber'], number),
    [actions.setBirthDate]: (state, { birthDate }) => state.setIn(['birthDate'], birthDate),
    [actions.setUserInfo]: (state, { firstName, lastName, birthDate }) => {
      state.setIn(['firstName'], firstName);
      state.setIn(['lastName'], lastName);
      state.setIn(['birthDate'], birthDate);
      return state;
    },
    [actions.setUserName]: (state, { userName }) => state.setIn(['userName'], userName),
    [actions.setUserClub]: (state, { club }) => state.setIn(['userClub'], club),
    [actions.setUserClubs]: reducers.setUserClubs,
    [actions.setLevel]: (state, { level }) => state.setIn(['level'], level),
    [actions.setPicture]: (state, { picture }) => state.setIn(['picture'], picture),
    [actions.setLocalPicture]: (state, { picture }) => state.setIn(['localPicture'], picture),
    [actions.setGoals]: (state, { goalsType, value }) => state.setIn(['goals', goalsType], value),
    [actions.setPassword]: (state, { password }) => state.setIn(['password'], password),
    [actions.setClubs]: (state, { clubs }) => state.setIn(['clubs'], clubs),
    [actions.setUserNotFound]: (state, { value }) => state.setIn(['userNotFound'], value),
    [actions.setIsSync]: (state, { value }) => state.setIn(['isSync'], value),
  },
  initialState
);

export const selectors = {
  email: (state) => state.account.email,
  emailToken: (state) => state.account.emailToken,
  userId: (state) => state.account.userId,
  firstName: (state) => state.account.firstName,
  lastName: (state) => state.account.lastName,
  birthDate: (state) => state.account.birthDate,
  userName: (state) => state.account.userName,
  phoneNumber: (state) => state.account.phoneNumber,
  userClub: (state) => state.account.userClub,
  level: (state) => state.account.level,
  goals: (state) => state.account.goals,
  picture: (state) => state.account.picture,
  localPicture: (state) => state.account.localPicture,
  password: (state) => state.account.password,
  clubs: (state) => state.account.clubs,
  userClubs: (state) => state.account.userClubs,
  error: (state) => state.account.error,
  userNotFound: (state) => state.account.userNotFound,
  isSync: (state) => state.account.isSync,
};
