import { useCallback, useMemo } from 'react';
import getBoulderColor from '../../utils/getBoulderColor';
import { useLocalize } from '../../redux/translation/localize';
import Boulder from '../../utils/boulder.interface';

const BoulderValue: React.FC<{
  boulder: Boulder & { value: number };
  onClick: (id: number) => void;
}> = ({ boulder, onClick }) => {
  const t = useLocalize();
  const { color } = useMemo(() => getBoulderColor(boulder.color?.name), [boulder.color?.name]);

  const handleClick = useCallback(() => onClick(boulder.id), [boulder.id, onClick]);

  return (
    <div style={{ backgroundColor: color }} className='boulder_value' onClick={handleClick}>
      {t('boulder_value', { value: boulder.value?.toFixed() })}
    </div>
  );
};

export default BoulderValue;
