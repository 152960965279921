import { gql } from '@apollo/client';

export const GetNews = gql`
  query GetNews($roomId: Int, $skip: Int) {
    homemessagesWithFilters(roomId: $roomId, limit: 20, skip: $skip) {
      roomId
      id
      title
      image
      content
      creationDate
      link
      room {
        name
      }
    }
  }
`;
