import { useCallback } from 'react';
import getBoulderColor from '../../../../utils/getBoulderColor';

const ColorFilter: React.FC<{
  colorId: number;
  sectorId: number;
  boulderId?: number;
  name: string;
  selectedColor: number;
  onClick: (id: number) => void;
}> = ({ name, colorId, selectedColor, onClick }) => {
  // const { boulders } = useSectorBoulders(sectorId);
  const { color, borderColor } = getBoulderColor(name);

  // const isDisabled = useMemo(
  //   () => boulders.some((boulder) => boulderId !== boulder.id && boulder.color.id === colorId),
  //   [boulderId, boulders, colorId]
  // );

  const handleClick = useCallback(() => onClick(colorId), [colorId, onClick]);

  return (
    <div
      key={colorId}
      className='individualColor'
      style={{
        background: color,
        border: selectedColor === colorId ? 'solid' : '',
        borderColor: selectedColor === colorId ? borderColor : '',
        height: selectedColor === colorId ? '2.75rem' : '1.875rem',
        // opacity: isDisabled ? 0.2 : 1,
      }}
      onClick={handleClick}
    ></div>
  );
};

export default ColorFilter;
