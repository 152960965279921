import { gql } from '@apollo/client';

export const GetLeaderboard = gql`
  query getLeaderboard($skip: Int, $limit: Int, $colorId: Int, $roomId: Int) {
    leaderboard(skip: $skip, limit: $limit, colorId: $colorId, roomId: $roomId) {
      user {
        id
        isAdmin
        picture
        login
        colorRank {
          colorId
          rank
        }
        dominantColorId(roomId: $roomId)
      }
      userId
      score
    }
  }
`;

export const GetUserScore = gql`
  query GetUserScore($roomId: Int, $colorId: Int) {
    myScore(roomId: $roomId, colorId: $colorId)
  }
`;

export const GetDominantColor = gql`
  query GetDominantColor($userId: Int!, $roomId: Int) {
    dominantColor(userId: $userId, roomId: $roomId)
  }
`;

export const GetMyRank = gql`
  query GetMyRank($roomId: Int, $colorId: Int) {
    myRank(roomId: $roomId, colorId: $colorId)
  }
`;

export const GetUserBestBoulders = gql`
  query GetUserBestBoulders($roomId: Int, $colorId: Int, $userId: Int!) {
    userBestBoulders(roomId: $roomId, colorId: $colorId, userId: $userId) {
      boulder {
        color {
          name
        }
        intensity {
          name
        }
        sector {
          name
        }
      }
      room {
        name
      }
      color {
        name
      }
      sessionBoulder {
        status
        session {
          startDate
        }
      }
      score
    }
  }
`;

export const GetMyBestBoulders = gql`
  query GetMyBestBoulders($roomId: Int, $colorId: Int) {
    myBestBoulders(roomId: $roomId, colorId: $colorId) {
      boulder {
        color {
          name
        }
        intensity {
          name
        }
        sector {
          name
        }
      }
      room {
        name
      }
      color {
        name
      }
      sessionBoulder {
        status
        session {
          startDate
        }
      }
      score
    }
  }
`;
