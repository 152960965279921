import { useEffect, useRef } from 'react';
import { useLocalize } from '../../redux/translation/localize';
import { useLiberfitShop } from '../../redux/user/hooks';
import { AccessDto } from '../../types/AccessTypes';
import Access from './Access';
import './AccessList.scss';

const AccessList = ({ userTickets }: { userTickets: number | AccessDto[] }) => {
  const timer = useRef<NodeJS.Timeout>();
  const t = useLocalize();
  const { getIframeToken } = useLiberfitShop('accessList');

  useEffect(() => {
    getIframeToken();
    if (timer.current) clearInterval(timer.current);
    timer.current = setInterval(() => getIframeToken(), 15000);

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [getIframeToken]);

  if (userTickets === 0) {
    return (
      <div className='accessList'>
        {Array(2)
          .fill(0)
          .map((_, index) => (
            <Access key={index} />
          ))}
      </div>
    );
  }

  if ((userTickets as AccessDto[]).length === 0) {
    return (
      <div className='accessList'>
        <div className='accessMessage'>{t('no_tickets_or_subscription')}</div>
      </div>
    );
  }

  return (
    <div className='accessList'>
      {(userTickets as AccessDto[]).map((access, index) => (
        <Access key={index} access={access} />
      ))}
    </div>
  );
};

export default AccessList;
