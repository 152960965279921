import { IonFooter } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { useLogged } from '../../../redux/auth/hooks';
import { useLocation, useNavigate } from 'react-router';
import { useLocalize } from '../../../redux/translation/localize';

import FooterItem from './FooterItem/FooterItem';
import Alert from '../../../components/Alert/Alert';

import './Footer.scss';
import ROUTES from '../../constants';
import room from '../../../utils/room.interface';
import { FooterItemProps } from '../MainFooter/footerTypes';
import { trackEvent } from '../../../providers/tracker';

interface Props {
  room?: room;
  items?: Array<FooterItemProps> | null;
}

const FooterMapItem: React.FC<{ [key: string]: any }> = (props) => {
  const { onClick, disabled, path, needsAuth } = props;

  const handleClick = useCallback(() => {
    trackEvent(`click_${props.path.replace('/', '')}`, {
      event_category: props.path.replace('/', ''),
      event_action: `click_${props.path.replace('/', '')}`,
    });
    onClick({ path, disabled, needsAuth });
  }, [disabled, needsAuth, onClick, path, props.path]);

  return (
    <FooterItem
      {...props}
      path={path}
      idx={props.idx}
      name={props.name}
      icon={props.icon}
      isSelected={props.isSelected}
      onClick={handleClick}
    />
  );
};

const Footer: React.FC<Props> = ({ items = [] }) => {
  const t = useLocalize();
  const location = useLocation();
  const { logged } = useLogged();
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleClick = useCallback(
    ({ path, disabled, needsAuth }) => {
      if (disabled) return;
      if (needsAuth && !logged) {
        setShowAlert(true);
      } else {
        navigate(path);
      }
    },
    [navigate, logged]
  );

  const handleAlerte = useCallback(() => {
    setShowAlert(false);
    trackEvent('footer');
    navigate(ROUTES.LOGIN);
  }, [navigate]);

  if (!items) return null;

  return (
    <>
      <Alert
        onAlertChange={setShowAlert}
        title={t('register_alert_title')}
        message={t('register_alert_description')}
        actionName={t('register')}
        action={handleAlerte}
        position=' 4.99rem'
        display={showAlert}
      />
      <IonFooter className='footer'>
        <div className='footer_container'>
          {items?.map((ele, idx) => (
            <FooterMapItem
              {...ele}
              key={idx}
              idx={idx}
              isSelected={ele.path === location?.pathname}
              onClick={handleClick}
            />
          ))}
        </div>
      </IonFooter>
    </>
  );
};

export default Footer;
