import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as FiltersActions, selectors as FiltersSelector } from './redux.js';

export const useFilter = () => {
  const dispatch = useDispatch();

  const tags = useSelector((state) => FiltersSelector.tags(state));
  const colors = useSelector((state) => FiltersSelector.colors(state));
  const intensities = useSelector((state) => FiltersSelector.intensities(state));
  const specialities = useSelector((state) => FiltersSelector.specialities(state));

  const getFilters = useCallback(() => dispatch(FiltersActions.requestFilters()), [dispatch]);

  useEffect(() => {
    if (!colors.length) getFilters();
  }, [colors.length, getFilters]);

  return {
    // Selectors
    tags,
    colors,
    intensities,
    specialities,

    // Actions
    getFilters,
  };
};

export const useColors = () => {
  const dispatch = useDispatch();
  const colors = useSelector((state) => FiltersSelector.colors(state));
  const getColors = useCallback(
    (roomId = null) => dispatch(FiltersActions.requestColors(roomId)),
    [dispatch]
  );

  return {
    colors,
    getColors,
  };
};
