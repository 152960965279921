import { StickerType } from '../type';
import { useCallback, useMemo, useRef } from 'react';
import OptimizedImage from '../../../../atoms/OptimizedImage/OptimizedImage';

import './StickerItem.scss';
import Icon from '../../../../atoms/Icon/Icon';
import { close, resize } from 'ionicons/icons';

const StickerItem: React.FC<{
  index: number;
  selected: boolean;
  sticker: StickerType;
  onClick: (index: number) => void;
  onChange: (sticker: StickerType | null, index: number) => void;
}> = ({ sticker, index, selected, onChange, onClick }) => {
  const resizeRef = useRef<any>(null);

  const style = useMemo(
    () => ({
      top: sticker.y,
      left: sticker.x,
      width: sticker.width,
      height: sticker.height,
    }),
    [sticker.height, sticker.width, sticker.x, sticker.y]
  );

  const handleDelete = useCallback(() => onChange(null, index), [index, onChange]);

  const handleClick = useCallback(
    (ev) => {
      onClick(index);
      ev.preventDefault();
      ev.stopPropagation();
    },
    [index, onClick]
  );

  const handleDrag = useCallback(
    (ev) => {
      if (!selected) {
        onClick(index);
      }
      if (ev?.touches?.[0]) {
        onChange(
          {
            ...sticker,
            y: ev.touches[0].clientY - sticker.height / 2,
            x: ev.touches[0].clientX - sticker.width / 2,
          },
          index
        );
      }
    },
    [index, onChange, onClick, selected, sticker]
  );

  const handleSizeDrag = useCallback(
    (ev) => {
      if (!resizeRef.current) {
        resizeRef.current = {
          startX: sticker.x,
          startY: sticker.y,
          size: sticker.height,
        };
      } else if (ev.type === 'touchend') {
        resizeRef.current = null;
      } else {
        const size = ev.touches[0].clientX - resizeRef.current.startX;
        onChange(
          {
            ...sticker,
            width: size,
            height: size,
          },
          index
        );
      }
    },
    [index, onChange, sticker]
  );

  return (
    <div
      id='StickerItem'
      className={selected ? 'selected' : ''}
      style={style}
      onClick={handleClick}
    >
      {!!selected && (
        <div className='close'>
          <Icon icon={close} onClick={handleDelete} />
        </div>
      )}
      {!!selected && (
        <div
          className='resize'
          onTouchStart={handleSizeDrag}
          onTouchMove={handleSizeDrag}
          onTouchEnd={handleSizeDrag}
        >
          <Icon icon={resize} />
        </div>
      )}
      <OptimizedImage
        draggable
        format='original'
        src={sticker.path}
        className='stickerItem'
        onTouchMove={handleDrag}
      />
    </div>
  );
};

export default StickerItem;
