// const CACHE_VIDEO = {};

const getVideoCover = async (url: string, seekTo: number = 0.0, hasBlob?: boolean) => {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', url);
    videoPlayer.setAttribute('crossOrigin', 'anonymous');
    videoPlayer.load();

    videoPlayer.addEventListener('error', (ex) => {
      console.error('Problem loading video', url, ex);
      reject(ex);
    });

    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.');
        return;
      }

      setTimeout(() => (videoPlayer.currentTime = seekTo), 200);

      videoPlayer.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx?.canvas.toBlob(
          (blob) => resolve(!hasBlob && !!blob ? URL.createObjectURL(blob) : blob || null),
          'image/jpeg',
          0.75
        );
      });
    });
  });
};

export default getVideoCover;
