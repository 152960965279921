import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useCoaching } from '../../redux/coaching/hooks';
import { useLocalize } from '../../redux/translation/localize';

import Levels from '../Levels/Levels';

import './EndCoach.scss';
import room from '../../utils/room.interface';
import ROUTES from '../../routes/constants';
import { trackEvent } from '../../providers/tracker';

interface Props {
  room: room;
  noAddPossibility?: boolean;
  onDismiss: (val: boolean) => void;
  showTop?: Function;
}

const EndCoach: React.FC<Props> = ({ onDismiss, showTop, noAddPossibility }) => {
  const t = useLocalize();
  const { clearSession, terminateSession } = useCoaching();
  const navigate = useNavigate();

  const handleEditSession = useCallback(() => {
    onDismiss(false);
    trackEvent('coach_add_more', {
      event_category: 'coach',
      event_action: 'coach_add_more',
    });
    navigate(ROUTES.TRAINING_EDIT);
  }, [navigate, onDismiss]);

  const handleFinish = useCallback(() => {
    onDismiss(false);
    terminateSession();
    clearSession();
    if (showTop) showTop(false);
    trackEvent('coach_complete', {
      event_category: 'coach',
      event_action: 'coach_complete',
    });
    navigate(ROUTES.COACH);
  }, [clearSession, navigate, onDismiss, showTop, terminateSession]);

  return (
    <div className='endCoachContent'>
      <div className='lastSessionRecap'>
        <div className='title'>{t('is_coach_finished')}</div>
        <Levels title='Ta performance' isLastSession styleOff />
        {!noAddPossibility && (
          <div className='addBlocHint'>
            <div className='addTitle'>{t('boulder_to_add')}</div>
            <div className='description'>{t('boulder_to_add_2')}</div>
            <div className='addButtonContainer' onClick={handleEditSession}>
              <button className='addButton'>{t('add')}</button>
            </div>
          </div>
        )}
      </div>
      <button className='endButton' onClick={handleFinish}>
        {t('finish')}
      </button>
    </div>
  );
};

export default EndCoach;
