import React, { useCallback, useMemo, useState } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { useLocation } from 'react-router';
import { useToken } from '../../redux/auth/hooks';

import Header from '../../routes/components/Header/Header';
import Loader from '../../atoms/Loader/Loader';

import './Boutique.scss';

const Boutique: React.FC = () => {
  const location: any = useLocation();
  const { token } = useToken();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { src } = useMemo(
    () => ({
      src: location.state?.src,
    }),
    [location.state]
  );
  const usedLink = useMemo(
    () =>
      src
        ? `${src}/?jwt_token=${token}`
        : `https://moncompte.climbingdistrict.com/?jwt_token=${token}boutique-mobile/#seances/`,
    [token, src]
  );

  const frameload = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <IonPage className='boutiqueContent'>
      <Header
        isCoach={false}
        showProfile
        showRoom={false}
        showMonth={false}
        showWalls={false}
        showSectors={false}
        shop
      />
      <IonContent className='shopContent'>
        <iframe
          className='boutiqueIframe'
          id='boutiqueIframe'
          title='boutique'
          src={usedLink}
          onLoad={frameload}
          sandbox='allow-storage-access-by-user-activation allow-scripts allow-same-origin'
        ></iframe>
      </IonContent>
      {isLoading && <Loader />}
    </IonPage>
  );
};

export default Boutique;
