import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import { StickerType } from '../../templates/VideoEditor/partial/type';
import getExtension from '../getExtension';
import { trimVideoWeb } from './utils/trimVideo';
import { CreateVideoProps, CreatorStatusEnum } from './utils/utils';

const PATH = '/tmp';
export const createWebVideo = ({
  video,
  onFinish,
  onLoading,
  onProgress,
  params = {},
}: CreateVideoProps) => {
  onLoading(true);
  const ffmpeg = createFFmpeg({
    log: true,
    corePath: `${window.location.origin}/ffmpeg/ffmpeg-core.js`,
  });

  (async () => {
    try {
      // Getting basic information
      const extension = getExtension(video.type);
      const videoUint8 = await fetchFile(video);
      let videoPath = `${PATH}/video.${extension}`;

      await ffmpeg.load();
      onProgress(CreatorStatusEnum.ONGOING, 0);

      // Creating the video file
      await ffmpeg.FS('writeFile', videoPath, videoUint8);

      // If there's no treatment to be done, we can use the basic file
      if (!params.trim && !params.stickers?.length) {
        return onFinish(video, `${PATH}/video.${extension}`);
      }

      // Trim the video if there's trimming to do
      if (params.trim) {
        await trimVideoWeb(
          ffmpeg,
          videoPath,
          params.trim.startTime,
          params.trim.endTime,
          `${PATH}/video_trim.${extension}`
        );

        videoPath = `${PATH}/video_trim.${extension}`;

        // If there's no other treatment to do other than trimming
        // We can use the trimming version
        if (!params.stickers?.length) {
          const data = ffmpeg.FS('readFile', videoPath);
          return onFinish(new Blob([data.buffer], { type: `video/${extension}` }), videoPath);
        }
      }

      // If you get here, it gets complicated, stickers are around
      // We set the basic runner for FFmpeg
      let runner = [
        '-hide_banner',
        '-loglevel',
        'error',
        '-r',
        '15',
        '-threads',
        '8',
        '-i',
        videoPath,
      ];

      // Then we create a filter complex to add all the stickers as overlays
      let filterComplex = '';
      const paths: string[] = [];
      for (let i = 0; i < params.stickers.length; i++) {
        const sticker: StickerType = params.stickers[i];

        // Load Image to get image original information and blob
        const filterBlob = await (await fetch(sticker.path))?.blob();
        const filterUint8 = await fetchFile(filterBlob);

        const img = new Image();
        img.src = URL.createObjectURL(filterBlob);

        await new Promise((resolve) => {
          img.onload = resolve;
        });

        // With the information we can create a safe size dividable by 2, otherwise FFmpeg won't be happy
        const safeWidth = Number((sticker.width / 2).toFixed(0)) * 2;
        const height = (sticker.height * img.naturalHeight) / (img.naturalWidth || 1);

        // We create the file to add it
        const name = `${PATH}/filter_${i}.png`;
        ffmpeg.FS('writeFile', name, filterUint8);

        // This is too complex I don't even know how to fully explain
        // But it's scaling and adding the sticker to the video creating a new image
        // And stacking the images because that's how it works
        const scaleFilter = `[${
          i + 1
        }:v]scale=${safeWidth}:${height}:force_original_aspect_ratio=decrease[img${i + 1}];`;
        const overlayFilter = `${i === 0 ? '[0:v]' : `[out${i}]`}[img${i + 1}]overlay=${
          sticker.x
        }:${sticker.y}:enable='between(t,0,999999)'`;

        filterComplex += `${scaleFilter}${overlayFilter}${
          i === params.stickers.length - 1 ? '[out]' : `[out${i + 1}];`
        }`;
        paths.push('-i');
        paths.push(name);
      }

      // Once filter complex done we add it to the runner
      runner = runner.concat([
        ...paths,
        '-filter_complex',
        filterComplex,
        '-map',
        `[out]`,
        '-map',
        '0:a',
      ]);

      // We add last parameters to prevent useless encoding + the output path
      runner = runner.concat([
        '-crf',
        '40',
        '-c:v',
        'libvpx',
        '-c:a',
        'copy',
        `${PATH}/output.${extension}`,
      ]);

      // We run the runner
      await ffmpeg.run(...runner);
      console.timeEnd('runner');

      // We get the file to use it
      const data = ffmpeg.FS('readFile', `${PATH}/output.${extension}`);
      onFinish(
        new Blob([data.buffer], { type: `video/${extension}` }),
        `${PATH}/output.${extension}`
      );

      // All done, gg wp amigos
      ffmpeg.exit();
    } catch (err) {
      console.error(err);
    }
    onLoading(false);
  })();
};
