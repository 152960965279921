import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as EditorActions, selectors as EditorSelectors } from '../redux.js';

export const usePencil = () => {
  const dispatch = useDispatch();

  const pencilWidth = useSelector(EditorSelectors.pencilWidth);
  const pencilColorIndex = useSelector(EditorSelectors.pencilColorIndex);

  const setPencilWidth = useCallback(
    (width) => dispatch(EditorActions.setPencilWidth(width)),
    [dispatch]
  );
  const setPencilColorIndex = useCallback(
    (index) => dispatch(EditorActions.setPencilColorIndex(index)),
    [dispatch]
  );

  return {
    pencilWidth,
    pencilColorIndex,
    setPencilWidth,
    setPencilColorIndex,
  };
};

export const usePencilHistory = () => {
  const dispatch = useDispatch();

  const history = useSelector(EditorSelectors.history);
  const lastInHistory = useSelector(EditorSelectors.lastInHistory);

  const addToHistory = useCallback(
    (serializedPath) => dispatch(EditorActions.addToHistory(serializedPath)),
    [dispatch]
  );
  const historyBack = useCallback(() => dispatch(EditorActions.historyBack()), [dispatch]);

  const removeHistory = useCallback(
    (name) => dispatch(EditorActions.removeHistory(name)),
    [dispatch]
  );

  const updateHistory = useCallback(
    (name, data) => dispatch(EditorActions.updateHistory(name, data)),
    [dispatch]
  );

  return {
    history,
    lastInHistory,
    historyBack,
    removeHistory,
    addToHistory,
    updateHistory,
  };
};
