const colors = [
  '#FFFFFF',
  '#f7b500',
  '#fa6400',
  '#6dd400',
  '#0091ff',
  '#e02020',
  '#262323',
  '#b620e0',

  '#ff4ddd',
  '#FEBDBD',
  '#FFE3D4',
  '#FFDFAF',
  '#FFBF7D',
  '#D6905B',
  '#BA6C31',
  '#AD00E9',
];
export default colors;
