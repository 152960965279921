import { createAction } from 'redux-act';

export const initialState = {
  initialBound: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  bound: {
    x: 100,
    y: 100,
    width: 100,
    height: 100,
    scale: 1,
  },
  isInEditMode: false,
  rotationIndex: 0,
};

export const actions = {
  setBound: createAction('Editor: set bound', (x, y, width, height, scale) => ({
    x,
    y,
    width,
    height,
    scale,
  })),
  setEditMode: createAction('Editor: set edit mode', (isInEditMode) => ({
    isInEditMode,
  })),
  setInitialBound: createAction('Editor: set initial bound', (x, y, width, height) => ({
    x,
    y,
    width,
    height,
  })),
  setRotationIndex: createAction('Editor: set rotation index', (rotationIndex) => ({
    rotationIndex,
  })),
};

export const reducers = {
  [actions.setBound]: (state, { x, y, width, height, scale }) =>
    state.setIn(['bound'], { x, y, width, height, scale }),
  [actions.setInitialBound]: (state, { x, y, width, height, scale }) => {
    state = state.setIn(['initialBound'], { x, y, width, height, scale });
    return state.setIn(['bound'], { x, y, width, height, scale: 1 });
  },
  [actions.setEditMode]: (state, { isInEditMode }) => state.setIn(['isInEditMode'], isInEditMode),
  [actions.setRotationIndex]: (state, { rotationIndex }) =>
    state.setIn(['rotationIndex'], rotationIndex),
};

export const selectors = {
  bound: (state) => state.editor.bound,
  initialBound: (state) => state.editor.initialBound,
  isInEditMode: (state) => state.editor.isInEditMode,
  rotationIndex: (state) => state.editor.rotationIndex,
};
