import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { IonButton, useIonViewWillLeave } from '@ionic/react';
import { useNavigate } from 'react-router';
import { useFilter } from '../../../../redux/filters/hooks';
import { useLocalize } from '../../../../redux/translation/localize';

import Icon from '../../../../atoms/Icon/Icon';
import FilterCard from '../../../../components/FilterCard/FilterCard';

import './FiltresOuverture.scss';
import room from '../../../../utils/room.interface';
import wall from '../../../../utils/wall.interface';
import sector from '../../../../utils/sector.interface';
import IconTypes from '../../../../types/IconTypes';
import { color } from '../../../../utils/filter.interface';
import DemoPopup from '../../../../components/DemoPopup/DemoPopup';
import ROUTES from '../../../../routes/constants';
import ColorFilter from './ColorFilter';

const MAXTAG = 3;

interface Props {
  room: room;
  wall: wall;
  sector: sector;
  background: string;
  initialTags?: number[];
  initialIntensity?: number;
  initialColor?: number;
  boulder?: any;
  dismiss: Function;
}

const FiltresOuverture: React.FC<Props> = ({
  room,
  wall,
  sector,
  background,
  initialColor,
  initialIntensity,
  initialTags,
  boulder,
  dismiss,
}) => {
  // Refs
  const pageRef = useRef<HTMLIonContentElement>(null);

  // Hooks
  const t = useLocalize();
  const navigate = useNavigate();
  const { tags, colors, intensities, getFilters } = useFilter();

  // States
  const [demoText, setDemoText] = useState<string>();
  const [demoMedia, setDemoMedia] = useState<string>();
  const [showDemo, setShowDemo] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<number[]>(initialTags ? initialTags : []);
  const [selectedColor, setSelectedColor] = useState<number>(initialColor ? initialColor : 0);
  const [selectedIntensities, setSelectedIntensities] = useState<number[]>(
    initialIntensity ? [initialIntensity] : []
  );

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useIonViewWillLeave(() => {
    pageRef.current?.scrollToTop(0);
  });

  const addTag = useCallback(
    (id: number) => {
      if (selectedTags.includes(id)) {
        const temp = [...selectedTags];
        setSelectedTags(temp.filter((el) => el !== id));
      } else {
        if (selectedTags.length < MAXTAG) {
          setSelectedTags((prevState: number[]) => [...prevState, id]);
        }
      }
    },
    [setSelectedTags, selectedTags]
  );

  const addIntensity = useCallback(
    (id: number) => {
      if (selectedIntensities.length) {
        setSelectedIntensities([]);
      }
      setSelectedIntensities((prevState: number[]) => [...prevState, id]);
    },
    [setSelectedIntensities, selectedIntensities]
  );

  const color = useMemo(() => {
    const tab = colors?.filter((col: color) => col.id === selectedColor);
    if (tab && tab[0]) return tab[0];
    return undefined;
  }, [colors, selectedColor]);

  const handleNext = useCallback(() => {
    if (color) {
      navigate(ROUTES.OPENING_BLOC, {
        state: {
          background,
          room,
          wall,
          sector,
          boulderColor: color,
          intensity: selectedIntensities[0],
          tags: selectedTags,
          boulder,
          demoMedia,
          demoText,
        },
      });
    }
  }, [
    background,
    boulder,
    color,
    demoMedia,
    demoText,
    navigate,
    room,
    sector,
    selectedIntensities,
    selectedTags,
    wall,
  ]);

  const handleDemo = useCallback(() => {
    setShowDemo(true);
  }, []);

  const handleBack = useCallback(() => {
    dismiss(false);
  }, [dismiss]);

  return (
    <div className='filtreOuverture'>
      <Icon icon={IconTypes.BACK_ARROW_FULL} onClick={handleBack} className='backButton' />
      <div className='filterTypes'>
        {showDemo && (
          <DemoPopup
            display={setShowDemo}
            setMedia={setDemoMedia}
            setText={setDemoText}
            top
            media={demoMedia || boulder?.mediaHelp}
            text={demoText || boulder?.textHelp}
          />
        )}
        <div className='individualType'>
          <div className='filterTitle'>{t('sector')}</div>
          <div className='sectorNames'>
            {room?.name}, {wall?.name}, {sector?.name}
          </div>
        </div>
        <div className='individualType'>
          <div className='filterTitle'>{t('difficulty')}</div>
          <div className='filtreColors'>
            {colors.map((col: color) => (
              <ColorFilter
                key={col.id}
                colorId={col.id}
                name={col.name}
                sectorId={sector?.id}
                boulderId={boulder?.id}
                selectedColor={selectedColor}
                onClick={setSelectedColor}
              />
            ))}
          </div>
        </div>
        <FilterCard
          filterName='Intensité'
          filterTab={intensities}
          filterChange={addIntensity}
          selectedFilter={selectedIntensities}
        />
        <FilterCard
          filterName='Tags'
          filterTab={tags}
          filterChange={addTag}
          selectedFilter={selectedTags}
        />
        <div className='individualType'>
          <div className='filterTitle'>{t('tutorial')}</div>
          <IonButton className='addButton' onClick={handleDemo}>
            {t('add')}
          </IonButton>
        </div>
      </div>
      <IonButton
        expand='block'
        className='nextButton'
        disabled={color && selectedTags.length && selectedIntensities.length ? false : true}
        onClick={handleNext}
      >
        {t('next')}
      </IonButton>
    </div>
  );
};

export default FiltresOuverture;
