import { createAction } from 'redux-act';

export const initialState = {
  text: '',
  textAlignIndex: 0,
  textColorIndex: 0,
  textStyleIndex: 0,
  textFontIndex: 0,
};

export const actions = {
  setText: createAction('set text', (text) => ({ text })),
  setTextAlignIndex: createAction('set alignIndex', (alignIndex) => ({
    alignIndex,
  })),
  setTextColorIndex: createAction('set color index', (colorIndex) => ({
    colorIndex,
  })),
  setTextStyleIndex: createAction('set style index', (styleIndex) => ({
    styleIndex,
  })),
  setTextFontIndex: createAction('set font index', (fontIndex) => ({
    fontIndex,
  })),
};

export const reducers = {
  [actions.setText]: (state, { text }) => state.setIn(['text'], text),
  [actions.setTextAlignIndex]: (state, { alignIndex }) =>
    state.setIn(['textAlignIndex'], alignIndex),
  [actions.setTextColorIndex]: (state, { colorIndex }) =>
    state.setIn(['textColorIndex'], colorIndex),
  [actions.setTextStyleIndex]: (state, { styleIndex }) =>
    state.setIn(['textStyleIndex'], styleIndex),
  [actions.setTextFontIndex]: (state, { fontIndex }) => state.setIn(['textFontIndex'], fontIndex),
};

export const selectors = {
  text: (state) => state.editor.text,
  textAlignIndex: (state) => state.editor.textAlignIndex,
  textColorIndex: (state) => state.editor.textColorIndex,
  textStyleIndex: (state) => state.editor.textStyleIndex,
  textFontIndex: (state) => state.editor.textFontIndex,
};
