import React, { useCallback, useEffect, useMemo } from 'react';
import Swiper from 'swiper';
import 'swiper/scss';

import './ColorSwiper.scss';

// Method
const groupBy = (count) => (result, color, index) => {
  let last = result[result.length - 1];
  if (last.length >= count) {
    last = [];
    result.push(last);
  }
  last.push({ index: index, color: color });
  return result;
};

// Item Render
interface ColorItemProps {
  item: { index: number; color: string };
  isSelected: boolean;
  onChange: (index: number) => void;
}

const ColorItem: React.FC<ColorItemProps> = ({ isSelected, item, onChange }) => {
  // Memos
  const className = useMemo(
    () => (isSelected ? 'color-item-selected' : 'color-item'),
    [isSelected]
  );
  const style = useMemo(() => ({ backgroundColor: item.color }), [item.color]);

  // Handler
  const handleChange = useCallback(() => onChange(item.index), [item.index, onChange]);

  return <div key={item.index} className={className} onClick={handleChange} style={style} />;
};

// Swiper Render
interface ColorSwiperProps {
  colors: string[];
  selectedColor: number;
  onChange: (index: number) => void;

  colorsByPage?: number;
}

const ColorSwiper: React.FC<ColorSwiperProps> = ({
  colors,
  selectedColor,
  onChange,
  colorsByPage = 4,
}) => {
  // Refs
  useEffect(() => {
    new Swiper('.editorColorSwiperContainer', {
      pagination: {
        el: '.swiper-pagination',
      },
    });
  }, []);

  // Memos
  const colorsToShow = useMemo(
    () => colors.reduce(groupBy(colorsByPage + 1), [[]]),
    [colors, colorsByPage]
  );

  return (
    <div className='color-swiper'>
      <div className='editorColorSwiperContainer swiper-container'>
        <div className='swiper-wrapper'>
          {colorsToShow.map((group, index) => (
            <div key={index} className='swiper-slide'>
              {group.map((item) => (
                <ColorItem
                  key={item.index}
                  isSelected={item.index === selectedColor}
                  item={item}
                  onChange={onChange}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className='pagination-container'>
        <div className='swiper-pagination'></div>
      </div>
    </div>
  );
};

export default ColorSwiper;
