import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useToken } from '../redux/auth/hooks';
import { gql } from '@apollo/client';
import { client } from '../App';

// take token as argument
const SUBSCRIPTION_QR_CODE_QUERY = gql`
  subscription User($token: String!) {
    flashQrCode(token: $token) {
      roomId
      mindflurryRoomId
    }
  }
`;

interface IQRCodeFlashProvider {
  roomId?: number;
  mindflurryRoomId?: number;
}

const QRCodeFlashContext = createContext<IQRCodeFlashProvider | null>(null);

export const useQRCodeFlash = () => {
  const context = useContext(QRCodeFlashContext);
  if (!context) {
    throw new Error('useQRCodeFlash must be used within a QRCodeFlashProvider');
  }
  return context;
};

export const QRCodeFlashProvider = ({ children }: { children: ReactNode }) => {
  const { token } = useToken();
  const [roomId, setRoomId] = useState<number | undefined>(undefined);
  const [mindflurryRoomId, setMindflurryRoomId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!token) return;
    const subscriptionObservable = client.subscribe({
      query: SUBSCRIPTION_QR_CODE_QUERY,
      variables: {
        token,
      },
    });
    const subscription = subscriptionObservable.subscribe({
      next: (data) => {
        setRoomId(data.data.flashQrCode.roomId);
        setMindflurryRoomId(data.data.flashQrCode.mindflurryRoomId);
      },
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, [token]);

  return (
    <QRCodeFlashContext.Provider value={{ roomId: roomId, mindflurryRoomId }}>
      {children}
    </QRCodeFlashContext.Provider>
  );
};
