import { gql } from '@apollo/client';

export const GetUserAudits = gql`
  query GetUserAudits($boulderIds: [Int!], $date: DateTime, $typeIds: [Int!]) {
    getUserAudits(typeIds: $typeIds, date: $date, boulderIds: $boulderIds) {
      boulderId
      typeId
      value
      updateDate
    }
  }
`;
