import { createAction, createReducer } from 'redux-act';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  tags: [],
});

export const actions = {
  requestTags: createAction('request tags'),
  updateTags: createAction('update tags', (tags) => ({ tags })),
};

export const reducer = createReducer(
  {
    [actions.updateTags]: (state, { tags }) => state.setIn(['tags'], tags),
  },
  initialState
);

export const selectors = {
  tags: (state) => state.tags,
};
