import React, { useCallback, useEffect, useRef, useState } from 'react';
import Hammer from 'hammerjs';
import * as PIXI from 'pixi.js-legacy';

import Slider from '@appigram/react-rangeslider';
import '@appigram/react-rangeslider/lib/index.css';

// Hooks
import {
  usePencil,
  useCropEditor,
  useEditorTools,
  usePencilHistory,
} from '../../../../../../redux/editor/hooks';

// Components
import HistoryItem from './partial/HistoryItem';
import IconButton from '../../../IconButton/IconButton';
import ColorSwiper from '../../../ColorSwiper/ColorSwiper';

// Utils
import config from '../../../../config';
import PixiApp from '../../../../utils/PixiApp';
import * as utils from '../../../../utils/utils';
import icons from '../../../../../../types/IconTypes';
import Icon from '../../../../../../atoms/Icon/Icon';
import { radioButtonOff, removeOutline } from 'ionicons/icons';
import isPointInsideCircle from '../../../../utils/isPointInsideCircle';

const MAX_WIDTH = 30;
const MIN_WIDTH = 3;

interface DrawCircleToolbarProps {
  additionalColors?: string[];
  onClose: () => void;
  onSave: () => void;
}

const DrawCircleToolbar: React.FC<DrawCircleToolbarProps> = ({
  additionalColors = [],
  onClose,
  onSave,
}) => {
  const { cropBound } = useCropEditor();
  const { clearPrimaryTool } = useEditorTools();
  const { pencilWidth, pencilColorIndex, setPencilColorIndex, setPencilWidth } = usePencil();
  const { history, addToHistory, historyBack, removeHistory, updateHistory, lastInHistory } =
    usePencilHistory();

  // Refs
  const mc = useRef<any>(null);
  const spriteId = useRef<any>(null);
  const mounted = useRef<boolean>(true);
  const offset = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  // Drawing refs
  const tempCanvas = useRef<any>(null);
  const tempContext = useRef<any>(null);
  const renderTexture = useRef<any>(null);
  const availableColors = useRef<string[]>(config.colors);

  // Refs for Handlers in Listeners
  const pLineWidth = useRef<number>(8);
  const pHistory = useRef<any>(history);
  const pCropBound = useRef<any>(cropBound);
  const pWidth = useRef<number>(pencilWidth);
  const pColorIndex = useRef<number>(pencilColorIndex);
  const pSelected = useRef<any>(pHistory.current[pHistory.current.length - 1]);

  const [pencilLineWidth, setLineWidth] = useState(8);
  const [selectedItem, setSelectedItem] = useState<any | null>(
    pHistory.current[pHistory.current.length - 1]
  );

  useEffect(() => {
    if (!pHistory.current?.length && !!history.length) {
      setTimeout(() => {
        handleChangeWidth(pencilWidth);
        handleChangeLineWidth(pencilLineWidth);
      }, 1000);
    }

    pHistory.current = history?.asMutable ? history.asMutable() : history;
    pWidth.current = pencilWidth;
    pCropBound.current = cropBound;
    pSelected.current = selectedItem;
    pLineWidth.current = pencilLineWidth;
    pColorIndex.current = pencilColorIndex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropBound, history, selectedItem, pencilColorIndex, pencilLineWidth, pencilWidth]);

  useEffect(() => {
    availableColors.current = [...additionalColors, ...config.colors];
  }, [additionalColors]);

  // Handler
  const handleChanges = useCallback(
    (item: any) => {
      setSelectedItem(item);
      setLineWidth(item.lineWidth);
      setPencilWidth(item.data.radius / 5 / PIXI.settings.RESOLUTION);
      setPencilColorIndex(item.data.colorIndex);
    },
    [setPencilColorIndex, setPencilWidth]
  );

  // Draw Handlers
  const handleDrawStart = useCallback(
    (evt) => {
      if (!PixiApp.cropContainer || !PixiApp.zoomContainer || !PixiApp.drawContainer) return;

      const lineWidth = pLineWidth.current;
      const radius = pWidth.current * 5 * PIXI.settings.RESOLUTION;
      const strokeStyle = availableColors.current[pColorIndex.current];
      const [px, py] = utils.screenToCrop(pCropBound.current, evt.center.x, evt.center.y);

      const item = pHistory.current.find((hItem) =>
        isPointInsideCircle(
          px - pCropBound.current.x,
          py - pCropBound.current.y,
          hItem.data.x,
          hItem.data.y,
          radius
        )
      );

      if (item) {
        handleChanges(item);
        return;
      }

      const historyItem = new HistoryItem(strokeStyle, lineWidth);

      const circleGraphics: any = new PIXI.Graphics();
      circleGraphics.lineStyle(lineWidth, strokeStyle);
      circleGraphics.drawCircle(px - pCropBound.current.x, py - pCropBound.current.y, radius);
      circleGraphics.name = Date.now().toString();

      PixiApp.drawContainer.addChild(circleGraphics);

      historyItem.drawAll(tempContext.current);

      historyItem.data = {
        x: px - pCropBound.current.x,
        y: py - pCropBound.current.y,
        radius,
        name: circleGraphics.name,
        colorIndex: pColorIndex.current,
      };

      addToHistory(historyItem.serialize());
      setSelectedItem(historyItem.serialize());
    },
    [addToHistory, handleChanges]
  );

  // Colors Handler
  const handleChangeColor = useCallback(
    (colorIndex) => {
      setPencilColorIndex(colorIndex);

      if (pSelected.current && PixiApp.drawContainer) {
        const lastCircle: any = PixiApp.drawContainer.getChildByName(pSelected.current.data.name);

        // Update circle size and stroke width
        const newSize = pWidth.current * 5 * PIXI.settings.RESOLUTION;

        if (lastCircle && lastCircle.line) {
          lastCircle.clear?.();
          lastCircle.lineStyle(pLineWidth.current, availableColors.current[colorIndex]);
          lastCircle.drawCircle(pSelected.current.data.x, pSelected.current.data.y, newSize);

          updateHistory(pSelected.current.data.name, {
            strokeStyle: availableColors.current[colorIndex],
            lineWidth: pLineWidth.current,
            data: {
              x: pSelected.current.data.x,
              y: pSelected.current.data.y,
              radius: newSize,
              colorIndex,
              name: pSelected.current.data.name,
            },
          });
        }
        renderTexture.current.baseTexture.update();
      }
    },
    [setPencilColorIndex, updateHistory]
  );

  // Drag and Drop
  const handlePanMove = useCallback(
    (evt: any) => {
      const radius = pWidth.current * 5 * PIXI.settings.RESOLUTION;
      const [px, py] = utils.screenToCrop(pCropBound.current, evt.center.x, evt.center.y);
      const [newX, newY] = [px - pCropBound.current.x, py - pCropBound.current.y];

      const item =
        pHistory.current.find((hItem) => hItem.data.isDragging) ||
        pHistory.current.find((hItem) =>
          isPointInsideCircle(newX, newY, hItem.data.x, hItem.data.y, radius)
        );

      if (item && PixiApp.drawContainer) {
        const lastCircle: any = PixiApp.drawContainer.getChildByName(item.data.name);

        if (lastCircle && lastCircle.line) {
          lastCircle.clear?.();
          lastCircle.lineStyle(item.lineWidth, item.strokeStyle);
          lastCircle.drawCircle(newX, newY, item.data.radius);
          updateHistory(item.data.name, {
            strokeStyle: item.strokeStyle,
            lineWidth: item.lineWidth,
            data: {
              ...item.data,
              isDragging: true,
              x: newX,
              y: newY,
            },
          });
        }
        renderTexture.current.baseTexture.update();
      }
    },
    [updateHistory]
  );

  const handlePanEnd = useCallback(
    (evt: any) => {
      const radius = pWidth.current * 5 * PIXI.settings.RESOLUTION;
      const [px, py] = utils.screenToCrop(pCropBound.current, evt.center.x, evt.center.y);

      const item =
        pHistory.current.find((hItem) => hItem.data.isDragging) ||
        pHistory.current.find((hItem) =>
          isPointInsideCircle(
            px - pCropBound.current.x,
            py - pCropBound.current.y,
            hItem.data.x,
            hItem.data.y,
            radius
          )
        );

      if (item) {
        const updatedItem = {
          strokeStyle: item.strokeStyle,
          lineWidth: item.lineWidth,
          data: {
            ...item.data,
            isDragging: false,
          },
        };
        updateHistory(item.data.name, updatedItem);
        handleChanges(updatedItem);
      }
    },
    [handleChanges, updateHistory]
  );

  // Size Change
  const handleChangeWidth = useCallback(
    (v: number) => {
      const elem = document.getElementById('RadiusWidth');
      const handle: any = elem?.querySelector('.rangeslider__handle');
      if (handle) {
        const size = v * 0.4 + 30;
        handle.style.width = size + 'px';
        handle.style.height = size + 'px';
        handle.style.borderRadius = size / 2 + 'px';
        handle.style.left = (v - MIN_WIDTH) * -0.2 - 15 + 'px';
      }
      setPencilWidth(v);

      if (pSelected.current && PixiApp.drawContainer) {
        const lastCircle: any = PixiApp.drawContainer.getChildByName(pSelected.current.data.name);

        // Update circle size and stroke width
        const newSize = v * 5 * PIXI.settings.RESOLUTION;

        if (lastCircle && lastCircle.line) {
          lastCircle.clear?.();
          lastCircle.lineStyle(pLineWidth.current, availableColors.current[pColorIndex.current]);
          lastCircle.drawCircle(pSelected.current.data.x, pSelected.current.data.y, newSize);
          updateHistory(pSelected.current.data.name, {
            strokeStyle: availableColors.current[pColorIndex.current],
            lineWidth: pLineWidth.current,
            data: {
              x: pSelected.current.data.x,
              y: pSelected.current.data.y,
              radius: newSize,
              name: pSelected.current.data.name,
              colorIndex: pColorIndex.current,
            },
          });
        }
        renderTexture.current.baseTexture.update();
      }
    },
    [setPencilWidth, updateHistory]
  );

  const handleChangeLineWidth = useCallback(
    (v: number) => {
      const elem = document.getElementById('LineWidth');
      const handle: any = elem?.querySelector('.rangeslider__handle');

      if (handle) {
        const size = v * 0.4 + 30;
        handle.style.width = size + 'px';
        handle.style.height = size + 'px';
        handle.style.borderRadius = size / 2 + 'px';
        handle.style.left = (v - MIN_WIDTH) * -0.2 - 15 + 'px';
      }
      setLineWidth(v);

      if (pSelected.current && PixiApp.drawContainer) {
        const lastCircle: any = PixiApp.drawContainer.getChildByName(pSelected.current.data.name);

        const radius = pWidth.current * 5 * PIXI.settings.RESOLUTION;

        if (lastCircle && lastCircle.line) {
          lastCircle.clear?.();
          lastCircle.lineStyle(v, availableColors.current[pColorIndex.current]);
          lastCircle.drawCircle(pSelected.current.data.x, pSelected.current.data.y, radius);
          updateHistory(pSelected.current.data.name, {
            strokeStyle: availableColors.current[pColorIndex.current],
            lineWidth: v,
            data: {
              x: pSelected.current.data.x,
              y: pSelected.current.data.y,
              radius: radius,
              name: pSelected.current.data.name,
              colorIndex: pColorIndex.current,
            },
          });
        }
        renderTexture.current.baseTexture.update();
      }
    },
    [updateHistory]
  );

  // Validation and Undo
  const handleUndo = useCallback(() => {
    if (history.length === 0) return;
    tempContext.current.clearRect(0, 0, tempCanvas.current.width, tempCanvas.current.height);

    if (PixiApp.drawContainer && PixiApp.drawContainer.children.length > 0) {
      PixiApp.drawContainer.removeChildAt(PixiApp.drawContainer.children.length - 1);
      renderTexture.current.baseTexture.update();
    }
    setSelectedItem(
      pHistory.current.reverse().find((_) => _.data.name !== pSelected.current.data.name)
    );
    historyBack();
  }, [history, historyBack]);

  const handleDeleteSelected = useCallback(() => {
    if (!selectedItem) return;
    if (PixiApp.drawContainer) {
      const selectedCircle: any = PixiApp.drawContainer.getChildByName(selectedItem.data.name);
      PixiApp.drawContainer.removeChild(selectedCircle);
    }
    removeHistory(selectedItem.data.name);
    setSelectedItem(pHistory.current.reverse().find((_) => _.data.name !== selectedItem.data.name));
  }, [removeHistory, selectedItem]);

  useEffect(() => {
    const canvas = document.getElementById('ImageEditorCanvas');
    if (!canvas || !PixiApp.drawContainer || !PixiApp.backgroundContainer) return;

    mc.current = new Hammer.Manager(canvas);
    mc.current.add(new Hammer.Tap());
    mc.current.on('tap', handleDrawStart);
    mc.current.add(new Hammer.Pan({ threshold: 10, pointers: 0 }));
    mc.current.on('panstart', handlePanMove);
    mc.current.on('panmove', handlePanMove);
    mc.current.on('panend', handlePanEnd);
    mc.current.on('pancancel', handlePanEnd);

    offset.current.x = -canvas.getBoundingClientRect().left;
    offset.current.y = -canvas.getBoundingClientRect().top;

    if (PixiApp.drawContainer.children.length === 1) {
      renderTexture.current = PixiApp.drawContainer.children[0]['texture'];
      tempContext.current = PixiApp.drawContainer.children[0]['tempContext'];
      tempCanvas.current = PixiApp.drawContainer.children[0]['tempCanvas'];
    } else {
      tempCanvas.current = document.createElement('canvas');
      tempCanvas.current.width = PixiApp.backgroundContainer.width * PIXI.settings.RESOLUTION; // sourceCanvas.width
      tempCanvas.current.height = PixiApp.backgroundContainer.height * PIXI.settings.RESOLUTION; //sourceCanvas.height
      tempCanvas.current.style.position = 'absolute';
      tempCanvas.current.style.top = 0;
      tempCanvas.current.style.left = 0;
      tempCanvas.current.style.width = window.innerWidth + 'px';
      tempCanvas.current.style.height = window.innerHeight + 'px';

      tempContext.current = tempCanvas.current.getContext('2d');

      renderTexture.current = PIXI.Texture.from(tempCanvas.current, {
        resolution: PIXI.settings.RESOLUTION,
      });
      const sprite: any = new PIXI.Sprite(renderTexture.current);
      sprite.tempContext = tempContext.current;
      sprite.tempCanvas = tempCanvas.current;

      PixiApp.drawContainer.addChild(sprite);
      spriteId.current = PixiApp.registerObject(sprite);
    }

    handleChangeWidth(pencilWidth);
    return () => {
      mounted.current = false;
      mc.current.destroy();
      // Clean up Pixi.js resources
      if (renderTexture.current) {
        renderTexture.current.destroy(true);
        renderTexture.current = null;
      }

      if (tempContext.current) {
        tempContext.current.destroy?.(true);
        tempContext.current = null;
      }

      if (tempCanvas.current) {
        tempCanvas.current.destroy?.(true);
        tempCanvas.current = null;
      }

      if (PixiApp.drawContainer) {
        PixiApp.drawContainer.removeChildren(); // Remove all children from the draw container
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id='DrawCircleToolbar'>
      <div className='top-toolbar'>
        <div className='right-side'>
          <IconButton icon={icons.CLOSE_CIRCLE} onClick={onClose} />
        </div>

        <div className='center'>
          <IconButton
            isSelected
            icon={icons.PENCIL}
            iconSelected={icons.PENCIL_WHITE}
            onClick={clearPrimaryTool}
          />
        </div>

        <div className='left-side'>
          <IconButton icon={icons.VALIDATE} onClick={onSave} />
        </div>
      </div>
      {!!lastInHistory && (
        <div className='undo-draw'>
          <IconButton icon={icons.UNDO} onClick={handleUndo} />
        </div>
      )}
      {!!selectedItem && (
        <div className='delete-draw'>
          <IconButton icon={icons.TRASH} onClick={handleDeleteSelected} />
        </div>
      )}

      {history.length && (
        <div id='LineWidth' className='lineWidth-stroke-width-container'>
          <div className='stroke-width-wrapper'>
            <div className='stroke-width-background'>
              <Icon icon={icons.STROKE_WIDTH} />
            </div>
            <div className='slider-container'>
              <Slider
                value={pencilLineWidth}
                orientation='vertical'
                onChange={handleChangeLineWidth}
                min={MIN_WIDTH}
                max={MAX_WIDTH}
                tooltip={false}
              />
            </div>
          </div>
          <Icon icon={removeOutline} />
        </div>
      )}

      {history.length && (
        <div id='RadiusWidth' className='stroke-width-container'>
          <div className='stroke-width-wrapper'>
            <div className='stroke-width-background'>
              <Icon icon={icons.STROKE_WIDTH} />
            </div>
            <div className='slider-container'>
              <Slider
                value={pencilWidth}
                orientation='vertical'
                onChange={handleChangeWidth}
                min={MIN_WIDTH}
                max={MAX_WIDTH}
                tooltip={false}
              />
            </div>
          </div>
          <Icon icon={radioButtonOff} />
        </div>
      )}

      {history.length && (
        <div className='bottom-toolbar'>
          <ColorSwiper
            onChange={handleChangeColor}
            colors={availableColors.current}
            colorsByPage={7}
            selectedColor={pencilColorIndex}
          />
        </div>
      )}
    </div>
  );
};

export default DrawCircleToolbar;
