import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';

export const getAndroidPermissions = async (list: string[]) => {
  const needAccess: string[] = [];
  for (let i = 0; i < list.length; i++) {
    const result = await AndroidPermissions.checkPermission(list[i]);
    if (!result.hasPermission) needAccess.push(list[i]);
  }

  if (needAccess.length) {
    const access = await AndroidPermissions.requestPermissions(needAccess);
    return access.hasPermission;
  }
  return true;
};
