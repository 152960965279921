import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

import * as reducers from './reducers';

const initialState = Immutable({
  boulderIds: {
    /*
     ** [boulderId]: { [BoulderVideoType]: [ ...videoIds ] }
     */
  },
  videosByIds: {
    /*
     ** [videoId]: { ...video }
     */
  },

  // Settings
  isFetching: false,
  isFullLoaded: false,
});

export const actions = {
  requestBoulderVideo: createAction('requestBoulderVideo', (id) => ({ id })),
  requestBoulderVideos: createAction('requestBoulderVideos', (boulderId, filters) => ({
    boulderId,
    filters,
  })),

  createBoulderVideo: createAction(
    'createBoulderVideo',
    (boulderId, videoBlob, params, callback) => ({
      boulderId,
      videoBlob,
      params,
      callback,
    })
  ),
  requestDeleteVideo: createAction('deleteBoulderVideo', (boulderId, videoId, type) => ({
    boulderId,
    videoId,
    type,
  })),

  addVideo: createAction('addBoulderVideo', (video) => ({ video })),
  addVideos: createAction('addBoulderVideos', (videos, reset) => ({ videos, clean: reset })),

  cleanVideos: createAction('cleanVideos', (boulderId) => ({ boulderId })),
  removeVideo: createAction('removeVideo', (videoId, boulderId, type) => ({
    videoId,
    boulderId,
    type,
  })),

  setProgress: createAction('setProgress', (value) => ({ value })),
  setFetching: createAction('setFetching', (value) => ({ value })),
  setFullLoaded: createAction('setFullLoaded', (value) => ({ value })),
};

export const reducer = createReducer(
  {
    [actions.addVideo]: reducers.addVideo,
    [actions.addVideos]: reducers.addVideos,

    [actions.cleanVideos]: reducers.cleanVideos,
    [actions.removeVideo]: reducers.removeVideo,

    [actions.setProgress]: (state, { value }) => state.setIn(['uploadProgress'], value),
    [actions.setFetching]: (state, { value }) => state.setIn(['isFetching'], value),
    [actions.setFullLoaded]: (state, { value }) => state.setIn(['isFullLoaded'], value),
  },
  initialState
);

const emptyArray = [];

export const selectors = {
  videoIds: (state, boulderId, type) =>
    state.boulderVideo.boulderIds[boulderId]?.[type] || emptyArray,
  videoById: (state, id) => state.boulderVideo.videosByIds[id],

  isFetching: (state) => state.boulderVideo.isFetching,
  isFullLoaded: (state) => state.boulderVideo.isFullLoaded,
};
