import React, { useCallback, useEffect, useMemo } from 'react';

// Hooks
import { useNavigate } from 'react-router';
import { useLogged } from '../../redux/auth/hooks';
import { useColors } from '../../redux/filters/hooks';
import { useCurrentUser, useLiberfitShop, useTickets } from '../../redux/user/hooks';
import { useMyRank } from '../../redux/leaderBoard/hooks';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { SHOP_PAGES, useWebSiteShop } from '../../utils/shop.utils';
import { useQrcode, useUserColor } from '../../redux/user/hooks';

// Components
import Icon from '../../atoms/Icon/Icon';
import Header from '../../routes/components/Header/Header';
import { IonButton, IonPage, IonSpinner } from '@ionic/react';
import AccessList from '../../components/AccessList/AccessList';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';

// Styles & Utils
import './MaCarte.scss';
import IconTypes from '../../types/IconTypes';
import getBoulderColor from '../../utils/getBoulderColor';
import QrCodePreview from '../../components/QrCodePreview/QrCodePreview';
import { trackEvent } from '../../providers/tracker';

const DEFAULT_QRCODE = 'https://youtu.be/dQw4w9WgXcQ';

const MaCarte: React.FC<unknown> = () => {
  const t = useLocalize();
  const { logged } = useLogged();
  const navigate = useNavigate();
  const { room } = useSelectedRoom();
  const { userTickets } = useTickets();
  const { userColor } = useMyRank(room?.id);
  const { getColors, colors } = useColors();
  const { currentUser, requestCurrentUser } = useCurrentUser();
  const { requestUserGlobalColor, userGlobalColor } = useUserColor();
  const { getIframeToken, liberfitToken } = useLiberfitShop('ma_carte');
  const { requestQrcodes, isFetchingQrCode, qrCode, resetSelectedQrcode } = useQrcode();

  const name = useMemo(() => {
    if (!currentUser) return '';
    if (!currentUser?.mindFlurryUser?.firstName && !currentUser?.mindFlurryUser?.lastName)
      return '';
    if (!currentUser?.mindFlurryUser?.firstName) return currentUser?.mindFlurryUser?.lastName;
    if (!currentUser?.mindFlurryUser?.lastName) return currentUser?.mindFlurryUser?.firstName;
    return `${currentUser?.mindFlurryUser?.firstName} ${currentUser?.mindFlurryUser?.lastName}`;
  }, [currentUser]);

  const containerStyle = useMemo(
    () => ({
      backgroundColor: logged ? '#f2f4f8' : 'white',
    }),
    [logged]
  );

  const { color } = useMemo(
    () => getBoulderColor(colors.find((col) => col.id === userGlobalColor)?.name),
    [colors, userGlobalColor]
  );

  useEffect(() => {
    getColors();
    getIframeToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logged) {
      if (!currentUser) requestCurrentUser();
      requestUserGlobalColor();
      requestQrcodes();
      resetSelectedQrcode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged]);

  const handleConnect = useCallback(() => {
    trackEvent('click_ma_carte_button', {
      event_category: 'ma_carte',
      event_action: 'login',
    });
    localStorage.removeItem('isOnboarded');
    localStorage.removeItem('skippedOnboarding');
    window.location.href = '/';
  }, []);

  const handleBackButton = useCallback(() => {
    trackEvent('back', { path: 'my_card' });
    navigate('/home');
  }, [navigate]);

  const handleProfil = useCallback(() => {
    trackEvent('click_ma_carte_button', {
      event_category: 'ma_carte',
      event_action: 'profil',
    });
    navigate(logged ? 'profil' : 'login');
  }, [navigate, logged]);

  const handlePlanning = useWebSiteShop(SHOP_PAGES.BOOK_A_TRIAL, liberfitToken);
  const handleBooking = useWebSiteShop(SHOP_PAGES.ORDER_A_CALENDAR_LOL, liberfitToken);
  const handleShop = useWebSiteShop(SHOP_PAGES.MOBILE_SHOP, liberfitToken);

  const leftBorderStyle = useMemo(() => ({ borderLeftColor: color }), [color]);
  const borderStyle = useMemo(() => ({ borderColor: color }), [color]);
  const backgroundStyle = useMemo(
    () => ({ backgroundColor: color, color: !color?.length ? '#000' : '#FFF' }),
    [color]
  );

  return (
    <IonPage className='maCartePage'>
      <Header
        isCoach={false}
        showProfile
        showRoom={false}
        showMonth={false}
        showWalls={false}
        showSectors={false}
        isProfile
      />
      <div className='profil_back_button' onClick={handleBackButton}>
        <Icon className='back_button' icon={IconTypes.LEFT_OPEN} />
      </div>
      {
        <div className='maCarteLogedContent'>
          <div className='body'>
            <div className='bodyContainer' style={containerStyle}>
              <div className='idContainer' style={borderStyle}>
                <div className='pictureAndQrc'>
                  <div className='picture'>
                    {logged && (
                      <ProfilePicture
                        logged={true}
                        border='none'
                        user={currentUser}
                        classProps='largeProfile'
                        crowned={false}
                        link={false}
                        userColor={userColor}
                      />
                    )}
                    {!logged && <Icon className='iconProfil' icon={IconTypes.ICON_PROFILE} />}
                  </div>
                  <div className='qrcContainer' style={leftBorderStyle}>
                    {!!isFetchingQrCode && (
                      <div className='spinnerLoader'>
                        <IonSpinner name='crescent' />
                      </div>
                    )}
                    <QrCodePreview
                      qrCode={qrCode || DEFAULT_QRCODE}
                      enabled={logged && !!currentUser && !!qrCode}
                      onClick={requestQrcodes}
                    />
                  </div>
                </div>
                <div className='maCarteName' style={backgroundStyle}>
                  {logged ? name : 'Hello !'}
                </div>
              </div>
              {logged && (
                <div className='tiketContainer'>
                  <AccessList userTickets={userTickets} />
                </div>
              )}
              {!logged && (
                <div className='hintToLog'>
                  <div className='maCarteLine'>{t('maCarteNoProfil1')}</div>
                  <div className='maCarteLine'>{t('maCarteNoProfil2')}</div>
                  <div className='maCarteButtons notConnected'>
                    <IonButton className='maCarteButton connect-button' onClick={handleProfil}>
                      {t('maCarteButtonConnect')}
                    </IonButton>
                    <IonButton className='maCarteButton' onClick={handleConnect}>
                      {t('create_account')}
                    </IonButton>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='maCarteFooter'>
            <div className='shopButton' onClick={handleShop}>
              <Icon className='shopIcon' icon={IconTypes.SHOP} />
              <div className='shopButtonName'>{t('footer_location')}</div>
            </div>
            <div className='classesButtons'>
              <div className='classeButtonsHint'>{t('classes')}:</div>
              <div className='classeButtonsContainer'>
                <div className='classeButtonSearch' onClick={handleBooking}>
                  <Icon className='shopIcon' icon={IconTypes.SEARCH} />
                  <div className='classSearchHint'>{t('search')}</div>
                </div>
                <div className='classeButtonBookings' onClick={handlePlanning}>
                  <Icon className='shopIcon' icon={IconTypes.CALENDAR} />
                  <div className='classSearchHint'>{t('planned')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </IonPage>
  );
};

export default MaCarte;
