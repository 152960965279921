import { useCallback } from 'react';
import './Toggle.scss';

interface ToggleProps {
  value: boolean;
  onChange: (key?: string) => void;
  label?: string;
  valueKey?: string;
}

const Toggle: React.FC<ToggleProps> = ({ value, onChange, label, valueKey }) => {
  const handleChange = useCallback(() => onChange(valueKey), [onChange, valueKey]);

  return (
    <div className='Toggle'>
      <label className='switch'>
        <input type='checkbox' checked={value} onChange={handleChange} />
        <span className='slider round' />
      </label>
      {!!label && <span>{label}</span>}
    </div>
  );
};

export default Toggle;
