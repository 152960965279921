import { close, shareSocial } from 'ionicons/icons';
import { isIOS } from 'react-device-detect';
import getVideoCover from '../../utils/getVideoCover';
import { IonIcon, IonModal, IonSpinner } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Format, Resolution } from '../../utils/media-store';

import './OptimizedVideo.scss';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import { useLocalize } from '../../redux/translation/localize';
import getVideoStatus from './getVideoStatus';
import shareToApp from '../../utils/shareToApp';

export type OptimizedVideoProps = {
  id?: string;
  src: string;
  date?: string;
  format?: Format;
  jobName?: string;
  resolution?: Resolution;
  className?: string;
  thumbnail?: string;
  allowShare?: boolean;
};

const CACHE_THUMB = {};
const ONE_HOUR = 3600000;

const OptimizedVideo = ({
  src,
  thumbnail,
  jobName,
  date,
  allowShare,
  ...rest
}: OptimizedVideoProps) => {
  const t = useLocalize();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoWasPlaying = useRef<boolean>(false);

  const [fullscreen, setFullscreen] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isLoading, setIsLoading] = useState(!!jobName);
  const [thumbUrl, setThumb] = useState<undefined | string>(thumbnail || CACHE_THUMB[src]);

  const stopLoader = useCallback(() => setIsLoading(false), []);

  const handleClick = useCallback(() => setFullscreen(true), []);
  const handleClose = useCallback(() => setFullscreen(false), []);

  const handleShare = useCallback(() => {
    setIsLoading(true);
    shareToApp({}, src, undefined, stopLoader, stopLoader);
  }, [src, stopLoader]);

  useEffect(() => {
    if (isAvailable && videoRef.current) {
      if (isLoading && !videoRef.current.paused) {
        videoRef.current.pause();
        videoWasPlaying.current = true;
      }
      if (!isLoading && videoRef.current.paused) {
        if (videoWasPlaying.current) {
          videoRef.current.play();
        }
        videoWasPlaying.current = false;
      }
    }
  }, [isAvailable, isLoading]);

  useEffect(() => {
    if (!!CACHE_THUMB[src]) return;
    if (thumbnail) {
      CACHE_THUMB[src] = thumbnail;
      return;
    }
    (async () => {
      try {
        const cover: any = await getVideoCover(src, 1);
        if (cover) {
          setThumb(cover);
        }
      } catch (ex) {
        console.error('ERROR: ', ex);
      }
    })();
  }, [src, thumbnail]);

  useEffect(() => {
    const isValid = date && Number(new Date(date)) + ONE_HOUR < Number(Date.now());
    const handleCallback = (val: boolean) => {
      if (val && timerRef.current) clearInterval(timerRef.current);
      setIsAvailable(val);
    };

    if (jobName && !isValid) {
      if (timerRef.current) clearInterval(timerRef.current);
      timerRef.current = setInterval(() => {
        getVideoStatus(jobName, handleCallback, setIsLoading);
      }, 2000);
    } else {
      handleCallback(true);
      setIsLoading(false);
    }
  }, [date, jobName]);

  return (
    <>
      <IonModal
        isOpen={fullscreen}
        className={`OptimizedVideo ${isIOS ? 'ios' : ''}`}
        onWillDismiss={handleClose}
      >
        {isAvailable && (
          <>
            <IonIcon icon={close} className='close' onClick={handleClose} />
            <video
              src={src}
              autoPlay
              controls
              playsInline
              ref={videoRef}
              crossOrigin='anonymous'
              controlsList='nofullscreen'
            />
            {!!allowShare && <IonIcon icon={shareSocial} onClick={handleShare} className='share' />}
          </>
        )}
        {isLoading && (
          <div id='OptimizedVideoModalLoader'>
            <IonSpinner />
          </div>
        )}
      </IonModal>
      {!!thumbUrl && !!isAvailable && !isLoading && (
        <OptimizedImage
          {...rest}
          onClick={handleClick}
          src={thumbUrl}
          format='thumb'
          alt='Video Thumbnail'
          crossOrigin='anonymous'
        />
      )}
      {!thumbUrl && isAvailable && (
        <div className='OptimizedVideoLoader'>
          <IonSpinner />
        </div>
      )}
      {(!isAvailable || isLoading) && (
        <div
          className='OptimizedVideoLoader compress'
          style={
            thumbUrl
              ? {
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${thumbUrl}")`,
                }
              : undefined
          }
        >
          <IonSpinner />
          {!isAvailable && <span>{t('new_video_coming')}</span>}
        </div>
      )}
    </>
  );
};

export default OptimizedVideo;
