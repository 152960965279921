import React, { useMemo } from 'react';

import { useLocalize } from '../../../../redux/translation/localize';
import Icon from '../../../../atoms/Icon/Icon';

import './BoulderRankingCard.scss';
import IconTypes from '../../../../types/IconTypes';
import { getDayDifference } from '../../../../utils/dateTime';
import getBoulderColor from '../../../../utils/getBoulderColor';
import { useSelector } from 'react-redux';

interface Props {
  item: {
    boulderId: number;
    color: string;
    difficulty: string;
    flash: boolean;
    days: number;
    points: number;
    sector: string;
    [key: string]: any;
  };
  index: number;
  lastRow: boolean;
}

const BoulderRankingCard: React.FC<Props> = ({ item, index, lastRow }) => {
  const t = useLocalize();
  const lang = useSelector((state: any) => state.i18nState.lang);

  const boulderLevels = `${item.boulder.color.name}, ${item.boulder.intensity.name}`;
  const boulderLocation = `${item.room.name}, ${item.boulder.sector.name}`;
  const flashStyle = useMemo(
    () => ({ opacity: item.sessionBoulder.status === 'FLASH' ? '1' : '0' }),
    [item.sessionBoulder.status]
  );

  const borderStyle = useMemo(
    () => ({
      borderBottomColor: lastRow ? 'transparent' : '',
    }),
    [lastRow]
  );

  const { color } = getBoulderColor(item.boulder.color.name);

  const sessionDate = useMemo(() => {
    const date = new Date(item.sessionBoulder.session.startDate);
    const month = date.toLocaleDateString(lang || 'fr', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  }, [item.sessionBoulder.session.startDate, lang]);

  const dayDiff = useMemo(() => {
    const TODAY = new Date();
    TODAY.setHours(23, 59, 59, 999);
    return getDayDifference(new Date(item.sessionBoulder.session.startDate), TODAY);
  }, [item.sessionBoulder.session.startDate]);

  const points = useMemo(() => item?.score?.toFixed(0), [item?.score]);
  const backgroundStyle = useMemo(() => ({ backgroundColor: color }), [color]);

  return (
    <div className='boulderRankingCard' style={borderStyle}>
      <div className='boulderRank'>{index + 1}</div>
      <div className='boulderColor' style={backgroundStyle} />
      <div className='boulderInfos'>
        <div className='boulderLevels'>{boulderLevels}</div>
        <div className='boulderDate'>{sessionDate}</div>
        <div className='boulderLocation'>{boulderLocation}</div>
      </div>
      <div className='flashInfo'>
        <Icon icon={IconTypes.BLOCS_FULL} className='flashIcon' style={flashStyle} />
      </div>
      <div className='daysCount'>
        {!index && <div className='colTitle'>{t('days_count')}</div>}
        <div>{dayDiff || '-'}</div>
      </div>
      <div className='daysCount'>
        {!index && <div className='colTitle'>{t('lesson_points')}</div>}
        <div>{points}</div>
      </div>
    </div>
  );
};

export default BoulderRankingCard;
