import bootLoop from './bootLoop';
import { fork, call, put, select, take } from 'redux-saga/effects';

// redux
import { actions as AppActions } from '../app/redux';
import { actions as RoomsActions } from '../rooms/redux';
import { actions as UserActions } from '../user/redux';
import { selectors as AuthSelectors, actions as AuthActions } from '../auth/redux';
import { actions as TranslationActions } from '../translation/redux';
import ROUTES from '../../routes/constants';
import { initFirebase } from '../../providers/firebase/firebase';

export function* bootStart() {
  yield put(TranslationActions.requestLoadLanguages('fr'));
  yield put(TranslationActions.setLanguage('fr'));

  let savedToken = yield select(AuthSelectors.token);
  if (savedToken === 'null' || savedToken === 'undefined') savedToken = undefined;
  if (savedToken) {
    // Check Token First)
    let token = localStorage.getItem('token');
    if (token === 'null' || token === 'undefined') token = undefined;

    yield put(AuthActions.setToken(token));
    yield put(AuthActions.setRefreshToken(localStorage.getItem('refreshToken')));

    if (!token) {
      window.location.href = ROUTES.LOGIN;
      return;
    }
    // Load Me
    yield put(UserActions.requestCurrentUser());
    yield take(UserActions.addCurrentUser.getType());

    // Load Rooms
    yield put(RoomsActions.requestRooms());
    yield take(RoomsActions.addRooms.getType());
  }

  yield call(initFirebase);
  yield put(AppActions.setAppStarted(true));
}

export function* boot() {
  yield fork(bootLoop);
  yield call(bootStart);
}
