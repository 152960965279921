import React, { ChangeEvent, useCallback, useRef, KeyboardEvent, ClipboardEvent } from 'react';

// Components
import Icon from '../Icon/Icon';

// Style
import './Input.scss';
import { Keyboard } from '@capacitor/keyboard';

interface InputProps {
  placeholder?: string;
  value: string | number | undefined;
  type: 'email' | 'text' | 'password' | 'date' | 'tel';
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>, index?: number, valueKey?: string) => void;
  onFocus?: () => void;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  minLength?: number;
  maxLength?: number;
  rightIcon?: string;
  valueKey?: string;
  onClickIcon?: () => void;
  inputRef?: (el: HTMLInputElement) => HTMLInputElement;
  index?: number;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>, index?: number) => void;
}

const Input: React.FC<InputProps> = React.memo(
  ({
    placeholder = '',
    value,
    valueKey,
    type,
    className = '',
    onChange,
    onFocus = undefined,
    disabled = false,
    error = false,
    errorMessage = '',
    minLength = undefined,
    maxLength = undefined,
    rightIcon = undefined,
    onClickIcon = undefined,
    inputRef,
    index = undefined,
    onPaste,
  }) => {
    const refInput = useRef<HTMLInputElement>(null);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (index !== undefined) onChange(e, index, valueKey);
        else onChange(e, undefined, valueKey);
      },
      [onChange, valueKey, index]
    );

    const handlePaste = useCallback(
      (e: ClipboardEvent<HTMLInputElement>) => {
        if (onPaste) onPaste(e);
      },
      [onPaste]
    );

    const handleKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        Keyboard.hide();
        refInput.current?.blur();
      }
    }, []);

    return (
      <div className='InputContainer'>
        <input
          ref={inputRef || refInput}
          placeholder={placeholder}
          value={value}
          type={type}
          className={`Input ${className}`}
          onChange={handleChange}
          onFocus={onFocus}
          disabled={disabled}
          minLength={minLength}
          maxLength={maxLength}
          onPaste={handlePaste}
          tabIndex={-1}
          onKeyDown={handleKeyDown}
          enterKeyHint='done'
        />
        {!!rightIcon && (
          <Icon
            className='inputRightIcon'
            icon={rightIcon}
            onClick={!!onClickIcon ? onClickIcon : undefined}
          />
        )}
        {error && errorMessage && <p>{errorMessage}</p>}
      </div>
    );
  }
);

export default Input;
