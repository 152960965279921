import * as reducers from './reducers';
import { createSelector } from 'reselect';
import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

const initialState = Immutable({
  news: {},
  newsIds: {},

  isFetching: false,
  isFullLoaded: false,
});

export const actions = {
  requestNews: createAction('request all news', (roomId = null, skip) => ({ roomId, skip })),

  addNews: createAction('add news', (news, roomId, reset) => ({ news, roomId, reset })),

  setFetching: createAction('setFetching', (value) => ({ value })),
  setFullLoaded: createAction('setFullLoaded', (value) => ({ value })),
};

export const reducer = createReducer(
  {
    [actions.addNews]: reducers.addNews,

    [actions.setFetching.toString()]: (state, { value }) => state.setIn(['isFetching'], value),
    [actions.setFullLoaded.toString()]: (state, { value }) => state.setIn(['isFullLoaded'], value),
  },
  initialState
);

export const createNewsRoomSelector = () =>
  createSelector(
    (state, roomId) => state.news.getIn(['newsIds', roomId]),
    (_) => _
  );

export const createNewsSelector = () =>
  createSelector(
    (state, newsId) => state.news.getIn(['news', newsId]),
    (_) => _
  );

export const selectors = {
  newsIds: (state) => state.news.newsIds,
  newsByRoom: (state, roomId) => state.news.newsIds[roomId],
  isFetching: (state) => state.news.isFetching,
  isFullLoaded: (state) => state.news.isFullLoaded,
};
