import React from 'react';

// Hooks
import { IonIcon } from '@ionic/react';

// Style
import IconTypes from '../../types/IconTypes';
import './Icon.scss';

const myIcons = Object.values(IconTypes);

interface IonIconProps {
  id?: string;
  ariaLabel?: string;
  color?: string;
  flipRtl?: boolean;
  icon?: string;
  ios?: string;
  lazy?: boolean;
  md?: string;
  mode?: 'ios' | 'md';
  name?: string;
  size?: string;
  src?: string;
  slot?: 'start' | 'end';
  onClick?: Function;
  className?: string;
  style?: any;
  disabled?: boolean;
  onMouseDown?: any;
  onTouchStart?: any;
  onTouchEnd?: any;
  onTouchMove?: any;
}

// Renderer
const Icon: React.FC<IonIconProps> = React.memo((props: any) => {
  let myProps = { ...props };
  const { icon, disabled } = myProps;

  if (!!disabled) {
    myProps.onClick = undefined;
    myProps.className = (myProps.className || '') + ' disabled';
  }

  if (myIcons.includes(icon)) {
    return <IonIcon {...myProps} src={`/assets/icon/${icon}.svg`} />;
  }

  return <IonIcon {...myProps} />;
});

export const icons = IconTypes;
export default Icon;
