import React from 'react';
import { IonDatetime, IonModal } from '@ionic/react';

import './DatePickerModal.scss';
import { useLocalize } from '../../redux/translation/localize';

interface DatePickerModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  chosenDate: string;
  onChangeDate: (e) => void;
}

const DatePickerModal: React.FC<DatePickerModalProps> = ({
  isOpen,
  onDismiss,
  chosenDate,
  onChangeDate,
}) => {
  const t = useLocalize();

  return (
    <IonModal
      isOpen={isOpen}
      id='DatePickerModal'
      onDidDismiss={onDismiss}
      animated
      keepContentsMounted
    >
      {/**TODO : Fix onClose button to be absolute with new Ionic version 7.1 -- issue on iOs
       * Current behavior if absolute: the button on absolute position doesn't show
       * unless a action is made on the date picker
       */}
      <div className='pickerCloseBtnContainer'>
        <button className='pickerCloseBtn' onClick={onDismiss}>
          {t('OK')}
        </button>
      </div>
      <IonDatetime
        className='datePicker'
        presentation='date'
        preferWheel={true}
        value={chosenDate}
        mode='ios'
        onIonChange={onChangeDate}
      ></IonDatetime>
    </IonModal>
  );
};

export default DatePickerModal;
