import { useNavigate, useParams } from 'react-router';
import { useLogged } from '../../../../../../redux/auth/hooks';
import { useCurrentUser } from '../../../../../../redux/user/hooks';
import { useCallback, useEffect, useRef } from 'react';
import { useLocalize } from '../../../../../../redux/translation/localize';
import { useBoulderVideos } from '../../../../../../redux/boulderVideo/hooks';

import Icon from '../../../../../../atoms/Icon/Icon';
import BlocChallengeVideo from './partials/BlocChallengeVideo';
import { IonButton, IonContent, IonSpinner } from '@ionic/react';

import './BlocChallenge.scss';
import ROUTES from '../../../../../../routes/constants';
import IconTypes from '../../../../../../types/IconTypes';
import Boulder from '../../../../../../utils/boulder.interface';
import { trackEvent } from '../../../../../../providers/tracker';

// enum TYPES {
//   MINE = 0,
//   WORKERS = 'OPENER',
//   MEMBERS = 'USER',
// }

const BlocChallenge: React.FC<{ boulder: Boulder; modalContent: any; onDismiss: () => void }> = ({
  boulder,
  onDismiss,
}) => {
  const didItOnce = useRef(false);
  const blockRef = useRef(false);

  const t = useLocalize();
  const navigate = useNavigate();
  const { logged } = useLogged();
  const { currentUser } = useCurrentUser();
  const { sectorId }: { sectorId?: string } = useParams();
  const { videoIds, getVideos, isFetching, isFullLoaded } = useBoulderVideos(boulder?.id);

  const handleScroll = useCallback(
    (e: any) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
      if (bottom && !isFullLoaded && !blockRef.current) {
        blockRef.current = true;
        getVideos({
          skip: videoIds.length,
          type: undefined,
          orderBy: 'desc',
          noClean: true,
          userId: undefined,
        });
      }
    },
    [getVideos, isFullLoaded, videoIds.length]
  );

  const handleRecord = useCallback(() => {
    trackEvent('block_video_record', { sectorId, block: boulder?.id });
    navigate(`${ROUTES.RECORD}/${sectorId}/${boulder?.id}`);
    onDismiss();
  }, [boulder?.id, navigate, onDismiss, sectorId]);
  const handleSelectVideo = useCallback(() => {
    trackEvent('block_video_gallery', { sectorId, block: boulder?.id });
    navigate(`${ROUTES.RECORD}/${sectorId}/${boulder?.id}`, { state: { library: true } });
    onDismiss();
  }, [boulder?.id, navigate, onDismiss, sectorId]);

  useEffect(() => {
    getVideos({
      skip: 0,
      type: undefined,
      orderBy: 'desc',
      noClean: false,
      userId: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.id]);

  useEffect(() => {
    if (!isFetching) {
      blockRef.current = false;
    }
  }, [isFetching]);

  useEffect(() => {
    if (currentUser?.isAdmin && !didItOnce.current) {
      didItOnce.current = true;
    }
  }, [currentUser]);

  return (
    <IonContent id='BlocChallenge'>
      <div className='content' onScroll={handleScroll}>
        <span className='divTitle'>{t('method_bloc')}</span>
        <div className='membersRun'>
          <div className={`list ${!videoIds?.length ? 'empty' : ''}`}>
            {/* eslint-disable-next-line prettier/prettier */}
            {videoIds?.map((videoId) => <BlocChallengeVideo videoId={videoId} key={videoId} />)}
            {isFetching && <IonSpinner color='primary' />}
            {!isFetching && !videoIds?.length && (
              <div className='emptyText'>{t('no_videos_available_boulder')}</div>
            )}
          </div>
        </div>
        {logged && (
          <div className='shareButton'>
            <IonButton onClick={handleRecord} disabled={currentUser.isAdmin}>
              <Icon slot='start' icon={IconTypes.VIDEO} />
              {t('record')}
            </IonButton>
            <IonButton onClick={handleSelectVideo} disabled={currentUser.isAdmin}>
              <Icon slot='start' icon={IconTypes.GALLERY} />
              {t('gallery')}
            </IonButton>
          </div>
        )}
      </div>
    </IonContent>
  );
};

export default BlocChallenge;
