import axios from 'axios';
// import { eventChannel } from 'redux-saga';
import { actions as MediasActions } from './redux';
// import { selectors as UserSelector } from '../user/redux';
import { readFile, getMimeType } from '../../utils/fileUtils';
import { all, takeLatest, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

// import getHeader from '../../utils/getHeader';
// import AppConfig from '../../AppConfig';

export default class MediaSagas {
  static *requestUploadMedia({ payload }) {
    try {
      const { path, type } = payload;
      yield put(MediasActions.setIsLoading(true));

      // const user = yield select(UserSelector.currentUser); //  sender

      if (!path) {
        console.error('missing binary');
        yield put(MediasActions.setUploadFail(true));
        return null;
      }

      // creating random name
      let extension = 'jpeg';
      try {
        extension = path.startsWith('data:')
          ? path.replace('data:', '').split(';base64')[0].split('/')[1]
          : path.split('.').reverse()[0];
      } catch (err) {
        console.warn(err);
        Sentry.captureException(err);
      }
      // const fileName = `${user?.id}_media_${Date.now()}${parseInt(
      //   Math.random() * 1000
      // )}.${extension}`;

      const dataURItoBlob = async (path) => {
        let byteString;
        let result = path;
        if (!path?.startsWith('data:')) {
          const { result: r, err } = await readFile(path);
          if (err) return;
          result = r;
        }
        if (result.split(',')[0].indexOf('base64') >= 0) {
          byteString = atob(result.split(',')[1]);
        } else {
          byteString = unescape(result.split(',')[1]);
        }
        // get mime type
        const { mimeString } = getMimeType(extension.toLowerCase());
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      };

      const blobImg = yield dataURItoBlob(path);

      // const dForm = new FormData();
      // dForm.append('photos', blobImg, fileName);
      // const mediaUrl = AppConfig.IMAGE_OPTI_URL;

      const pEnv = window['env']?.REACT_APP_UPLOAD_KEY ? window['env'] : process.env;
      const API_KEY = pEnv.REACT_APP_UPLOAD_KEY;

      let upload = null;
      try {
        let body = new FormData();
        body.set('key', API_KEY);
        body.append('image', blobImg);

        const res = yield axios({
          method: 'post',
          url: 'https://api.imgbb.com/1/upload',
          data: body,
        });

        if (res.data.data.display_url) {
          upload = res.data.data.display_url;
        }
        yield put(MediasActions.setIsLoading(false));

        // // Starting upload
        // let upload = null;
        // try {
        //   const conf = getHeader({ 'Content-Type': 'multipart/form-data' });
        //   const progressChannel = eventChannel((emitter) => {
        //     axios({
        //       method: 'post',
        //       url: mediaUrl,
        //       data: dForm,
        //       headers: conf,
        //     })
        //       .then((res) => {
        //         console.info('axios success');
        //         emitter(res);
        //       })
        //       .catch((err) => {
        //         console.error('error axios', err);
        //         emitter(err);
        //       });
        //     return () => {};
        //   });

        //   while (true) {
        //     // Awaiting finish event
        //     const redirectEvent = yield take(progressChannel);

        //     if (redirectEvent.type === 'progress') {
        //       yield put(MediasActions.setIsLoading(true));
        //     } else if (redirectEvent.type !== 'progress') {
        //       upload = redirectEvent;
        //       yield put(MediasActions.setIsLoading(false));
        //       break;
        //     }
        //   }
      } catch (err) {
        console.warn('upload error', err);
        yield put(MediasActions.setIsLoading(false));
        Sentry.captureException(err);
      }

      // Setting new url
      if (!!upload) {
        if (type === 2) yield put(MediasActions.addUploadedDemo(upload));
        if (type === 3) yield put(MediasActions.addUploadEdition(upload));
        else yield put(MediasActions.addUploadedMedia(upload));
        yield put(MediasActions.setUploadSuccess(true));
        return;
      }
      yield put(MediasActions.setUploadFail(true));
      yield put(MediasActions.setIsLoading(false));
      return null;
    } catch (err) {
      console.error(err);
      yield put(MediasActions.setIsLoading(false));
      yield put(MediasActions.setUploadFail(true));
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(MediasActions.requestUploadMedia.getType(), MediaSagas.requestUploadMedia),
    ]);
  }
}
