import { isMobile } from 'react-device-detect';
import { useLogged } from '../../redux/auth/hooks';
import { useBoulder } from '../../redux/boulders/hooks';
import { useLocation, useNavigate } from 'react-router';
import { useCoaching } from '../../redux/coaching/hooks';
import { Vibration } from '@awesome-cordova-plugins/vibration';
import { useLocalize } from '../../redux/translation/localize';
import { useAudits, useBoulderValidation } from '../../redux/audits/hooks';
import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';

import Icon from '../../atoms/Icon/Icon';
import { IonLoading, IonPage } from '@ionic/react';
import Alert from '../../components/Alert/Alert';
import { ModalContent } from '../../pages/Sector/Sector';
import EndCoach from '../../components/EndCoach/EndCoach';
import BoulderNob from '../../components/BoulderNob/BoulderNob';
import MovableImage from '../../atoms/MovableImage/MovableImage';
import BlocDetailsModal from './partials/BlocDetailsModal/BlocDetailsModal';

import './Bloc.scss';
import ROUTES from '../../routes/constants';
import IconTypes from '../../types/IconTypes';
import AuditTypes from '../../types/AuditTypes';
import getBoulderColor from '../../utils/getBoulderColor';
import { trackEvent } from '../../providers/tracker';

const BRAVO = require('../../assets/img/bravo.png');

const Bloc: React.FC = () => {
  // Refs
  const timer = useRef<NodeJS.Timeout | null>(null);
  const bravoRef = useRef<NodeJS.Timeout | null>(null);

  // Props Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Props Memos
  const { room, boulders, coach, index } = useMemo(
    () => ({
      room: location.state?.room,
      boulders: location.state?.boulders,
      coach: location.state?.coach,
      index: location.state?.index,
    }),
    [location.state]
  );

  // Hooks
  const t = useLocalize();
  const { logged } = useLogged();
  const boulder: any = useBoulder(boulders?.[index]?.id);
  const { audits, sendClap, sendIncrementation } = useAudits(boulder?.id);
  const { validateBoulder, clearSession } = useCoaching();
  const { sendSingleValidation, unvalidateBoulder } = useBoulderValidation();

  // States
  const [end, setEnd] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showTop, setShowTop] = useState<boolean>(false);
  const [_showFail, setShowFail] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [breakpoint, setBreakpoints] = useState<number>(0.25);
  const [isPanelOpen, setOpenPanel] = useState<boolean>(false);
  const [clap, setClap] = useState<number>(audits?.[AuditTypes.CLAP] || 0);
  const [auditTemp, setAuditTemp] = useState<string>();
  const [classNm, setClassNm] = useState<string>();

  const [modalContent, setModalContent] = useState<ModalContent>({
    blocInfo: false,
    blocLocation: false,
    blocDefi: false,
    blocValidation: false,
    blocColor: false,
  });

  // Memos
  const { color, colorRgba } = useMemo(
    () => getBoulderColor(boulder?.color?.name),
    [boulder?.color?.name]
  );

  const dateTimeFormat = useMemo(() => {
    const date = new Date(boulder?.createDate);
    if (date && boulder?.createDate) {
      return new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: 'short',
      }).format(date);
    } else {
      return null;
    }
  }, [boulder]);

  // Effects
  useEffect(() => {
    if (!!boulder?.id && logged) {
      sendIncrementation(boulder.id, AuditTypes.READ);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boulder?.id, logged]);

  useEffect(() => {
    if (!!audits) {
      if (audits[AuditTypes.CLAP]) {
        setClap(audits[AuditTypes.CLAP]);
      } else setClap(0);
    }
  }, [audits]);

  // Handlers
  const handleModal = useCallback(
    (key: string, initBreakpoint: number | null) => {
      trackEvent('block_modal_open', { modal: key });
      if (key === 'blocColor' && coach) return;
      if (initBreakpoint) setBreakpoints(initBreakpoint);
      else if (key === 'blocColor') setBreakpoints(0.5);
      else setBreakpoints(0.25);

      setShowModal(true);
      setModalContent((prevState: any) => ({
        ...prevState,
        [key]: true,
      }));
    },
    [coach]
  );

  const handleAuditTemp = useCallback((value: string) => {
    setAuditTemp(value);
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    timer.current = setTimeout(() => {
      setAuditTemp(undefined);
    }, 20000);
    return () => timer.current && clearTimeout(timer.current);
  }, []);

  const hideTop = useCallback(() => {
    setShowTop(false);
    setShowFail(false);
    setOpenPanel(false);
  }, []);

  const hideModal = useCallback(() => {
    setShowModal(false);
    setModalContent(() => ({
      blocInfo: false,
      blocLocation: false,
      blocDefi: false,
      blocValidation: false,
      blocColor: false,
    }));
  }, []);

  const handleTop = useCallback(
    (flash: boolean) => {
      if (isMobile) Vibration.vibrate(1000);
      setShowFail(false);
      if (flash) {
        trackEvent('block_flash', { blockId: boulder?.id });
        sendSingleValidation(boulder?.id, boulder?.room?.id, true, 'FLASH');
        handleAuditTemp('FLASH');
      } else {
        trackEvent('block_validate', { blockId: boulder?.id });
        sendSingleValidation(boulder?.id, boulder?.room?.id, true, 'TOP');
        handleAuditTemp('TOP');
      }
      validateBoulder(boulder?.id);
      setShowTop(true);
      if (bravoRef.current) clearTimeout(bravoRef.current);
      bravoRef.current = setTimeout(() => setShowTop(false), 2000);
    },
    [boulder?.id, boulder?.room?.id, handleAuditTemp, sendSingleValidation, validateBoulder]
  );

  const handleClose = useCallback(() => {
    hideTop();
    setClap(0);
    setEnd(false);
    navigate(ROUTES.TRAINING_PLAN, {
      state: {
        room: room,
      },
    });
    setAuditTemp(undefined);
  }, [hideTop, navigate, room]);

  const handleNext = useCallback(() => {
    const next = index + 1;
    hideTop();
    setOpenPanel(false);
    setClap(0);
    navigate(`${ROUTES.BLOC}/${boulders[next].id}`, {
      state: {
        room: room,
        boulders: boulders,
        index: next,
        coach: coach,
      },
    });
    setAuditTemp(undefined);
  }, [index, hideTop, navigate, boulders, room, coach]);

  const handlePrev = useCallback(() => {
    const prev = index > 0 ? index - 1 : 0;
    hideTop();
    setOpenPanel(false);
    setClap(0);
    navigate(`${ROUTES.BLOC}/${boulders[prev].id}`, {
      state: {
        room: room,
        boulders: boulders,
        index: prev,
        coach: coach,
      },
    });
    setAuditTemp(undefined);
  }, [index, hideTop, navigate, boulders, room, coach]);

  const handlePanel = useCallback(() => {
    setOpenPanel(!isPanelOpen);
  }, [isPanelOpen]);

  const terminateCoach = useCallback(() => {
    setClap(0);
    hideTop();
    clearSession();
    setEnd(true);
    setAuditTemp(undefined);
  }, [clearSession, hideTop]);

  const handleClap = useCallback(
    (value) => {
      if (logged && value > 0) {
        sendClap(boulder?.id, value);
      }
    },
    [boulder?.id, sendClap, logged]
  );

  const handleUnvalidate = useCallback(() => {
    if (logged && boulder?.id && boulder?.room?.id) {
      trackEvent('block_unvalidate', { blockId: boulder.id });
      unvalidateBoulder(boulder.id, boulder.room.id);
      setAuditTemp(undefined);
    }
    setShowTop(false);
    setShowFail(false);
  }, [boulder?.id, boulder?.room?.id, logged, unvalidateBoulder]);

  const handleFail = useCallback(() => {
    handleAuditTemp('FAIL');
    if (logged && boulder?.id && boulder?.room?.id) {
      sendSingleValidation(boulder.id, boulder.room.id, true, 'FAIL');
    }
  }, [boulder?.id, boulder?.room?.id, handleAuditTemp, logged, sendSingleValidation]);

  const handleAlert = useCallback(() => {
    setShowAlert(false);
    setShowTop(false);
    terminateCoach();
  }, [terminateCoach]);

  useEffect(() => {
    if (showTop) setClassNm('animation-bounce');
    else setClassNm('');
  }, [showTop]);

  return (
    <IonPage>
      <IonLoading isOpen={!boulder?.validationConditions} spinner='crescent' />
      {!!boulder && (
        <div className='bloc_content'>
          <div className='boulder_header'>
            {boulder.value !== undefined && (
              <div style={{ backgroundColor: color }} className='boulder_value'>
                {t('boulder_value', { value: boulder.value?.toFixed() })}
              </div>
            )}
            <div className='boulder_title'>
              <span className='daily_date'>{dateTimeFormat}</span>
              <div className='dot'></div>
              <span>{boulder?.room?.name}</span>
              <div className='dot'></div>
              <span>{boulder?.sector?.name}</span>
            </div>
            <Icon icon={IconTypes.CLOSE_CIRCLE} className='close_icon' onClick={handleClose} />
          </div>
          {!end && !!boulder.validationConditions && (
            <BoulderNob
              clap={clap}
              color={color}
              boulder={boulder}
              colorRgba={colorRgba}
              showModal={showModal}
              modalContent={modalContent}
              totalClaps={boulder?.claps}
              addClap={handleClap}
              onTop={handleTop}
              onFail={handleFail}
              invalidateBloc={handleUnvalidate}
              setPanel={handlePanel}
              isPanelOpen={isPanelOpen}
              setModal={handleModal}
              hideTop={hideTop}
              // audits={audits}
              nextBloc={handleNext}
              prevBloc={handlePrev}
              disableNext={index === boulders?.length - 1}
              disablePrev={index === 0}
              auditTemp={auditTemp}
            />
          )}
          <BlocDetailsModal
            isOpen={showModal}
            onDismiss={hideModal}
            isCoach={coach}
            boulder={boulder}
            boulders={boulders}
            modalContent={modalContent}
            breakpoint={breakpoint}
          />
          {showTop && (
            <div className={`topContainer ${classNm}`}>
              {logged && <img src={BRAVO} alt='bravo' className='bravoIcon' />}
            </div>
          )}
          {end && <EndCoach room={room} onDismiss={setEnd} showTop={setShowTop} />}
          <Alert
            onAlertChange={setShowAlert}
            title={t('terminate_session_alert')}
            message={t('terminate_session_confirmation')}
            actionName={t('finish')}
            action={handleAlert}
            position=' 60%'
            display={showAlert}
          />
          <MovableImage
            url={boulder?.picture}
            alt='background'
            className='bloc_picture'
            handleClick={hideTop}
          />
        </div>
      )}
    </IonPage>
  );
};

export default Bloc;
