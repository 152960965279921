import React from 'react';

import './Point.scss';

interface Props {
  color?: string;
}

const Point: React.FC<Props> = ({ color }) => (
  <div className='point' style={{ backgroundColor: color }}></div>
);

export default Point;
