import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuditsActions, selectors as AuditsSelector } from './redux';

export const useAudits = (boulderId) => {
  const dispatch = useDispatch();

  const audits = useSelector((state) => AuditsSelector.audits(state));

  const sendClap = useCallback(
    (boulderId, value) => dispatch(AuditsActions.sendClap(boulderId, value)),
    [dispatch]
  );
  const sendUpdate = useCallback(
    (typeId, value) => dispatch(AuditsActions.sendUpdate(boulderId, typeId, value)),
    [boulderId, dispatch]
  );
  const sendIncrementation = useCallback(
    (boulderId, typeId, date = undefined) =>
      dispatch(AuditsActions.sendIncrementation(boulderId, typeId, date)),
    [dispatch]
  );

  const sendManyIncrementation = useCallback(
    (incrementAuditsData) => dispatch(AuditsActions.sendManyIncrementation(incrementAuditsData)),
    [dispatch]
  );

  return {
    audits,

    sendClap,
    sendUpdate,
    sendIncrementation,
    sendManyIncrementation,
  };
};

export const useBoulderValidation = () => {
  const dispatch = useDispatch();

  const sendSingleValidation = useCallback(
    (boulderId, roomId, isCoaching, status) =>
      dispatch(AuditsActions.sendSingleValidation(boulderId, roomId, isCoaching, status)),
    [dispatch]
  );
  const unvalidateBoulder = useCallback(
    (boulderId, roomId) => dispatch(AuditsActions.SendUnvalidateBoulder(boulderId, roomId)),
    [dispatch]
  );

  const sendMultipleValidation = useCallback(
    (roomId, boulders, startDate, sessionId = undefined) =>
      dispatch(AuditsActions.sendMultipleValidation(roomId, boulders, startDate, sessionId)),
    [dispatch]
  );

  return {
    sendSingleValidation,
    unvalidateBoulder,
    sendMultipleValidation,
  };
};
