const fonts = [
  {
    name: 'default',
    style: {
      fontFamily: 'Arial',
      fontSize: 36,
      fontWeight: 'bold',
      leading: 4,
    },
  },
];

export default fonts;
