import { gql } from '@apollo/client';

export const GetRooms = gql`
  query GetRooms {
    rooms {
      id
      name
      plan
      walls {
        id
        name
        plan
        sectors {
          status
          creationDate
          updateDate
          name
          id
          picture
          name
          boulders {
            id
          }
        }
        room {
          id
          name
        }
      }
    }
  }
`;
export const GetRoom = gql`
  query GetRoom($id: Int!) {
    room(id: $id) {
      id
      name
      plan
      walls {
        id
        name
        sectors {
          status
          creationDate
          updateDate
          name
          id
          picture
          name
          boulders {
            id
          }
        }
        room {
          id
          name
        }
      }
    }
  }
`;
