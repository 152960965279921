// Hooks
import React, { useEffect, useMemo, useRef } from 'react';
import { useAppToken, useOnboarding } from '../redux/auth/hooks';
import { useAppLoading, useAppStarted } from '../redux/app/hooks';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// Components
import { IonRouterOutlet, IonLoading } from '@ionic/react';
import MainFooter from './components/MainFooter/MainFooter';
import OptimizedImage from '../atoms/OptimizedImage/OptimizedImage';
import NotifyQrScan from '../components/NotifyQrScan/NotifyQrScan';

// Config
import routes from './routes';
import { trackPage } from '../providers/tracker';

const splashScreenImage = '/assets/backgrounds/splash.png';
const backgroundScreenImage = '/assets/backgrounds/background_splash.png';

const MainRouter: React.FC<any> = () => {
  const { isOnboarded, skippedOnboarding } = useOnboarding();

  const shouldOnboard = useMemo(
    () => !skippedOnboarding && !isOnboarded,
    [skippedOnboarding, isOnboarded]
  );

  const filteredRoutes = useMemo(
    () => routes.filter(({ needOnboarding }) => !needOnboarding || !shouldOnboard),
    [shouldOnboard]
  );

  return (
    <IonRouterOutlet>
      <Routes>
        {filteredRoutes.map(({ path, component: Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        <Route path='*' key='/' element={<Navigate to={shouldOnboard ? '/login' : '/home'} />} />
      </Routes>
    </IonRouterOutlet>
  );
};

const Router: React.FC<any> = () => {
  const location = useLocation();
  const appStarted = useAppStarted();
  const appLoading = useAppLoading();
  const { setToken } = useAppToken();

  const lastPathname = useRef<string | undefined>();

  useEffect(() => {
    if (appStarted) {
      const token = localStorage.getItem('token');
      setToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStarted]);

  useEffect(() => {
    if (location.pathname !== lastPathname.current) {
      trackPage(location.pathname);
    }
    lastPathname.current = location.pathname;
  }, [location]);

  if (!appStarted)
    return (
      <div className='appOpening'>
        <OptimizedImage src={splashScreenImage} format='original' alt='Climbing District' />
        <div className='background' style={{ backgroundImage: `url(${backgroundScreenImage})` }} />
      </div>
    );

  return (
    <div id='mainPage'>
      <IonLoading cssClass='custom-loader-class' isOpen={!appStarted || appLoading} message={''} />
      <NotifyQrScan />
      <MainRouter />
      <MainFooter />
    </div>
  );
};
export default Router;
