import { IonContent } from '@ionic/react';
import { useCallback } from 'react';
import { useLocalize } from '../../../../../redux/translation/localize';

import BoulderItem from './BoulderItem';

import Boulder from '../../../../../utils/boulder.interface';

interface Props {
  boulder: Boulder;
  boulders: Boulder[];
  onDismiss: () => void;
  onBlocChange?: (i: number) => void;
}

const BlocLocation: React.FC<Props> = ({ boulders, boulder, onBlocChange, onDismiss }) => {
  const t = useLocalize();

  const handleBlocClick = useCallback(
    (value) => {
      if (!!onBlocChange) {
        onBlocChange(value);
        onDismiss();
      }
    },
    [onBlocChange, onDismiss]
  );

  return (
    <IonContent className='planContainer'>
      <span className='difficultiesTitle'>{t('difficulty')}</span>
      <div className='boulderDifficulties'>
        {boulders.map((item, index) => (
          <BoulderItem
            key={index}
            id={item.id}
            index={index}
            onClick={handleBlocClick}
            colorName={item.color?.name}
            isCurrent={item.id === boulder.id}
          />
        ))}
      </div>
      {!!onBlocChange && <span className='comments'>{t('block_guild')}</span>}
      <img
        crossOrigin='anonymous'
        className='sectorPlan'
        src={boulder?.sector?.plan}
        alt='plan du secteur'
      />
    </IonContent>
  );
};

export default BlocLocation;
