import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as OpeningsActions, selectors as OpeningsSelector } from './redux.js';

export const useManageOpenings = () => {
  const dispatch = useDispatch();

  const dismantleSector = useCallback(
    (sectorId) => dispatch(OpeningsActions.dismantleSector(sectorId)),
    [dispatch]
  );

  const addSectorPicture = useCallback(
    (id, picture) => dispatch(OpeningsActions.addSectorPicture(id, picture)),
    [dispatch]
  );

  const mountBoulder = useCallback(
    (params) => dispatch(OpeningsActions.mountBoulder(params)),
    [dispatch]
  );
  const updateBoulder = useCallback(
    (params) => dispatch(OpeningsActions.updateBoulder(params)),
    [dispatch]
  );

  const isPublishing = useSelector((state) => OpeningsSelector.isPublishing(state));

  return {
    addSectorPicture,
    mountBoulder,
    dismantleSector,
    updateBoulder,
    isPublishing,
  };
};
