import { useSelector } from 'react-redux';
import { selectors as AppSelectors } from './redux';

export const useAppStarted = () => {
  const appStarted = useSelector((state) => AppSelectors.appStarted(state));
  return appStarted;
};

export const useAppLoading = () => {
  const appLoading = useSelector((state) => AppSelectors.isLoading(state));

  return appLoading;
};
