import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as EditorActions, selectors as EditorSelectors } from '../redux.js';

export const useEditorTools = () => {
  const dispatch = useDispatch();

  const primaryTool = useSelector(EditorSelectors.primaryTool);
  const editingDisplayId = useSelector(EditorSelectors.editingDisplayId);

  const setEdit = useCallback(
    (toolbar, editingDisplayId) => dispatch(EditorActions.edit(toolbar, editingDisplayId)),
    [dispatch]
  );

  const setText = useCallback(() => dispatch(EditorActions.edit('text')), [dispatch]);
  const setDraw = useCallback(() => dispatch(EditorActions.edit('draw')), [dispatch]);
  const setTag = useCallback(() => dispatch(EditorActions.edit('tag')), [dispatch]);
  const setCrop = useCallback(() => dispatch(EditorActions.edit('crop')), [dispatch]);

  const clearPrimaryTool = useCallback(
    () => dispatch(EditorActions.edit('manipulation')),
    [dispatch]
  );

  return {
    primaryTool,
    editingDisplayId,

    setEdit,
    setCrop,
    setDraw,
    setTag,
    setText,
    clearPrimaryTool,
  };
};
