import { client } from '../../App';
import AppConfig from '../../AppConfig';
import * as Sentry from '@sentry/browser';
import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import {
  createBoulderVideo,
  deleteBoulderVideo,
  getBoulderVideos,
} from '../../GraphQL/BoulderVideo';
import { actions as BoulderVideoActions, selectors as BoulderVideoSelector } from './redux';
import axios from 'axios';
import { eventChannel } from 'redux-saga';

// Utils
import getHeader from '../../utils/getHeader';
import getExtension from '../../utils/getExtension';
import getVideoCover from '../../utils/getVideoCover';

// File systems
import { File } from '@ionic-native/file';
import { isAndroid, isIOS } from 'react-device-detect';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';

const LIMIT = 10;

export default class BouldersSagas {
  static *requestBoulderVideos({ payload }) {
    try {
      yield put(BoulderVideoActions.setFetching(true));
      const { boulderId, filters } = payload;

      if (!filters.noClean) {
        yield put(BoulderVideoActions.cleanVideos(boulderId));
      }

      if (filters.skip) {
        const videos = yield select(
          BoulderVideoSelector.videoIds,
          boulderId,
          filters.type || 'USER'
        );
        filters.skip = videos?.length || 0;
      } else if (!filters.noClean) {
        yield put(BoulderVideoActions.setFullLoaded(false));
      }

      if (!filters.type) {
        yield put(
          BoulderVideoActions.requestBoulderVideos(boulderId, {
            ...filters,
            type: 'OPENER',
            noClean: true,
          })
        );
      }

      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: getBoulderVideos,
        variables: {
          boulderId,
          ...filters,
          limit: LIMIT,
          type: filters.type || 'USER',
          userId: (filters.type || 'USER') === 'USER' ? filters.userId : undefined,
        },
      });

      if (!filters.type) {
        filters.noClean = true;
      }

      if (result?.data && result.data.boulder) {
        if (result.data.boulder.boulderVideos?.length < LIMIT && filters.type !== 'OPENER') {
          yield put(BoulderVideoActions.setFullLoaded(true));
        }

        yield put(
          BoulderVideoActions.addVideos(
            result.data.boulder.boulderVideos || [],
            !filters.skip && !filters.noClean
          )
        );
      }
      yield put(BoulderVideoActions.setFetching(false));
    } catch (err) {
      console.warn(err);
      yield put(BoulderVideoActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *requestCreateBoulderVideo({ payload }) {
    const { boulderId, videoBlob, params, callback } = payload;

    yield put(BoulderVideoActions.setFetching(true));

    // File Info
    const extension = getExtension(videoBlob.type);
    const fileName = `ClimbingDistrict_${boulderId}_${Number(Date.now())}.${extension}`;

    if (params.download) {
      try {
        let PATH = (yield Filesystem.getUri({
          directory: Directory.ExternalStorage,
          path: '',
        })).uri;
        if (isAndroid) {
          PATH += '/Download';
        }
        const result = yield File.writeFile(PATH, fileName, videoBlob);
        if (isIOS) {
          yield FileOpener.open({ filePath: result.nativeURL });
        }
      } catch (err) {
        console.error('Problem download', err);
      }
    }

    if (params.communityShare) {
      try {
        yield put(BoulderVideoActions.setFetching(true));
        const mediaUrl = AppConfig.IMAGE_OPTI_URL;
        const conf = getHeader({ 'Content-Type': 'multipart/form-data' });

        // Thumb media
        const channel = yield eventChannel((emitter) => {
          getVideoCover(URL.createObjectURL(videoBlob), 1, true).then((data) => emitter(data));
          return () => {};
        });

        const previewVideo = yield take(channel);

        const pForm = new FormData();
        const pFileName = `${boulderId}_${Number(Date.now())}_thumb.jpg`;
        pForm.append('photos', previewVideo, pFileName);
        const previewUpload = yield axios({
          method: 'post',
          url: mediaUrl,
          data: pForm,
          headers: conf,
        });

        // Starting upload
        let upload = null;
        const dForm = new FormData();
        dForm.append('video_to_convert', videoBlob, fileName);

        // Request normal version upload
        const progressChannel = eventChannel((emitter) => {
          axios({
            method: 'post',
            url: mediaUrl.replace('/upload', '/video/conversation'),
            data: dForm,
            headers: conf,
          })
            .then((res) => {
              emitter(res);
            })
            .catch((err) => {
              console.error('Error axios', err);
              emitter(err);
            });
          return () => {};
        });

        while (true) {
          // Awaiting finish event
          const redirectEvent = yield take(progressChannel);

          if (redirectEvent.type === 'progress') {
            yield put(BoulderVideoActions.setProgress(10));
          } else if (redirectEvent.type !== 'progress') {
            upload = redirectEvent;
            break;
          }
        }

        yield put(BoulderVideoActions.setProgress(null));
        if (upload.data) {
          const result = yield client.mutate({
            fetchPolicy: 'network-only',
            mutation: createBoulderVideo,
            variables: {
              boulderId,
              url: upload.data.url,
              jobName: upload.data.jobName,
              previewImage: previewUpload.data,
            },
          });

          if (result?.data) {
            const { createBoulderVideo } = result.data;
            yield put(BoulderVideoActions.setFetching(false));
            yield put(BoulderVideoActions.addVideo({ ...createBoulderVideo, new: true }));
          }

          callback?.();
        }
      } catch (err) {
        console.warn('Error sending Video', err);
        Sentry.captureException(err);
      }
    }
    callback?.();
    yield put(BoulderVideoActions.setFetching(false));
  }

  static *requestDeleteBoulderVideo({ payload }) {
    const { videoId, boulderId, type } = payload;

    yield put(BoulderVideoActions.removeVideo(videoId, boulderId, type));
    yield client.mutate({
      fetchPolicy: 'network-only',
      mutation: deleteBoulderVideo,
      variables: {
        videoId,
      },
    });
  }

  static *loop() {
    yield all([
      takeEvery(
        BoulderVideoActions.requestBoulderVideos.getType(),
        BouldersSagas.requestBoulderVideos
      ),
      takeEvery(
        BoulderVideoActions.requestDeleteVideo.getType(),
        BouldersSagas.requestDeleteBoulderVideo
      ),
      takeEvery(
        BoulderVideoActions.createBoulderVideo.getType(),
        BouldersSagas.requestCreateBoulderVideo
      ),
    ]);
  }
}
