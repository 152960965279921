import Immutable from 'seamless-immutable';
import * as reducers from './reducers';
import { createAction, createReducer } from 'redux-act';

const initialState = Immutable({
  sessionBoulders: [],
  sessionAudits: [],
  nbOfSession: 0,
  coachSession: {},
  coachDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),

  isFetching: false,
  isFullLoaded: false,
});

export const actions = {
  requestSessionBoulders: createAction('request coaching boulders', (volume, time, roomId) => ({
    volume,
    time,
    roomId,
  })),
  createSession: createAction('create coaching session ', (volume, roomId, boulderIds) => ({
    volume,
    roomId,
    boulderIds,
  })),
  requestSession: createAction('request coaching session ', (id) => ({ id })),
  clearSession: createAction('clear coaching session '),
  validateBoulder: createAction('validate boulder in coaching session ', (boulderId) => ({
    boulderId,
  })),
  terminateSession: createAction('terminate coaching session '),
  updateSession: createAction(
    'update terminated session ',
    (sessionId, boulderId, iscoaching, isvalidated) => ({
      sessionId,
      boulderId,
      iscoaching,
      isvalidated,
    })
  ),
  requestSessionAudits: createAction('request session audits', (sessionId) => ({
    sessionId,
  })),
  requestCoachSession: createAction('active session', (roomId) => ({
    roomId,
  })),

  addSessionBoulders: createAction('add Session boulders', (boulders) => ({
    boulders,
  })),
  addSessionAudits: createAction('add Session audits', (audits) => ({ audits })),
  addCoachSession: createAction('add active session', (session) => ({ session })),
  incrementNb: createAction('increment number of session'),

  setFetching: createAction('setFetching', (value) => ({ value })),
  setFullLoaded: createAction('setFullLoaded', (value) => ({ value })),
  setCoachDate: createAction('set coach date', (date) => ({ date })),

  cleanCoaching: createAction('cleanCoaching'),
};

export const reducer = createReducer(
  {
    [actions.validateBoulder]: reducers.validateBoulder,
    [actions.addSessionBoulders]: (state, { boulders }) =>
      state.setIn(['sessionBoulders'], boulders),
    [actions.addSessionAudits]: (state, { audits }) => state.setIn(['sessionAudits'], audits),
    [actions.addCoachSession]: (state, { session }) => state.setIn(['coachSession'], session),
    [actions.incrementNb]: (state) => state.setIn(['nbOfSession'], state.nbOfSession + 1),
    [actions.clearSession]: (state) => state.setIn(['session'], initialState.session),
    [actions.setFetching]: (state, { value }) => state.setIn(['isFetching'], value),
    [actions.setFullLoaded]: (state, { value }) => state.setIn(['isFullLoaded'], value),
    [actions.setCoachDate]: (state, { date }) => state.setIn(['coachDate'], date),

    [actions.cleanCoaching]: (state) => state.merge({ ...initialState }),
  },
  initialState
);

export const selectors = {
  nbOfSession: (state) => state.coaching.nbOfSession,
  sessionBoulders: (state) => state.coaching.sessionBoulders,
  sessionAudits: (state) => state.coaching.sessionAudits,
  coachSession: (state) => state.coaching.coachSession,
  isFetching: (state) => state.coaching.isFetching,
  isFullLoaded: (state) => state.coaching.isFullLoaded,
  coachDate: (state) => state.coaching.coachDate,
};
