import { client } from '../../App';
import { all, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import {
  SendClap,
  SendUpdate,
  SendIncrementation,
  SendIncrementations,
  SendSingleValidation,
  SendMultipleValidation,
  UnvalidateBoulder,
} from '../../GraphQL/SendAudit';
import { GetUserAudits } from '../../GraphQL/GetUserAudits';

import { actions as AppActions } from '../app/redux';
import { actions as CoachingActions } from '../coaching/redux';
import { actions as AuditsActions } from './redux';
import { actions as SessionsActions, selectors as SessionsSelector } from '../sessions/redux';
import { actions as BouldersActions } from '../boulders/redux';

export default class AuditsSagas {
  static *uploadUpdate({ payload }) {
    try {
      const { boulderId, typeId, value } = payload;
      const result = yield client.mutate({
        mutation: SendUpdate,
        fetchPolicy: 'network-only',
        variables: {
          boulderId: boulderId,
          typeId: typeId,
          value: value,
        },
      });
      if (!!result?.data) {
        yield put(AuditsActions.requestAudits(boulderId, [1, 2, 4, 9]));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *uploadClap({ payload }) {
    try {
      const { boulderId, value } = payload;
      const result = yield client.mutate({
        mutation: SendClap,
        fetchPolicy: 'network-only',
        variables: {
          boulderId: boulderId,
          value: value,
        },
      });
      if (!!result?.data) {
        yield put(AuditsActions.requestAudits(boulderId, [1, 2, 4, 9]));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *uploadIncrementation({ payload }) {
    try {
      const { boulderId, typeId, date } = payload;
      const result = yield client.mutate({
        mutation: SendIncrementation,
        fetchPolicy: 'network-only',
        variables: {
          boulderId: boulderId,
          typeId: typeId,
          date,
        },
      });
      if (result?.data) {
        yield put(AuditsActions.requestAudits(boulderId, [1, 2, 4, 9]));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *uploadSingleValidation({ payload }) {
    const { boulderId, roomId, isCoaching, status } = payload;
    try {
      const activeSession = yield select(SessionsSelector.activeSession);
      if (activeSession) {
        const result = yield client.mutate({
          mutation: SendSingleValidation,
          fetchPolicy: 'network-only',
          variables: {
            sessionId: activeSession.id,
            boulderId,
            roomId,
            isCoaching,
            status,
          },
        });
        if (result?.data) {
          yield put(
            SessionsActions.updateActiveSession(result.data.validateBoulder.sessionBoulder)
          );
          yield put(CoachingActions.terminateSession());
        }
      } else {
        yield put(SessionsActions.createSession(roomId, payload, undefined));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
      yield put(SessionsActions.createSession(payload.roomId, payload, undefined));
    }
  }

  static *uploadMultipleValidation({ payload }) {
    try {
      const { roomId, boulders, startDate, sessionId } = payload;
      if (sessionId) {
        const result = yield client.mutate({
          mutation: SendMultipleValidation,
          fetchPolicy: 'network-only',
          variables: { sessionId, roomId, boulders },
        });
        if (result?.data) {
          yield put(SessionsActions.updateSession(result.data.validateBoulders, sessionId));
          yield put(CoachingActions.terminateSession());
        }
      } else {
        yield put(SessionsActions.createSession(roomId, payload, startDate));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *SendUnvalidateBoulder({ payload }) {
    try {
      const { boulderId, roomId } = payload;
      const activeSession = yield select(SessionsSelector.activeSession);
      const sessionBoulder = activeSession.sessionboulders.find(
        (boulder) => boulder.boulderId === boulderId
      );
      if (sessionBoulder?.id) {
        const result = yield client.mutate({
          mutation: UnvalidateBoulder,
          fetchPolicy: 'network-only',
          variables: { sessionBoulderId: sessionBoulder.id, roomId },
        });
        if (result?.data) {
          yield put(SessionsActions.removeBoulderOnActiveSession(sessionBoulder.id));
          yield put(
            BouldersActions.updateBoulderValidationConditions(
              boulderId,
              result?.data.unvalidateBoulder.boulder.validationConditions
            )
          );
          yield put(CoachingActions.terminateSession());
        }
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *sendManyIncrementation({ payload }) {
    try {
      const { incrementAuditsData } = payload;
      const result = yield client.mutate({
        mutation: SendIncrementations,
        fetchPolicy: 'network-only',
        variables: {
          incrementAuditsData: {
            audits: incrementAuditsData,
          },
        },
      });
      if (!!result?.data) {
        yield put(CoachingActions.terminateSession());
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadAudits({ payload }) {
    try {
      const { boulderId, typeIds } = payload;
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const result = yield client.query({
        query: GetUserAudits,
        fetchPolicy: 'network-only',
        variables: {
          typeIds: typeIds,
          date,
          boulderIds: [boulderId],
        },
      });
      if (result?.data) {
        yield put(BouldersActions.getBoulderById(boulderId));
        yield put(AuditsActions.addAudits(result.data.getUserAudits, boulderId));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *logout() {
    try {
      yield put(AuditsActions.cleanAudits());
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(AuditsActions.sendUpdate.getType(), AuditsSagas.uploadUpdate),
      takeLatest(AuditsActions.sendClap.getType(), AuditsSagas.uploadClap),
      takeLatest(AuditsActions.requestAudits.getType(), AuditsSagas.loadAudits),
      takeEvery(AuditsActions.sendIncrementation.getType(), AuditsSagas.uploadIncrementation),
      takeEvery(AuditsActions.sendSingleValidation.getType(), AuditsSagas.uploadSingleValidation),
      takeEvery(
        AuditsActions.sendMultipleValidation.getType(),
        AuditsSagas.uploadMultipleValidation
      ),
      takeEvery(AuditsActions.sendManyIncrementation.getType(), AuditsSagas.sendManyIncrementation),
      takeEvery(AuditsActions.SendUnvalidateBoulder.getType(), AuditsSagas.SendUnvalidateBoulder),
      takeLatest(AppActions.requestAppLogout.getType(), AuditsSagas.logout),
    ]);
  }
}
