import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions as EditorActions } from './redux.js';

// Hooks
export * from './cropEditor/hooks';
export * from './display/hooks';
export * from './drawEditor/hooks';
export * from './tagEditor/hooks';
export * from './textEditor/hooks';
export * from './tools/hooks';

// Big hook
export const useEditor = () => {
  const dispatch = useDispatch();

  const clearEditor = useCallback(() => dispatch(EditorActions.clearEditor()), [dispatch]);
  return {
    clearEditor,
  };
};
