import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as CoachingsActions, selectors as CoachingSelector } from './redux.js';

export const useCoaching = () => {
  const dispatch = useDispatch();

  const sessionBoulders = useSelector(CoachingSelector.sessionBoulders);
  const isFetching = useSelector(CoachingSelector.isFetching);
  const isFullLoaded = useSelector(CoachingSelector.isFullLoaded);

  const getSessionBoulders = useCallback(
    (volume, time, roomId) =>
      dispatch(CoachingsActions.requestSessionBoulders(volume, time, roomId)),
    [dispatch]
  );
  const clearSession = useCallback(() => dispatch(CoachingsActions.clearSession()), [dispatch]);
  const cleanCoaching = useCallback(() => dispatch(CoachingsActions.cleanCoaching()), [dispatch]);
  const validateBoulder = useCallback(
    (boulderId) => dispatch(CoachingsActions.validateBoulder(boulderId)),
    [dispatch]
  );
  const terminateSession = useCallback(
    () => dispatch(CoachingsActions.terminateSession()),
    [dispatch]
  );

  return {
    sessionBoulders,
    isFetching,
    isFullLoaded,
    clearSession,
    cleanCoaching,
    terminateSession,
    validateBoulder,
    getSessionBoulders,
  };
};

export const useCoachingUpdate = () => {
  const dispatch = useDispatch();

  const nbOfSession = useSelector(CoachingSelector.nbOfSession);
  const updateSession = useCallback(
    (sessionId, boulderId, iscoaching, isvalidated) =>
      dispatch(CoachingsActions.updateSession(sessionId, boulderId, iscoaching, isvalidated)),
    [dispatch]
  );

  return {
    nbOfSession,
    updateSession,
  };
};

export const useCoachDate = () => {
  const dispatch = useDispatch();

  const coachDate = useSelector(CoachingSelector.coachDate);
  const setCoachDate = useCallback(
    (date) => dispatch(CoachingsActions.setCoachDate(date)),
    [dispatch]
  );

  return {
    coachDate,
    setCoachDate,
  };
};
