import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Hooks
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { useFormattedDate } from '../../redux/common/hooks';
import { useUserPerformance } from '../../redux/user/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useCoachingUpdate } from '../../redux/coaching/hooks';

// Components
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import Loader from '../../atoms/Loader/Loader';

// Styles & Utils
import './Levels.scss';
import { getPourcentage } from '../../utils/mathUtils';
import { specialitiesConfig } from '../../utils/chartConfig';

// Types
import SpeTypes from '../../types/SpecialityTypes';
import { Performance } from '../../utils/coach.interface';
import { useLogged } from '../../redux/auth/hooks';

interface Props {
  title: string;
  isLastSession: boolean;
  dateStart?: Date;
  dateEnd?: Date;
  styleOff?: boolean;
  isHome?: boolean;
}

const Levels: React.FC<Props> = ({
  title,
  isLastSession,
  dateStart,
  dateEnd,
  styleOff,
  isHome,
}) => {
  // Hooks
  const t = useLocalize();
  const { logged } = useLogged();
  const { nbOfSession } = useCoachingUpdate();
  const { roomId: selectedRoomId } = useSelectedRoom();
  const {
    lastSession,
    userPerf,
    userPerfCoach,
    isFetchingPerfs,
    isFetchingLastSession,
    requestUserPerf,
    requestLastSession,
  } = useUserPerformance();
  const date = useFormattedDate(
    dateStart ? new Date(dateStart) : new Date(),
    {
      year: 'numeric',
      month: 'long',
    },
    [dateStart]
  );
  const lastSessionDate = useFormattedDate(
    lastSession?.startDate ? new Date(lastSession?.startDate) : new Date(),
    {
      day: 'numeric',
      month: 'short',
    },
    [lastSession?.startDate]
  );
  const startHome = useFormattedDate(
    dateStart ? new Date(dateStart) : new Date(),
    {
      day: 'numeric',
      month: 'short',
    },
    [dateStart]
  );
  const endHome = useFormattedDate(
    dateEnd ? new Date(dateEnd) : new Date(),
    {
      day: 'numeric',
      month: 'short',
    },
    [dateEnd]
  );

  // States
  const [specialities, setSpecialities] = useState({
    prehensions: 0,
    physiques: 0,
    techniques: 0,
    deplacements: 0,
  });

  // Memos
  const backgroundStyle = useMemo(() => {
    if (styleOff) return { backgroundColor: '' };
    return { backgroundColor: '#f5f7fb' };
  }, [styleOff]);

  // Handlers
  const setPerf = useCallback(
    (tab: Performance[]) => {
      const total = tab.reduce((acc: number, { value }) => acc + value, 0);
      let prehensionsToSet = 0;
      let physiquesToSet = 0;
      let techniquesToSet = 0;
      let deplacementsToSet = 0;

      tab.forEach((perf: Performance) => {
        switch (perf.specialityId) {
          case SpeTypes.PREHENSION:
            prehensionsToSet = getPourcentage(perf.value, total);
            break;
          case SpeTypes.PHYSIQUE:
            physiquesToSet = getPourcentage(perf.value, total);
            break;
          case SpeTypes.TECHNIQUE:
            techniquesToSet = getPourcentage(perf.value, total);
            break;
          case SpeTypes.DEPLACEMENT:
            deplacementsToSet = getPourcentage(perf.value, total);
            break;
        }
      });
      setSpecialities((prevState: any) => ({
        ...prevState,
        prehensions: prehensionsToSet,
        physiques: physiquesToSet,
        techniques: techniquesToSet,
        deplacements: deplacementsToSet,
      }));
    },
    [setSpecialities]
  );

  // Effects
  useEffect(() => {
    if (logged && dateStart && dateEnd) {
      requestUserPerf(dateStart.toDateString(), dateEnd, selectedRoomId, isHome);
    } else if (logged && isLastSession) {
      requestLastSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedRoomId,
    dateStart,
    dateEnd,
    requestUserPerf,
    isLastSession,
    requestLastSession,
    nbOfSession,
  ]);

  useEffect(() => {
    if (!isLastSession && userPerf && isHome) setPerf(userPerf);
    else if (!isLastSession && userPerf && !isHome) setPerf(userPerfCoach);
    else if (isLastSession && lastSession?.sessionspecialities)
      setPerf(lastSession.sessionspecialities);
    else if (isLastSession && !lastSession) setPerf([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPerf, lastSession, userPerfCoach]);

  return (
    <div className='levelContent' style={backgroundStyle}>
      <div className='sectionName'>
        <div className='levelTitle'>{title}</div>
        {!isLastSession && !!dateEnd && !isHome && <span className='levelDate'>{date}</span>}
        {isLastSession && !!lastSession?.startDate && (
          <span className='levelDate'>{lastSessionDate}</span>
        )}
        {!!isHome && <div className='levelDate'>{startHome + ' - ' + endHome}</div>}
      </div>
      {((isFetchingPerfs && !specialitiesConfig) ||
        (isFetchingLastSession && !specialitiesConfig)) && <Loader />}
      <div className='levels'>
        {specialitiesConfig.map((item: any, key: number) => (
          <div className='levelItem' key={key}>
            <ProgressCircle
              color={item.backgroundColor}
              value={specialities[item.label]}
              styleOff={styleOff}
            />
            <div className='levelType'>{t(item.name)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Levels;
