import React from 'react';

import Icon from '../../../../atoms/Icon/Icon';

interface FooterItemProps {
  idx: number;
  onClick: any;
  disabled?: boolean;
  isSelected: boolean;
}

interface ItemProps {
  name: string;
  path: string;
  icon: string;
}

const FooterItem: React.FC<FooterItemProps & ItemProps> = React.memo(
  ({ idx, name, icon, onClick, isSelected, disabled }) => {
    return (
      <div key={idx} onClick={onClick} className='footer_content'>
        <div>
          <Icon icon={icon} className='footer_icon' disabled={disabled} />
          <div className={`footer_text ${disabled ? 'disabled' : ''}`}>{name}</div>
          {isSelected && <div className='dot'></div>}
        </div>
      </div>
    );
  }
);

export default FooterItem;
