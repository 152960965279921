// HOOKS
import { useLocalize } from '../../redux/translation/localize';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useBoulder, useBoulderValidationConditions } from '../../redux/boulders/hooks';

// Components
import Icon from '../../atoms/Icon/Icon';
import Loader from '../../atoms/Loader/Loader';
import OptimizedImage from '../../atoms/OptimizedImage/OptimizedImage';

// Utils
import './BoulderCard.scss';
import IconTypes from '../../types/IconTypes';
import { getDisplayDate } from '../../utils/dateTime';
import getBoulderColor from '../../utils/getBoulderColor';

interface Props {
  boulderId: string;
  selectedBouldersTop: any[];
  selectedBouldersFlash: any[];
  bouldersToValidate: any[];
  onValidateBoulder: Function;
  bouldersToFlash: any[];
  onFlashBoulder: Function;
  date?: Date;
  isOverlayOpen: Boolean;
  onOpenOverlay: Function;
}

const BoulderCard: React.FC<Props> = ({
  boulderId,
  selectedBouldersTop,
  selectedBouldersFlash,
  bouldersToValidate,
  onValidateBoulder,
  bouldersToFlash,
  onFlashBoulder,
  isOverlayOpen,
  onOpenOverlay,
}) => {
  const t = useLocalize();

  // Hooks
  const boulder: { [key: string]: any } = useBoulder(boulderId);
  const { color } = getBoulderColor(boulder?.color?.name);
  const { isFlashable, isValidable, messageParams } = useBoulderValidationConditions(boulderId);
  // Memos
  const flashStyle = useMemo(
    () => (bouldersToValidate?.includes(boulderId) || !isFlashable ? '' : 'opaque'),
    [boulderId, bouldersToValidate, isFlashable]
  );
  const topStyle = useMemo(
    () => (bouldersToFlash?.includes(boulderId) || !isValidable ? '' : 'opaque'),
    [boulderId, bouldersToFlash, isValidable]
  );
  const hasAudit = useMemo(
    () => !selectedBouldersTop?.includes(boulderId) && !selectedBouldersFlash?.includes(boulderId),
    [boulderId, selectedBouldersFlash, selectedBouldersTop]
  );

  // States
  const [popupDirection, setPopupDirection] = useState('onTop');
  const [displayInfo, setDisplayInfo] = useState<string | null>(null);

  useEffect(() => {
    if (!isOverlayOpen) setDisplayInfo(null);
  }, [isOverlayOpen]);

  const handleTop = useCallback(
    (event) => {
      if (event.clientY < window.innerHeight / 2) setPopupDirection('onBottom');
      else setPopupDirection('onTop');
      if (!bouldersToFlash?.includes(boulderId)) {
        if (isValidable) onValidateBoulder(boulderId);
        else if (messageParams) {
          setDisplayInfo('top');
          onOpenOverlay(true);
        }
      }
    },
    [bouldersToFlash, boulderId, messageParams, isValidable, onValidateBoulder, onOpenOverlay]
  );

  const handleFlash = useCallback(
    (event) => {
      if (event.clientY < window.innerHeight / 2) setPopupDirection('onBottom');
      else setPopupDirection('onTop');
      if (!bouldersToValidate?.includes(boulderId)) {
        if (isFlashable) onFlashBoulder(boulderId);
        else if (messageParams) {
          setDisplayInfo('flash');
          onOpenOverlay(true);
        }
      }
    },
    [bouldersToValidate, boulderId, messageParams, isFlashable, onFlashBoulder, onOpenOverlay]
  );

  if (!boulder) return null;

  return (
    <div className='boulderCard'>
      {!boulder?.picture && <Loader />}
      {boulder?.picture && (
        <>
          <OptimizedImage className='boulderPicture' src={boulder?.picture} format='fullScreen' />
          <div className='wallName'>{boulder?.sector?.name}</div>
          <div className='gradient' />
          {hasAudit && <div className='auditBackground' />}
          {hasAudit && (
            <div className='auditContainer'>
              {bouldersToValidate?.includes(boulderId) ? (
                <Icon
                  icon={IconTypes.CHECK_CIRCLE_FULL}
                  className='iconTopFull animation-zoomin'
                  onClick={handleTop}
                />
              ) : (
                <div className={`iconContainer ${topStyle}`} onClick={handleTop}>
                  {displayInfo === 'top' && (
                    <>
                      <div className={`popup ${popupDirection}`}>
                        {t('no_boulder_validation_top', {
                          status: t(messageParams.status),
                          date: getDisplayDate(new Date(messageParams.date)),
                        })}
                      </div>
                      <div className={`alertTick ${popupDirection}`} />
                    </>
                  )}
                  <Icon icon={IconTypes.VALIDATE} className={`iconTop ${topStyle}`} />
                </div>
              )}
              {bouldersToFlash?.includes(boulderId) ? (
                <Icon
                  icon={IconTypes.FLASH_CIRCLE_FULL}
                  className='iconFlashFull animation-zoomin'
                  onClick={handleFlash}
                />
              ) : (
                <div className={`iconContainer ${flashStyle}`} onClick={handleFlash}>
                  {displayInfo === 'flash' && (
                    <>
                      <div className={`popup ${popupDirection}`}>
                        {t('no_boulder_validation_flash', {
                          status: t(messageParams.status),
                          date: getDisplayDate(new Date(messageParams.date)),
                        })}
                      </div>
                      <div className={`alertTick ${popupDirection}`} />
                    </>
                  )}
                  <Icon icon={IconTypes.BLOCS} className={`iconFlash ${flashStyle}`} />
                </div>
              )}
            </div>
          )}
          {selectedBouldersTop?.includes(boulder.id) && (
            <div className='validated'>
              <Icon
                icon={IconTypes.CHECK_CIRCLE_FULL}
                className='iconTopFull disabled animation-zoomin'
                onClick={handleTop}
              />
            </div>
          )}
          {selectedBouldersFlash?.includes(boulder.id) && (
            <div className='validated'>
              <Icon
                icon={IconTypes.FLASH_CIRCLE_FULL}
                className='iconFlashFull disabled animation-zoomin'
                onClick={handleTop}
              />
            </div>
          )}
          <div className='boulderColor' style={{ backgroundColor: color }}></div>
        </>
      )}
    </div>
  );
};

export default BoulderCard;
