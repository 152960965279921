import React, { useMemo } from 'react';
import { Format, MediaStore, Resolution } from '../../utils/media-store';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement>;

export type OptimizedImageProps = ImageProps & {
  src: string;
  format: Format;
  className?: string;
  resolution?: Resolution;
};

const OptimizedImage = ({ src, resolution = 'hd', format, ...rest }: OptimizedImageProps) => {
  const cdnUrl = useMemo(
    () => src && MediaStore.getCdnUrl(src, format, resolution),
    [format, resolution, src]
  );

  // since we pass rest props we have the ability to pass in an alt tag
  // so we can disable the rule here
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...rest} src={cdnUrl} crossOrigin='anonymous' />;
};

export default OptimizedImage;
