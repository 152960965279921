import { useEffect, useState } from 'react';
import { Keyboard } from '@capacitor/keyboard';

const useKeyboard = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const onKeyboardWillShow = (event: any) => {
      setKeyboardHeight(event.keyboardHeight);
    };

    const onKeyboardWillHide = () => {
      setKeyboardHeight(0);
    };

    Keyboard.addListener('keyboardWillShow', onKeyboardWillShow);
    Keyboard.addListener('keyboardWillHide', onKeyboardWillHide);

    return () => {
      Keyboard.removeAllListeners();
    };
  }, []);

  return keyboardHeight;
};

export default useKeyboard;
