import React, { useCallback, useEffect, useRef, useState } from 'react';

// Components
import { IonPage } from '@ionic/react';
import Icon from '../../atoms/Icon/Icon';
import Connection from './partials/Connection/Connection';

// Hooks
import { useNavigate } from 'react-router-dom';
import { useAuth, useOnboarding } from '../../redux/auth/hooks';
import { useLocalize } from '../../redux/translation/localize';

// Style & Config
import './Login.scss';
import ROUTES from '../../routes/constants';
import { trackEvent, trackPage } from '../../providers/tracker';
import { event_special } from '../../providers/firebase/event_special_params';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { isAndroid } from 'react-device-detect';

enum LoginStatus {
  DEFAULT = 0,
  LOGIN = 1,
  QRCODE = 2,
}

const Login: React.FC = () => {
  const t = useLocalize();
  const navigate = useNavigate();
  const { requestLoginQrCode } = useAuth();
  const { skipOnboarding } = useOnboarding();

  const [hide, setHide] = useState(false);
  const [status, setStatus] = useState(LoginStatus.DEFAULT);

  const listener = useRef<any>();

  const handleSkipLogin = useCallback(() => {
    if (status === LoginStatus.DEFAULT) {
      navigate(ROUTES.HOME);
      skipOnboarding(true);
    } else {
      setStatus(LoginStatus.DEFAULT);
    }
  }, [navigate, skipOnboarding, status]);

  const handleStopQrCode = useCallback(async () => {
    setHide(false);
    // Remove all listeners
    await BarcodeScanner.removeAllListeners();
    // Stop the barcode scanner
    await BarcodeScanner.stopScan();
  }, []);

  const handleResponse = useCallback(
    (isOnboarded: boolean, errorMessage?: string) => {
      if (!errorMessage) {
        if (!isOnboarded) navigate(ROUTES.ONBOARDING_GOALS);
        else {
          navigate('/');
        }
      } else {
        alert(t(`CODE_${errorMessage}`));
      }
    },
    [navigate, t]
  );

  const handleQrCode = useCallback(async () => {
    try {
      const { supported } = await BarcodeScanner.isSupported();
      if (!supported) {
        alert(t('not_supported'));
        return;
      }

      if (isAndroid) {
        const { available } = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
        if (!available) {
          await BarcodeScanner.installGoogleBarcodeScannerModule();
        }
      }

      const { camera } = await BarcodeScanner.checkPermissions();
      if (camera === 'denied' || camera !== 'granted') {
        const { camera: granted } = await BarcodeScanner.requestPermissions();
        if (granted === 'denied' || granted !== 'granted') {
          await alert(t('camera_access'));
          await BarcodeScanner.openSettings();
          return;
        }
      }

      // Start Scan
      setHide(true);

      if (!!listener.current) {
        listener.current?.remove?.();
      }

      listener.current = await BarcodeScanner.addListener('barcodeScanned', async (result) => {
        await listener.current?.remove?.();
        setHide(false);
        await BarcodeScanner.stopScan();

        if (result?.barcode?.displayValue) {
          const [roomId, code] = result.barcode.displayValue.split(';');

          if (!roomId || !code) {
            alert(t('QrCode_wrong'));
          } else {
            requestLoginQrCode(code, handleResponse);
          }
        } else {
          alert(t('QrCode_wrong'));
        }
      });
      await BarcodeScanner.startScan();
    } catch (err) {
      console.error(err);
    }
  }, [handleResponse, requestLoginQrCode, t]);

  const handleLogin = useCallback(() => {
    trackEvent(event_special.onboarding_landing_page, {
      event_category: event_special.onboarding_landing_page,
      event_action: 'connect',
      event_label: t('login_account'),
    });
    setStatus(LoginStatus.LOGIN);
  }, [t]);

  const handleCreateAccount = useCallback(() => {
    trackEvent(event_special.onboarding_landing_page, {
      event_category: event_special.onboarding_landing_page,
      event_action: 'createAccount',
      event_label: t('login_access_account'),
    });
    navigate(ROUTES.ONBOARDING_GOALS);
  }, [t, navigate]);

  useEffect(() => {
    if (status === LoginStatus.LOGIN) {
      trackPage('/login2');
    }
  }, [status]);

  return (
    <IonPage className={`LoginPage ${hide ? 'hide' : ''}`}>
      {!hide && (
        <>
          {status === LoginStatus.DEFAULT && (
            <div className='login'>
              <div className='LoginHeader'>
                <h1>{t('login_title')}</h1>
                <p>{t('login_subtitle')}</p>
              </div>
              <div className=''>
                <div className='contentActions'>
                  <button className='white' onClick={handleQrCode}>
                    {t('login_qr_code')}
                  </button>
                  <button className='white' onClick={handleLogin}>
                    {t('login_account')}
                  </button>
                  <button className='white' onClick={handleSkipLogin}>
                    {t('login_skip')}
                  </button>
                  <button onClick={handleCreateAccount}>{t('login_access_account')}</button>
                </div>
              </div>
            </div>
          )}
          {status === LoginStatus.LOGIN && <Connection goBack={handleSkipLogin} />}
          <div className='loginFooter'>
            <Icon icon='buddy-boulder' />
            <p>{t('login_footer')}</p>
          </div>
        </>
      )}
      {hide && (
        <div className='ScanQrCode'>
          <div className='head'>
            <div className='closeIcon' onClick={handleStopQrCode}>
              <Icon icon='close' />
            </div>
          </div>
          <div className='spacer'>
            <span>{t('scan_code')}</span>
            <div className='rectangle' />
          </div>
          <div className='loginFooter'>
            <Icon icon='buddy-boulder' />
            <p>{t('login_footer')}</p>
          </div>
        </div>
      )}
    </IonPage>
  );
};

export default Login;
