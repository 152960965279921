import { StickerType } from '../type';

import './StickersBackdrop.scss';
import StickerItem from '../StickerItem/StickerItem';

interface Props {
  selected: number;
  stickers: StickerType[];
  onClick: (index: number) => void;
  onChange: (sticker: StickerType | null, index: number) => void;
}

const StickersBackdrop: React.FC<Props> = ({ stickers, selected, onClick, onChange }) => {
  return (
    <div id='StickersBackdrop'>
      {stickers.map((sticker, index) => (
        <StickerItem
          index={index}
          sticker={sticker}
          selected={selected === index}
          onClick={onClick}
          onChange={onChange}
          key={`${sticker.path}_${index}`}
        />
      ))}
    </div>
  );
};

export default StickersBackdrop;
