import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { selectors as UserSelectors } from '../user/redux.js';
import { actions as RoomsActions, selectors as RoomsSelector } from './redux.js';
import { trackEvent } from '../../providers/tracker';

export const usePreferredRoom = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => UserSelectors.currentUser(state));
  const roomId = useMemo(
    () => currentUser?.mindFlurryUser?.roomId || 0,
    [currentUser?.mindFlurryUser?.roomId]
  );

  const room = useSelector((state) => RoomsSelector.roomById(state, roomId));

  const requestRoomById = useCallback(
    () => dispatch(RoomsActions.requestRoomById(roomId)),
    [dispatch, roomId]
  );

  useEffect(() => {
    if (!room && roomId && !!currentUser?.mindFlurryUser) {
      requestRoomById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, roomId]);

  return {
    currentUser,
    room,
  };
};

export const useRoom = (roomId) => {
  const dispatch = useDispatch();

  const room = useSelector((state) => RoomsSelector.roomById(state, roomId));

  const requestRoomById = useCallback(
    () => dispatch(RoomsActions.requestRoomById(roomId)),
    [dispatch, roomId]
  );

  useEffect(() => {
    if (!room && roomId) requestRoomById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, roomId]);

  return {
    room,
  };
};

export const useRooms = () => {
  const dispatch = useDispatch();

  const roomByIds = useSelector((state) => RoomsSelector.roomByIds(state));
  const roomIds = useSelector((state) => RoomsSelector.roomIds(state));

  const requestRooms = useCallback(() => dispatch(RoomsActions.requestRooms()), [dispatch]);

  return {
    roomIds,
    requestRooms,
    roomById: roomByIds,
  };
};

export const useSelectedRoom = () => {
  const dispatch = useDispatch();

  const selectedRoomId = useSelector((state) => RoomsSelector.selectedRoomId(state));
  const room = useSelector((state) => RoomsSelector.roomById(state, selectedRoomId));

  const requestRoomById = useCallback(
    () => dispatch(RoomsActions.requestRoomById(selectedRoomId)),
    [dispatch, selectedRoomId]
  );

  useEffect(() => {
    if (!room && selectedRoomId) requestRoomById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, selectedRoomId]);

  return {
    roomId: selectedRoomId,
    room,
  };
};

export const useSelectedWall = () => {
  const selectedWallId = useSelector((state) => RoomsSelector.selectedWallId(state));
  return { selectedWallId };
};

export const useSelectedSector = () => {
  const selectedSectorId = useSelector((state) => RoomsSelector.selectedSectorId(state));
  return { selectedSectorId };
};

export const useFullRoomSelection = () => {
  const dispatch = useDispatch();

  const selectedRoomId = useSelector((state) => RoomsSelector.selectedRoomId(state));
  const selectedWallId = useSelector((state) => RoomsSelector.selectedWallId(state));
  const selectedSectorId = useSelector((state) => RoomsSelector.selectedSectorId(state));

  const setSelectedRoomId = useCallback(
    (id) => {
      trackEvent('room_change', { roomId: id });
      dispatch(RoomsActions.setSelectedRoomId(id));
    },
    [dispatch]
  );
  const setSelectedWallId = useCallback(
    (id) => {
      trackEvent('zone_change', { zoneId: id, comment: 'Zone is Wall' });
      dispatch(RoomsActions.setSelectedWallId(id));
    },
    [dispatch]
  );
  const setSelectedSectorId = useCallback(
    (id) => {
      trackEvent('sector_change', { sectorId: id });
      dispatch(RoomsActions.setSelectedSectorId(id));
    },
    [dispatch]
  );

  return {
    selectedRoomId,
    selectedWallId,
    selectedSectorId,
    setSelectedRoomId,
    setSelectedWallId,
    setSelectedSectorId,
  };
};
