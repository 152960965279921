import { useMemo } from 'react';
import { IonIcon } from '@ionic/react';
import { getVideoTime } from '../../utils/getVideoTimes';
import { chevronBack, close, pause } from 'ionicons/icons';

import './VideoHeader.scss';

interface Props {
  children?: any;
  granted?: boolean;
  playing?: boolean;
  isCamera?: boolean;
  videoTime?: number;
  videoConstraints?: any;
  onBack?: () => void;
  onClose?: () => void;
  onSwitchView?: (ev: any) => void;
}

const VideoHeader: React.FC<Props> = ({
  granted = true,
  playing,
  isCamera,
  videoTime,
  children,
  onBack,
  onClose,
}) => {
  const videoTimeValue = useMemo(() => videoTime && getVideoTime(videoTime), [videoTime]);

  if (isCamera) {
    return (
      <div id='VideoHeader'>
        <div className='button' onClick={onBack}>
          <IonIcon icon={chevronBack} />
        </div>
        {granted && !!videoTimeValue && <div className='videoTimer'>{videoTimeValue}</div>}
      </div>
    );
  }

  return (
    <div id='VideoHeader'>
      {!!onBack && (
        <div className='button' onClick={onBack}>
          <IonIcon icon={chevronBack} />
        </div>
      )}

      {children && <div className='children'>{children}</div>}
      {!playing && (
        <div className='smallStatusIndicator'>
          <IonIcon icon={pause} />
        </div>
      )}

      {!!onClose && (
        <div className='button' onClick={onClose}>
          <IonIcon icon={close} />
        </div>
      )}
    </div>
  );
};

export default VideoHeader;
