import { useEffect, useRef, useState } from 'react';
import { useQRCodeFlash } from '../../providers/qr-code-flash.provider';
import { useLocalize } from '../../redux/translation/localize';
import './NotifyQrScan.scss';

const NotifyQrScan = () => {
  const t = useLocalize();
  const qrCodeFlash = useQRCodeFlash();

  const [isOpen, setIsOpen] = useState(false);
  const timeoutClose = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (qrCodeFlash.roomId) {
      setIsOpen(true);
      if (timeoutClose.current) {
        clearTimeout(timeoutClose.current);
      }

      timeoutClose.current = setTimeout(() => {
        setIsOpen(false);
        clearTimeout(timeoutClose.current);
        timeoutClose.current = undefined;
      }, 3000);
    }

    return () => {
      if (timeoutClose.current) {
        clearTimeout(timeoutClose.current);
      }
    };
  }, [qrCodeFlash.roomId, timeoutClose]);

  return (
    <div className={`notifyQrScan ${isOpen ? 'open' : ''}`}>
      <div className='welcome'>{t('welcome')}</div>
      <div className='title'>
        {qrCodeFlash?.roomId &&
          `${t('connected_to')} ${t(`club_${qrCodeFlash?.mindflurryRoomId}`)}`}
      </div>
    </div>
  );
};

export default NotifyQrScan;
