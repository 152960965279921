import { useNavigate } from 'react-router';
import useKeyboard from '../../utils/keyboard';
import { useCreateAccount } from '../../redux/account/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IonCheckbox, IonPage } from '@ionic/react';
import Icon from '../../atoms/Icon/Icon';
import Input from '../../atoms/Input/Input';
import DatePickerModal from '../../components/DatePickerModal/DatePickerModal';

import './CreateAccount.scss';
import ROUTES from '../../routes/constants';
import IconTypes from '../../types/IconTypes';
import { trackEvent } from '../../providers/tracker';
import { getDisplayDate, getUTCString } from '../../utils/dateTime';

type UserInfo = {
  first_name: string;
  last_name: string;
  email_address: string;
  birth_date: string;
  date: string;
  username: string;
  password: string;
  cgvaccepted?: boolean;
  rgpd_sms?: boolean;
  rgpd_directemails?: boolean;
  rgpd_notifications_byemail?: boolean;
};

const CreateAccount: React.FC<unknown> = () => {
  const t = useLocalize();
  const navigate = useNavigate();
  const keyboardHeight = useKeyboard();
  const { requestCreateAccount } = useCreateAccount();

  // States
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [info, setInfo] = useState<UserInfo>({
    first_name: '',
    last_name: '',
    email_address: '',
    date: 'Jan 01 2000 00:00:00 GMT+0100',
    birth_date: '2000-01-01',
    username: '',
    password: '',
  });

  // Memos
  const displayValue = useMemo(() => getDisplayDate(new Date(info.date)), [info.date]);
  const actionIsDisabled = useMemo(
    () =>
      !info.username.length ||
      !info.password.length ||
      !info.email_address.length ||
      !info.birth_date ||
      !info.first_name.length ||
      !info.last_name.length ||
      !info.cgvaccepted,
    [
      info.username.length,
      info.password.length,
      info.email_address.length,
      info.birth_date,
      info.first_name.length,
      info.last_name.length,
      info.cgvaccepted,
    ]
  );

  // Callback
  const handleResponse = useCallback((error?: string) => {
    if (error) {
      setErrorMessage(error);
    } else {
      window.location.pathname = ROUTES.HOME;
    }
  }, []);

  const handleCreateAccount = useCallback(() => {
    if (!actionIsDisabled) {
      setErrorMessage(undefined);
      requestCreateAccount(info, handleResponse);
    }
    trackEvent('onboarding_create_account_step_2', {
      event_category: 'create_account',
      event_action: 'create_account',
      event_label: 'step_2',
    });
  }, [actionIsDisabled, handleResponse, info, requestCreateAccount]);

  const handleChange = useCallback(
    (e, _, valueKey) => setInfo((prev) => ({ ...prev, [valueKey]: e.target.value || '' })),
    []
  );

  const handleDate = useCallback((e: CustomEvent) => {
    const date = getUTCString(new Date(e.detail.value));
    setInfo((prev) => ({ ...prev, birth_date: date, date: e.detail.value }));
  }, []);

  const showDatePicker = useCallback(() => setIsDatePickerOpen(true), []);
  const hideDatePicker = useCallback(() => setIsDatePickerOpen(false), []);

  const handleTogglePassword = useCallback(() => setShowPassword((prev) => !prev), []);

  const handleCGV = useCallback(
    () => setInfo((prev) => ({ ...prev, cgvaccepted: !prev.cgvaccepted })),
    []
  );

  const handleNewsletter = useCallback(
    () =>
      setInfo((prev) => ({
        ...prev,
        rgpd_directemails: !prev.rgpd_directemails,
        rgpd_notifications_byemail: !prev.rgpd_notifications_byemail,
      })),
    []
  );

  const handleSMS = useCallback(
    () => setInfo((prev) => ({ ...prev, rgpd_sms: !prev.rgpd_sms })),
    []
  );

  const handleCGVLink = useCallback(
    () => window.open('https://climbingdistrict.com/conditions-generales-de-vente/'),
    []
  );
  const handleGoBack = useCallback(() => {
    trackEvent('back', { path: 'onboarding' });
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (!!keyboardHeight) {
      try {
        const elem = document.activeElement;
        if (elem) {
          elem?.scrollIntoView?.({ behavior: 'smooth' });
        }
      } catch (err) {
        console.warn(err);
      }
    }
  }, [keyboardHeight]);

  return (
    <IonPage
      className='CreateAccount'
      style={keyboardHeight ? { paddingBottom: `${keyboardHeight / 4}px` } : undefined}
    >
      <div className='LoginHeader'>
        <h1>{t('create_account_title')}</h1>
        <p>{t('create_account_subtitle')}</p>
        <div className='skipOnboarding' onClick={handleGoBack}>
          <Icon icon='close' />
        </div>
      </div>
      <div className='createAccountBox'>
        <Input
          type='text'
          valueKey='first_name'
          value={info.first_name}
          placeholder={t('firstname')}
          onChange={handleChange}
        />
        <Input
          type='text'
          valueKey='last_name'
          value={info.last_name}
          placeholder={t('lastname')}
          onChange={handleChange}
        />
        <Input
          type='email'
          valueKey='email_address'
          value={info.email_address}
          placeholder={t('email')}
          onChange={handleChange}
        />
        <div className='userInfoForm'>
          <input
            type='text'
            value={displayValue || ''}
            placeholder='Date de naissance'
            onClick={showDatePicker}
            readOnly
          />
          <DatePickerModal
            isOpen={isDatePickerOpen}
            onDismiss={hideDatePicker}
            chosenDate={info.date}
            onChangeDate={handleDate}
          />
        </div>
        <Input
          type='text'
          valueKey='username'
          value={info.username}
          placeholder={t('username')}
          onChange={handleChange}
        />
        <div className='loginPassword'>
          <Input
            type={showPassword ? 'text' : 'password'}
            className='inputLogin'
            value={info.password}
            valueKey='password'
            placeholder={t('password_fr')}
            onChange={handleChange}
            rightIcon={!showPassword ? IconTypes.EYE : IconTypes.EYE_SLASH}
            onClickIcon={handleTogglePassword}
          />
        </div>
        {!!errorMessage && <span className='loginError'>{t(errorMessage)}</span>}
        <div className='checkboxesBox'>
          <div className='checkboxItem'>
            <IonCheckbox
              value={info.cgvaccepted}
              checked={info.cgvaccepted}
              onIonChange={handleCGV}
            />
            <div className='checkboxLabel'>
              {t('cgv_accept_start')}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={handleCGVLink}>{t('cgv_accept_link')}</a>
              {t('cgv_accept_end')}
            </div>
          </div>
          <div className='checkboxItem'>
            <IonCheckbox
              value={info.rgpd_directemails}
              checked={info.rgpd_directemails}
              onIonChange={handleNewsletter}
            />
            <div className='checkboxLabel'>{t('cgv_newsletter')}</div>
          </div>
          <div className='checkboxItem'>
            <IonCheckbox value={info.rgpd_sms} checked={info.rgpd_sms} onIonChange={handleSMS} />
            <div className='checkboxLabel'>{t('cgv_sms')}</div>
          </div>
        </div>
        <button
          disabled={actionIsDisabled}
          className='contentLoginBtn'
          onClick={handleCreateAccount}
        >
          {t('continue')}
        </button>
      </div>
      <div className='loginFooter'>
        <Icon icon='buddy-boulder' />
        <p>{t('login_footer')}</p>
      </div>
    </IonPage>
  );
};

export default CreateAccount;
