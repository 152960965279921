import {
  appAnalyticsTrackEvent,
  appAnalyticsTrackPage,
  appAnalyticsTrackUserLogged,
} from './applicationInsight';
import {
  firebaseTrackEvent,
  firebaseTrackPage,
  firebaseTrackUserLogged,
} from './firebase/firebase';

export function trackPage(path: string) {
  firebaseTrackPage(path);
  appAnalyticsTrackPage(path);
}

export function trackUserLogged(userId: string) {
  firebaseTrackUserLogged(userId);
  appAnalyticsTrackUserLogged(userId);
}

export function trackEvent(name: any, params: any = undefined) {
  firebaseTrackEvent(name, params);
  appAnalyticsTrackEvent(name, params);
}
