// Hooks
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  IonPage,
  IonButton,
  IonLoading,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import { useLogged } from '../../redux/auth/hooks';
import { useFilter } from '../../redux/filters/hooks';
import { useLocation, useNavigate } from 'react-router';
import { useCoaching } from '../../redux/coaching/hooks';
import { useFormattedDate } from '../../redux/common/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useBoulderValidation } from '../../redux/audits/hooks';
import { useSelectedRoom, useSelectedWall } from '../../redux/rooms/hooks';
import { useBoulders, useBouldersDetails } from '../../redux/boulders/hooks';
import { useActiveSession, useSessionById, useSessions } from '../../redux/sessions/hooks';

// Components
import EndCoach from '../../components/EndCoach/EndCoach';
import Header from '../../routes/components/Header/Header';
import ColorCard from '../../components/ColorCard/ColorCard';
import BoulderSelector from '../../components/BoulderSelector/BoulderSelector';

// Styles & Utils
import './CoachEditing.scss';
import ROUTES from '../../routes/constants';
import wall from '../../utils/wall.interface';
import { isDateToday } from '../../utils/dateTime';
import { trackEvent } from '../../providers/tracker';

const FILTERS = [
  { name: 'Tous', id: 1 },
  { name: 'Mes blocs', id: 2 },
];

const CoachEditing: React.FC = () => {
  // Props
  const location = useLocation();
  const navigate = useNavigate();
  // Hooks
  const { sessionDate } = useMemo(
    () => ({
      sessionDate: location.state?.sessionDate,
    }),
    [location.state]
  );
  const t = useLocalize();
  const { logged } = useLogged();
  const { room } = useSelectedRoom();
  const { selectedWallId } = useSelectedWall();
  const { sendMultipleValidation } = useBoulderValidation();
  const {
    getBouldersByWall,
    boulderList,
    isFullLoaded,
    isFetching,
    isFetchingValidatedBoulder,
    isFullLoadedValidatedBoulder,
    getMyValidatedBoulders,
    validatedBoulder,
  } = useBoulders();

  const { clearSession } = useCoaching();
  const { colors, getFilters } = useFilter();
  const { requestActiveSession, activeSession } = useActiveSession();
  const { requestDailySession, dailySessionId } = useSessions();
  const { session } = useSessionById(dailySessionId);
  const date = useMemo(
    () => sessionDate || new Date(activeSession?.startDate),
    [sessionDate, activeSession]
  );
  const { getBouldersDetails } = useBouldersDetails(date);

  // States
  const [end, setEnd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedColors, setSelectedColors] = useState<number[]>([]);
  const [bouldersToValidate, setBouldersToValidate] = useState<number[]>([]);
  const [bouldersToFlash, setBouldersToFlash] = useState<number[]>([]);
  const [selectedFilter, setSelectedFilter] = useState(FILTERS[0].id);

  // Memos
  const currentWall = useMemo(
    () => room?.walls.find((wall: wall) => wall.id === selectedWallId),
    [selectedWallId, room?.walls]
  );
  const updateDate = useFormattedDate(date ? date : new Date(), { day: 'numeric', month: 'long' }, [
    date,
  ]);
  const finishMsg = useMemo(() => {
    if (date) return t('coach_finish_date', { date: updateDate });
    return t('coach_finish');
  }, [date, t, updateDate]);

  const { validatedBlocs, flashedBlocs } = useMemo(() => {
    if ((!date || isDateToday(date)) && activeSession) {
      return {
        flashedBlocs: activeSession?.sessionboulders?.map((boulder) => {
          if (boulder.status === 'FLASH') return boulder.boulderId;
          return undefined;
        }),
        validatedBlocs: activeSession?.sessionboulders?.map((boulder) => {
          if (boulder.status === 'TOP') return boulder.boulderId;
          return undefined;
        }),
      };
    } else if (session) {
      return {
        flashedBlocs: session.sessionboulders?.map((boulder) => {
          if (boulder.status === 'FLASH') return boulder.boulderId;
          return undefined;
        }),
        validatedBlocs: session.sessionboulders?.map((boulder) => {
          if (boulder.status === 'TOP') return boulder.boulderId;
          return undefined;
        }),
      };
    }
    return {
      validatedBlocs: [],
      flashedBlocs: [],
    };
  }, [activeSession, date, session]);

  const bouldersToShow = useMemo(() => {
    if (selectedFilter === 1) return boulderList;
    else if (selectedFilter === 2) {
      return validatedBoulder;
    }
  }, [selectedFilter, boulderList, validatedBoulder]);

  const handleBack = useCallback(() => {
    clearSession();
    setSelectedFilter(0);
    if (date) {
      setBouldersToValidate([]);
      setBouldersToFlash([]);
    }
    navigate(ROUTES.COACH);
    trackEvent('back', { path: 'coach_add' });
  }, [clearSession, date, navigate]);

  const endSession = useCallback(() => {
    setLoading(true);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let boulders: any = [];
    bouldersToValidate.forEach((boulder: any) => {
      const objAudit = {
        id: boulder,
        status: 'TOP',
      };
      boulders.push(objAudit);
    });
    bouldersToFlash.forEach((boulder: any) => {
      const objAudit = {
        id: boulder,
        status: 'FLASH',
      };
      boulders.push(objAudit);
    });
    if (boulders.length) {
      trackEvent('coach_added_more', { blocks: boulders });
      sendMultipleValidation(room?.id, boulders, date, date ? dailySessionId : activeSession?.id);
    }
    setLoading(false);
    setBouldersToValidate([]);
    setBouldersToFlash([]);
    trackEvent('coach_complete');
    if (date) {
      clearSession();
      setSelectedFilter(0);
      if (date) {
        setBouldersToValidate([]);
        setBouldersToFlash([]);
      }
      navigate(ROUTES.COACH);
    } else setEnd(true);
  }, [
    bouldersToValidate,
    bouldersToFlash,
    date,
    sendMultipleValidation,
    room?.id,
    dailySessionId,
    activeSession?.id,
    clearSession,
    navigate,
  ]);

  const hideEnd = useCallback(() => {
    setEnd(false);
  }, []);

  const addColor = useCallback(
    (id: number) => {
      if (selectedColors.includes(id)) {
        const temp = [...selectedColors];
        setSelectedColors(temp.filter((el) => el !== id));
      } else {
        setSelectedColors((prevState: number[]) => [...prevState, id]);
      }
    },
    [setSelectedColors, selectedColors]
  );

  const handleScroll = useCallback(
    (e) => {
      if (room?.id) {
        if (
          (selectedFilter === 1 && isFullLoaded) ||
          isFetching ||
          (selectedFilter === 2 && isFullLoadedValidatedBoulder) ||
          isFetchingValidatedBoulder
        ) {
          e.target.complete();
          return;
        }
        if (selectedFilter === 1) {
          getBouldersByWall({
            roomIds: room.id,
            wallIds: currentWall?.id,
            colorIds: selectedColors?.length ? selectedColors : undefined,
            date,
            skip: true,
          });
        } else if (selectedFilter === 2) {
          getMyValidatedBoulders({
            date,
            roomIds: room.id,
            wallIds: currentWall?.id,
            colorIds: selectedColors?.length ? selectedColors : undefined,
            skip: true,
          });
        }
        e.target.complete();
      }
    },
    [
      room.id,
      selectedFilter,
      isFullLoaded,
      isFetching,
      isFullLoadedValidatedBoulder,
      isFetchingValidatedBoulder,
      getBouldersByWall,
      currentWall?.id,
      selectedColors,
      date,
      getMyValidatedBoulders,
    ]
  );

  useEffect(() => {
    if (room?.id && selectedFilter === 2) {
      getMyValidatedBoulders({
        date,
        roomIds: room.id,
        wallIds: currentWall?.id,
        colorIds: selectedColors?.length ? selectedColors : undefined,
        skip: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, currentWall, selectedColors, date, room]);

  useEffect(() => {
    if (room?.id) {
      getBouldersByWall({
        roomIds: room.id,
        wallIds: currentWall?.id,
        colorIds: selectedColors?.length ? selectedColors : undefined,
        date,
        skip: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWall, selectedColors, date, room]);

  useEffect(() => {
    if (boulderList.length) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
    }
  }, [boulderList, date]);

  useEffect(() => {
    getBouldersDetails(bouldersToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bouldersToShow]);

  useEffect(() => {
    if (!colors) getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors]);

  useEffect(() => {
    if (logged && room?.id && !date) {
      requestActiveSession(room.id);
    } else if (logged && room?.id && date) {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + 1);
      requestDailySession(room?.id, date.toDateString(), newDate.toDateString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, date, logged]);

  // OVERLAY
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const openOverlay = useCallback(() => {
    setIsOverlayOpen(true);
  }, []);

  const closeOverlay = useCallback(() => {
    setIsOverlayOpen(false);
  }, []);

  return (
    <IonPage className='coachEditionPage'>
      <>
        <Header
          isCoach
          goBack={handleBack}
          showRoom
          showProfile={false}
          showMonth={false}
          showWalls
          showSectors={false}
          updateDate={date}
          showFilter
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          disableRoom
        />
        <IonContent className='coachEditionContent' id='coachEditionContent'>
          <div className='mainBody'>
            <div className='colorFilter'>
              <div className='sectionHintColor'>{t('select_color')}</div>
              <div className='filtreColor'>
                {colors.map((col: any, index: number) => (
                  <ColorCard
                    colorName={col.name}
                    index={col.id}
                    key={index}
                    selectIndex={addColor}
                    selectedIndex={selectedColors.includes(col.id) ? col.id : 0}
                    multiple
                  />
                ))}
              </div>
            </div>
            <div className='filteredBloc'>
              <div className='sectionHintBoulders'>{t('select_boulder')}</div>
              {!!bouldersToShow?.length && (
                <BoulderSelector
                  boulderIds={bouldersToShow}
                  selectedBouldersTop={validatedBlocs}
                  selectedBouldersFlash={flashedBlocs}
                  bouldersToValidate={bouldersToValidate}
                  onValidateBoulder={setBouldersToValidate}
                  bouldersToFlash={bouldersToFlash}
                  onFlashBoulder={setBouldersToFlash}
                  date={date}
                  isOverlayOpen={isOverlayOpen}
                  onOpenOverlay={openOverlay}
                />
              )}
            </div>
            <IonInfiniteScroll onIonInfinite={handleScroll}>
              <IonInfiniteScrollContent
                loadingText='Please wait...'
                loadingSpinner='bubbles'
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </div>
        </IonContent>
        <IonButton className='terminateButton' onClick={endSession}>
          {finishMsg}
        </IonButton>
        {end && <EndCoach room={room} noAddPossibility onDismiss={setEnd} />}
        {end && <div className='endBackground' onClick={hideEnd} />}
        <IonLoading cssClass='custom-loader-class' isOpen={loading} message={''} />
      </>
      {isOverlayOpen && <div className='overlay' onClick={closeOverlay} />}
    </IonPage>
  );
};

export default CoachEditing;
