import React, { useCallback, useMemo } from 'react';
import { color } from '../../../utils/filter.interface';

import './Difficulty.scss';
import { getPourcentage } from '../../../utils/mathUtils';
import getBoulderColor from '../../../utils/getBoulderColor';

interface Props {
  item: color;
  selectedColor: number;
  total: number;
  selectColor: Function;
}

const Difficulty: React.FC<Props> = ({ item, selectedColor, total, selectColor }) => {
  const { color, borderColor } = getBoulderColor(item.name);
  const height = (item.count / total) * 3.25 + 1.37;
  const value = getPourcentage(item.count, total);
  const styleSelected = useMemo(
    () => ({ background: item.id === selectedColor ? borderColor : 'transparent' }),
    [borderColor, item.id, selectedColor]
  );

  const handleChange = useCallback(() => {
    selectColor(item.id);
  }, [item.id, selectColor]);

  return (
    <div className='difficulty' style={styleSelected}>
      <div
        className='difficultyBox'
        style={{
          background: color,
          height: `${height}rem`,
        }}
        onClick={handleChange}
      >
        <div className='percent'>{value.toString()}</div>
      </div>
    </div>
  );
};

export default Difficulty;
