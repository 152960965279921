import React, { useCallback, useEffect, useRef } from 'react';
import Hammer from 'hammerjs';

// Hooks
import { useDisplayItems, useEditorTools } from '../../../../../../redux/editor/hooks';

// Components
import Icon from '../../../../../../atoms/Icon/Icon';
import icons from '../../../../../../types/IconTypes';
import IconButton from '../../../IconButton/IconButton';

// Utils
import PixiApp from '../../../../utils/PixiApp';

const MAX_ZOOM_SCALE = 5;

const ManipulationToolbar: React.FC<any> = ({ onClose, onSave }) => {
  // Hooks
  const { items } = useDisplayItems();
  const { setDraw } = useEditorTools();

  // Refs
  const mc = useRef<any>(null);
  const offset = useRef<any>({ x: 0, y: 0 });

  const currentSelection = useRef<any>(null);
  const currentSelectionId = useRef<any>(null);

  // Rotation Refs
  const initAngle = useRef<any>(null);
  const startSpriteRot = useRef<any>(null);

  // Pan & Zoom Refs
  const startX = useRef<any>(null);
  const startY = useRef<any>(null);
  const deltaX = useRef<any>(null);
  const deltaY = useRef<any>(null);
  const offsetX = useRef<any>(null);
  const offsetY = useRef<any>(null);
  const startScale = useRef<any>(null);

  // Items Ref for listeners
  const mItems = useRef<any>(items);
  useEffect(() => {
    mItems.current = items;
  }, [items]);

  // Methods
  const handleRotate = useCallback((ev) => {
    if (!currentSelection.current) return;
    if (ev.type === 'rotatestart') {
      initAngle.current = ev.rotation;
      startSpriteRot.current = currentSelection.current.rotation;
    }
    currentSelection.current.rotation =
      startSpriteRot.current + ((ev.rotation - initAngle.current) / 180) * Math.PI;
  }, []);

  const handlePanView = useCallback((ev) => {
    if (!PixiApp.zoomContainer) return;
    if (ev.type === 'panstart') {
      startX.current = PixiApp.zoomContainer.x;
      startY.current = PixiApp.zoomContainer.y;
      return;
    }

    const px = startX.current + ev.deltaX;
    const py = startY.current + ev.deltaY;
    const ww = window.innerWidth;
    const wh = window.innerHeight;
    const contentWidth = ww * PixiApp.zoomContainer.scale.x;
    const contentHeight = wh * PixiApp.zoomContainer.scale.x;

    PixiApp.zoomContainer.x = Math.min(Math.max(px, ww - contentWidth), 0);
    PixiApp.zoomContainer.y = Math.min(Math.max(py, wh - contentHeight), 0);
  }, []);

  const handlePan = useCallback(
    (ev) => {
      if (!PixiApp.zoomContainer || !PixiApp.cropContainer) return;
      if (!currentSelection.current) {
        handlePanView(ev);
        return;
      }

      if (ev.type === 'panstart') {
        startScale.current = currentSelection.current.scale.x;
        offsetX.current = currentSelection.current.x; //- cropPx
        offsetY.current = currentSelection.current.y; //- cropPy
        return;
      }

      currentSelection.current.x =
        offsetX.current + ev.deltaX / PixiApp.zoomContainer.scale.x / PixiApp.cropContainer.scale.x;
      currentSelection.current.y =
        offsetY.current + ev.deltaY / PixiApp.zoomContainer.scale.y / PixiApp.cropContainer.scale.y;
    },
    [handlePanView]
  );

  const handlePinchView = useCallback((ev) => {
    if (!PixiApp.zoomContainer) return;
    if (ev.type === 'pinchstart') {
      startScale.current = PixiApp.zoomContainer.scale.x;

      startX.current = PixiApp.zoomContainer.x;
      startY.current = PixiApp.zoomContainer.y;

      deltaX.current = ev.center.x - startX.current;
      deltaY.current = ev.center.y - startY.current;
    }

    const scale = startScale.current * ev.scale;
    const scaleDelta = ev.scale - 1;
    PixiApp.zoomContainer.scale.set(Math.max(Math.min(MAX_ZOOM_SCALE, scale), 1));

    if (scale >= MAX_ZOOM_SCALE) return;

    const px = startX.current - deltaX.current * scaleDelta;
    const py = startY.current - deltaY.current * scaleDelta;

    const ww = window.innerWidth;
    const wh = window.innerHeight;
    const contentWidth = ww * PixiApp.zoomContainer.scale.x;
    const contentHeight = wh * PixiApp.zoomContainer.scale.x;

    PixiApp.zoomContainer.x = Math.min(Math.max(px, ww - contentWidth), 0);
    PixiApp.zoomContainer.y = Math.min(Math.max(py, wh - contentHeight), 0);
  }, []);

  const handlePinch = useCallback(
    (ev) => {
      if (!currentSelection.current) {
        handlePinchView(ev);
        return;
      }
      if (ev.type === 'pinchstart') {
        startScale.current = currentSelection.current.scale.x;
      }
      currentSelection.current.scale.x = currentSelection.current.scale.y =
        startScale.current * ev.scale;
      currentSelection.current.pivot.x =
        (currentSelection.current.width / currentSelection.current.scale.x) * 0.5;
      currentSelection.current.pivot.y =
        (currentSelection.current.height / currentSelection.current.scale.y) * 0.5;

      currentSelection.current.resolution =
        currentSelection.current.scale.x * window.devicePixelRatio;
      currentSelection.current.dirty = true;
    },
    [handlePinchView]
  );

  useEffect(() => {
    const canvas = document.getElementById('ImageEditorCanvas');
    if (!canvas) return;

    mc.current = new Hammer.Manager(canvas);
    if (!mc.current) return;

    mc.current.add(new Hammer.Pan({ threshold: 0 }));
    mc.current.add(new Hammer.Rotate({ threshold: 0 })).recognizeWith(mc.current.get('pan'));
    mc.current
      .add(new Hammer.Pinch({ threshold: 0 }))
      .recognizeWith([mc.current.get('pan'), mc.current.get('rotate')]);
    mc.current.add(new Hammer.Tap());

    offset.current.x = -canvas.getBoundingClientRect().left;
    offset.current.y = -canvas.getBoundingClientRect().top;

    mc.current.on('rotatestart panstart pinchstart', () => {
      currentSelection.current = null;
      currentSelectionId.current = null; // PixiApp.getId(hit)
    });

    mc.current.on('rotatestart rotatemove', handleRotate);
    mc.current.on('panstart panmove', handlePan);
    mc.current.on('pinchstart pinchmove', handlePinch);

    return () => mc.current.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className='top-toolbar'>
        <div className='right-side'>
          <IconButton icon={icons.CLOSE_CIRCLE} onClick={onClose} />
        </div>

        <div className='center'>
          <IconButton icon={icons.PENCIL} iconSelected={icons.PENCIL_WHITE} onClick={setDraw} />
        </div>

        <div className='left-side'>
          <IconButton icon={icons.VALIDATE} onClick={onSave} />
        </div>
      </div>

      <div className='icon-delete'>
        <Icon icon={icons.DELETE} />
      </div>
    </>
  );
};

export default ManipulationToolbar;
