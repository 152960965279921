export const addVideo = (state, { video }) => {
  if (!video) return state;

  const { type, id, boulderId } = video;
  if (!state.getIn(['boulderIds', boulderId])) {
    state = state.setIn(['boulderIds', boulderId], {});
  }

  let videoIds = state.getIn(['boulderIds', boulderId, type]) || [];
  if (videoIds.asMutable) {
    videoIds = videoIds.asMutable();
  }

  if (!videoIds.includes(id)) {
    video.new ? videoIds.unshift(id) : videoIds.push(id);
  }

  state = state.setIn(['videosByIds', id], video);
  return state.setIn(['boulderIds', boulderId, type], videoIds);
};

export const addVideos = (state, { videos, clean }) => {
  if (!videos?.length) return state;
  if (clean) {
    state = state.setIn(['boulderIds'], {});
  }
  videos.forEach((video) => (state = addVideo(state, { video })));
  return state;
};

export const removeVideo = (state, { videoId, boulderId, type }) => {
  let videoIds = state.getIn(['boulderIds', boulderId, type]) || [];
  state = state.setIn(
    ['boulderIds', boulderId, type],
    videoIds.filter((id) => id !== videoId)
  );
  return state;
};

export const cleanVideos = (state, { boulderId }) => state.setIn(['boulderIds', boulderId], {});
