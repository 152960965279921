export enum CreatorStatusEnum {
  PREPARING = 0,
  ONGOING = 1,
  UPLOAD = 2,
  FINISHED = 3,
}

export type CreateVideoProps = {
  video: Blob;
  onLoading: (value: boolean) => void;
  onProgress: (status: CreatorStatusEnum, percentage?: number) => void;
  onFinish: (data: Blob | null, path: string) => void;
  params: { [key: string]: any };
};

export const getTimeSeconds = (time: string) => {
  const [minutes, seconds] = time.split(':').map(Number);
  return minutes * 60 + seconds;
};
