import React, { useCallback, useState } from 'react';

// Components
import { IonPage } from '@ionic/react';
import GoalTypes from '../../types/GoalTypes';
import Range from '../../components/Range/Range';

// Hooks
import { useNavigate } from 'react-router';
import { useLogged } from '../../redux/auth/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useCreateAccount, useAccountAction, useOnboardingInfo } from '../../redux/account/hooks';

// Style & Config
import './OnboardingGoals.scss';
import ROUTES from '../../routes/constants';
import { trackEvent } from '../../providers/tracker';
import Icon from '../../atoms/Icon/Icon';

interface OnboardingGoalsProps {}

const OnboardingGoals: React.FC<OnboardingGoalsProps> = () => {
  const t = useLocalize();
  const { logged } = useLogged();
  const navigate = useNavigate();
  const { requestClubs } = useAccountAction();
  const { addOnboarding } = useOnboardingInfo();
  const { level, setLevel, setGoals, goals } = useCreateAccount();

  //state
  const [Add, setAdd] = useState<number>(0);

  // Handlers
  const handleGoBack = useCallback(() => {
    trackEvent('back', { path: 'onboarding' });
    navigate(-1);
  }, [navigate]);

  const handleNext = useCallback(() => {
    trackEvent('onboarding_create_account_step_1', {
      event_category: 'create_account',
      event_action: 'onboarding_goals',
      event_label: 'step_1',
    });
    if (!logged) {
      requestClubs();
      navigate(ROUTES.CREATE_ACCOUNT);
    } else {
      addOnboarding(() => {
        window.location.pathname = ROUTES.HOME;
      });
    }
  }, [addOnboarding, logged, navigate, requestClubs]);

  const handleBeginner = useCallback(() => {
    setLevel('beginner');
  }, [setLevel]);

  const handleRegular = useCallback(() => {
    setLevel('regular');
  }, [setLevel]);

  const handleConfirmed = useCallback(() => {
    setLevel('confirmed');
  }, [setLevel]);

  return (
    <IonPage className='OnboardingGoalsPage'>
      <div className='LoginHeader'>
        <h1>{t('onboarding_goals_title')}</h1>
        <p>{t('onboarding_goals_subtitle')}</p>
        <div className='skipOnboarding' onClick={handleGoBack}>
          <Icon icon='close' />
        </div>
      </div>
      <div className='OnboardingGoals'>
        <div className='onboardingGoalsContainer'>
          <div className='onboardingGoalsStatus'>
            <span>{t('onboarding_goals_status')}</span>
            <div className='goalsStatus'>
              <button disabled={level === 'beginner'} onClick={handleBeginner}>
                {t('onboarding_goals_beginner')}
              </button>
              <button disabled={level === 'regular'} onClick={handleRegular}>
                {t('onboarding_goals_regular')}
              </button>
              <button disabled={level === 'confirmed'} onClick={handleConfirmed}>
                {t('onboarding_goals_confirmed')}
              </button>
            </div>
          </div>
          <div className='onboardingGoalsSliders'>
            <span>{t('goal_question')}</span>
            <div>
              <Range
                labelTitle={t('spe_prehension')}
                value={goals[GoalTypes.PREHENSION]}
                type={GoalTypes.PREHENSION}
                setValue={setGoals}
                toAdd={Add}
              />
              <Range
                labelTitle={t('spe_physical')}
                value={goals[GoalTypes.PHYSIQUE]}
                type={GoalTypes.PHYSIQUE}
                setValue={setGoals}
                toAdd={Add}
              />
              <Range
                labelTitle={t('spe_technical')}
                value={goals[GoalTypes.TECHNIQUE]}
                type={GoalTypes.TECHNIQUE}
                setValue={setGoals}
                toAdd={Add}
              />
            </div>
            <div className='globalGoals'>
              <Range labelTitle={t('all')} value={Add} setValue={setAdd} toAdd={0} />
            </div>
          </div>
        </div>
        <div className='buttonContainer'>
          <button onClick={handleNext} disabled={!level}>
            {t('continue')}
          </button>
        </div>
      </div>
      <div className='loginFooter'>
        <Icon icon='buddy-boulder' />
        <p>{t('login_footer')}</p>
      </div>
    </IonPage>
  );
};

export default OnboardingGoals;
