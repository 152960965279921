import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import events from './events';
import screenOverrides from './screenOverrides';
import isBrowser from '../../utils/isBrowser';

const pEnv = (window as any)['env']?.REACT_APP_FIREBASE_API_KEY
  ? (window as any)['env']
  : process.env;

let firebase: any = null;
export const initFirebase = async () => {
  try {
    if (isBrowser()) {
      firebase = await FirebaseAnalytics.initializeFirebase({
        apiKey: pEnv.REACT_APP_FIREBASE_API_KEY,
        authDomain: pEnv.REACT_APP_FIREBASE_DOMAIN,
        projectId: pEnv.REACT_APP_FIREBASE_PROJECT,
        storageBucket: pEnv.REACT_APP_FIREBASE_STORAGE,
        messagingSenderId: pEnv.REACT_APP_FIREBASE_MESSAGING,
        appId: pEnv.REACT_APP_FIREBASE_APPID,
        measurementId: pEnv.REACT_APP_FIREBASE_MEASUREMENT,
      });
    }
  } catch (err) {
    console.error('Error', err);
  }
};

export function firebaseTrackPage(path: string) {
  const screenPage = firebase ? firebase.setCurrentScreen : FirebaseAnalytics.setScreenName;
  console.info('FIREBASE PAGE', path, screenOverrides[path] || path);
  screenPage({ screenName: screenOverrides[path] || path });
}

export function firebaseTrackUserLogged(userId: string) {
  console.info('FIREBASE LOGGED', userId);
  if (userId) {
    (firebase || FirebaseAnalytics).setUserId({
      userId: `${userId}`,
    });
  }
}

export function firebaseTrackEvent(name: keyof typeof events, params: any = undefined) {
  console.info('FIREBASE EVENT', name, params);
  (firebase || FirebaseAnalytics).logEvent({
    name: events[name] || name,
    params: {
      ...(params || {}),
      firebase_screen: screenOverrides[window.location.pathname] || window.location.pathname,
      firebase_screen_class: screenOverrides[window.location.pathname] || window.location.pathname,
    },
  });
}
