// export const addSessionBoulders = (state, {boulders}) => {
//   const sessionBoulders = boulders.map((boulder) => (
//       {...boulder,
//         ...{isValidated: false}
//       }
//     ))
//   state = state.setIn(['sessionBoulders'], sessionBoulders);
//   return state
// }

export const validateBoulder = (state, { boulderId }) => {
  const bouldersUpdated = state.getIn(['sessionBoulders']).map((boulder) => {
    if (boulder.id === boulderId) {
      return { ...boulder, ...{ status: 'TOP' } };
    }
    return boulder;
  });
  state = state.setIn(['sessionBoulders'], bouldersUpdated);
  return state;
};

// export const addSession = (state, {session}) => {
//   if (!session) return state;

//   let ids = state.getIn(['sessionIds']) || [];
//   if (ids.asMutable) {
//     ids = ids.asMutable();
//   }
//   if (!ids.includes(session.id)) {
//     ids.push(session.id);
//   }

//   state = state.setIn(['sessionById', session.id], session);
//   return state.setIn(['sessionIds'], ids);
// }
