import { client } from '../../App';
import { MAXTAGSPROFIL } from './redux';
import { all, put, takeLatest, select, takeEvery, take, race } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

// Redux
import { actions as UserActions } from './redux';
import { actions as AppActions } from '../app/redux';
import { actions as AccountActions } from '../account/redux';
import { actions as AuthActions } from '../auth/redux';
import { actions as SessionsActions } from '../sessions/redux';
import { actions as MediasActions, selectors as MediasSelector } from '../medias/redux';
import { selectors as UserSelectors } from '../user/redux';
import { actions as RoomsActions, selectors as RoomsSelectors } from '../rooms/redux';
import { actions as LeaderBoardActions } from '../leaderBoard/redux';

// Other
import axios from 'axios';
import getHeader from '../../utils/getHeader';
import { GETTICKETS, GETQRCODES, IFRAME_URL } from '../../utils/env';
import { GetUser, GetUserPerf, GetUserLastSession } from '../../GraphQL/GetUser';
import { GetDominantColor, GetMyRank, GetUserScore } from '../../GraphQL/GetLeaderBoard';
import { CreateOrDeleteUsertag } from '../../GraphQL/CreateOrDeleteUsertag';
import { updateUser } from '../../GraphQL/UpdateUser';

export default class UserSagas {
  static *loadCurrentUser({ payload }) {
    try {
      const { callback } = payload;
      if (callback) yield put(AppActions.setAppLoader(true));
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetUser,
      });
      if (result?.data?.user) {
        //set default settings
        yield put(UserActions.addCurrentUser(result.data.user));
        yield put(UserActions.setUsertags(result.data.user.usertags));
        yield put(AccountActions.setUserName(result.data.user.mindFlurryUser.login));
        yield put(AccountActions.setFirstName(result.data.user.mindFlurryUser.firstName));
        yield put(AccountActions.setLastName(result.data.user.mindFlurryUser.lastName));

        //updating room only on first init
        const roomId = yield select(RoomsSelectors.selectedRoomId);
        if (roomId === null) {
          yield put(RoomsActions.setSelectedRoomId(result.data.user.mindFlurryUser.roomId));
        }
        yield put(LeaderBoardActions.requestUserRankUpdate());
        yield put(SessionsActions.requestActiveSession(result.data.user.mindFlurryUser.roomId));
        if (callback) {
          callback();
        }
        yield put(AppActions.setAppLoader(false));
        return;
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
    yield put(UserActions.addCurrentUser(null));
    yield put(AppActions.setAppLoader(false));
  }

  static *loadUserPerf({ payload }) {
    try {
      const { dateStart, dateEnd, roomId, isHome } = payload;
      yield put(UserActions.setFetchingPerfs(true));
      let result;
      if (roomId) {
        result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetUserPerf,
          variables: { dateStart, dateEnd, roomId },
        });
      } else {
        result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetUserPerf,
          variables: { dateStart, dateEnd },
        });
      }
      if (result?.data) {
        if (isHome) yield put(UserActions.addUserPerf(result.data.userperformancesbydate));
        else yield put(UserActions.addUserPerfCoach(result.data.userperformancesbydate));
      }
      yield put(UserActions.setFetchingPerfs(false));
    } catch (err) {
      console.warn(err);
      yield put(UserActions.setFetchingPerfs(false));
      Sentry.captureException(err);
    }
  }

  static *loadUserLastSession() {
    try {
      const roomId = yield select(RoomsSelectors.selectedRoomId);

      yield put(UserActions.setFetchingLastSession(true));
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetUserLastSession,
        variables: { roomId: roomId || undefined },
      });
      if (result?.data?.user) {
        yield put(UserActions.addLastSession(result.data.user?.lastSession));
      }
      yield put(UserActions.setFetchingLastSession(false));
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *getTickets() {
    try {
      const conf = {
        headers: getHeader(),
      };
      const res = yield axios.get(GETTICKETS, conf);
      if (res?.data) {
        yield put(UserActions.setTickets(res.data));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *getQrcodes() {
    try {
      const conf = {
        headers: getHeader(),
      };
      const res = yield axios.get(GETQRCODES, conf);
      if (res?.data) {
        yield put(UserActions.setQrcodes(res.data));
      }
      yield put(UserActions.setIsFetchingQrCode(false));
    } catch (err) {
      console.warn(err);
      yield put(UserActions.setIsFetchingQrCode(false));
      Sentry.captureException(err);
    }
  }

  static *requestUpdatePicture({ payload }) {
    try {
      const { data, isLogin } = payload;
      yield put(AppActions.setAppLoader(true));

      let url = '';

      yield put(MediasActions.requestUploadMedia(data, 1));
      const [success] = yield race([
        take(MediasActions.setUploadSuccess.getType()),
        take(MediasActions.setUploadFail.getType()),
      ]);
      if (success) url = yield select(MediasSelector.uploadedMedia);
      const result = yield client.mutate({
        fetchPolicy: 'network-only',
        mutation: updateUser,
        variables: { picture: url },
      });
      if (result?.data) {
        yield put(UserActions.requestCurrentUser());
      }
      if (isLogin) yield put(AuthActions.setIsOnboarded(true));
      yield put(MediasActions.resetMedia());
      yield put(AppActions.setAppLoader(false));
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
      yield put(AppActions.setAppLoader(false));
    }
  }

  static *requestUserBestColor({ payload }) {
    try {
      const userId = yield select(UserSelectors.currentUserId);
      const { roomId, callback } = payload;
      if (!userId) {
        !!callback && callback();
        return;
      }
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetDominantColor,
        variables: { roomId, userId },
      });
      if (result?.data) {
        yield put(UserActions.setUserColor(result.data.dominantColor));
        !!callback && callback(result.data.dominantColor);
        return;
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *requestUserGlobalColor() {
    try {
      const userId = yield select(UserSelectors.currentUserId);
      if (!userId) {
        return;
      }
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetDominantColor,
        variables: { userId },
      });
      if (result?.data) {
        yield put(UserActions.setUserGlobalColor(result.data.dominantColor));
        return;
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *requestUserColorRank({ payload }) {
    try {
      const { roomId, colorId } = payload;

      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetMyRank,
        variables: { roomId, colorId },
      });

      if (result?.data) {
        yield put(UserActions.setUserColorRank(result.data.myRank));
        return;
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }
  static *requestUserGlobalRank({ payload }) {
    try {
      const { roomId } = payload;

      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetMyRank,
        variables: { roomId },
      });

      if (result?.data) {
        yield put(UserActions.setUserRank(result.data.myRank));
        return;
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *requestUserScore({ payload }) {
    try {
      const { roomId, isByUserColor } = payload;
      let colorId = undefined;

      if (isByUserColor) {
        colorId = yield select(UserSelectors.userColor);
      }

      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetUserScore,
        variables: { roomId, colorId },
      });
      if (result?.data) {
        yield put(UserActions.setUserScore(result.data.myScore));
        return;
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *sendUsertag({ payload }) {
    try {
      const { tagId } = payload;
      const tab = yield select(UserSelectors.currentUser).usertags;
      const check = tab?.find((usertag) => usertag.tagId === tagId);
      if (tab?.length === MAXTAGSPROFIL && !check) return;

      yield client.mutate({
        mutation: CreateOrDeleteUsertag,
        fetchPolicy: 'network-only',
        variables: {
          tagId: tagId,
        },
      });
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *getIframeToken({ payload }) {
    try {
      // yield put(AppActions.setAppLoader(true));
      const res = yield axios.get(IFRAME_URL, {
        headers: yield getHeader(),
      });
      yield put(UserActions.setIframeToken(res.data.iframeToken, payload.source));
      // yield put(AppActions.setAppLoader(false));
    } catch (e) {
      // yield put(AppActions.setAppLoader(false));
      console.error(e);
    }
    // yield put(AppActions.setAppLoader(false));
  }

  static *loop() {
    yield all([
      takeEvery(UserActions.requestIframeToken.getType(), UserSagas.getIframeToken),
      takeLatest(UserActions.requestCurrentUser.getType(), UserSagas.loadCurrentUser),
      takeEvery(UserActions.requestUserPerf.getType(), UserSagas.loadUserPerf),
      takeLatest(UserActions.requestLastSession.getType(), UserSagas.loadUserLastSession),
      takeLatest(UserActions.requestTickets.getType(), UserSagas.getTickets),
      takeLatest(UserActions.requestQrcodes.getType(), UserSagas.getQrcodes),
      takeLatest(UserActions.requestUpdatePicture.getType(), UserSagas.requestUpdatePicture),
      takeLatest(UserActions.requestUserBestColor.getType(), UserSagas.requestUserBestColor),
      takeLatest(UserActions.requestUserGlobalColor.getType(), UserSagas.requestUserGlobalColor),
      takeLatest(UserActions.requestUserColorRank.getType(), UserSagas.requestUserColorRank),
      takeLatest(UserActions.requestUserGlobalRank.getType(), UserSagas.requestUserGlobalRank),
      takeLatest(UserActions.requestUserScore.getType(), UserSagas.requestUserScore),
      takeEvery(UserActions.sendUsertag.getType(), UserSagas.sendUsertag),
    ]);
  }
}
