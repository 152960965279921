import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { selectors as AuthSelectors } from '../auth/redux';
import { actions as UserActions, selectors as UserSelector } from './redux';
import { actions as AccountActions } from '../account/redux';

export const useUser = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => UserSelector.user(state));
  const errorUserCreation = useSelector((state) => UserSelector.errorUserCreation(state));
  const userGoals = useSelector((state) => UserSelector.goals(state));

  const retryUserCreation = useCallback(
    () => dispatch(UserActions.setErrorUserCreation(null)),
    [dispatch]
  );

  return {
    user,
    userGoals,
    errorUserCreation,
    retryUserCreation,
  };
};

export const useTickets = () => {
  const dispatch = useDispatch();

  const userTickets = useSelector((state) => UserSelector.tickets(state));
  const requestTickets = useCallback(() => dispatch(UserActions.requestTickets()), [dispatch]);
  const resetTickets = useCallback(() => dispatch(UserActions.resetTickets()), [dispatch]);

  return {
    userTickets,
    requestTickets,
    resetTickets,
  };
};

export const useQrcode = () => {
  const dispatch = useDispatch();
  const [qrCode, setQrcode] = useState(null);

  const myQrCode = useSelector((state) => UserSelector.qrcode(state));
  const selectedQrcode = useSelector((state) => UserSelector.selectedQrcode(state));
  const isFetchingQrCode = useSelector((state) => UserSelector.isFetchingQrCode(state));

  const requestQrcodes = useCallback(() => dispatch(UserActions.requestQrcodes()), [dispatch]);
  const resetQrcodes = useCallback(() => dispatch(UserActions.resetQrcodes()), [dispatch]);
  const setSelectedQrcode = useCallback(
    (url) => dispatch(UserActions.setSelectedQrcode(url)),
    [dispatch]
  );
  const resetSelectedQrcode = useCallback(
    () => dispatch(UserActions.resetSelectedQrcode()),
    [dispatch]
  );

  useEffect(() => {
    setQrcode(myQrCode);
  }, [myQrCode]);

  return {
    qrCode,
    isFetchingQrCode,
    requestQrcodes,
    resetQrcodes,
    selectedQrcode,
    setSelectedQrcode,
    resetSelectedQrcode,
  };
};

export const useCurrentUser = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => AuthSelectors.token(state));
  const usertags = useSelector((state) => UserSelector.usertags(state));

  const currentUser = useSelector((state) => UserSelector.currentUser(state));
  const requestCurrentUser = useCallback(
    () => dispatch(UserActions.requestCurrentUser()),
    [dispatch]
  );

  const userTickets = useSelector((state) => UserSelector.tickets(state));
  const requestTickets = useCallback(() => dispatch(UserActions.requestTickets()), [dispatch]);
  const resetTickets = useCallback(() => dispatch(UserActions.resetTickets()), [dispatch]);

  const updateAccount = useCallback(
    (info) => dispatch(AccountActions.updateAccount(info)),
    [dispatch]
  );

  useEffect(() => {
    if (!currentUser && token) {
      requestCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, token]);

  return {
    currentUser,
    userTickets,
    usertags,
    requestTickets,
    resetTickets,
    requestCurrentUser,
    updateAccount,
  };
};

export const useUpdateUser = () => {
  const dispatch = useDispatch();

  const requestUpdatePicture = useCallback(
    (data, isLogin = false) => dispatch(UserActions.requestUpdatePicture(data, isLogin)),
    [dispatch]
  );

  const clickUsertag = useCallback((tagId) => dispatch(UserActions.sendUsertag(tagId)), [dispatch]);

  return {
    requestUpdatePicture,
    clickUsertag,
  };
};

export const useUserPerformance = () => {
  const dispatch = useDispatch();
  const userPerf = useSelector((state) => UserSelector.userPerf(state));
  const userRank = useSelector((state) => UserSelector.userRank(state));
  const userColorRank = useSelector((state) => UserSelector.userColorRank(state));
  const userColor = useSelector((state) => UserSelector.userColor(state));
  const userPerfCoach = useSelector((state) => UserSelector.userPerfCoach(state));
  const lastSession = useSelector((state) => UserSelector.lastSession(state));
  const isFetchingPerfs = useSelector((state) => UserSelector.isFetchingPerfs(state));
  const isFetchingLastSession = useSelector((state) => UserSelector.isFetchingLastSession(state));

  const requestUserPerf = useCallback(
    (dateStart, dateEnd, roomId = null, isHome = false) =>
      dispatch(UserActions.requestUserPerf(dateStart, dateEnd, roomId, isHome)),
    [dispatch]
  );

  const resetUserPerf = useCallback(() => dispatch(UserActions.resetUserPerf()), [dispatch]);

  const requestLastSession = useCallback(
    () => dispatch(UserActions.requestLastSession()),
    [dispatch]
  );
  const resetLastSession = useCallback(
    () => dispatch(UserActions.requestLastSession()),
    [dispatch]
  );

  return {
    userPerf,
    userRank,
    userColorRank,
    userColor,
    userPerfCoach,
    lastSession,
    isFetchingPerfs,
    isFetchingLastSession,
    resetUserPerf,
    requestUserPerf,
    resetLastSession,
    requestLastSession,
  };
};

export const useUserColor = () => {
  const dispatch = useDispatch();
  const userGlobalColor = useSelector((state) => UserSelector.userGlobalColor(state));

  const requestUserGlobalColor = useCallback(
    () => dispatch(UserActions.requestUserGlobalColor()),
    [dispatch]
  );

  return {
    userGlobalColor,
    requestUserGlobalColor,
  };
};

export const useLiberfitShop = (source) => {
  const dispatch = useDispatch();
  const liberfitToken = useSelector((state) => UserSelector.liberfitToken(state, { source }));

  const getIframeToken = useCallback(
    () => dispatch(UserActions.requestIframeToken(source)),
    [dispatch, source]
  );

  const clearIframe = useCallback(
    () => dispatch(UserActions.clearIframeToken(source)),
    [dispatch, source]
  );

  return {
    liberfitToken,
    getIframeToken,
    clearIframe,
  };
};
