import { Filesystem } from '@capacitor/filesystem';

export const mediaType = {
  PHOTO_TYPE: 1,
  VIDEO_TYPE: 2,
  DEFAULT_TYPE: 3,
};

export const readFile = async (path: string) => {
  return Filesystem.readFile({
    path: path,
  })
    .then((res) => {
      const result = 'data:image/jpeg;base64,' + res.data;
      return { result };
    })
    .catch((err) => {
      return { err };
    });
};

export const getMimeType = (ext: string) => {
  switch (ext) {
    case 'mov':
      return {
        mimeString: 'video/quicktime',
        type: mediaType.VIDEO_TYPE,
      };
    case 'mp4':
      return {
        mimeString: 'video/mp4',
        type: mediaType.VIDEO_TYPE,
      };
    case 'm4v':
      return {
        mimeString: 'video/mp4',
        type: mediaType.VIDEO_TYPE,
      };
    case 'avi':
      return {
        mimeString: 'video/avi',
        type: mediaType.VIDEO_TYPE,
      };
    case 'mpg':
      return {
        mimeString: 'video/mpeg',
        type: mediaType.VIDEO_TYPE,
      };
    case 'mpeg':
      return {
        mimeString: 'video/mpeg',
        type: mediaType.VIDEO_TYPE,
      };
    case 'jpeg':
      return {
        mimeString: 'image/jpeg',
        type: mediaType.PHOTO_TYPE,
      };
    case 'jpg':
      return {
        mimeString: 'image/jpeg',
        type: mediaType.PHOTO_TYPE,
      };
    case 'webp':
      return {
        mimeString: 'image/webp',
        type: mediaType.PHOTO_TYPE,
      };
    case 'png':
      return {
        mimeString: 'image/png',
        type: mediaType.PHOTO_TYPE,
      };
    case 'bmp':
      return {
        mimeString: 'image/bmp',
        type: mediaType.PHOTO_TYPE,
      };
    default:
      return {
        mimeString: '',
        type: mediaType.DEFAULT_TYPE,
      };
  }
};
