export const countValidatedSessionBouldersByColor = (sessions) =>
  sessions.reduce((acc, { sessionboulders }) => {
    sessionboulders
      .filter(({ status }) => status !== 'FAIL')
      .forEach(
        ({
          boulder: {
            color: { name },
          },
        }) => {
          acc[name] = acc[name] || 0;
          acc[name]++;
        }
      );
    return acc;
  }, {});
