const getExtension = (ext: string) => {
  switch (ext.split(';')[0]) {
    case 'video/x-matroska':
      return 'mkv';
    case 'video/mp4':
      return 'mp4';
    case 'video/x-m4v':
      return 'm4v';
    case 'video/quicktime':
      return 'mov';
    case 'video/webm':
      return 'webm';
    case 'video/x-ms-wmv':
      return 'wmv';
    case 'video/x-flv':
      return 'flv';
    case 'video/mpeg':
      return 'mpg';
    case 'video/x-msvideo':
      return 'avi';
    case 'video/avi':
      return 'avi';
    case 'video/x-ms-asf':
      return 'asf';
    default:
      return 'mp4';
  }
};

export default getExtension;
