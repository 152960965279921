import React from 'react';
import { IonBackdrop, IonModal } from '@ionic/react';

import BlocChallenge from './partials/BlocChallenge/BlocChallenge';
import BoulderInfo from './partials/BlocInfo';
import BlocLocation from './partials/BlocLocation';

import 'swiper/scss';
import './BlocDetailsModal.scss';
import boulder from '../../../../utils/boulder.interface';

interface Props {
  isOpen: boolean;
  isCoach: boolean;
  boulder: boulder;
  boulders: any[];
  modalContent: any;
  breakpoint: number;
  onDismiss: () => void;
  onBlocChange?: (i: number) => void;
}

const BlocDetailsModal: React.FC<Props> = ({
  isOpen,
  onDismiss,
  boulder,
  boulders,
  modalContent,
  onBlocChange,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      initialBreakpoint={0.98}
      breakpoints={[0, 0, 0.98, 0.98]}
      onDidDismiss={onDismiss}
      handle={true}
      showBackdrop
      backdrop-dismiss={true}
      canDismiss
      id='BlocDetailsModal'
    >
      <div className='backgroundHandle' />
      {modalContent?.blocInfo && <BoulderInfo boulder={boulder} />}
      {modalContent?.blocDefi && (
        <BlocChallenge boulder={boulder} modalContent={modalContent} onDismiss={onDismiss} />
      )}
      {modalContent?.blocLocation && (
        <BlocLocation
          boulder={boulder}
          boulders={boulders}
          onDismiss={onDismiss}
          onBlocChange={onBlocChange}
        />
      )}
      <IonBackdrop visible tappable />
    </IonModal>
  );
};

export default BlocDetailsModal;
