import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { IonPage, IonLoading } from '@ionic/react';
import { useLocation, useNavigate } from 'react-router';
import { useManageOpenings } from '../../redux/openings/hooks';

import Icon from '../../atoms/Icon/Icon';
import Point from '../../components/Point/Point';
import DemoPopup from '../../components/DemoPopup/DemoPopup';

import './BlocOpening.scss';
import IconTypes from '../../types/IconTypes';
import getBoulderColor from '../../utils/getBoulderColor';
import room from '../../utils/room.interface';
import wall from '../../utils/wall.interface';
import sector from '../../utils/sector.interface';
import { color } from '../../utils/filter.interface';
import { useMediaEditing } from '../../redux/medias/hooks';
import MovableImage from '../../atoms/MovableImage/MovableImage';
import ImageEditor from '../../components/ImageEditor/ImageEditor';
import icons from '../../types/IconTypes';
import ROUTES from '../../routes/constants';

interface Location {
  pathname: string;
  state?: {
    background?: string;
    room?: room;
    wall?: wall;
    sector?: sector;
    boulderColor?: color;
    intensity?: number;
    tags?: number[];
    video?: string;
    boulder?: any;
    demoMedia: string;
    demoText: string;
  };
}

const BlocOpening: React.FC = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();
  const location: Location = useLocation();
  const { mountBoulder, updateBoulder } = useManageOpenings();
  const { isLoading, uploadedEdition, resetEdition } = useMediaEditing();
  const {
    background,
    room,
    wall,
    sector,
    boulderColor,
    intensity,
    tags,
    boulder,
    demoMedia,
    demoText,
  } = useMemo(
    () => ({
      background: location.state?.background,
      room: location.state?.room,
      wall: location.state?.wall,
      sector: location.state?.sector,
      boulderColor: location.state?.boulderColor,
      intensity: location.state?.intensity,
      tags: location.state?.tags,
      boulder: location.state?.boulder,
      demoMedia: location.state?.demoMedia,
      demoText: location.state?.demoText,
    }),
    [location.state]
  );
  const [showDemo, setShowDemo] = useState<boolean>(false);
  const [media, setDemoMedia] = useState<string | undefined>(demoMedia);
  const [text, setDemoText] = useState<string | undefined>(demoText);
  const [picture, setPicture] = useState<string | null>(null);

  const pictureMemo = useMemo(() => picture || background, [background, picture]);

  const color = useMemo(() => {
    if (boulderColor) {
      const { color } = getBoulderColor(boulderColor.name);
      return color;
    }
    return undefined;
  }, [boulderColor]);

  const handleBack = useCallback(() => {
    if (isEdit) {
      setIsEdit(false);
    } else {
      navigate(ROUTES.OPENING);
      setPicture(null);
    }
    resetEdition();
  }, [isEdit, navigate, resetEdition]);

  const createBoulder = useCallback(() => {
    if (boulder) {
      const params = {
        sectorId: sector?.id,
        boulderId: boulder.id,
        boulderColorId: boulderColor?.id,
        intensity,
        tagIds: tags,
        picture: pictureMemo,
        media,
        textHelp: text,
      };
      updateBoulder(params);
    } else {
      const params = {
        onDate: new Date(),
        status: 1,
        roomId: room?.id,
        wallId: wall?.id,
        sectorId: sector?.id,
        colorId: boulderColor?.id,
        intensityId: intensity,
        tagIds: tags,
        picture: pictureMemo,
        media,
        textHelp: text,
      };
      mountBoulder(params);
    }
    navigate(ROUTES.OPENING, {
      state: {
        mounted: true,
      },
    });
    resetEdition();
    setPicture(null);
  }, [
    boulder,
    navigate,
    resetEdition,
    sector?.id,
    boulderColor?.id,
    intensity,
    tags,
    pictureMemo,
    media,
    text,
    updateBoulder,
    room?.id,
    wall?.id,
    mountBoulder,
  ]);

  const handleEditPicture = useCallback(() => {
    setIsEdit(true);
  }, []);

  useEffect(() => {
    setPicture(uploadedEdition);
  }, [uploadedEdition]);

  const handleDemo = useCallback(() => {
    setShowDemo(true);
  }, []);

  const handleChangePicture = useCallback(
    (value) => {
      setPicture(value);
      handleBack();
    },
    [handleBack]
  );
  return (
    <IonPage>
      {isEdit ? (
        <>
          <ImageEditor
            additionalColors={color ? [color] : undefined}
            source={picture || background || ''}
            onEditClose={handleBack}
            onEditCompleted={handleChangePicture}
          />
          <IonLoading cssClass='custom-loader-class' isOpen={isLoading} message={''} />
        </>
      ) : (
        <div className='blocOpeningContent'>
          <Icon icon={icons.LEFT_OPEN} className='backButton' onClick={handleBack} />
          <div className='blocHeader'>
            <div className='names'>{room?.name}</div>
            <Point color='white' />
            <div className='names'>{wall?.name}</div>
            <Point color='white' />
            <div className='names'>{sector?.name}</div>
            <div className='headerColor' style={{ backgroundColor: color }}></div>
          </div>
          <div className='nobs'>
            {!showDemo && (
              <Icon icon={IconTypes.INFO_CIRCLE} className='nobIcon' onClick={handleDemo} />
            )}
            {!!showDemo && <Icon icon={IconTypes.INFO_CIRCLE_FULL} className='nobIcon' />}
            {<Icon icon={IconTypes.EDIT_CIRCLE} className='nobIcon' onClick={handleEditPicture} />}
            {<Icon icon={IconTypes.TICK_CIRCLE} className='nobIcon' onClick={createBoulder} />}
          </div>
          {pictureMemo && (
            <MovableImage url={pictureMemo} alt='background' className='blocPicture' />
          )}
          <IonLoading cssClass='custom-loader-class' isOpen={isLoading} message={''} />
          {showDemo && (
            <DemoPopup
              display={setShowDemo}
              setMedia={setDemoMedia}
              setText={setDemoText}
              media={media || boulder?.mediaHelp}
              text={text || boulder?.textHelp}
            />
          )}
        </div>
      )}
    </IonPage>
  );
};

export default BlocOpening;
