export type Format = 'original' | 'fullScreen' | 'card' | 'thumb' | 'smallThumb' | 'profile';
export type Resolution = 'hd' | 'sd';

const PROTOCOL_INDEX = 1;
const DOMAIN_NAME_INDEX = 2;
const CONTAINER_NAME_INDEX = 3;
const FILENAME_INDEX = 4;

/**
 * This class contains utils to interact with the media store
 * @class MediaStore
 * @classdesc This class contains utils to interact with the media store
 * @example
 * import { MediaStore } from 'utils/media-store';
 * const cdnUrl = MediaStore.getCdnUrl(mediaStoreUrl, 'thumb', 'hd');
 */
export class MediaStore {
  /**
   * This function get the cdn url from the media store url
   * @param mediaStoreUrl the url given by the media store
   * @param format the aspect ratio of the image
   * @param resolution the resolution of the image
   * @returns the cdn url
   */
  public static getCdnUrl(mediaStoreUrl: string, format: Format, resolution: Resolution): string {
    if (typeof mediaStoreUrl !== 'string' || mediaStoreUrl.includes('.gif')) return mediaStoreUrl;
    const extracted = mediaStoreUrl.match(/^(http[s]?:\/\/)?([^/\s]+\/)?([^/\s]+\/)(.*)$/);
    if (
      !extracted ||
      !extracted[DOMAIN_NAME_INDEX]?.match(/mediastore(.+).blob.core.windows.net\//)
    )
      return mediaStoreUrl;
    const cdnUrl = `${extracted[PROTOCOL_INDEX]}${extracted[2]}`;
    const container = extracted[CONTAINER_NAME_INDEX].slice(0, -1); // we remove the trailing slash
    const dimension = format !== 'original' ? `${resolution}_${format}_` : '';
    const encodedFilename = encodeURIComponent(decodeURIComponent(extracted[FILENAME_INDEX]))
      .split('%2F')
      .join('/');
    return `${cdnUrl}${container}/${dimension}${encodedFilename}`;
  }
}
