import React, { useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useCoachDate } from '../../redux/coaching/hooks';

import Levels from '../../components/Levels/Levels';
import Sessions from './partials/Sessions/Sessions';
import Activity from '../../components/Activity/Activity';
import LastMonths from './partials/LastMonths/LastMonths';
import Header from '../../routes/components/Header/Header';
import { IonPage, IonContent } from '@ionic/react';

import './CoachHome.scss';
import ROUTES from '../../routes/constants';
import { getDateRange } from '../../utils/dateTime';
import Alert from '../../components/Alert/Alert';
import { trackEvent } from '../../providers/tracker';

const CoachHome: React.FC = () => {
  const t = useLocalize();
  const navigate = useNavigate();
  const pageRef = useRef<HTMLIonContentElement>(null);
  const { room } = useSelectedRoom();
  const { coachDate, setCoachDate } = useCoachDate();
  const endDate = getDateRange(coachDate, 0, 1, 0);
  const semester = getDateRange(coachDate, 0, -6, 1);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const startCoaching = useCallback(() => {
    if (room?.id) {
      trackEvent('coach_start', {
        event_category: 'coach',
        event_action: 'coach_start',
      });
      navigate(ROUTES.TRAINING, {
        state: {
          room: room,
        },
      });
    } else {
      setShowAlert(true);
    }
  }, [navigate, room]);

  const hideAlert = useCallback(() => {
    setShowAlert(false);
  }, []);

  return (
    <IonPage id='coachHome'>
      <Header
        showProfile={false}
        isCoach={false}
        showRoom
        showMonth
        showWalls={false}
        showSectors={false}
        selectedDate={coachDate}
        selectDate={setCoachDate}
      />
      <IonContent scrollEvents ref={pageRef} id='coachHomeContainer' className='coachHomeContent'>
        <Activity dateStart={coachDate} dateEnd={endDate} />
        <Levels
          title={t('my_level')}
          dateStart={coachDate}
          dateEnd={endDate}
          isLastSession={false}
        />
        <Levels title={t('last_session')} isLastSession />
        <Sessions roomId={room?.id} startDate={coachDate} endDate={endDate} />
        <LastMonths roomId={room?.id} dateEnd={endDate} dateStart={semester} />
      </IonContent>
      <div className='buttonOverlay'>
        <Alert
          onAlertChange={setShowAlert}
          message={t('select_room_request')}
          title=''
          actionName={t('OK')}
          action={hideAlert}
          position=' 10%'
          className='AlertCoachHome'
          display={showAlert}
        />
        <button className='homeButton' onClick={startCoaching}>
          {t('access_coach')}
        </button>
      </div>
    </IonPage>
  );
};

export default CoachHome;
