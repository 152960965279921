export default class HistoryItem {
  strokeStyle: any = null;
  lineWidth: number = 0;
  path: any;
  data: any = [];

  constructor(strokeStyle, lineWidth) {
    this.strokeStyle = strokeStyle;
    this.lineWidth = lineWidth;
    this.path = new Path2D();
    this.data = [];
  }

  static deserialize(serialized) {
    const historyItem: any = new HistoryItem(serialized.strokeStyle, serialized.lineWidth);

    serialized.data.forEach((coords, index) => {
      const { x, y } = coords;
      historyItem.data.push({ x, y });
      if (index === 0) {
        historyItem.path.moveTo(x, y);
      } else {
        historyItem.path.lineTo(x, y);
      }
    });

    return historyItem;
  }

  drawAll(context2d) {
    this.beginPath(context2d);
    context2d.stroke(this.path);
  }

  beginPath(context2d) {
    context2d.lineWidth = this.lineWidth;
    context2d.strokeStyle = this.strokeStyle;
    context2d.lineCap = 'round';
    context2d.lineJoin = 'round';
    context2d.beginPath();
  }

  moveTo(x: number, y: number) {
    this.path.moveTo(x, y);
    this.data.push({ x, y });
  }

  lineTo(x, y, context2d) {
    this.path?.lineTo(x, y);
    context2d.stroke(this.path);
    this.data.push({ x, y });
  }

  end(context2d) {
    context2d.stroke(this.path);
  }

  serialize() {
    return {
      strokeStyle: this.strokeStyle,
      lineWidth: this.lineWidth,
      data: this.data,
    };
  }
}
