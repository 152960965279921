import * as reducers from './reducers';
import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

export const MAXTAGSPROFIL = 8;

const initialState = Immutable({
  currentUser: null,
  userPerf: [],
  userPerfCoach: [],
  lastSession: {},
  user: {},
  goals: {},
  tickets: 0,
  isFetchingPerfs: false,
  isFetchingLastSession: false,
  qrcode: null,
  selectedQrcode: null,
  errorUserCreation: null,
  userRank: undefined,
  userColorRank: undefined,
  userColor: undefined,
  userGlobalColor: undefined,
  userScore: undefined,
  usertags: undefined,
  isFetchingQrCode: false,
  liberfitToken: {},
});

export const actions = {
  addUserGoals: createAction('add user goals', (userId, goals) => ({ userId, goals })),

  requestCurrentUser: createAction(' request current user', (callback = undefined) => ({
    callback,
  })),
  addCurrentUser: createAction('add current user', (user) => ({ user })),

  requestUserPerf: createAction(
    ' request current user performance',
    (dateStart, dateEnd, roomId, isHome) => ({ dateStart, dateEnd, roomId, isHome })
  ),
  resetUserPerf: createAction(' reset current user performance'),
  requestLastSession: createAction(' request current user last session'),
  resetLastSession: createAction(' reset current user last session'),
  addUserPerf: createAction('add current user performance on home', (perf) => ({ perf })),
  addUserPerfCoach: createAction('add current user performance on coach', (perf) => ({ perf })),
  addLastSession: createAction('add current last session', (session) => ({ session })),

  setFetchingPerfs: createAction('set Fetching Perfs', (value) => ({ value })),
  setFetchingLastSession: createAction('set Fetching LastSession', (value) => ({ value })),
  addOnboardingData: createAction('add onboarding', (user) => ({ user })),

  requestTickets: createAction('request tickets'),
  setTickets: createAction('set tickets', (tickets) => ({ tickets })),
  resetTickets: createAction('reset tickets'),

  requestQrcodes: createAction('request qrcode'),
  setQrcodes: createAction('set qrcode', (qrcode) => ({ qrcode })),
  resetQrcodes: createAction('reset qrcode'),
  setIsFetchingQrCode: createAction('qrcode loading', (value) => ({ value })),

  setSelectedQrcode: createAction('set selected qrcode', (url) => ({ url })),
  setErrorUserCreation: createAction('set error user creation', (value) => ({ value })),
  resetSelectedQrcode: createAction('reset selected qrcode'),

  requestIframeToken: createAction('Request iframe token', (source) => ({ source })),
  setIframeToken: createAction('Set iframe token', (value, source) => ({ value, source })),
  clearIframeToken: createAction('Clear iframe token', (source) => ({ source })),

  requestUpdatePicture: createAction('update user picture', (data, isLogin = false) => ({
    data,
    isLogin,
  })),
  setUserRank: createAction('set rank', (value) => ({ value })),
  setUserColorRank: createAction('set color rank', (value) => ({ value })),
  setUserColor: createAction('set color', (value) => ({ value })),
  setUserGlobalColor: createAction('set global color', (value) => ({ value })),
  setUserScore: createAction('set score', (value) => ({ value })),
  requestUserBestColor: createAction('get my best color', (roomId, callback) => ({
    roomId,
    callback,
  })),
  requestUserGlobalColor: createAction('get my best color'),
  requestUserScore: createAction('get my score', (roomId, isByUserColor) => ({
    roomId,
    isByUserColor,
  })),
  requestUserColorRank: createAction('get my color rank', (roomId, colorId) => ({
    roomId,
    colorId,
  })),
  requestUserGlobalRank: createAction('get my global rank', (roomId) => ({ roomId })),
  sendUsertag: createAction('send usertag', (tagId) => ({ tagId })),
  setUsertags: createAction('set usertags', (tags) => ({ tags })),
};

export const reducer = createReducer(
  {
    [actions.addOnboardingData]: reducers.addOnboardingData,
    [actions.addCurrentUser]: (state, { user }) => {
      try {
        localStorage.setItem('localUser', JSON.stringify(user));
      } catch (e) {
        console.error(e);
      }
      return state.setIn(['currentUser'], user);
    },
    [actions.addUserPerf]: (state, { perf }) => state.setIn(['userPerf'], perf),
    [actions.setUserRank]: (state, { value }) => state.setIn(['userRank'], value),
    [actions.setUserColorRank]: (state, { value }) => state.setIn(['userColorRank'], value),
    [actions.setUserColor]: (state, { value }) => state.setIn(['userColor'], value),
    [actions.setUserGlobalColor]: (state, { value }) => state.setIn(['userGlobalColor'], value),
    [actions.setUserScore]: (state, { value }) => state.setIn(['userScore'], value),
    [actions.addUserPerfCoach]: (state, { perf }) => state.setIn(['userPerfCoach'], perf),
    [actions.resetUserPerf]: (state) => state.setIn(['userPerf'], initialState.userPerf),
    [actions.addLastSession]: (state, { session }) => state.setIn(['lastSession'], session),
    [actions.resetLastSession]: (state) => state.setIn(['lastSession'], initialState.lastSession),

    [actions.setFetchingPerfs]: (state, { value }) => state.setIn(['isFetchingPerfs'], value),
    [actions.setFetchingLastSession]: (state, { value }) =>
      state.setIn(['isFetchingLastSession'], value),
    [actions.setIframeToken]: (state, { value, source }) =>
      state.setIn(['liberfitToken', source], value),
    [actions.clearIframeToken]: (state, { source }) =>
      !!source
        ? state.setIn(['liberfitToken', source], undefined)
        : state.setIn(['liberfitToken'], {}),
    [actions.setTickets]: (state, { tickets }) => state.setIn(['tickets'], tickets),
    [actions.resetTickets]: (state) => state.setIn(['tickets'], initialState.tickets),
    [actions.requestQrcodes]: (state) => state.setIn(['isFetchingQrCode'], true),
    [actions.setQrcodes]: (state, { qrcode }) => state.setIn(['qrcode'], qrcode),
    [actions.setIsFetchingQrCode]: (state, { value }) => state.setIn(['isFetchingQrCode'], value),
    [actions.setErrorUserCreation]: (state, { value }) => state.setIn(['errorUserCreation'], value),
    [actions.resetQrcodes]: (state) => state.setIn(['qrcode'], initialState.qrcode),
    [actions.setSelectedQrcode]: (state, { url }) => state.setIn(['selectedQrcode'], url),
    [actions.resetSelectedQrcode]: (state) =>
      state.setIn(['selectedQrcode'], initialState.selectedQrcode),
    [actions.sendUsertag]: reducers.sendUserTag,
    [actions.setUsertags]: (state, { tags }) => state.setIn(['usertags'], tags),
  },
  initialState
);

export const selectors = {
  user: (state) => state.user.user,
  currentUserId: (state) => state.user.currentUser?.id,
  currentUser: (state) => state.user.currentUser,
  userPerf: (state) => state.user.userPerf,
  userPerfCoach: (state) => state.user.userPerfCoach,
  lastSession: (state) => state.user.lastSession,
  goals: (state) => state.user.goals,
  tickets: (state) => state.user.tickets,
  isFetchingPerfs: (state) => state.user.isFetchingPerfs,
  isFetchingLastSession: (state) => state.user.isFetchingLastSession,
  qrcode: (state) => state.user.qrcode,
  isFetchingQrCode: (state) => state.user.isFetchingQrCode,
  selectedQrcode: (state) => state.user.selectedQrcode,
  errorUserCreation: (state) => state.user.errorUserCreation,
  userRank: (state) => state.user.userRank,
  userColorRank: (state) => state.user.userColorRank,
  userColor: (state) => state.user.userColor,
  userGlobalColor: (state) => state.user.userGlobalColor,
  userScore: (state) => state.user.userScore,
  usertags: (state) => state.user.usertags,
  liberfitToken: (state, { source }) => state.user.liberfitToken[source],
};
