const environment = {
  ...((window as any)?.env || {}),
  ...process.env,
};

export const REST_URL = environment.REACT_APP_URL_REST;
console.debug('REST_URL', REST_URL);

export const WS_URL = environment.REACT_APP_URL_WS;
console.debug('WS_URL', WS_URL);

export const APP_VERSION = environment.REACT_APP_VERSION;
console.debug('APP_VERSION', APP_VERSION);

export const LOGIN_URL = REST_URL + '/login';
console.debug('LOGIN_URL', LOGIN_URL);

export const LOGIN_QRCODE_URL = REST_URL + '/loginQrCode';
console.debug('LOGIN_QRCODE_URL', LOGIN_QRCODE_URL);

export const IFRAME_URL = REST_URL + '/iframeLogin';
console.debug('IFRAME_URL', LOGIN_URL);

export const USER_CREATION_URL = REST_URL + '/register';
console.debug('USER_CREATION_URL', USER_CREATION_URL);

export const ASK_EMAIL_VERIF_URL = REST_URL + '/askEmailVerification';
console.debug('ASK_EMAIL_VERIF_URL', ASK_EMAIL_VERIF_URL);

export const EMAIL_VERIF_URL = REST_URL + '/verifyEmail';
console.debug('EMAIL_VERIF_URL', EMAIL_VERIF_URL);

export const ASK_RESET_PASSWORD_URL = REST_URL + '/askResetPassword';
console.debug('ASK_RESET_PASSWORD_URL', ASK_RESET_PASSWORD_URL);

export const RESET_PASSWORD_URL = REST_URL + '/resetPassword';
console.debug('RESET_PASSWORD_URL', RESET_PASSWORD_URL);

export const CHECKAUTH = REST_URL + '/checkAuth';
console.debug('CHECKAUTH', CHECKAUTH);

export const GETTICKETS = REST_URL + '/getTickets';
console.debug('GETTICKETS', GETTICKETS);

export const GETQRCODES = REST_URL + '/getQrcodes';
console.debug('GETQRCODES', GETQRCODES);

export const DELETE_ACCOUNT_URL = REST_URL + '/deleteAccount';
console.debug('ASK_EMAIL_VERIF_URLDELETE_ACCOUNT_URL', DELETE_ACCOUNT_URL);

export const MEDIA_CONVERTER_URL = environment.REACT_APP_URL_MEDIA_CONVERTER;
console.debug('MEDIA_CONVERTER_URL', MEDIA_CONVERTER_URL);

export const GQL_URL = environment.REACT_APP_URL_GQL;
console.debug('GQL_URL', GQL_URL);

export const SYNC_USER = REST_URL + '/syncUserDeciplus';
console.debug('SYNC_USER', SYNC_USER);

export const UPDATE_USERNAME = REST_URL + '/updateUsername';
console.debug('UPDATE_USERNAME', UPDATE_USERNAME);

export const UPDATE_ACCOUNT = REST_URL + '/updateAccount';
console.debug('UPDATE_ACCOUNT', UPDATE_ACCOUNT);

export const CLUBS = REST_URL + '/clubs';
console.debug('CLUBS', CLUBS);

export const REFRESH_TOKEN_URL = REST_URL + '/refreshToken';
console.debug('REFRESH_TOKEN_URL', REFRESH_TOKEN_URL);
