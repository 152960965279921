import { gql } from '@apollo/client';
import { BoulderFragment, BoulderFragmentMini } from './GetBoulders';

export const GetSector = gql`
  query GetSector($id: Int!) {
    sector(id: $id) {
      id
      name
      status
      picture
      wallId

      boulders {
        id
        numberHolds
        claps
        textHelp
        mediaHelp
        mediaType
        color {
          id
          name
        }
        intensity {
          id
          name
        }
        bouldertags {
          tag {
            id
            name
          }
        }
        boulderspecialities {
          value
          speciality {
            name
          }
        }
        sector {
          plan
          name
        }
        coefficient {
          coefficient
        }
        validationConditions {
          isFlashable
          isValidable
          session {
            startDate
          }
          status
        }
      }
    }
  }
`;

export const GetOpenningsSectors = gql`
  query GetOpenningsSectors($roomId: Int, $orderBy: String, $date: DateTime) {
    sectors(roomId: $roomId, orderBy: $orderBy, statusId: 1) {
      id
      plan
      creationDate
      updateDate
      name
      picture
      boulders {
        ...BoulderFragmentMini
      }
      wall {
        id
        name
      }
    }
  }
  ${BoulderFragmentMini}
`;

export const GetSectorsFiltered = gql`
  query GetSectorsFiltered(
    $roomId: Int
    $wallId: Int
    $colorIds: [Int!]
    $tagIds: [Int!]
    $intensityIds: [Int!]
    $skip: Int
    $date: DateTime
  ) {
    sectorsFiltered(
      roomId: $roomId
      wallId: $wallId
      colorIds: $colorIds
      tagIds: $tagIds
      intensityIds: $intensityIds
      skip: $skip
      take: 6
    ) {
      id
      name
      plan
      picture
      creationDate
      updateDate
      boulders {
        ...BoulderFragmentMini
      }
    }
  }
  ${BoulderFragmentMini}
`;

export const GetSectorById = gql`
  query GetSectorById($sectorId: Int!, $date: DateTime) {
    sector(id: $sectorId) {
      id
      name
      picture
      creationDate
      updateDate
      boulders {
        ...BoulderFragment
      }
    }
  }
  ${BoulderFragment}
`;

export const DismantelSector = gql`
  mutation DismantelSector($sectorId: Int!) {
    dismantleSector(id: $sectorId) {
      id
      name
    }
  }
`;
export const AddSectorPicture = gql`
  mutation AddSectorPicture(
    $id: Int!
    $name: String
    $picture: String
    $status: Int
    $wallId: Int
    $creationDate: DateTime
  ) {
    updateSector(
      updateSectorData: {
        id: $id
        name: $name
        picture: $picture
        status: $status
        wallId: $wallId
        creationDate: $creationDate
      }
    ) {
      id
      picture
    }
  }
`;
