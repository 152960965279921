import { useCallback, useMemo } from 'react';
import getBoulderColor from '../../../../../utils/getBoulderColor';

interface BoulderItemProps {
  id: string | number;
  index: number;
  colorName: string;
  isCurrent: boolean;
  onClick: (i: number) => void;
}

const BoulderItem: React.FC<BoulderItemProps> = ({ id, colorName, isCurrent, onClick, index }) => {
  const { color, borderColor } = useMemo(() => getBoulderColor(colorName), [colorName]);

  const style = useMemo(
    () => ({
      background: color,
      height: isCurrent ? '2.75rem' : '1.875rem',
      border: isCurrent ? `solid 0.188rem ${borderColor}` : '',
    }),
    [borderColor, color, isCurrent]
  );

  const handleClick = useCallback(() => onClick(index), [index, onClick]);

  return <button style={style} key={id} onClick={handleClick} className='boulderDifficulty' />;
};

export default BoulderItem;
