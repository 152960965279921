import { FFMpeg } from '@awesome-cordova-plugins/ffmpeg';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { getTimeSeconds } from './utils';

const trimRunner = (inputPath: string, startTime: string, endTime: string, outputPath: string) => {
  const timeStart = getTimeSeconds(startTime);
  const timeEnd = getTimeSeconds(endTime);

  const timeInSeconds = timeEnd - timeStart;
  const runner = [
    '-hide_banner',
    '-loglevel',
    'error',
    '-i',
    inputPath,
    '-ss',
    startTime,
    '-t',
    `${timeInSeconds}`,
    '-c:v',
    'copy',
    '-c:a',
    'copy',
    outputPath,
  ];
  return runner;
};

export const trimVideoWeb = async (
  ffmpeg: FFmpeg,
  inputPath: string,
  startTime: string,
  endTime: string,
  outputPath: string
) => {
  const runner = trimRunner(inputPath, startTime, endTime, outputPath);
  await ffmpeg.run(...runner);
};

export const trimVideoMobile = async (
  inputPath: string,
  startTime: string,
  endTime: string,
  outputPath: string
) => {
  const runner = trimRunner(inputPath, startTime, endTime, outputPath);
  await FFMpeg.exec(runner.join(' '));
};
