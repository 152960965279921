import axios from 'axios';
import AppConfig from '../../AppConfig';
import getHeader from '../../utils/getHeader';

const CACHE = {};

const getVideoStatus = async (
  jobName: string,
  callback: (val: boolean) => void,
  onLoading: (val: boolean) => void
) => {
  if (CACHE[jobName]) {
    onLoading(false);
    callback(CACHE[jobName]);
    return;
  }

  const mediaUrl = AppConfig.IMAGE_OPTI_URL;
  const conf = getHeader();
  axios({
    method: 'get',
    url: mediaUrl.replace('/upload', `/video/status?job_name=${jobName}`),
    headers: conf,
  })
    .then((value) => {
      onLoading(false);
      CACHE[jobName] = value?.data ? value?.data?.progress >= 100 : true;
      callback(value?.data ? value?.data?.progress >= 100 : true);
    })
    .catch(() => {
      onLoading(false);
      CACHE[jobName] = true;
      callback(true);
    });
};

export default getVideoStatus;
