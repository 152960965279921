import { useLocation } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { useLocalize } from '../../redux/translation/localize';

import { Camera as IonCamera } from '@capacitor/camera';
import Header from '../../components/VideoHeader/VideoHeader';

import './MobileCamera.scss';

import { isAndroid, osVersion } from 'react-device-detect';
import { AndroidPermissionList } from '../../utils/MobilePermissions/AndroidPermissionList';
import { getAndroidPermissions } from '../../utils/MobilePermissions/getAndroidPermissions';

import { File } from '@ionic-native/file';
import { VideoCapturePlusOptions, VideoCapturePlus } from '@ionic-native/video-capture-plus';
import {
  DestinationType,
  Camera as AwesomeCamera,
  PictureSourceType,
  MediaType,
} from '@awesome-cordova-plugins/camera';

const CameraOpts: VideoCapturePlusOptions = { limit: 1, duration: 300 };

interface MobileCameraProps {
  onChange: (data: Blob) => void;
  onBack: () => void;
  onLoading: (val: boolean) => void;
}

const MobileCamera: React.FC<MobileCameraProps> = ({ onChange, onLoading, onBack }) => {
  // Refs & Hook
  const t = useLocalize();
  const location = useLocation();

  // States
  const [permission, setPermission] = useState('');

  const handleCapture = useCallback(
    async (capture) => {
      onLoading(true);
      try {
        const media = capture[0];
        let fileName = media.fullPath;
        if (!fileName.startsWith('file:/')) {
          fileName = 'file://' + fileName;
        }
        const fileEntry: any = await File.resolveLocalFilesystemUrl(fileName);

        fileEntry.file((resFile: any) => {
          const reader = new FileReader();
          reader.onloadend = (evt: any) => {
            const imgBlob: Blob = new Blob([evt.target.result], {
              type: resFile.type,
            });
            onChange(imgBlob);
            onLoading(false);
          };
          reader.readAsArrayBuffer(resFile);
        });
      } catch (err) {
        onLoading(false);
        console.error('Error on Capture', err);
        onBack();
      }
    },
    [onBack, onChange, onLoading]
  );

  // Effects
  useEffect(() => {
    (async () => {
      let granted = true;
      if (isAndroid) {
        if (parseInt(osVersion) <= 12) {
          granted = await getAndroidPermissions([
            AndroidPermissionList.CAMERA,
            AndroidPermissionList.WRITE_EXTERNAL_STORAGE,
            AndroidPermissionList.READ_EXTERNAL_STORAGE,
            AndroidPermissionList.RECORD_AUDIO,
          ]);
        } else {
          granted = await getAndroidPermissions([
            AndroidPermissionList.CAMERA,
            AndroidPermissionList.READ_MEDIA_IMAGES,
            AndroidPermissionList.READ_MEDIA_VIDEO,
            AndroidPermissionList.RECORD_AUDIO,
          ]);
        }
      } else {
        const permissions = await IonCamera.requestPermissions({
          permissions: ['camera', 'photos'],
        });

        if (permissions.camera !== 'granted') {
          setPermission('denied');
          granted = false;
        }
      }

      if (granted) {
        setPermission('granted');
        if (location?.state?.library) {
          AwesomeCamera.getPicture({
            quality: 50,
            destinationType: DestinationType.DATA_URL,
            sourceType: PictureSourceType.PHOTOLIBRARY,
            mediaType: MediaType.VIDEO,
          })
            .then((data) => {
              handleCapture([
                {
                  fullPath: data,
                },
              ]);
            })
            .catch(() => onBack());
        } else {
          VideoCapturePlus.captureVideo(CameraOpts)
            .then(handleCapture)
            .catch(() => {
              onBack();
            });
        }
      }
    })();
  }, [handleCapture, location?.state?.library, onBack]);

  return (
    <div id='MobileCamera'>
      <div className='CameraBox'>
        {permission !== 'granted' && (
          <div className='cameraDenied'>{t('authorize_access_video')}</div>
        )}
        <Header granted={permission === 'granted'} isCamera onBack={onBack} />
      </div>
    </div>
  );
};

export default MobileCamera;
