import React, { useEffect } from 'react';
import { IonList } from '@ionic/react';
import BoulderRankingCard from './partials/BoulderRankingCard/BoulderRankingCard';
import { useLocalize } from '../../redux/translation/localize';

import Icon from '../../atoms/Icon/Icon';

import './UserBoulderRanking.scss';
import room from '../../utils/room.interface';
import IconTypes from '../../types/IconTypes';
import Loader from '../../atoms/Loader/Loader';
import { useUserBestBoulders } from '../../redux/leaderBoard/hooks';

interface Props {
  room?: room;
  userId: number;
  hideUserRanking: () => void;
}

const UserBoulderRanking: React.FC<Props> = ({ room, userId, hideUserRanking }) => {
  const { getUserBestBoulders, bestBoulders, isFetching, isByUserColor } = useUserBestBoulders(
    room?.id,
    userId
  );
  const t = useLocalize();

  useEffect(() => {
    getUserBestBoulders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isByUserColor]);

  return (
    <div className='usersRanking'>
      <div className='ranking'>
        <Icon icon={IconTypes.CLOSE_CIRCLE} className='closeIcon' onClick={hideUserRanking} />
        <div className='boulderRankingTitle'>
          {t('boulder_ranking_title', { nbr_of_boulders: Math.max(10, bestBoulders.length) + '' })}
        </div>
        <IonList className='userList'>
          {bestBoulders.map((item, index) => (
            <BoulderRankingCard
              key={index}
              item={item}
              index={index}
              lastRow={index + 1 === bestBoulders?.length}
            />
          ))}
          {!!isFetching && <Loader />}
        </IonList>
      </div>
    </div>
  );
};

export default UserBoulderRanking;
