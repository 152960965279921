import React, { useCallback, useMemo } from 'react';

import './ColorCard.scss';
import getBoulderColor from '../../utils/getBoulderColor';

interface Props {
  colorName?: string;
  index: number;
  selectIndex: Function;
  selectedIndex?: number;
  noBorder?: boolean;
  multiple?: boolean;
}

const ColorCard: React.FC<Props> = ({
  colorName,
  index,
  selectIndex,
  selectedIndex,
  noBorder,
  multiple,
}) => {
  const { color, borderColor } = useMemo(() => {
    if (colorName?.length) {
      const { color, borderColor } = getBoulderColor(colorName);
      return { color, borderColor };
    }
    return {
      color: '#f4f4f4',
      borderColor: '',
    };
  }, [colorName]);

  const borderStyle = useMemo(
    () => ({
      backgroundColor: selectedIndex === index ? borderColor : '',
    }),
    [borderColor, index, selectedIndex]
  );
  const style = useMemo(() => {
    if (colorName?.length) {
      const heightSize = noBorder ? '2.75rem' : '2.9rem';
      return {
        backgroundColor: color,
        height: selectedIndex === index ? heightSize : '2rem',
      };
    } else {
      return {
        backgroundColor: color,
      };
    }
  }, [color, colorName?.length, index, noBorder, selectedIndex]);

  const handleClick = useCallback(() => {
    if (!multiple && selectedIndex === index) selectIndex();
    else selectIndex(index);
  }, [index, multiple, selectIndex, selectedIndex]);

  return (
    <div className='cardBorder' style={borderStyle}>
      <div className='colorCard' style={style} onClick={handleClick}></div>
    </div>
  );
};

export default ColorCard;
