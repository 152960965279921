import { createAction } from 'redux-act';

export const initialState = {
  displayItems: {},
};

export const actions = {
  addItem: createAction('Editor: add item', (id, meta) => ({ id, meta })),
  removeItem: createAction('Editor: remove item', (id) => ({ id })),
  updateMeta: createAction('Editor: update meta', (id, meta) => ({ id, meta })),
};

export const reducers = {
  [actions.addItem]: (state, { id, meta }) => state.setIn(['displayItems', id], meta),
  [actions.removeItem]: (state, { id }) =>
    state.updateIn(['displayItems'], (items) => items.without((value, key) => key === id)),
  [actions.updateMeta]: (state, { id, meta }) => {
    const source = state.getIn(['displayItems', id]).asMutable();
    return state.setIn(['displayItems', id], { ...source, ...meta });
  },
};

export const selectors = {
  displayItems: (state) => state.editor.items,
  displayItem: (state, id) => state.editor.items[id],
};
