import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { isPlatform } from '@ionic/react';
// Hooks
import { useLogged } from '../../../redux/auth/hooks';
import { useFormattedDate } from '../../../redux/common/hooks';
import { useUserVisit } from '../../../redux/sessions/hooks';
import { useActiveSession } from '../../../redux/sessions/hooks';
import { useLocalize } from '../../../redux/translation/localize';
import { useQRCodeFlash } from '../../../providers/qr-code-flash.provider';
import { useCurrentUser } from '../../../redux/user/hooks';
import { useRooms, useFullRoomSelection, useSelectedRoom } from '../../../redux/rooms/hooks';
import { useMyRank, useRefreshLeaderboard } from '../../../redux/leaderBoard/hooks';

// Components
import Icon from '../../../atoms/Icon/Icon';
import Ticket from '../../../components/Ticket/Ticket';
import SelectBox from '../../../components/SelectBox/SelectBox';
import { IonHeader } from '@ionic/react';
import SelectDate from '../../../components/SelectDate/SelectDate';
import ProfilePicture from '../../../components/ProfilePicture/ProfilePicture';

// Style & utils
import './Header.scss';
import wall from '../../../utils/wall.interface';
import { resetRoom, resetWall, resetSector } from '../../../utils/datepickerConfig';
import icons from '../../../types/IconTypes';
import { asMutable } from 'seamless-immutable';
import ROUTES from '../../constants';
import { useNavigate } from 'react-router';
import { trackEvent } from '../../../providers/tracker';

const FILTERS = [
  { name: 'Tous', id: 1 },
  { name: 'Mes blocs', id: 2 },
];

interface Props {
  autoSelect?: boolean;
  goBack?: Function;
  isCoach: boolean;
  coachStart?: boolean;
  showProfile: boolean;
  showRoom: boolean;
  showMonth: boolean;
  showWalls: boolean;
  showSectors: boolean;
  selectedDate?: Date;
  selectDate?: (date: Date) => void;
  shop?: boolean;
  updateDate?: Date;
  isProfile?: boolean;
  showFilter?: boolean;
  setSelectedFilter?: (id: number) => void;
  selectedFilter?: number;
  disableRoom?: boolean;
  allowAccess?: boolean;
}

const Header: React.FC<Props> = ({
  autoSelect,
  goBack,
  isCoach,
  showRoom,
  showProfile,
  showMonth,
  showWalls,
  showSectors,
  selectedDate,
  selectDate,
  shop,
  updateDate,
  isProfile,
  showFilter,
  setSelectedFilter,
  selectedFilter,
  disableRoom,
  allowAccess,
}) => {
  // Hooks
  const t = useLocalize();
  const { logged } = useLogged();
  const {
    selectedWallId,
    selectedSectorId,
    setSelectedRoomId,
    setSelectedWallId,
    setSelectedSectorId,
  } = useFullRoomSelection();

  const { refreshLeaderboard } = useRefreshLeaderboard();
  const qrCodeFlash = useQRCodeFlash();
  const navigate = useNavigate();
  const { requestRooms, roomById } = useRooms();
  const { requestUserVisites } = useUserVisit();
  const { requestActiveSession } = useActiveSession();
  const { room, roomId: selectedRoomId } = useSelectedRoom();
  const { userColor, userRank } = useMyRank(room?.id);
  const { currentUser, requestTickets, userTickets } = useCurrentUser();

  // 01 of the month
  const startDate = useMemo(() => {
    const date = new Date(selectedDate || new Date());
    date.setDate(1);
    return date;
  }, [selectedDate]);

  const endDate = useMemo(() => {
    const date = new Date(selectedDate || new Date());
    date.setDate(1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);
    return date;
  }, [selectedDate]);

  useEffect(() => {
    const roomId = qrCodeFlash.roomId;
    if (logged && roomId) {
      handleSelectRoom(roomId);
      requestUserVisites(startDate, endDate, roomId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCodeFlash, logged, startDate, endDate]);

  const date = useFormattedDate(
    updateDate ? updateDate : new Date(),
    { day: 'numeric', month: 'long' },
    [selectedDate]
  );

  // Memos
  const sortedArr: any[] = useMemo(
    () => Object.values(roomById).sort((a: any, b: any) => (a.name < b.name ? -1 : 1)),
    [roomById]
  );
  const today = useMemo(() => new Date(), []);
  const rooms = useMemo(() => {
    if (!autoSelect) return [resetRoom].concat(sortedArr);
    return sortedArr;
  }, [sortedArr, autoSelect]);
  const wall = useMemo(
    () => room?.walls.find((wall: wall) => wall.id === selectedWallId),
    [selectedWallId, room?.walls]
  );
  const sector = useMemo(
    () => wall?.sectors.find((sector: wall) => sector.id === selectedSectorId),
    [selectedSectorId, wall?.sectors]
  );
  const initialRoom = useMemo(() => ({ id: room?.id, name: room?.name }), [room]);

  useEffect(() => {
    requestRooms();
    if (logged && userTickets === 0) {
      requestTickets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged, userTickets]);

  useEffect(() => {
    if (autoSelect && (selectedRoomId === 0 || selectedRoomId === null) && rooms.length) {
      handleSelectRoom(currentUser?.mindFlurryUser?.roomId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelect, currentUser?.mindFlurryUser?.roomId, rooms, selectedRoomId]);

  const wallSelection = useMemo(() => [resetWall].concat(room?.walls), [room?.walls]);
  const sectorSelection = useMemo(
    () =>
      [resetSector].concat(
        wall?.sectors && asMutable(wall?.sectors).sort((a, b) => a.name.localeCompare(b.name))
      ),
    [wall?.sectors]
  );

  const handleSelectRoom = useCallback(
    (roomId) => {
      setSelectedRoomId(roomId);
      requestActiveSession(roomId);
      refreshLeaderboard(roomId);
    },
    [setSelectedRoomId, requestActiveSession, refreshLeaderboard]
  );

  const handleAccessRoom = useCallback(() => navigate(ROUTES.MY_CARD), [navigate]);

  const convertDate = useCallback(
    (date: Date) => {
      if (selectDate) {
        if (date <= new Date(today.getFullYear(), today.getMonth(), 1)) {
          trackEvent('coach_calendar_month', { date });
          selectDate(date);
        }
      }
    },
    [selectDate, today]
  );

  const handleLogin = useCallback(() => {
    trackEvent(logged ? 'header_profile_click' : 'header_login_click', {
      event_category: logged ? 'profile' : 'login',
      event_action: logged ? 'header_profile_click' : 'header_login_click',
    });
    navigate(logged ? ROUTES.PROFILE : ROUTES.LOGIN);
  }, [navigate, logged]);

  const handleBack = useCallback(() => {
    if (goBack) goBack();
  }, [goBack]);

  const [darkMode, setDarkMode] = useState<Boolean>();
  useEffect(() => {
    if (isPlatform('ios')) setDarkMode(true);
  }, []);

  return (
    <IonHeader class='ion-no-border' className={isCoach ? 'headerContentCoach' : 'headerContent'}>
      {darkMode && <div className='darkMode' />}
      <div className='headerTop'>
        {isCoach && (
          <div className='coachGreating'>
            <Icon icon={icons.LEFT_OPEN} className='backButton' onClick={handleBack} />
            <div className='title'>
              {t('session')} {date}{' '}
            </div>
          </div>
        )}
        {!isCoach && !isProfile && (
          <Icon className='buddyboulderLogo' icon='buddy-boulder-reverse' />
        )}
        {isProfile && <div />}
        {showProfile && (
          <div>
            {(!logged && !currentUser) || isProfile ? (
              <button className='signInButton' onClick={handleLogin}>
                {t(!logged ? 'login' : 'maCarteButtonProfil')}
              </button>
            ) : (
              <div className='loggedUser'>
                <div className='infoUser'>
                  <Ticket access={userTickets?.[0]} />
                  <ProfilePicture
                    logged={logged}
                    border='solid'
                    user={currentUser}
                    classProps='smallProfile'
                    crowned={userRank > 0 && userRank < 10}
                    link
                    userColor={userColor}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='headerBottom'>
        {!!shop && <div className='boutiqueTitle'>{t('maCarteButtonBoutique')}</div>}
        {showRoom && (
          <div className='headerBottomLeftRoom'>
            {!disableRoom ? (
              <SelectBox
                label={t('select_room_hint')}
                toSelect={rooms}
                onSelect={handleSelectRoom}
                placeholder={room?.name || 'Toutes les salles'}
                initValue={initialRoom}
                isCoach={isCoach}
              />
            ) : (
              <div className='roomDisabled'>
                <span className='select'>{t('select_room_hint')}</span>
                <div>{room?.name || 'Toutes les salles'}</div>
              </div>
            )}
            {allowAccess && <button onClick={handleAccessRoom}>{t('access_room')}</button>}
          </div>
        )}
        {showMonth && (
          <div className='headerBottomLeftDate'>
            <SelectDate
              isCoach={isCoach}
              selectedItem={selectedDate || today}
              onSelect={convertDate}
              hint={t('select_month_hint')}
            />
          </div>
        )}
        {showWalls && (
          <div className='headerRightWall'>
            <SelectBox
              label={t('select_wall_hint')}
              toSelect={wallSelection}
              onSelect={setSelectedWallId}
              placeholder={wall?.name || resetWall.name}
              isCoach={isCoach}
            />
          </div>
        )}
      </div>
      {showSectors && (
        <div className='sectors'>
          <SelectBox
            label={t('sector')}
            toSelect={sectorSelection}
            onSelect={setSelectedSectorId}
            placeholder={sector?.name || resetSector.name}
            isCoach={isCoach}
          />
        </div>
      )}
      {showFilter && !!setSelectedFilter && !!selectedFilter && (
        <div className='sectors'>
          <SelectBox
            label={t('boulder')}
            toSelect={FILTERS}
            onSelect={setSelectedFilter}
            placeholder={FILTERS[selectedFilter - 1]?.name}
            isCoach
          />
        </div>
      )}
    </IonHeader>
  );
};

export default Header;
