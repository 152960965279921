import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import ROUTES from '../../routes/constants';
import { AccessDto, PriceType } from '../../types/AccessTypes';

import './Ticket.scss';
interface Props {
  access?: AccessDto;
  isBig?: boolean;
}

const ColorsByPricing = {
  [PriceType.FULL_PRICE]: {
    foreground: '#5cbb0d',
    background: '#55a711',
  },
  [PriceType.REDUCED_PRICE]: {
    foreground: '#5fcbbd',
    background: '#41a79a',
  },
  [PriceType.CHILD_PRICE]: {
    foreground: '#bb2ff4',
    background: '#8b0dbb',
  },
};

const COLOR_EXPIRED = '#dedede';
const COLOR_RED = '#e02020';
const COLOR_ORANGE = '#fa6400';

const getPriceType = (name: string) => {
  if (name.includes('Enfant')) {
    return PriceType.CHILD_PRICE;
  }
  if (name.includes('pleines')) {
    return PriceType.FULL_PRICE;
  }
  return PriceType.REDUCED_PRICE;
};

const getStyleSubscription = (access: AccessDto) => {
  const dateEnd = new Date(access.start_date);
  const height = '100%';

  if (dateEnd < new Date()) {
    return [COLOR_EXPIRED, COLOR_EXPIRED, height, ''];
  }

  const priceType = getPriceType(access.subscription_name);
  const backgroundColor = ColorsByPricing[priceType].background;
  const foregroundColor = ColorsByPricing[priceType].foreground;

  const text = (() => {
    switch (priceType) {
      case PriceType.FULL_PRICE:
        return 'A';
      case PriceType.REDUCED_PRICE:
        return 'H';
      case PriceType.CHILD_PRICE:
        return 'E';

      default:
        return '?';
    }
  })();

  return [backgroundColor, foregroundColor, height, text];
};

const getStyleTicketBook = (access: AccessDto) => {
  let ratio = access.remaining_sessions / access.sessions;
  const priceType = getPriceType(access.subscription_name);
  const backgroundColor = ColorsByPricing[priceType].background;
  let foregroundColor = ColorsByPricing[priceType].foreground;

  if (ratio <= 30 / 100) {
    foregroundColor = COLOR_ORANGE;
  }
  if (ratio <= 10 / 100) {
    foregroundColor = COLOR_RED;
    ratio = 15 / 100;
  }
  const height = `${Math.round(ratio * 100)}%`;
  const text = String(access.remaining_sessions);

  return [backgroundColor, foregroundColor, height, text];
};

const Ticket: React.FC<Props> = ({ access, isBig }) => {
  const [backgroundColor, foregroundColor, height, text] = useMemo(() => {
    if (!access) return [COLOR_EXPIRED, COLOR_EXPIRED, '100%', '0'];
    return !!access.end_date ? getStyleSubscription(access) : getStyleTicketBook(access);
  }, [access]);
  const navigate = useNavigate();

  const handleTicketClick = useCallback(() => {
    navigate(ROUTES.MY_CARD);
  }, [navigate]);

  const toAddCss = useMemo(() => {
    if (isBig) {
      return 'big';
    }
    return '';
  }, [isBig]);

  return (
    <div className={`ticket_container ${toAddCss}`} onClick={handleTicketClick}>
      <div className='main_ticket'>
        <div className='full_ticket' style={{ backgroundColor }}>
          <div
            className='ratio_of_ticket'
            style={{ height: height, backgroundColor: foregroundColor }}
          />
        </div>
        <div className='circle_two'></div>
        <div className='circle_one'></div>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default Ticket;
