// Redux
import createSagaMiddleware from 'redux-saga';
import StoreValues from '../utils/storeValues.interface';
import { i18nState } from 'redux-i18n';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

//Configurations
import entities from './init/entities';
import * as Sentry from '@sentry/react';
import { boot as bootSaga } from './init/bootSaga';

const actions: StoreValues = {};
const selectors: StoreValues = {};
const reducers: StoreValues = {
  i18nState,
};

Object.keys(entities).forEach((key: string) => {
  selectors[key] = entities[key].selectors;
  actions[key] = entities[key].actions;
  reducers[key] = entities[key].reducer;
});

const sagaMiddleware = createSagaMiddleware();

const middlewares: Array<any> = [];
middlewares.push(sagaMiddleware);

const enhancer = composeWithDevTools(
  applyMiddleware(...middlewares),
  Sentry.createReduxEnhancer({})
);
const combinedReducers = combineReducers(reducers);

export const store = createStore(combinedReducers, enhancer);

export const startSaga = () => {
  sagaMiddleware.run(bootSaga);
};
