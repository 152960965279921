import { client } from '../../App';
import { all, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { GetTags, GetColors, GetIntensities, GetSpecialities } from '../../GraphQL/GetFilters';

import { actions as FiltersActions } from './redux';
import { actions as AppActions } from '../app/redux';

export default class FiltersSagas {
  static *loadFilters() {
    try {
      yield put(FiltersActions.requestTags());
      yield put(FiltersActions.requestColors());
      yield put(FiltersActions.requestIntensities());
      yield put(FiltersActions.requestSpecialities());
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadTags() {
    try {
      const result = yield client.query({ query: GetTags });
      if (!!result?.data) {
        yield put(FiltersActions.addTags(result.data.tags));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadColors({ payload }) {
    try {
      const { roomId } = payload;
      let result;
      if (!!roomId) {
        result = yield client.query({
          query: GetColors,
          variables: {
            roomId: roomId,
          },
        });
      } else {
        result = yield client.query({ query: GetColors });
      }
      if (!!result?.data) {
        yield put(FiltersActions.addColors(result.data.colors));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadIntensities() {
    try {
      const result = yield client.query({ query: GetIntensities });
      if (!!result?.data) {
        yield put(FiltersActions.addIntensities(result.data.intensities));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadSpecialities() {
    try {
      const result = yield client.query({ query: GetSpecialities });
      if (!!result?.data) {
        yield put(FiltersActions.addSpecialities(result.data.specialities));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *logout() {
    try {
      yield put(FiltersActions.cleanFilters());
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(FiltersActions.requestTags.getType(), FiltersSagas.loadTags),
      takeLatest(FiltersActions.requestFilters.getType(), FiltersSagas.loadFilters),
      takeLatest(FiltersActions.requestColors.getType(), FiltersSagas.loadColors),
      takeLatest(FiltersActions.requestIntensities.getType(), FiltersSagas.loadIntensities),
      takeLatest(FiltersActions.requestSpecialities.getType(), FiltersSagas.loadSpecialities),
      takeLatest(AppActions.requestAppLogout.getType(), FiltersSagas.logout),
    ]);
  }
}
