import React, { useCallback, useMemo, useState } from 'react';

import { useLogged } from '../../../../redux/auth/hooks';
import { useLocalize } from '../../../../redux/translation/localize';

import { getDisplayDate } from '../../../../utils/dateTime';

import IconTypes from '../../../../types/IconTypes';
import Icon from '../../../../atoms/Icon/Icon';

interface ItemProps {
  id?: string;
  style?: any;
  icon?: string;
  keyValue: string;
  className: string;
  disabled?: boolean;
  fill?: boolean;
  messageParams?: {
    status: string;
    date: Date;
  };
  breakpoint?: number | null;
  onClick: (value: string, breakpoint: number | null) => void;
  thumbsUp?: boolean;
}

const BoulderNobItem: React.FC<ItemProps> = React.memo(
  ({
    id,
    fill,
    icon,
    style,
    thumbsUp,
    keyValue,
    disabled,
    className,
    messageParams,
    breakpoint = null,
    onClick,
  }) => {
    const t = useLocalize();
    const { logged } = useLogged();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // Memos
    const message = useMemo(() => {
      return (
        messageParams &&
        messageParams.status &&
        messageParams.date &&
        t('no_boulder_validation_' + id, {
          status: t(messageParams.status),
          date: getDisplayDate(new Date(messageParams.date)),
        })
      );
    }, [id, messageParams, t]);

    // Handlers
    const handleClick = useCallback(() => {
      if (!disabled) onClick(keyValue, breakpoint);
      else if (messageParams && logged) setIsPopupOpen(!isPopupOpen);
    }, [disabled, onClick, keyValue, breakpoint, messageParams, isPopupOpen, logged]);

    return (
      <div className='nobContainer' onClick={handleClick}>
        {thumbsUp && (
          <div className='thumbsUp'>
            <Icon icon={IconTypes.LIKE} className='thumbsUpIcon' />
          </div>
        )}
        <div
          className={`boulderNobItem ${className} ${disabled ? 'disabled' : ''} ${
            fill ? 'filled' : ''
          }`}
          style={style}
        >
          {!!icon && <Icon icon={icon} className={`${className}_icon`} />}
        </div>
        {isPopupOpen && logged && !!message && (
          <>
            <div className='popup'>{message}</div>
            <div className='alertTick' />
          </>
        )}
        <div className={`popoupBackdrop ${isPopupOpen ? '' : 'hidden'}`}></div>
      </div>
    );
  }
);

export default BoulderNobItem;
