import { useNavigate } from 'react-router';
import { useAccountAction, useAccountSelector } from '../../../../redux/account/hooks';
import { useLocalize } from '../../../../redux/translation/localize';
import './Connection.scss';
import Icon from '../../../../atoms/Icon/Icon';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import Input from '../../../../atoms/Input/Input';
import ROUTES from '../../../../routes/constants';
import { useAuth } from '../../../../redux/auth/hooks';
import IconTypes from '../../../../types/IconTypes';
import { trackEvent } from '../../../../providers/tracker';

const Connection: React.FC<{ goBack: () => void }> = ({ goBack }) => {
  const t = useLocalize();
  const navigate = useNavigate();
  const { requestLogin } = useAuth();
  const { userName, password } = useAccountSelector();
  const { setUserName, setPassword } = useAccountAction();

  // Memos
  const actionIsDisabled = useMemo(
    () => !userName.length || !password.length,
    [userName, password]
  );

  // States
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  // Handlers
  const handleSetUsername = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setUserName(e.target.value);
    },
    [setUserName]
  );

  const handleSetPassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    [setPassword]
  );

  const handleTogglePassword = useCallback(() => setShowPassword((prev) => !prev), []);

  const handleResponse = useCallback(
    (isOnboarded: boolean, errorMessage?: string) => {
      if (!errorMessage) {
        if (!isOnboarded) navigate(ROUTES.ONBOARDING_GOALS);
        else {
          navigate('/');
        }
      } else {
        setErrorMessage(errorMessage);
      }
    },
    [navigate]
  );

  const handleLogin = useCallback(() => {
    if (userName && password) {
      setErrorMessage(undefined);
      trackEvent('onboarding_login_step_2', {
        event_category: 'login',
        event_action: 'submit_form',
        event_label: 'step_2',
      });
      requestLogin(userName, password, handleResponse);
    }
  }, [requestLogin, password, userName, handleResponse]);

  const handlePasswordRecovery = useCallback(() => {
    setErrorMessage(undefined);
    navigate(ROUTES.FORGOT_PASSWORD);
  }, [navigate]);

  return (
    <div className='Connection'>
      <div className='LoginHeader'>
        <h1>{t('connection_title')}</h1>
        <p>{t('connection_subtitle')}</p>
        <div className='skipOnboarding' onClick={goBack}>
          <Icon icon='close' />
        </div>
      </div>
      <div className='content'>
        <div className='contentLogin'>
          <Input
            type='email'
            className='inputLogin'
            value={userName}
            placeholder={t('user_pseudo')}
            onChange={handleSetUsername}
          />
          <div className='loginPassword'>
            <Input
              type={showPassword ? 'text' : 'password'}
              className='inputLogin'
              value={password}
              placeholder={t('password_fr')}
              onChange={handleSetPassword}
              rightIcon={!showPassword ? IconTypes.EYE : IconTypes.EYE_SLASH}
              onClickIcon={handleTogglePassword}
            />
          </div>
          {!!errorMessage && <span className='loginError'>{t(errorMessage)}</span>}
          <button disabled={actionIsDisabled} className='contentLoginBtn' onClick={handleLogin}>
            {t('continue')}
          </button>
          <div className='forgotPassword' onClick={handlePasswordRecovery}>
            {t('login_forgot_password')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connection;
