import React, { useCallback, useEffect, useState } from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';

import './SelectBox.scss';
interface Props {
  label: string;
  toSelect?: { id: any; name: string }[];
  isCoach?: boolean;
  placeholder?: string;
  initValue?: { id: any; name: string };
  onReset?: () => void;
  onSelect?: (id: any) => void;
  onboardingStyle?: boolean;
  disableRoom?: boolean;
}

const SelectBox: React.FC<Props> = ({
  onSelect,
  toSelect,
  label,
  placeholder,
  onReset,
  isCoach,
  initValue,
  onboardingStyle,
  disableRoom,
}) => {
  const [selected, setSelected] = useState(initValue?.id);

  const handleChange = useCallback(
    (e) => {
      onReset?.();
      onSelect?.(e.detail.value);
      setSelected(e.detail.value);
    },
    [onSelect, onReset]
  );

  useEffect(() => {
    if (initValue?.id) setSelected(initValue?.id);
  }, [initValue]);

  return (
    <div className={isCoach ? 'selectBoxContainerCoach' : 'selectBoxContainer'}>
      {!!label && <span className={onboardingStyle ? 'hint medium' : 'hint'}>{label}</span>}
      <div className='box_devider'>
        <IonSelect
          interface='popover'
          placeholder={placeholder}
          onIonChange={handleChange}
          value={selected}
          className='room-select'
          disabled={disableRoom}
        >
          {toSelect?.map((item: any, index: number) => (
            <IonSelectOption value={item?.id} key={index}>
              {item?.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </div>
    </div>
  );
};

export default SelectBox;
