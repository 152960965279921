import { useLocalize } from '../../redux/translation/localize';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { IonButton, IonIcon, IonModal } from '@ionic/react';
import Icon from '../../atoms/Icon/Icon';
import Toggle from '../../components/Toggle/Toggle';
import Header from '../../components/VideoHeader/VideoHeader';
import StickersModal from './partial/StickersModal/StickersModal';
import StickersBackdrop from './partial/StickersBackdrop/StickersBackdrop';

import './VideoEditor.scss';
import icons from '../../types/IconTypes';
import { StickerType } from './partial/type';
import { getVideoTime } from '../../utils/getVideoTimes';
import { chevronForward, pauseCircle, playCircle } from 'ionicons/icons';

const MAX_STICKERS = 4;
const STICKER_SIZE = 5.5 * 16;

interface VideoEditorProps {
  video: Blob;
  stickers: StickerType[];
  videoTimers: any;
  onDone: (params?: any) => void;
  onClose: () => void;
  onTrim: (val: boolean) => void;
  onStickersChange: React.Dispatch<React.SetStateAction<StickerType[]>>;
  onTimersChanged: (params: any) => void;
  filter?: string;
  filters?: string[];
  onLoading?: (val: boolean) => void;
  onFilterChange?: (name: string) => void;
}

const VideoEditor: React.FC<VideoEditorProps> = ({
  video,
  stickers,
  videoTimers,
  onTrim,
  onDone,
  onClose,
  onLoading,
  onStickersChange,
  onTimersChanged,
}) => {
  const videoTimersRef = useRef(videoTimers);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const statusIcon = useRef<HTMLDivElement | null>(null);

  const t = useLocalize();

  const [_, setCurrentTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [selected, setSelected] = useState<number>(-1);
  const [stickersModal, setStickersModal] = useState(false);
  const [videoState, setVideoState] = useState<HTMLVideoElement | null>();
  const [currentVideo, setCurrentVideo] = useState<string | undefined>();
  const [info, setInfo] = useState({ communityShare: true, download: false });

  // Video Editors
  const handleTrim = useCallback(() => onTrim(true), [onTrim]);
  const handleDone = useCallback(() => {
    if (videoState) {
      localStorage.setItem('video_info_temp', JSON.stringify(info));
      if (videoTimers.startTime === 0 && videoTimers.endTime === videoState.duration) {
        onDone();
      } else {
        onDone({
          trim: {
            startTime: getVideoTime(videoTimers.startTime, true),
            endTime: getVideoTime(videoTimers.endTime, true),
          },
        });
      }
    }
  }, [info, onDone, videoState, videoTimers.endTime, videoTimers.startTime]);

  const handleStickersModal = useCallback(() => setStickersModal((prev) => !prev), []);
  const handleAddSticker = useCallback(
    (stickerUrl: string) => {
      setStickersModal(false);
      onStickersChange((prev) => {
        prev.push({
          x: window.innerWidth / 2 - STICKER_SIZE,
          y: window.innerHeight / 2 - STICKER_SIZE,
          path: stickerUrl,
          width: 88,
          height: 88,
        });
        return prev;
      });
    },
    [onStickersChange]
  );

  const handleChangeSticker = useCallback(
    (sticker: StickerType | null, index: number) =>
      onStickersChange((prev) => {
        if (sticker) {
          prev[index] = sticker;
          return [...prev];
        } else {
          delete prev[index];
          return [...prev.filter((_) => !!_)];
        }
      }),
    [onStickersChange]
  );

  // Video Management Info
  const handleChangeInfo = useCallback((key?: string) => {
    if (key) setInfo((prev) => ({ ...prev, [key]: !prev[key] }));
  }, []);

  // Video Handlers
  const handleTimeUpdate = useCallback(() => {
    let actualTime = videoState?.currentTime || 0;

    if (videoState && actualTime > videoTimersRef.current.endTime) {
      actualTime = videoTimersRef.current.startTime;
      videoState.currentTime = actualTime;
    } else if (videoState && actualTime < videoTimersRef.current.startTime) {
      actualTime = videoTimersRef.current.startTime;
      videoState.currentTime = actualTime;
    }
    setCurrentTime(actualTime);
  }, [videoState]);

  const handleClickScreen = useCallback(() => {
    setSelected(-1);
    if (videoState) {
      setPlaying((prev) => {
        if (statusIcon.current) {
          statusIcon.current.style.display = 'flex';
          statusIcon.current.style.opacity = '1';

          if (timerRef.current) clearTimeout(timerRef.current);
          timerRef.current = setTimeout(() => {
            if (statusIcon.current) statusIcon.current.style.opacity = '0';
            timerRef.current = setTimeout(
              () => statusIcon.current && (statusIcon.current.style.display = 'none'),
              500
            );
          }, 1000);
        }
        prev ? videoState?.pause() : videoState?.play();
        return !prev;
      });
    }
  }, [videoState]);

  const handlePauseVideo = useCallback(() => {
    videoState?.pause();
    setPlaying(false);
  }, [videoState]);

  const handleDurationChanged = useCallback(
    (ev: any) => {
      if (!!ev.target?.duration && ev.target.duration !== Infinity) {
        onLoading?.(false);
        ev.target.loop = true;
        ev.target.play();

        if (!videoTimers.ready) {
          onTimersChanged({
            ready: true,
            startTime: 0,
            endTime: ev.target.duration,
            duration: ev.target.duration,
          });
        }
        setPlaying(true);
        setVideoState(ev.target);
      } else if (ev.target.duration === Infinity || isNaN(Number(ev.target.duration))) {
        ev.target.currentTime = 1e101;
      }
    },
    [onLoading, onTimersChanged, videoTimers.ready]
  );

  useEffect(() => {
    videoTimersRef.current = videoTimers;
  }, [onTimersChanged, videoState?.duration, videoTimers]);

  useLayoutEffect(() => {
    const objectUrl: string = URL.createObjectURL(video);
    setCurrentVideo(objectUrl);
  }, [onLoading, video]);

  return (
    <div id='VideoEditor'>
      <div className='videoBox' onClick={handleClickScreen}>
        <video
          playsInline
          controls={false}
          preload='metadata'
          src={currentVideo}
          onTimeUpdate={handleTimeUpdate}
          onDurationChange={handleDurationChanged}
        />
      </div>

      <IonModal
        id='VideoTooShortModal'
        onWillPresent={handlePauseVideo}
        isOpen={!!videoState?.duration && videoState.duration < 2}
      >
        <div className='container'>
          <div className='content'>
            <div>{t('video_short')}</div>
            <IonButton onClick={onClose}>{t('try_again_video')}</IonButton>
          </div>
        </div>
      </IonModal>

      {/* Video Status */}
      <div className='videoStatusIcon' ref={statusIcon}>
        <IonIcon icon={playing ? playCircle : pauseCircle} />
      </div>

      <Header onBack={onClose} playing={playing}>
        <div className='button' onClick={handleTrim}>
          <Icon icon={icons.SCISSORS} />
        </div>
        {/* Stickers disabled for now */}
        <div className='button'>
          <Icon
            icon={icons.STICKERS}
            onClick={stickers.length >= MAX_STICKERS ? undefined : handleStickersModal}
            className={stickers.length >= MAX_STICKERS ? 'disabled' : ''}
          />
        </div>
      </Header>

      {/* Options */}
      <div className='editors'>
        <div className='togglers'>
          <Toggle
            valueKey='communityShare'
            onChange={handleChangeInfo}
            value={info.communityShare}
            label={t('the_community')}
          />
          <Toggle
            valueKey='download'
            onChange={handleChangeInfo}
            value={info.download}
            label={t('save_on_mobile')}
          />
        </div>
        <div className='button send'>
          <IonIcon icon={chevronForward} onClick={handleDone} />
        </div>
      </div>

      <StickersBackdrop
        selected={selected}
        stickers={stickers}
        onClick={setSelected}
        onChange={handleChangeSticker}
      />
      <StickersModal
        onClick={handleAddSticker}
        visible={stickersModal}
        setVisible={setStickersModal}
      />
    </div>
  );
};

export default VideoEditor;
