import React, { useCallback, useMemo } from 'react';

import './UserName.scss';
import { getFullName } from '../../../../utils/getFullName';
import { useCurrentUser } from '../../../../redux/user/hooks';

interface Props {
  user: any;
  color: string;
  lastRow: boolean;
  onClick?: () => void;
  isMyUser?: boolean;
}

const UserName: React.FC<Props> = ({ user, color, lastRow, onClick, isMyUser }) => {
  const { currentUser } = useCurrentUser();
  const userName = user.login || getFullName(user) || '---';
  const borderStyle = useMemo(
    () => ({
      borderBottomColor: lastRow ? 'transparent' : '',
    }),
    [lastRow]
  );

  const nameStyle = useMemo(() => {
    if (isMyUser) return { color: 'white' };
    else if (user?.id === currentUser?.id) return { color };
    return { color: 'black' };
  }, [color, currentUser?.id, isMyUser, user?.id]);

  const handleClickUserName = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  const points = useMemo(() => user?.points?.toFixed(0), [user?.points]);

  return (
    <div className={`userName ${onClick ? 'clickable' : ''}`} style={borderStyle}>
      <div onClick={handleClickUserName} className='userFullName' style={nameStyle}>
        {userName}
      </div>
      <div className='userPoints' style={nameStyle}>
        {points}
      </div>
    </div>
  );
};

export default UserName;
