import { toFixed } from './toFixed';

export type DisplayDateType = `${string}/${string}/${string}`;

export type DisplayTimeType = `${number}${string | 'h'}${string}`;

export type UTCStringType = `${number}-${string}-${string}`;

export const getPadDigits = (value: number, pad: number): string =>
  toFixed(value, 0).padStart(pad, '0');

/**
 * Pads a number with a leading zero if it is less than 10
 */
export const getPadTwoDigits = (value: number): string => toFixed(value, 0).padStart(2, '0');

/**
 * Returns the month number in a string representing "MM"
 */
export const getTwoDigitMonth = (monthIndex: number): string => getPadTwoDigits(monthIndex + 1);

/**
 * Returns a string representing the date in the format dd/mm/yyyy
 */
export const getDisplayDate = (openAt: Date): DisplayDateType =>
  `${getPadTwoDigits(openAt.getDate())}/${getTwoDigitMonth(
    openAt.getMonth()
  )}/${openAt.getFullYear()}`;

/**
 * Returns a string representing the time in the format H24hMM
 */
export const getDisplayTime = (openAt: Date, separator: string = 'h'): DisplayTimeType =>
  `${openAt.getHours()}${separator}${getPadTwoDigits(openAt.getMinutes())}`;

/**
 * Returns a string representing the date in the format yyyy-mm-dd.
 */
export const getUTCString = (date: Date): UTCStringType =>
  `${date.getFullYear()}-${getTwoDigitMonth(date.getMonth())}-${getPadTwoDigits(date.getDate())}`;

/**
 * Returns the diff in mute between two date
 */
export const minutesDiff = (firstDate: Date, secondDate: Date) => {
  let differenceValue = (secondDate.getTime() - firstDate.getTime()) / 1000;
  differenceValue /= 60;
  return differenceValue;
};

export const getDayDifference = (dateA, dateB = new Date()) =>
  Math.floor(Math.abs((dateA.getTime() - dateB.getTime()) / (1000 * 60 * 60 * 24)));

export const getDateRange = (dateBase: Date, moveYear = 0, moveMonth = 0, setDay = 0) => {
  return new Date(dateBase.getFullYear() + moveYear, dateBase.getMonth() + moveMonth, setDay);
};

/**
 * Compares date with today and returns if it's the same date
 */
export const isDateToday = (date) => {
  const today = new Date();
  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
    return true;
  return false;
};
