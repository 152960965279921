import { IonModal } from '@ionic/react';
import { useCallback } from 'react';

import './StickersModal.scss';
import AvailableStickers from './config';
import OptimizedImage from '../../../../atoms/OptimizedImage/OptimizedImage';

interface Props {
  visible: boolean;
  onClick: (path: string) => void;
  setVisible: (val: boolean) => void;
}

const StickersModal: React.FC<Props> = ({ visible, setVisible, onClick }) => {
  const handleDismiss = useCallback(() => visible && setVisible(false), [setVisible, visible]);

  return (
    <IonModal
      id='StickersModal'
      canDismiss
      showBackdrop
      handle={true}
      isOpen={visible}
      backdrop-dismiss={true}
      initialBreakpoint={0.75}
      breakpoints={[0, 0.5, 0.75, 0.9]}
      onDidDismiss={handleDismiss}
    >
      <div className='stickers'>
        {AvailableStickers.map((url) => (
          <OptimizedImage
            src={url}
            key={url}
            format='original'
            className='sticker'
            onClick={() => onClick(url)}
          />
        ))}
      </div>
    </IonModal>
  );
};

export default StickersModal;
