import PixiApp from './PixiApp';

export const screenToCrop = (cropBound, screenX, screenY) => {
  const scale = PixiApp.zoomContainer?.scale.x || 0;
  screenX -= PixiApp.zoomContainer?.x || 0;
  screenY -= PixiApp.zoomContainer?.y || 0;

  screenX /= scale;
  screenY /= scale;

  const viewport = PixiApp.FULL_VIEWPORT;

  const ratio = Math.min(viewport.width / cropBound.width, viewport.height / cropBound.height);

  const top = viewport.height / 2 - (cropBound.height * ratio) / 2 + viewport.y;

  const left = viewport.width / 2 - (cropBound.width * ratio) / 2 + viewport.x;

  const x = screenX / ratio + cropBound.x - left / ratio;
  const y = screenY / ratio + cropBound.y - top / ratio;

  return [x, y];
};
