import React, { useCallback, useEffect, useMemo } from 'react';

// Hooks
import { useColors } from '../../redux/filters/hooks';

// Components
import Icon from '../../atoms/Icon/Icon';

// Sttyles & Utils & Types
import './ProfilePicture.scss';
import user from '../../utils/user.interface';
import IconTypes from '../../types/IconTypes';
import { color } from '../../utils/filter.interface';
import defaultPicture from '../../assets/img/user.png';
import getBoulderColor from '../../utils/getBoulderColor';
import OptimizedImage from '../../atoms/OptimizedImage/OptimizedImage';
import ROUTES from '../../routes/constants';
import { useNavigate } from 'react-router';

interface Props {
  user?: user | any;
  classProps?: string;
  border?: string;
  logged: boolean;
  crowned: boolean;
  link: boolean;
  borderCol?: string;
  userColor?: number;
  leaderBoard?: boolean;
  isEditing?: boolean;
}

const ProfilePicture: React.FC<Props> = ({
  user,
  border = '',
  classProps = '',
  crowned,
  borderCol,
  userColor,
  leaderBoard,
  link = true,
  isEditing = false,
}) => {
  const { colors, getColors } = useColors();

  const userCol = userColor || user?.colorId;
  const { color } = useMemo(() => {
    const name = colors.filter((item: color) => item.id === userCol);
    return name[0]?.name ? getBoulderColor(name[0]?.name) : { color: '#f4f4f4' };
  }, [colors, userCol]);

  const imageStyle = useMemo(() => {
    if (classProps === 'largeProfile') return { border: `${border} 0.125rem white` };
    else if (user?.isAdmin && !leaderBoard) return { border: `${border} 0.125rem #17368f` };
    else if (borderCol) return { border: `${border} 0.125rem ${borderCol}` };
    else return { border: `${border} 0.125rem ${color}` };
  }, [border, borderCol, classProps, color, leaderBoard, user?.isAdmin]);

  const crownedColor = useMemo(() => {
    if (border === 'none') return { border: 'solid 0.125rem transparent' };
    if (user?.isAdmin) return { border: 'solid 0.125rem #17368f' };
    return { border: `solid 0.125rem ${color}` };
  }, [border, color, user?.isAdmin]);

  const iconColor = useMemo(() => {
    if (user?.isAdmin) return { color: '#17368f' };
    return { color: color };
  }, [color, user?.isAdmin]);

  const navigate = useNavigate();

  const handleProfileClick = useCallback(() => {
    if (user && link) {
      navigate(ROUTES.PROFILE, { state: { goBackTo: ROUTES.HOME } });
    }
  }, [navigate, user, link]);

  useEffect(() => {
    if (!colors.length) {
      getColors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colors.length]);

  if (!user) {
    return null;
  }

  return (
    <div className={classProps} onClick={user && link ? handleProfileClick : undefined}>
      {user?.isAdmin && !!border && !leaderBoard && !isEditing && (
        <Icon icon={IconTypes.GHOST_FULL} style={iconColor} className='iconStyle' />
      )}
      {!user?.isAdmin && !!border && crowned && (
        <Icon icon={IconTypes.CROWN_FULL} style={iconColor} className='iconStyle' />
      )}
      <div className='donuts'>
        {((user?.isAdmin && !!border && !leaderBoard && !isEditing) || (crowned && !!border)) && (
          <div className='crown' style={crownedColor}></div>
        )}
        {user.picture?.length ? (
          <OptimizedImage
            src={user.picture}
            className='profilePictureImage'
            style={imageStyle}
            alt='user'
            format='thumb'
          />
        ) : (
          <img
            crossOrigin='anonymous'
            src={defaultPicture}
            className='profilePictureImage'
            style={imageStyle}
            alt='user'
          />
        )}
      </div>
    </div>
  );
};

export default ProfilePicture;
