import { useCallback, useMemo, useState } from 'react';
import { useCurrentUser } from '../../../../../../../redux/user/hooks';
import { useBoulderVideo } from '../../../../../../../redux/boulderVideo/hooks';

import Icon from '../../../../../../../atoms/Icon/Icon';
import OptimizedVideo from '../../../../../../../atoms/OptimizedVideo/OptimizedVideo';

import { trash } from 'ionicons/icons';
import { IonButton, IonModal } from '@ionic/react';
import { useLocalize } from '../../../../../../../redux/translation/localize';
import { trackEvent } from '../../../../../../../providers/tracker';

const BlocChallengeVideo: React.FC<{ videoId: number }> = ({ videoId }) => {
  const t = useLocalize();
  const { currentUser } = useCurrentUser();
  const { video, requestDeleteVideo } = useBoulderVideo(videoId);

  const [isVisible, setIsVisible] = useState(false);

  const elemId = useMemo(() => `${videoId}_video`, [videoId]);
  const isOwner = useMemo(
    () => !!currentUser?.isAdmin || currentUser?.id === video?.video?.user?.id,
    [currentUser?.id, currentUser?.isAdmin, video?.video?.user?.id]
  );

  const handleSetVisible = useCallback(() => {
    trackEvent('block_video_visualize');
    setIsVisible(true);
  }, []);
  const handleSetNotVisible = useCallback(() => {
    trackEvent('block_video_close');
    setIsVisible(false);
  }, []);
  const handleDelete = useCallback(() => {
    setIsVisible(false);
    trackEvent('block_video_delete', { videoId: videoId });
    requestDeleteVideo();
  }, [requestDeleteVideo, videoId]);

  if (!video?.video?.url?.length) return null;

  const {
    video: { url, user, date, previewImage, jobName },
  } = video;

  return (
    <>
      <div className='BlocChallengeVideo'>
        {!!user && <span>{user.login}</span>}
        {!user && <span>Admin</span>}
        <div className={`videoBloc ${isOwner ? 'owner' : ''}`}>
          {!!isOwner && <Icon icon={trash} className='delete' onClick={handleSetVisible} />}
          <OptimizedVideo
            src={url}
            id={elemId}
            date={date}
            format='original'
            jobName={jobName}
            allowShare={isOwner}
            thumbnail={previewImage}
          />
        </div>
      </div>
      <IonModal
        id='DeleteBlocChallengeVideoModal'
        isOpen={isVisible}
        onWillDismiss={handleSetNotVisible}
      >
        <div className='modalBody'>
          <span className='title'>{t('deleteVideoModalTitle')}</span>
          <span className='description'>{t('deleteVideoModalDescription')}</span>
          <IonButton onClick={handleDelete}>{t('delete_video')}</IonButton>
        </div>
      </IonModal>
    </>
  );
};

export default BlocChallengeVideo;
