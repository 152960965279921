export const addArticle = (state, { article }) => state.setIn(['news', article.id], article);

export const addNews = (state, { news, roomId, reset }) => {
  if (!news) return state;
  if (reset || !state.newsIds[roomId]) state = state.setIn(['newsIds', roomId], []);

  const ids = state.newsIds[roomId].asMutable();

  if (!!news) {
    news.forEach((article) => {
      if (!ids.includes(article.id)) {
        ids.push(article.id);
      }
      state = addArticle(state, { article });
    });
  }
  return state.setIn(['newsIds', roomId], ids);
};
