import { client } from '../../App';
import { actions as RoomsActions } from './redux';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { GetRooms, GetRoom } from '../../GraphQL/GetRooms';
export default class RoomsSagas {
  static *loadRooms() {
    try {
      const result = yield client.query({ query: GetRooms });
      if (result.data.rooms) {
        yield put(RoomsActions.addRooms(result.data.rooms));
      } else {
        yield put(RoomsActions.addRooms(null));
      }
    } catch (e) {
      console.warn(e);
      yield put(RoomsActions.addRooms(null));
      Sentry.captureException(e);
    }
  }

  static *requestRoomById({ payload }) {
    try {
      const { roomId } = payload;
      const result = yield client.query({
        query: GetRoom,
        variables: {
          id: roomId,
        },
      });
      if (result.data.room) yield put(RoomsActions.addRoom(result.data.room));
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(RoomsActions.requestRooms.getType(), RoomsSagas.loadRooms),
      takeEvery(RoomsActions.requestRoomById.getType(), RoomsSagas.requestRoomById),
    ]);
  }
}
