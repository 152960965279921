import { useCallback, useRef } from 'react';
import OptimizedImage from '../OptimizedImage/OptimizedImage';
import './MovableImage.scss';

interface MovableImageProps {
  url: string;
  alt?: string;
  className?: string;
  handleClick?: () => void;
}

const setTranslate = (target, value, maxValue) => {
  if (value < -maxValue || value > 0) {
    value = value < -maxValue ? -maxValue : 0;
  }

  const percentage = (value * 100) / target.width;
  target.style.transform = `translate(${percentage}%)`;
  return value;
};

const MovableImage: React.FC<MovableImageProps> = ({
  url,
  alt = 'image',
  className = '',
  handleClick,
}) => {
  // Refs
  const currentLeft = useRef<number | null>(null);
  const initPointer = useRef<any | null>(null);

  const handleLoad = useCallback(({ target }) => {
    if (target) {
      const { width } = target;
      const maxWidth = width - window.innerWidth;
      const halfScreen = maxWidth / 2;

      currentLeft.current = setTranslate(target, -halfScreen, maxWidth);
    }
  }, []);

  const handleMove = useCallback(({ type, target, changedTouches }) => {
    let leftValue = currentLeft.current || 0;
    if (target) {
      const { width } = target;
      const maxWidth = width - window.innerWidth;

      if (currentLeft.current === null) {
        currentLeft.current = width / 2;
      }
      if (type === 'touchstart') {
        initPointer.current = changedTouches[0];
      }
      if (type !== 'touchstart' && initPointer.current) {
        const pointer = changedTouches[0];
        const lPointer = initPointer.current;
        const value = pointer.pageX - lPointer.pageX;

        leftValue = setTranslate(target, leftValue + value, maxWidth);
      }
      if (type === 'touchend') {
        currentLeft.current = leftValue;
        initPointer.current = null;
      }
    }
  }, []);

  if (!url) return null;

  return (
    <OptimizedImage
      src={url}
      alt={alt}
      className={`movableImage ${className}`}
      onLoad={handleLoad}
      onTouchStart={handleMove}
      onTouchMove={handleMove}
      onTouchEnd={handleMove}
      onClick={handleClick}
      format='fullScreen'
    />
  );
};

export default MovableImage;
