import { client } from '../../App';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { actions as NewsActions, selectors as NewsSelector } from './redux';
import * as Sentry from '@sentry/browser';

import { GetNews } from '../../GraphQL/GetNews';

const LIMITE = 20;

export default class NewsSagas {
  static *loadNews({ payload }) {
    try {
      yield put(NewsActions.setFetching(true));
      const { roomId, skip } = payload;
      let skipToUse = 0;

      if (skip) {
        const ids = yield select(NewsSelector.newsByRoom, roomId);
        skipToUse = ids.length;
      } else {
        yield put(NewsActions.setFullLoaded(false));
      }

      try {
        const result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetNews,
          variables: {
            roomId: roomId !== 'homeIds' ? roomId : undefined,
            skip: skipToUse,
          },
        });

        if (!!result?.data) {
          if (result.data.homemessagesWithFilters.length < LIMITE) {
            yield put(NewsActions.setFullLoaded(false));
          }
          yield put(
            NewsActions.addNews(result.data.homemessagesWithFilters, roomId || 'homeIds', !skip)
          );
        } else {
          // To trigger bootStart
          yield put(NewsActions.addNews(null));
        }
      } catch (e) {
        yield put(NewsActions.addNews(null));
        console.warn(e);
      }
      yield put(NewsActions.setFetching(false));
    } catch (err) {
      console.error(err);
      yield put(NewsActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([takeLatest(NewsActions.requestNews.getType(), NewsSagas.loadNews)]);
  }
}
