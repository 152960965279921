import * as reducers from './reducers';
import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';

const initialState = Immutable({
  audits: {},
});

export const actions = {
  sendUpdate: createAction('update audits on boulder', (boulderId, typeId, value) => ({
    boulderId,
    typeId,
    value,
  })),
  sendClap: createAction('update audits on boulder', (boulderId, value) => ({ boulderId, value })),
  sendIncrementation: createAction(
    'increment audits on boulder',
    (boulderId, typeId, date = undefined) => ({
      boulderId,
      typeId,
      date,
    })
  ),
  sendManyIncrementation: createAction(
    'increment audits on several boulder',
    (incrementAuditsData) => ({
      incrementAuditsData,
    })
  ),
  sendSingleValidation: createAction(
    'send a validation audit on a boulder',
    (boulderId, roomId, isCoaching, status) => ({
      boulderId,
      roomId,
      isCoaching,
      status,
    })
  ),
  sendMultipleValidation: createAction(
    'send multiple boulder validation',
    (roomId, boulders, startDate, sessionId = undefined) => ({
      roomId,
      boulders,
      startDate,
      sessionId,
    })
  ),
  SendUnvalidateBoulder: createAction('send boulder unvalidation', (boulderId, roomId) => ({
    boulderId,
    roomId,
  })),

  requestAudits: createAction('requestion audit on boulder', (boulderId, auditTypes) => ({
    boulderId,
    auditTypes,
  })),

  addAudits: createAction('add audits', (audits, boulderId) => ({ audits, boulderId })),
  cleanAudits: createAction('clean audits'),
};

export const reducer = createReducer(
  {
    [actions.addAudits]: reducers.addAudits,
    [actions.cleanAudits]: (state) => state.merge({ ...initialState }),
  },
  initialState
);

export const selectors = {
  audits: (state) => state.audits.audits,
};
