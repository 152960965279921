const icons = {
  ACTUALITES: 'actualites',
  ADD_CIRCLE: 'add-circle',
  ALIGN_CENTER: 'align-center',
  ALIGN_LEFT: 'align-left',
  ALIGN_RIGHT: 'align-right',
  ARROW_BlUE: 'arrow-blue',
  BACK_ARROW: 'back_arrow',
  BACK_ARROW_FULL: 'back_arrow_full',
  BLOCS: 'blocs',
  BLOCS_FULL: 'blocs-full',
  BOUTIQUE: 'boutique',
  BUDDYBOULDER: 'buddy-boulder',
  BUDDYBOULDER_REVERSE: 'buddy-boulder-reverse',
  BRAVO: 'bravo',
  CALENDAR: 'calendar',
  CAMERA: 'camera',
  CANCEL: 'cancel',
  CENTER: 'center',
  CENTER_WHITE: 'center_white',
  CHECK_CIRCLE_FULL: 'check-circle-full',
  CLAP: 'clap',
  CLIPBOARD: 'clipboard',
  CLOSE: 'close',
  CLOSE_CIRCLE: 'close-circle',
  CLOSE_WHITE: 'close_white',
  COFFEE: 'coffee',
  COMMUNAUTE: 'communaute',
  CROP: 'crop',
  CROP_WHITE: 'crop_white',
  CROWN_FULL: 'crown-full',
  CROWN_EMPTY: 'crown-empty',
  CROWN_GREEN: 'crown-green',
  DANGER: 'danger',
  DELETE: 'delete',
  DISLIKE: 'dislike',
  DOWN_OPEN: 'down-open',
  EDIT_CIRCLE: 'edit-circle',
  EDIT_CIRCLE_FULL: 'edit-circle-full',
  ENTRAINEMENT: 'entrainement',
  EYE: 'eye',
  EYE_SLASH: 'eye-slash',
  EXPORT: 'export',
  FLAG: 'flag',
  FLASH_CIRCLE_FULL: 'flash-circle-full',
  GALLERY: 'gallery',
  GALLERY_UNAVAILABLE: 'gallery-unavailable',
  GHOST: 'ghost',
  GHOST_FULL: 'ghost-full',
  GLOW: 'glow',
  GLOW_WHITE: 'glow_white',
  HAPPY: 'happy',
  HASHTAG: 'hashtag',
  HOME: 'home',
  ICON_PROFILE: 'icon-profile',
  INFO: 'info',
  INFO_CIRCLE: 'info-circle',
  INFO_CIRCLE_FULL: 'info-circle-full',
  KEY: 'key',
  LEFT: 'left',
  LEFT_WHITE: 'left_white',
  LEFT_OPEN: 'left-open',
  LOCATION: 'location',
  LOGO: 'logo',
  LIKE: 'like',
  MARKER: 'marker',
  NORMAL: 'normal',
  PENCIL: 'pencil',
  PENCIL_WHITE: 'pencil_white',
  PROFILE: 'profile',
  QUESTION: 'question',
  RANK_ARROW: 'rank-arrow',
  RETURN_ARROW: 'return-arrow',
  RIGHT: 'right',
  RIGHT_WHITE: 'right_white',
  RIGHT_OPEN: 'right-open',
  SAD: 'sad',
  SCISSORS: 'scissors',
  SEARCH: 'search',
  SHOP: 'shop',
  SIMPLE: 'simple',
  SMILEY: 'smiley',
  STAR: 'star',
  STICKERS: 'stickers',
  STROKE_WIDTH: 'stroke-width',
  TEXT: 'text',
  TEXT_WHITE: 'text_white',
  TEXT_BLACK_BACKGROUND: 'text-black-background',
  TEXT_LIGHT_BACKGROUND: 'text-light-background',
  TEXT_NO_BACKGROUND: 'text-no-background',
  TEXT_WHITE_BACKGROUND: 'text-white-background',
  TICK: 'tick',
  TICK_CIRCLE: 'tick-circle',
  TICK_CIRCLE_FULL: 'tick-circle-full',
  TICKET: 'ticket',
  TICKET_VERTICAL: 'ticket-vertical',
  TICKET_EMPTY: 'ticket-empty',
  TICKET_FULL: 'ticket-full',
  TRASH: 'trash',
  UNDO: 'undo',
  UP_OPEN: 'up-open',
  USER: 'user',
  VALIDATE: 'validate',
  VIDEO: 'video',
};

export default icons;
