import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as AuthActions, selectors as AuthSelectors } from './redux';

export const useAuth = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => AuthSelectors.token(state));

  // Actions
  const requestLogin = useCallback(
    (userName, password, callback, fromOnboarding = false) =>
      dispatch(AuthActions.requestLogin(userName, password, callback, fromOnboarding)),
    [dispatch]
  );

  const requestLoginQrCode = useCallback(
    (code, callback) => dispatch(AuthActions.requestLoginQrCode(code, callback)),
    [dispatch]
  );

  const requestLogout = useCallback(() => dispatch(AuthActions.requestLogout()), [dispatch]);

  return {
    token,
    requestLogin,
    requestLoginQrCode,
    requestLogout,
  };
};

export const useConnection = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => AuthSelectors.token(state));

  const checkConnection = useCallback(
    (cb) => dispatch(AuthActions.checkConnection(cb)),
    [dispatch]
  );

  return {
    token,
    checkConnection,
  };
};

export const useToken = () => {
  const token = useSelector((state) => AuthSelectors.token(state));
  return {
    token,
  };
};

export const useLogged = () => {
  const logged = !!useSelector((state) => AuthSelectors.token(state));
  return {
    logged,
  };
};

export const useDeleteAccount = () => {
  const dispatch = useDispatch();

  const deleteAccount = useCallback(() => dispatch(AuthActions.requestDeleteAccount()), [dispatch]);

  return deleteAccount;
};

export const useOnboarding = () => {
  const dispatch = useDispatch();

  const isOnboarded = useSelector((state) => AuthSelectors.isOnboarded(state));
  const skippedOnboarding = useSelector((state) => AuthSelectors.skippedOnboarding(state));

  const skipOnboarding = useCallback(
    (value) => dispatch(AuthActions.skipOnboarding(value)),
    [dispatch]
  );

  return { isOnboarded, skipOnboarding, skippedOnboarding };
};

export const useAppToken = () => {
  const dispatch = useDispatch();
  const setToken = useCallback((token) => dispatch(AuthActions.setToken(token)), [dispatch]);
  const setRefreshToken = useCallback(
    (token) => dispatch(AuthActions.setRefreshToken(token)),
    [dispatch]
  );
  return {
    setToken,
    setRefreshToken,
  };
};
