const getBoulderColor = (color: string) => {
  let trueColor: string;
  let trueColorRgba: string;
  let borderColor: string;

  switch (color) {
    case 'vert':
      trueColor = '#6dd400';
      trueColorRgba = 'rgba(109, 212, 0, 1)';
      borderColor = '#1e7701';
      break;
    case 'jaune':
      trueColor = '#f7b500';
      trueColorRgba = 'rgba(247, 181, 0, 1)';
      borderColor = '#f79c00';
      break;
    case 'bleu':
      trueColor = '#0091ff';
      trueColorRgba = 'rgba(0, 145, 255, 1)';
      borderColor = 'var(--ion-color-secondary)';
      break;
    case 'orange':
      trueColor = '#fa6400';
      trueColorRgba = 'rgba(250, 100, 0, 1)';
      borderColor = '#fa4b00';
      break;
    case 'violet':
      trueColor = '#b620e0';
      trueColorRgba = 'rgba(182, 32, 224, 1)';
      borderColor = '#7f2399';
      break;
    case 'rose':
      trueColor = '#ff4ddd';
      trueColorRgba = 'rgba(255, 77, 221, 1)';
      borderColor = '#c80085';
      break;
    case 'rouge':
      trueColor = '#e02020';
      trueColorRgba = 'rgba(224, 32, 32, 1)';
      borderColor = '#bf1717';
      break;
    case 'noir':
      trueColor = '#000000';
      trueColorRgba = 'rgba(0, 0, 0, 1)';
      borderColor = '#262323';
      break;
    default:
      trueColor = '';
      trueColorRgba = '';
      borderColor = '';
  }

  return {
    color: trueColor,
    colorRgba: trueColorRgba,
    borderColor: borderColor,
  };
};

export default getBoulderColor;
