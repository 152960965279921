import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';
import * as reducers from './reducers';

const initialState = Immutable({
  token: localStorage.getItem('token') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
  isOnboarded: localStorage.getItem('isOnboarded') || null,
  skippedOnboarding: false,
});

export const actions = {
  // Manage Login
  requestLogin: createAction('request login', (username, password, callback, fromOnboarding) => ({
    username,
    password,
    callback,
    fromOnboarding,
  })),
  requestLoginQrCode: createAction('request login Qr code', (code, callback) => ({
    code,
    callback,
  })),
  requestLogout: createAction('request logout'),
  requestDeleteAccount: createAction('request delete account'),
  checkConnection: createAction('checkConnection', (cb = null) => ({ cb })),

  // Settings
  setIsOnboarded: createAction('setIsOnboarded Auth', (value) => ({ value })),
  skipOnboarding: createAction('set SkippedOnboarding', (value) => ({ value })),
  setToken: createAction('set token', (value) => ({ value })),
  setRefreshToken: createAction('set refresh token', (value) => ({ value })),

  // Clear
  clearAuth: createAction('clear Auth'),
};

const setLocalStore = (state, key, value) => {
  localStorage.setItem(key, value);
  return state.setIn([key], value);
};

export const reducer = createReducer(
  {
    [actions.setIsOnboarded]: (state, { value }) => {
      localStorage.setItem('isOnboarded', value);
      return state.setIn(['isOnboarded'], value);
    },
    [actions.setToken]: reducers.setToken,
    [actions.setRefreshToken]: (state, { value }) => {
      return setLocalStore(state, 'refreshToken', value);
    },
    [actions.skipOnboarding]: (state, { value }) => {
      localStorage.setItem('skippedOnboarding', value);
      return state.setIn(['skippedOnboarding'], value);
    },
    // Other
    [actions.clearAuth]: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('localUser');
      localStorage.removeItem('mfToken');
      return state.merge({ ...initialState });
    },
  },
  initialState
);

export const selectors = {
  token: (state) =>
    state.auth.token === 'null' || state.auth.token === 'undefined' ? undefined : state.auth.token,
  refreshToken: (state) => state.auth.refreshToken,
  isOnboarded: (state) => state.auth.isOnboarded,
  skippedOnboarding: (state) => state.auth.skippedOnboarding,
};
