import { client } from '../../App';
import { actions as SectorsActions } from './redux';
import { actions as BouldersActions } from '../boulders/redux';
import { all, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { GetOpenningsSectors, GetSector } from '../../GraphQL/GetSector';

export default class SectorsSagas {
  static *loadFilteredSectors({ payload }) {
    try {
      yield put(SectorsActions.setFetching(true));
      const { roomId, orderBy } = payload;
      let result;
      if (!!roomId) {
        result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetOpenningsSectors,
          variables: { roomId, orderBy, date: new Date() },
        });
      } else {
        result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetOpenningsSectors,
          variables: {
            orderBy: orderBy,
            date: new Date(),
          },
        });
      }
      if (result?.data?.sectors) {
        yield put(SectorsActions.addSectors(result.data.sectors));
        yield put(BouldersActions.addSectors(result.data.sectors));
        yield put(SectorsActions.setFullLoaded(true));
      }
      yield put(SectorsActions.setFetching(false));
    } catch (err) {
      console.error(err);
      yield put(SectorsActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *requestSector({ payload }) {
    try {
      yield put(SectorsActions.setFetching(true));
      const { id } = payload;
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetSector,
        variables: { id },
      });
      if (result?.data) {
        yield put(SectorsActions.addSector(result.data.sector));
        yield put(SectorsActions.setFullLoaded(true));
      }
      yield put(SectorsActions.setFetching(false));
    } catch (err) {
      console.warn(err);
      yield put(SectorsActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(SectorsActions.requestFilteredSectors.getType(), SectorsSagas.loadFilteredSectors),
      takeLatest(SectorsActions.requestSector.getType(), SectorsSagas.requestSector),
    ]);
  }
}
