import { gql } from '@apollo/client';

export const BoulderFragmentMini = gql`
  fragment BoulderFragmentMini on Boulder {
    # Boulder Info
    value(date: $date)

    # Main Data
    id
    offDate
    picture
    createDate
    textHelp
    mediaHelp
    mediaType

    # Audits
    claps
    numberHolds

    color {
      id
      name
    }
    intensity {
      id
      name
    }
    coefficient {
      coefficient
    }

    # Boulder Links
    sectorId
    sector {
      id
      name
      picture
      plan
    }
    wall {
      name
      id
    }
    room {
      id
      name
    }
    bouldertags {
      tag {
        name
        id
      }
    }
    boulderspecialities {
      speciality {
        name
      }
    }
  }
`;

export const BoulderFragment = gql`
  fragment BoulderFragment on Boulder {
    ...BoulderFragmentMini

    validationConditions(date: $date) {
      isFlashable
      isValidable
      session {
        startDate
      }
      status
    }
  }
  ${BoulderFragmentMini}
`;

export const GetBoulders = gql`
  query GetBoulders {
    boulders {
      ...BoulderFragment
    }
  }
  ${BoulderFragment}
`;

export const GetFilteredBoulders = gql`
  query GetFilteredBoulders(
    $roomIds: [Int!]
    $wallIds: [Int!]
    $colorIds: [Int!]
    $tagIds: [Int!]
    $intensityIds: [Int!]
  ) {
    boulders(
      roomIds: $roomIds
      wallIds: $wallIds
      colorIds: $colorIds
      tagIds: $tagIds
      intensityIds: $intensityIds
    ) {
      id
      sector {
        id
        name
        picture
        creationDate
        updateDate
        boulders {
          ...BoulderFragment
        }
      }
    }
  }
  ${BoulderFragment}
`;

export const GetBoulderById = gql`
  query GetBoulderById($boulderId: Int!, $date: DateTime) {
    boulder(id: $boulderId) {
      ...BoulderFragment
    }
  }
  ${BoulderFragment}
`;

export const GetValidationConditions = gql`
  query GetValidationConditions($boulderId: Int!, $date: DateTime) {
    getMyBoulderValidationStatusesConditions(
      getValidationConditions: { boulderId: $boulderId, date: $date }
    ) {
      isFlashable
      isValidable
      session {
        startDate
      }
      status
    }
  }
`;

export const GetSectorsBoulders = gql`
  query GetSectorsBoulders(
    $roomIds: [Int!]
    $wallIds: [Int!]
    $sectorIds: [Int!]
    $colorIds: [Int!]
  ) {
    boulders(roomIds: $roomIds, wallIds: $wallIds, sectorIds: $sectorIds, colorIds: $colorIds) {
      ...BoulderFragment
    }
  }
  ${BoulderFragment}
`;

export const GetWallBoulders = gql`
  query GetWallBoulders(
    $roomIds: [Int!]
    $wallIds: [Int!]
    $sectorIds: [Int!]
    $colorIds: [Int!]
    $date: DateTime
    $skip: Int
  ) {
    boulders(
      roomIds: $roomIds
      wallIds: $wallIds
      sectorIds: $sectorIds
      colorIds: $colorIds
      date: $date
      skip: $skip
      limit: 10
    ) {
      id
    }
  }
`;

export const GetMyValidatedBoulders = gql`
  query getMyValidatedBoulders(
    $roomIds: [Int!]
    $wallIds: [Int!]
    $sectorIds: [Int!]
    $colorIds: [Int!]
    $date: DateTime
    $skip: Int
  ) {
    getMyValidatedBoulders(
      roomIds: $roomIds
      wallIds: $wallIds
      sectorIds: $sectorIds
      colorIds: $colorIds
      date: $date
      skip: $skip
      limit: 10
      validationStatus: [FLASH, TOP]
    ) {
      id
    }
  }
`;

export const MountBoulder = gql`
  mutation MountBoulder(
    $onDate: DateTime!
    $status: Int!
    $roomId: Int
    $wallId: Int
    $sectorId: Int
    $colorId: Int
    $intensityId: Int
    $tagIds: [Int]
    $picture: String
    $mediaHelp: String
    $mediaType: Int
    $textHelp: String
  ) {
    createBoulder(
      createBoulderData: {
        onDate: $onDate
        status: $status
        roomId: $roomId
        wallId: $wallId
        sectorId: $sectorId
        colorId: $colorId
        intensityId: $intensityId
        tagIds: $tagIds
        picture: $picture
        mediaHelp: $mediaHelp
        mediaType: $mediaType
        textHelp: $textHelp
      }
    ) {
      id
    }
  }
`;

export const UpdateBoulder = gql`
  mutation UpdateBoulder(
    $id: Int!
    $numberHolds: Int
    $colorId: Int
    $intensityId: Int
    $tagIds: [Int]
    $picture: String
    $mediaHelp: String
    $mediaType: Int
    $textHelp: String
  ) {
    updateBoulder(
      updateBoulderData: {
        id: $id
        numberHolds: $numberHolds
        colorId: $colorId
        intensityId: $intensityId
        tagIds: $tagIds
        picture: $picture
        mediaHelp: $mediaHelp
        mediaType: $mediaType
        textHelp: $textHelp
      }
    ) {
      id
    }
  }
`;

export const GetBouldersByIds = gql`
  query GetBouldersByIds($boulderIds: [Int!], $date: DateTime) {
    boulders(ids: $boulderIds) {
      id
      ...BoulderFragment
    }
  }
  ${BoulderFragment}
`;
