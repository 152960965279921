import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions as BouldersActions,
  createSelectorSectorBouldersById,
  selectors as BouldersSelector,
} from './redux.js';

export const useSector = (sectorId) => {
  const dispatch = useDispatch();

  const sector = useSelector((state) => BouldersSelector.sectorById(state, sectorId));

  const requestGetSector = useCallback(
    () => dispatch(BouldersActions.getSectorById(sectorId)),
    [dispatch, sectorId]
  );

  useEffect(() => {
    if (!sector && sectorId) {
      requestGetSector();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sector, sectorId]);

  return {
    ...(sector || {}),
    requestGetSector,
  };
};

export const useSectorBoulders = (sectorId) => {
  const dispatch = useDispatch();

  const sector = useSelector((state) => BouldersSelector.sectorById(state, sectorId));
  const sectorBouldersById = useMemo(createSelectorSectorBouldersById, []);
  const boulders = useSelector((state) => sectorBouldersById(state, sectorId));

  const requestGetSector = useCallback(
    () => dispatch(BouldersActions.getSectorById(sectorId)),
    [dispatch, sectorId]
  );

  useEffect(() => {
    if (sectorId && !sector) {
      requestGetSector();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sector, sectorId]);

  return {
    ...(sector || {}),
    boulders,
  };
};

export const useSectors = () => {
  const dispatch = useDispatch();

  const sectorIds = useSelector((state) => BouldersSelector.sectorIds(state));
  const sectorList = useSelector((state) => BouldersSelector.sectorList(state));
  const isFetching = useSelector((state) => BouldersSelector.isFetching(state));
  const isFullLoaded = useSelector((state) => BouldersSelector.isFullLoaded(state));

  const requestGetSectors = useCallback(
    (roomId, wallId, colorIds, tagIds, intensityIds, skip) =>
      dispatch(
        BouldersActions.requestFilteredBoulders(
          roomId,
          wallId,
          colorIds,
          tagIds,
          intensityIds,
          skip
        )
      ),
    [dispatch]
  );

  return {
    sectorIds,
    sectorList,
    isFetching,
    isFullLoaded,
    requestGetSectors,
  };
};

export const useBoulder = (boulderId) => {
  const dispatch = useDispatch();

  const boulder = useSelector((state) => BouldersSelector.boulderById(state, boulderId));

  const requestGetBoulder = useCallback(
    () => dispatch(BouldersActions.getBoulderById(boulderId)),
    [dispatch, boulderId]
  );

  useEffect(() => {
    if ((!boulder || !boulder.validationConditions) && boulderId) {
      requestGetBoulder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boulderId, boulder]);

  return boulder;
};

export const useBoulders = () => {
  const dispatch = useDispatch();

  const boulderIds = useSelector((state) => BouldersSelector.boulderIds(state));
  const boulderList = useSelector((state) => BouldersSelector.boulderList(state));
  const isFetching = useSelector((state) => BouldersSelector.isFetching(state));
  const isFullLoaded = useSelector((state) => BouldersSelector.isFullLoaded(state));
  const isFetchingValidatedBoulder = useSelector((state) =>
    BouldersSelector.isFetchingValidatedBoulder(state)
  );
  const isFullLoadedValidatedBoulder = useSelector((state) =>
    BouldersSelector.isFullLoadedValidatedBoulder(state)
  );
  const validatedBoulder = useSelector((state) => BouldersSelector.validatedBoulder(state));

  const getBouldersBySector = useCallback(
    (sectorIds) => dispatch(BouldersActions.getBouldersBySector(sectorIds)),
    [dispatch]
  );

  const getBouldersByWall = useCallback(
    (payload) => dispatch(BouldersActions.getBouldersByWall(payload)),
    [dispatch]
  );

  const getMyValidatedBoulders = useCallback(
    (payload) => dispatch(BouldersActions.getMyValidatedBoulders(payload)),
    [dispatch]
  );

  return {
    boulderIds,
    boulderList,
    isFetchingValidatedBoulder,
    isFetching,
    isFullLoadedValidatedBoulder,
    isFullLoaded,
    validatedBoulder,
    getBouldersBySector,
    getBouldersByWall,
    getMyValidatedBoulders,
  };
};

export const useBoulderValidationConditions = (boulderId) => {
  const boulder = useSelector((state) => BouldersSelector.boulderById(state, boulderId));

  return {
    isFlashable: boulder?.validationConditions?.isFlashable,
    isValidable: boulder?.validationConditions?.isValidable,
    messageParams: {
      date: boulder?.validationConditions?.session?.startDate,
      status: boulder?.validationConditions?.status,
    },
  };
};

export const useBouldersDetails = (date) => {
  const dispatch = useDispatch();

  const getBouldersDetails = useCallback(
    (boulderIds) => dispatch(BouldersActions.requestBouldersDetails(boulderIds, date)),
    [dispatch, date]
  );

  return {
    getBouldersDetails,
  };
};
