import React, { useCallback, useState, useEffect } from 'react';

import './Difficulties.scss';
import room from '../../utils/room.interface';
import Difficulty from './Difficulty/Difficulty';

interface color {
  id: number;
  name: string;
  count: number;
}

interface Props {
  room?: room;
  colors: color[];
  selectedColor: number;
  changeColor: Function;
}

const Difficulties: React.FC<Props> = ({ room, colors, selectedColor, changeColor }) => {
  const [selected, setSelected] = useState<number>(selectedColor);
  const [totalColors, setTotalColors] = useState<number>(0);

  const selectColor = useCallback(
    (id: number) => {
      setSelected(id);
      changeColor(id);
    },
    [changeColor, setSelected]
  );

  useEffect(() => {
    if (!!colors?.length && !room) {
      const tot = colors.reduce((acc: number, { count }) => acc + count, 0);
      setTotalColors(tot);
    }
  }, [colors, room]);

  return (
    <div className='difficulties'>
      {colors.map((item: color, index: number) => (
        <Difficulty
          key={index}
          item={item}
          selectedColor={selected}
          total={totalColors}
          selectColor={selectColor}
        />
      ))}
    </div>
  );
};

export default Difficulties;
