import icons from '../../../types/IconTypes';

const backgrounds = [
  { fill: 0xffffff, opacity: 1, icon: icons.TEXT_WHITE_BACKGROUND },
  { fill: 0x000000, opacity: 1, icon: icons.TEXT_BLACK_BACKGROUND },
  { fill: 0xffffff, opacity: 0.5, icon: icons.TEXT_LIGHT_BACKGROUND },
  { fill: 0x000000, opacity: 0, icon: icons.TEXT_NO_BACKGROUND },
];

export default backgrounds;
