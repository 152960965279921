import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions as NewsActions,
  selectors as NewsSelector,
  createNewsRoomSelector,
  createNewsSelector,
} from './redux';

export const useNews = (roomId) => {
  const dispatch = useDispatch();

  const newsByRoomSelector = useMemo(createNewsRoomSelector, []);
  const news = useSelector((state) => newsByRoomSelector(state, roomId));

  const getNews = useCallback(
    (roomId, skip) => dispatch(NewsActions.requestNews(roomId, skip)),
    [dispatch]
  );

  const isFetching = useSelector((state) => NewsSelector.isFetching(state));
  const isFullLoaded = useSelector((state) => NewsSelector.isFullLoaded(state));

  return {
    // Selectors
    news,
    isFetching,
    isFullLoaded,

    // Actions
    getNews,
  };
};

export const useArticle = (newsId) => {
  const newsByIdSelector = useMemo(createNewsSelector, []);
  const article = useSelector((state) => newsByIdSelector(state, newsId));

  return {
    article,
  };
};
