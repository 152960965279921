import { useMemo } from 'react';

// Footer
import Footer from '../Footer/Footer';

// Utils
import routes from '../../routes';
import * as footerTypes from './footerTypes';
import { useLocation } from 'react-router';

const MainFooter: React.FC = () => {
  const location = useLocation();

  const items = useMemo<footerTypes.FooterItemProps[] | null>(() => {
    const currentPage = routes.find(
      (page) => page.path.toLowerCase() === location?.pathname?.toLowerCase()
    );
    if (currentPage?.footer) return footerTypes[currentPage.footer] || null;
    return null;
  }, [location]);
  return <Footer items={items} />;
};

export default MainFooter;
