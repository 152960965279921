import React, { useEffect, useMemo } from 'react';

// Hooks
import { useFilteredSectors } from '../../../../redux/sectors/hooks';
import { useLocalize } from '../../../../redux/translation/localize';

// Components
import Loader from '../../../../atoms/Loader/Loader';

// Styles & Types
import 'swiper/scss';
import './HomeOpenings.scss';
import room from '../../../../utils/room.interface';
import { RenderSector } from '../../../Room/Room';
import ROUTES from '../../../../routes/constants';

interface Props {
  room?: room;
}

const HomeOpenings: React.FC<Props> = ({ room }) => {
  const t = useLocalize();
  const { sectors, getFilteredSectors, isFetching, isFullLoaded } = useFilteredSectors();

  // Memos
  const fewSectors = useMemo(() => {
    const sortedArr: any[] = [...(sectors || [])?.slice(0, 4)].sort((a: any, b: any) =>
      a.updateDate > b.updateDate ? -1 : 1
    );
    return sortedArr || [];
  }, [sectors]);

  useEffect(() => {
    getFilteredSectors(room?.id, 'desc');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room?.id]);

  return (
    <div className='sliderContainer'>
      <div className='sectionName'>
        <span className='title'>{t('opening_title')}</span>
      </div>
      {isFullLoaded && (
        <div className='slides'>
          <div className='flexList'>
            {fewSectors?.map((sector: any) => (
              <RenderSector
                key={sector.id}
                sectorId={sector.id}
                wall={sector.wall}
                prevRoute={ROUTES.HOME}
              />
            ))}
          </div>
        </div>
      )}
      {isFetching && <Loader />}
    </div>
  );
};

export default HomeOpenings;
