export const addRoom = (state, { room }) => {
  if (!room) return state;

  let roomIds = state.getIn(['roomIds']) || [];
  if (roomIds.asMutable) {
    roomIds = roomIds.asMutable();
  }
  if (!roomIds.includes(room.id)) {
    roomIds.push(room.id);
  }

  state = state.setIn(['roomById', room.id], room);
  return state.setIn(['roomIds'], roomIds);
};

export const addRooms = (state, { rooms }) => {
  if (!rooms) return state;

  rooms.forEach((room) => (state = addRoom(state, { room })));
  return state;
};
