import { client } from '../../App';
import { all, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { actions as AppActions } from '../app/redux';
import { actions as BouldersActions, selectors as BouldersSelector } from './redux';
import { actions as AuditsActions } from '../audits/redux';

import { GetSectorsFiltered, GetSectorById } from '../../GraphQL/GetSector';
import {
  GetSectorsBoulders,
  GetBoulderById,
  GetWallBoulders,
  GetBouldersByIds,
  GetMyValidatedBoulders,
} from '../../GraphQL/GetBoulders';

export default class BouldersSagas {
  static *loadFilteredBoulders({ payload }) {
    try {
      yield put(BouldersActions.setFetching(true));
      const { roomId, wallId, colorIds, tagIds, intensityIds, skip } = payload;
      let skipToUse = 0;
      if (skip) {
        const list = yield select(BouldersSelector.sectorList);
        skipToUse = list.length;
      } else {
        yield put(BouldersActions.addSectorList([], true));
        yield put(BouldersActions.setFullLoaded(false));
      }
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetSectorsFiltered,
        variables: {
          roomId: roomId,
          wallId: wallId,
          colorIds: colorIds,
          tagIds: tagIds,
          intensityIds: intensityIds,
          skip: skipToUse,
          date: new Date(),
        },
      });

      if (result?.data) {
        if (result.data.sectorsFiltered.length < 6) {
          yield put(BouldersActions.setFullLoaded(true));
        }

        const data = [...result.data.sectorsFiltered];
        const ids = data.map((sect) => sect.id);
        yield put(BouldersActions.addSectorList(ids, !skip));
        yield put(BouldersActions.addSectors(result.data.sectorsFiltered));
      }
      yield put(BouldersActions.setFetching(false));
    } catch (err) {
      console.warn(err);
      yield put(BouldersActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *getSectorById({ payload }) {
    try {
      const { sectorId } = payload;
      yield put(BouldersActions.setFetching(true));
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetSectorById,
        variables: { sectorId: parseInt(sectorId), date: new Date() },
      });
      if (!!result?.data) {
        yield put(BouldersActions.addSector(result.data.sector));
      }
      yield put(AppActions.setAppLoader(false));
      yield put(BouldersActions.setFetching(false));
    } catch (err) {
      console.warn(err);
      yield put(AppActions.setAppLoader(false));
      yield put(BouldersActions.setFetching(false));
      Sentry.captureException(err);
    }
    yield put(AppActions.setAppLoader(false));
  }

  static *getBoulderById({ payload }) {
    try {
      const { boulderId } = payload;
      yield put(BouldersActions.setFetching(true));

      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetBoulderById,
        variables: { boulderId: parseInt(boulderId), date: new Date() },
      });
      if (!!result?.data) {
        yield put(BouldersActions.addBoulder(result.data.boulder));
      }
      yield put(BouldersActions.setFetching(false));
    } catch (err) {
      console.warn(err);
      yield put(BouldersActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *getBouldersBySector({ payload }) {
    try {
      yield put(BouldersActions.setFetching(true));
      const { sectorIds } = payload;
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetSectorsBoulders,
        variables: { sectorIds },
      });
      if (result?.data) {
        yield put(BouldersActions.addBoulders(result.data.boulders));
        yield put(BouldersActions.setFullLoaded(true));
      }
      yield put(BouldersActions.setFetching(false));
    } catch (err) {
      console.warn(err);
      yield put(BouldersActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *getBouldersByWall({ payload }) {
    try {
      yield put(BouldersActions.setFetching(true));
      const { roomIds, wallIds, colorIds, date, skip } = payload.payload;
      let skipToUse = 0;
      if (skip) {
        const list = yield select(BouldersSelector.boulderList);
        skipToUse = list.length;
      } else {
        yield put(BouldersActions.setFullLoaded(false));
      }
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetWallBoulders,
        variables: { roomIds, wallIds, colorIds, date, skip: skipToUse },
      });
      if (result?.data) {
        if (result.data.boulders.length < 10) {
          yield put(BouldersActions.setFullLoaded(true));
        }
        const ids = result.data.boulders.map((bould) => bould.id);
        yield put(BouldersActions.addBoulderList(ids, !skip));
      }
      yield put(BouldersActions.setFetching(false));
    } catch (err) {
      console.warn(err);
      yield put(BouldersActions.setFetching(false));
      Sentry.captureException(err);
    }
  }

  static *getMyValidatedBoulders({ payload }) {
    try {
      yield put(BouldersActions.setFetchingValidatedBoulder(true));
      const { roomIds, wallIds, colorIds, date, skip } = payload.payload;
      let skipToUse = 0;
      if (skip) {
        const list = yield select(BouldersSelector.validatedBoulder);
        skipToUse = list.length;
      } else {
        yield put(BouldersActions.setFullLoadedValidatedBoulder(false));
      }
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetMyValidatedBoulders,
        variables: { roomIds, wallIds, colorIds, date, skip: skipToUse },
      });
      if (result?.data) {
        if (result.data.getMyValidatedBoulders.length < 10) {
          yield put(BouldersActions.setFullLoadedValidatedBoulder(true));
        }
        const ids = result.data.getMyValidatedBoulders.map((bould) => bould.id);
        yield put(BouldersActions.addValidatedBoulder(ids, !skip));
      }
      yield put(BouldersActions.setFetchingValidatedBoulder(false));
    } catch (err) {
      console.warn(err);
      yield put(BouldersActions.setFetchingValidatedBoulder(false));
      Sentry.captureException(err);
    }
  }

  static *updateClaps({ payload }) {
    try {
      const { boulderId, value } = payload;
      yield put(BouldersActions.updateClap(boulderId, value));
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *requestBouldersDetails({ payload }) {
    try {
      const { boulderIds, date } = payload;
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetBouldersByIds,
        variables: { boulderIds, date },
      });

      if (result?.data) {
        yield put(BouldersActions.addBoulders(result.data.boulders));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *logout() {
    try {
      yield put(BouldersActions.cleanBoulders());
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeLatest(
        BouldersActions.requestFilteredBoulders.getType(),
        BouldersSagas.loadFilteredBoulders
      ),
      takeEvery(BouldersActions.getSectorById.getType(), BouldersSagas.getSectorById),
      takeEvery(BouldersActions.getBoulderById.getType(), BouldersSagas.getBoulderById),
      takeLatest(
        BouldersActions.requestBouldersDetails.getType(),
        BouldersSagas.requestBouldersDetails
      ),
      takeLatest(BouldersActions.getBouldersBySector.getType(), BouldersSagas.getBouldersBySector),
      takeEvery(BouldersActions.getBouldersByWall.getType(), BouldersSagas.getBouldersByWall),
      takeEvery(
        BouldersActions.getMyValidatedBoulders.getType(),
        BouldersSagas.getMyValidatedBoulders
      ),
      takeEvery(AuditsActions.sendClap.getType(), BouldersSagas.updateClaps),
      takeLatest(AppActions.requestAppLogout.getType(), BouldersSagas.logout),
    ]);
  }
}
