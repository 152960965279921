import { trackEvent } from '../../providers/tracker';
import { MAXTAGSPROFIL } from './redux';

export const addOnboardingData = (state, { user, goals }) => {
  state = state.setIn(['user'], user);
  state = state.setIn(['goals'], goals);
  return state;
};

export const sendUserTag = (state, { tagId }) => {
  const usertag = state.usertags?.find((usertag) => usertag.tagId === tagId);
  let usertags;

  if (usertag) {
    usertags = state.usertags.filter((usertag) => usertag.tagId !== tagId);
  } else if (state.usertags?.length < MAXTAGSPROFIL) {
    usertags = [...state.usertags, { __typename: 'Usertag', tagId }];
  } else {
    usertags = state.usertags;
  }
  trackEvent('user_tags', { tags: usertags });
  return state.setIn(['usertags'], usertags);
};
