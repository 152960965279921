import Immutable from 'seamless-immutable';
import { createAction, createReducer } from 'redux-act';
import * as reducers from './reducers';

const initialState = Immutable({
  activity: [],
  coachActivity: [],
  semesterActivity: [],
  userVisites: [],

  activeSession: {},
  dailySession: null,

  sessionIds: [],
  sessionById: {},

  isFetching: false,
  isFetchingSemester: false,
  isFullLoaded: false,
  isFullLoadedSemester: false,
});

export const actions = {
  requestPeriodicActivity: createAction(
    'request activity',
    (dateStart, dateEnd, roomId, isHome) => ({
      dateStart,
      dateEnd,
      roomId,
      isHome,
    })
  ),
  resetPeriodicActivity: createAction('reset activity'),
  requestSemesterActivity: createAction(
    'request semester activity',
    (dateStart, dateEnd, roomId) => ({
      dateStart,
      dateEnd,
      roomId,
    })
  ),
  requestUserVisites: createAction('request visites', (startDate, endDate, roomId = undefined) => ({
    startDate,
    endDate,
    roomId,
  })),
  requestActiveSession: createAction(' request room active session', (roomId) => ({ roomId })),
  requestDailySession: createAction(
    ' request room active session of a day',
    (roomId, startDate, dateEnd) => ({ roomId, startDate, dateEnd })
  ),
  getSessionById: createAction(' request session by id', (id) => ({ id })),
  createSession: createAction(
    ' create session',
    (roomId, load = undefined, startDate = undefined) => ({
      roomId,
      load,
      startDate,
    })
  ),

  addActivity: createAction('add activity on home', (activity) => ({ activity })),
  addCoachActivity: createAction('add activity on coach', (activity) => ({ activity })),
  addUserVisites: createAction('add user visites', (visites) => ({ visites })),
  addSemesterActivity: createAction('add semester activity', (activity) => ({ activity })),
  setFetching: createAction('setFetching', (value) => ({ value })),
  setFullLoaded: createAction('setFullLoaded', (value) => ({ value })),
  setFetchingSemester: createAction('setFetching Sessions', (value) => ({ value })),
  setFullLoadedSemester: createAction('setFullLoaded Sessions', (value) => ({ value })),

  addActiveSession: createAction('add active session', (session) => ({ session })),
  addSession: createAction('add Session', (session) => ({ session })),
  addDailySession: createAction('add daily Session', (id) => ({ id })),
  updateActiveSession: createAction('update activeSession boulders', (sessionBoulderToUpdate) => ({
    sessionBoulderToUpdate,
  })),
  removeBoulderOnActiveSession: createAction(
    'remove boulder on ActiveSession boulders',
    (sessionBoulderId) => ({ sessionBoulderId })
  ),
  updateSession: createAction('update a Session boulders', (boulders, sessionId) => ({
    boulders,
    sessionId,
  })),
};

export const reducer = createReducer(
  {
    [actions.addActivity]: (state, { activity }) => state.setIn(['activity'], activity),
    [actions.addDailySession]: (state, { id }) => state.setIn(['dailySession'], id),
    [actions.addCoachActivity]: (state, { activity }) => state.setIn(['coachActivity'], activity),
    [actions.addUserVisites]: (state, { visites }) => state.setIn(['userVisites'], visites),
    [actions.resetPeriodicActivity]: (state) => state.setIn(['activity'], initialState.activity),
    [actions.addSemesterActivity]: (state, { activity }) =>
      state.setIn(['semesterActivity'], activity),

    [actions.setFetching]: (state, { value }) => state.setIn(['isFetching'], value),
    [actions.setFullLoaded]: (state, { value }) => state.setIn(['isFullLoaded'], value),
    [actions.setFetchingSemester]: (state, { value }) => state.setIn(['isFetchingSemester'], value),
    [actions.setFullLoadedSemester]: (state, { value }) =>
      state.setIn(['isFullLoadedSemester'], value),

    [actions.addActiveSession.toString()]: (state, { session }) => {
      return state.setIn(['activeSession'], session);
    },
    [actions.updateActiveSession]: reducers.updateActiveSession,
    [actions.removeBoulderOnActiveSession]: reducers.removeBoulderOnActiveSession,
    [actions.updateSession]: reducers.updateSession,

    [actions.addSession]: reducers.addSession,
  },
  initialState
);

export const selectors = {
  activity: (state) => state.sessions.activity,
  coachActivity: (state) => state.sessions.coachActivity,
  semesterActivity: (state) => state.sessions.semesterActivity,
  isFetching: (state) => state.sessions.isFetching,
  isFullLoaded: (state) => state.sessions.isFullLoaded,
  isFetchingSemester: (state) => state.sessions.isFetchingSemester,
  isFullLoadedSemester: (state) => state.sessions.isFullLoadedSemester,
  userVisites: (state) => state.sessions.userVisites,
  activeSession: (state) => state.sessions.activeSession,
  sessionIds: (state) => state.sessions.sessionIds,
  sessionById: (state, id) => state.sessions.sessionById[id],
  sessions: (state) => state.sessions.sessionById,
  dailySession: (state) => state.sessions.dailySession,
};
