import React, { useCallback, useMemo } from 'react';

import './Alert.scss';

interface Props {
  onAlertChange: Function;
  title: string;
  message: string;
  actionName: string;
  action: any;
  position: string;
  display: boolean;
  noTick?: boolean;
  className?: string;
}

const Alert: React.FC<Props> = ({
  onAlertChange,
  title,
  message,
  actionName,
  action,
  position,
  display,
  noTick,
  className = '',
}) => {
  const alertStyle = useMemo(() => (position ? { marginBottom: position } : undefined), [position]);
  const dismiss = useCallback(() => {
    onAlertChange(false);
  }, [onAlertChange]);

  if (!display) return null;

  return (
    <>
      <div className='alertBackdrop' onClick={dismiss} />
      <div className={`alert ${className}`} style={alertStyle}>
        <div className='alertContent'>
          <div className='alertMsg'>
            <div className='alertTitle'>{title}</div>
            <div className='alertDescription'>{message}</div>
            <button className='registerButton' onClick={action}>
              {actionName}
            </button>
          </div>
        </div>
        {!noTick && <div className='alertTick' />}
      </div>
    </>
  );
};

export default Alert;
