import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as EditorActions, selectors as EditorSelectors } from '../redux.js';

export const useDisplayItems = () => {
  const dispatch = useDispatch();

  const items = useSelector(EditorSelectors.displayItems);

  const addItem = useCallback((id, meta) => dispatch(EditorActions.addItem(id, meta)), [dispatch]);
  const updateMeta = useCallback(
    (id, meta) => dispatch(EditorActions.updateMeta(id, meta)),
    [dispatch]
  );
  const removeItem = useCallback((id) => dispatch(EditorActions.removeItem(id)), [dispatch]);

  return {
    items,
    addItem,
    updateMeta,
    removeItem,
  };
};

export const useDisplayItem = (id) => {
  const dispatch = useDispatch();

  const item = useSelector((state) => EditorSelectors.displayItems(state, id));

  const updateMeta = useCallback(
    (meta) => dispatch(EditorActions.updateMeta(id, meta)),
    [id, dispatch]
  );
  const removeItem = useCallback(() => dispatch(EditorActions.removeItem(id)), [id, dispatch]);

  return {
    item,
    updateMeta,
    removeItem,
  };
};
