import { all, delay, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

// Redux
import { actions as AuthActions } from './redux';
import { actions as AppActions } from '../app/redux';
import { actions as UserActions } from '../user/redux';

// Libraries
import axios from 'axios';

// Utils
import { LOGIN_URL, CHECKAUTH, DELETE_ACCOUNT_URL, LOGIN_QRCODE_URL } from '../../utils/env';
import getHeader from '../../utils/getHeader';
import { trackEvent, trackUserLogged } from '../../providers/tracker';

export default class AuthSagas {
  static *login({ payload }) {
    const { username, password, callback } = payload;
    try {
      yield put(AppActions.setAppLoader(true));
      const res = yield axios.post(
        LOGIN_URL,
        {
          username,
          password,
        },
        {
          headers: yield getHeader(),
        }
      );
      if (res?.data?.token) {
        yield trackUserLogged(res.data.user.id);
        yield put(AuthActions.setToken(res.data.token));
        yield put(AuthActions.setIsOnboarded(true)); //res.data.user.isOnboarded
        yield put(UserActions.requestCurrentUser(() => callback(true))); //res.data.user.isOnboarded
        return;
      }
      yield put(AppActions.setAppLoader(false));
      return;
    } catch (e) {
      Sentry.captureException(e);
      callback(false, 'error_credentials_not_valid');
      console.warn(e);
    }
    yield put(AppActions.setAppLoader(false));
  }

  static *loginQrCode({ payload }) {
    const { code, callback } = payload;
    try {
      yield put(AppActions.setAppLoader(true));
      const res = yield axios.post(
        LOGIN_QRCODE_URL,
        {
          code,
        },
        {
          headers: yield getHeader(),
        }
      );
      if (res?.data?.token) {
        yield trackUserLogged(res.data.user.id);
        yield put(AuthActions.setToken(res.data.token));
        yield put(AuthActions.setIsOnboarded(true)); //res.data.user.isOnboarded
        yield put(UserActions.requestCurrentUser(() => callback(true))); //res.data.user.isOnboarded
        return;
      }
      yield put(AppActions.setAppLoader(false));
      return;
    } catch (e) {
      Sentry.captureException(e);
      callback(false, 'error_credentials_not_valid');
      console.warn(e);
    }
    yield put(AppActions.setAppLoader(false));
  }

  static *checkConnection({ payload }) {
    try {
      const { cb } = payload;
      const conf = { headers: getHeader() };
      const res = yield axios.get(CHECKAUTH, conf);

      if (res.data !== 'connected' || !cb) {
        yield put(AuthActions.clearAuth());
      } else {
        cb();
      }
    } catch (e) {
      console.warn(e);
      Sentry.captureException(e);
    }
  }

  static *logout() {
    try {
      yield trackEvent('logout_click', {
        event_category: 'profile',
        event_action: 'logout_click',
      });
      yield trackUserLogged(undefined);
      yield put(AuthActions.clearAuth());
      yield put(AppActions.setAppLoader(true));
      yield put(AppActions.requestAppLogout());
      yield delay(1500);
      yield put(AppActions.setAppLoader(false));
      window.location.href = '/home';
    } catch (e) {
      console.warn(e);
      Sentry.captureException(e);
    }
  }

  static *deleteAccount() {
    try {
      yield put(AppActions.setAppLoader(true));
      const res = yield axios.delete(DELETE_ACCOUNT_URL, {
        headers: getHeader(),
      });
      if (res) {
        yield trackEvent('delete_account_apply');
        yield put(AuthActions.requestLogout());
      }
    } catch (e) {
      yield put(AuthActions.setError(e?.response?.data?.message));
      yield put(AppActions.setAppLoader(false));
      console.warn(e);
      Sentry.captureException(e);
    }
  }

  static *loop() {
    yield all([
      takeLatest(AuthActions.requestLogin.getType(), AuthSagas.login),
      takeLatest(AuthActions.requestLoginQrCode.getType(), AuthSagas.loginQrCode),
      takeLatest(AuthActions.checkConnection.getType(), AuthSagas.checkConnection),
      takeLatest(AuthActions.requestLogout.getType(), AuthSagas.logout),
      takeLatest(AuthActions.requestDeleteAccount.getType(), AuthSagas.deleteAccount),
    ]);
  }
}
