import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as SectorsActions, selectors as SectorsSelector } from './redux.js';

export const useFilteredSectors = () => {
  const dispatch = useDispatch();

  const sectors = useSelector((state) => SectorsSelector.sectors(state));
  const sector = useSelector((state) => SectorsSelector.sector(state));
  const isFetching = useSelector((state) => SectorsSelector.isFetching(state));
  const isFullLoaded = useSelector((state) => SectorsSelector.isFullLoaded(state));

  const getFilteredSectors = useCallback(
    (roomId?: number, orderBy?: any) =>
      // @ts-ignore
      dispatch(SectorsActions.requestFilteredSectors(roomId, orderBy)),
    [dispatch]
  );

  return {
    sector,
    sectors,
    isFetching,
    isFullLoaded,
    getFilteredSectors,
  };
};

export const useSector = () => {
  const dispatch = useDispatch();

  const sector = useSelector((state) => SectorsSelector.sector(state));
  const isFetching = useSelector((state) => SectorsSelector.isFetching(state));
  const isFullLoaded = useSelector((state) => SectorsSelector.isFullLoaded(state));

  const getSector = useCallback((id) => dispatch(SectorsActions.requestSector(id)), [dispatch]);
  const sectorReset = useCallback(() => dispatch(SectorsActions.sectorReset()), [dispatch]);

  return {
    sector,
    isFetching,
    isFullLoaded,
    getSector,
    sectorReset,
  };
};
