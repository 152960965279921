import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

// Libraries

// Components & Types
import Icon from '../Icon/Icon';
import IconTypes from '../../types/IconTypes';

// Styles
import './ButtonIconIncr.scss';

interface ButtonIconIncrProps {
  value: number;
  color?: string;
  myValue: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
}

const ButtonIconIncr: React.FC<ButtonIconIncrProps> = React.memo(
  ({ value = 0, color, myValue = 0, disabled, onChange }) => {
    const increment = useRef<number>(myValue);
    const intervalRef: any = useRef<NodeJS.Timer | null>(null);

    const [hold, setHold] = useState<boolean>(false);
    const [counter, setCounter] = useState<number>(myValue);

    const iconStyle = useMemo<{ backgroundColor?: string; opacity?: number } | undefined>(
      () =>
        hold && color
          ? {
              backgroundColor: color.replace('1)', '0.5)'),
              opacity: 1,
            }
          : { opacity: disabled || counter >= 50 ? 0.5 : 1 },
      [color, counter, disabled, hold]
    );
    const incrStyle = useMemo<{ backgroundColor: string } | undefined>(
      () => (color ? { backgroundColor: color } : undefined),
      [color]
    );

    useEffect(
      () => () => {
        if (intervalRef.current) clearInterval(intervalRef.current);
      },
      []
    );

    useEffect(() => {
      setCounter(myValue);
      increment.current = myValue;
    }, [myValue]);

    const handleStart = useCallback(() => {
      if (counter >= 50) return;

      setHold(true);
      setCounter(counter + 1);

      if (intervalRef.current) return;
      intervalRef.current = setInterval(() => {
        setCounter((prevCounter: number) => {
          if (prevCounter < 50) {
            Haptics.impact({ style: ImpactStyle.Light });
            return prevCounter + 1;
          }
          return prevCounter;
        });
      }, 200);
    }, [counter]);

    const handleStop = useCallback(() => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      onChange?.(counter - increment.current);
      increment.current = counter;
      setHold(false);
    }, [counter, onChange]);

    return (
      <div className='incrContainer'>
        {(value > 0 || counter > 0) && (
          <div className='buttonIncr_count' style={incrStyle}>
            <span>{hold ? '+' + counter : value}</span>
          </div>
        )}
        <div
          className='buttonIncr_icon'
          onPointerDown={!disabled ? handleStart : undefined}
          onPointerUp={!disabled ? handleStop : undefined}
          style={iconStyle}
        >
          <Icon icon={IconTypes.CLAP} className='large_nob_icon' />
        </div>
      </div>
    );
  }
);

export default ButtonIconIncr;
