import { useCallback, useEffect, useState } from 'react';
import Toggle from '../../../components/Toggle/Toggle';
import { useLocalize } from '../../../redux/translation/localize';
import { useCurrentUser } from '../../../redux/user/hooks';
import './ProfilNotifications.scss';

const ProfilNotifications: React.FC<unknown> = () => {
  const t = useLocalize();
  const { updateAccount, currentUser } = useCurrentUser();

  const [info, setInfo] = useState({
    news: currentUser.rgpd_directemails,
    sms: currentUser.rgpd_sms,
    notifications: currentUser.rgpd_notifications_byemail,
  });

  const handleNews = useCallback(() => {
    updateAccount({ rgpd_directemails: !info.news });
    setInfo((prev) => ({ ...prev, news: !prev.news }));
  }, [info, updateAccount]);

  const handleSMS = useCallback(() => {
    updateAccount({ rgpd_sms: !info.sms });
    setInfo((prev) => ({ ...prev, sms: !prev.sms }));
  }, [info, updateAccount]);

  const handleNotifications = useCallback(() => {
    updateAccount({ rgpd_notifications_byemail: !info.notifications });
    setInfo((prev) => ({ ...prev, notifications: !prev.notifications }));
  }, [info, updateAccount]);

  useEffect(() => {
    setInfo({
      news: currentUser.rgpd_directemails,
      sms: currentUser.rgpd_sms,
      notifications: currentUser.rgpd_notifications_byemail,
    });
  }, [currentUser]);

  return (
    <div className='ProfilNotifications'>
      <div className='head'>
        <div className='title'>{t('notifications_title')}</div>
        <div className='subtitle'>{t('notifications_subtitle')}</div>
      </div>
      <div className='toggleBox'>
        <div className='label'>{t('newsletter')}</div>
        <Toggle value={info.news} onChange={handleNews} />
      </div>
      <div className='toggleBox'>
        <div className='label'>{t('sms')}</div>
        <Toggle value={info.sms} onChange={handleSMS} />
      </div>
      <div className='toggleBox'>
        <div className='label'>{t('notifications')}</div>
        <Toggle value={info.notifications} onChange={handleNotifications} />
      </div>
      <div className='disclaimer'>{t('notifications_disclaimer')}</div>
    </div>
  );
};

export default ProfilNotifications;
