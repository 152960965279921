import React, { useCallback, useMemo } from 'react';
import { useArticle } from '../../redux/news/hooks';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import './NewsSlide.scss';
import OptimizedImage from '../../atoms/OptimizedImage/OptimizedImage';
import { trackEvent } from '../../providers/tracker';

interface Props {
  id: any;
}

const NewsSlide: React.FC<Props> = ({ id }) => {
  const { article }: any = useArticle(id);
  const date = useMemo(() => (article ? new Date(article.creationDate) : null), [article]);

  const handleNewClick = useCallback(() => {
    trackEvent('news', article);
    if (!article?.link) return;
    InAppBrowser.create(article.link);
  }, [article]);

  return (
    <div className='slideContent' onClick={handleNewClick}>
      <OptimizedImage
        className='slider_img'
        src={article?.image}
        alt='Dernière news'
        format='fullScreen'
      />
      <div className='article_info'>
        <div className='location_info'>
          <span className='room_name'>{article?.room?.name}</span>
          {!!date && (
            <span className='date'>
              &nbsp;.&nbsp;
              {new Intl.DateTimeFormat('fr-FR', {
                month: 'short',
                day: '2-digit',
              }).format(date)}
            </span>
          )}
        </div>
        <div className='articleTitle'>{article?.title + '\n' + article?.content}</div>
      </div>
    </div>
  );
};

export default NewsSlide;
