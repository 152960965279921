import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useFilter } from '../../redux/filters/hooks';
import { useLocalize } from '../../redux/translation/localize';
import { useSectorBoulders, useSectors } from '../../redux/boulders/hooks';
import { useSelectedRoom, useSelectedWall } from '../../redux/rooms/hooks';
import {
  IonButton,
  IonContent,
  IonPage,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
} from '@ionic/react';

import RoomFilters from './partials/RoomFilters/RoomFilters';
import Header from '../../routes/components/Header/Header';
import SectorCard from '../../components/SectorCard/SectorCard';
import ColorCard from '../../components/ColorCard/ColorCard';

import wall from '../../utils/wall.interface';
import './Room.scss';
import ROUTES from '../../routes/constants';
import { trackEvent } from '../../providers/tracker';

let prev = {
  selectedTags: [],
  selectedColors: [],
  selectedIntensities: [],
};

const setPrev = (newPrev) => {
  prev = newPrev;
};
const compareWithPrev = (prevToCheck) => {
  try {
    return JSON.stringify(prev) !== JSON.stringify(prevToCheck);
  } catch (err) {
    return false;
  }
};

export const RenderSector: React.FC<{
  sectorId: string;
  wall: wall;
  colorFilters?: number[];
  tagFilters?: number[];
  intensityFilters?: number[];
  sectorIds?: number[];
  prevRoute: string;
}> = ({ sectorId, wall, colorFilters, tagFilters, intensityFilters, sectorIds, prevRoute }) => {
  const sector = useSectorBoulders(sectorId);
  const { isFetching } = useSectors();

  const boulders = useMemo(() => {
    return sector?.boulders.filter((boulder) => {
      if (
        (!colorFilters?.length ||
          (colorFilters.length && colorFilters.includes(boulder.color.id))) &&
        (!intensityFilters?.length ||
          (intensityFilters.length && intensityFilters.includes(boulder.intensity.id)))
      ) {
        const boulderTags = boulder.bouldertags.map((tag) => tag.tag.id);
        let isHere = false;
        for (const tagId of boulderTags) {
          if (tagFilters?.includes(tagId)) isHere = true;
        }
        if (tagFilters?.length && !isHere) return undefined;
        return boulder;
      }
      return undefined;
    });
  }, [colorFilters, intensityFilters, sector?.boulders, tagFilters]);

  const boulderToUse = useMemo(() => {
    return [...boulders].sort((a, b) => (a.color.id < b.color.id ? -1 : 1));
  }, [boulders]);

  const colors = useMemo(() => {
    if (!boulderToUse) return [];
    const cColors = boulderToUse.map((boulder: any) => {
      if (!!colorFilters?.length && colorFilters.includes(boulder.color.id)) {
        return boulder.color.name;
      } else if (!!colorFilters && !colorFilters?.length) {
        return boulder.color.name;
      }
      return '';
    });
    return cColors.filter((value, index, self) => self.indexOf(value) === index);
  }, [boulderToUse, colorFilters]);

  return (
    <SectorCard
      wall={wall}
      colors={colors}
      key={sectorId}
      sectorId={sectorId}
      colorFilters={colorFilters}
      tagFilters={tagFilters}
      intensityFilters={intensityFilters}
      sectorIds={sectorIds}
      isFetching={isFetching}
      prevRoute={prevRoute}
    />
  );
};

const Room: React.FC = () => {
  const t = useLocalize();
  const { requestGetSectors, sectorList, isFetching, isFullLoaded } = useSectors();
  const { selectedWallId } = useSelectedWall();
  const { room } = useSelectedRoom();
  const { colors, getFilters } = useFilter();
  const location = useLocation();
  const { colorFilters, tagFilters, intensityFilters } = useMemo(
    () => ({
      colorFilters: location.state?.colorFilters || [],
      tagFilters: location.state?.tagFilters || [],
      intensityFilters: location.state?.intensityFilters || [],
    }),
    [location.state]
  );

  // States
  const [showModal, setShowModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState<number[]>(tagFilters);
  const [selectedColors, setSelectedColors] = useState<number[]>(colorFilters);
  const [selectedIntensities, setSelectedIntensities] = useState<number[]>(intensityFilters);

  const wall = useMemo(
    () => room?.walls.find((wall: wall) => wall.id === selectedWallId),
    [selectedWallId, room?.walls]
  );

  const planPicture = wall?.plan || room?.plan;

  const filterBoulders = useCallback(
    (selectedTags, selectedColors, selectedIntensities, reset) => {
      let tagIds = selectedTags;
      let colorIds = selectedColors;
      let intensityIds = selectedIntensities;
      if (selectedTags.length === 0) tagIds = null;
      if (selectedColors.length === 0) colorIds = null;
      if (selectedIntensities.length === 0) intensityIds = null;
      setPrev({
        roomId: room?.id,
        wallId: wall?.id,
        selectedTags,
        selectedColors,
        selectedIntensities,
      });
      requestGetSectors(room?.id, wall?.id, colorIds, tagIds, intensityIds, reset);
    },
    [requestGetSectors, room?.id, wall?.id]
  );

  const toggelModal = useCallback(() => {
    trackEvent('room_additional_filter', {
      event_category: 'room',
      event_action: 'clic_additional_filter',
    });
    setShowModal(!showModal);
  }, [showModal]);

  const addColor = useCallback(
    (id: number) => {
      trackEvent('room_filter', {
        event_category: 'room',
        event_action: 'click_room_filter',
        event_label: 'filter_color_click',
      });

      if (selectedColors.includes(id)) {
        const temp = [...selectedColors];
        setSelectedColors(temp.filter((el) => el !== id));
      } else {
        setSelectedColors((prevState: number[]) => [...prevState, id]);
      }
    },
    [setSelectedColors, selectedColors]
  );

  const handleScroll = useCallback(
    (e) => {
      if (isFetching || isFullLoaded) {
        e.target.complete();
        return;
      }
      filterBoulders(selectedTags, selectedColors, selectedIntensities, true);
      e.target.complete();
    },
    [isFetching, isFullLoaded, filterBoulders, selectedTags, selectedColors, selectedIntensities]
  );

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shouldReset = compareWithPrev({
      roomId: room?.id,
      wallId: wall?.id,
      selectedTags,
      selectedColors,
      selectedIntensities,
    });

    if (shouldReset) {
      filterBoulders(selectedTags, selectedColors, selectedIntensities, !shouldReset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags, selectedColors, selectedIntensities, wall, room]);

  return (
    <IonPage id='Room'>
      <Header
        showProfile={false}
        autoSelect
        showRoom
        showMonth={false}
        showWalls
        isCoach={false}
        showSectors={false}
      />
      <IonContent className='room_content' id='room_content'>
        <div className='colorSelector'>
          <div className='selectorTitle'>Niveaux</div>
          <div className='filtreColor'>
            {colors.map((col: any, index: number) => {
              return (
                <ColorCard
                  colorName={col.name}
                  index={col.id}
                  key={index}
                  selectIndex={addColor}
                  selectedIndex={selectedColors.includes(col.id) ? col.id : 0}
                  multiple
                />
              );
            })}
          </div>
        </div>
        <IonButton
          fill='outline'
          expand='block'
          className='filter_button'
          onClick={toggelModal}
          style={{
            '--background':
              selectedTags.length > 0 || selectedIntensities.length > 0 ? '#fff300' : '',
          }}
        >
          {t('filter_supp')}
        </IonButton>
        {
          <div className='sector_list'>
            <div className='hint_filter'>
              <span>{t('room_description')}</span>
            </div>
            <div className='flex_list'>
              {sectorList.map((sectorId: any, index: number) => (
                <RenderSector
                  key={index}
                  sectorId={sectorId}
                  wall={wall}
                  colorFilters={selectedColors}
                  tagFilters={selectedTags}
                  intensityFilters={selectedIntensities}
                  sectorIds={sectorList}
                  prevRoute={ROUTES.ROOM}
                />
              ))}
              {isFetching && <IonSpinner color='primary' name='crescent' />}
            </div>
          </div>
        }
        {!!room?.id && isFullLoaded && (
          <div className='room_plan'>
            <div className='title'>
              <span>{t('plan')}</span>
            </div>
            <div className='plan_img'>
              <img crossOrigin='anonymous' src={planPicture} alt='room plan' />
            </div>
          </div>
        )}
        <IonInfiniteScroll onIonInfinite={handleScroll} className='infinitScroll'>
          <IonInfiniteScrollContent
            loadingText='Please wait...'
            loadingSpinner='bubbles'
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <RoomFilters
          showModal={showModal}
          handleDismiss={toggelModal}
          initialTags={selectedTags}
          sendSelectedTags={setSelectedTags}
          initialIntensities={selectedIntensities}
          sendSelectedIntensities={setSelectedIntensities}
        />
      </IonContent>
    </IonPage>
  );
};

export default Room;
