import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import './ProgressCircle.scss';
import 'react-circular-progressbar/dist/styles.css';

interface Props {
  color: string;
  value: number;
  styleOff?: boolean;
}

const ProgressCircle: React.FC<Props> = ({ color, value, styleOff }) => (
  <div className='progressContainer'>
    <div className={styleOff ? 'progressCicleOff' : 'progressCicle'}>
      <CircularProgressbar
        value={value}
        text={''}
        styles={{
          text: { fontSize: '40px' },
          path: { stroke: color },
        }}
        strokeWidth={9}
      />
    </div>
    <div className='value' style={{ color: color }}>
      {value}
    </div>
  </div>
);

export default ProgressCircle;
