import { ChangeEvent, useCallback, useState } from 'react';
import { useResetPassword } from '../../redux/account/hooks';
import { useLocalize } from '../../redux/translation/localize';
import Input from '../../atoms/Input/Input';
import Icon from '../../atoms/Icon/Icon';
import { useNavigate } from 'react-router';

import './ForgotPassword.scss';

const ForgotPassword: React.FC<unknown> = () => {
  const t = useLocalize();
  const navigate = useNavigate();
  const { resetPassword } = useResetPassword();

  const [email, setEmail] = useState<string>();
  const [emailMessage, setMessage] = useState<string>('');

  const handleSetEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
    [setEmail]
  );

  const handleCallback = useCallback((status: any) => {
    if (status !== 201 && status !== 404) {
      setMessage(`email_recover_MAJOR`);
    } else {
      setMessage(`email_recover_${status}`);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    setMessage('');
    resetPassword(email, handleCallback);
  }, [email, handleCallback, resetPassword]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className='ForgotPassword'>
      <div className='LoginHeader'>
        <h1>{t('email_account_title')}</h1>
        <p>{t('email_account_subTitle')}</p>
        <div className='skipOnboarding' onClick={handleGoBack}>
          <Icon icon='close' />
        </div>
      </div>
      <div className='content'>
        <div className='contentLogin'>
          <Input
            type='email'
            className='inputLogin'
            value={email}
            placeholder={t('email')}
            onChange={handleSetEmail}
          />
          <span className={`message ${!emailMessage.includes('201') ? 'error' : 'success'}`}>
            {t(emailMessage)}
          </span>
          <button disabled={!email?.length} className='contentLoginBtn' onClick={handleSubmit}>
            {t('send')}
          </button>
        </div>
      </div>
      <div className='loginFooter'>
        <Icon icon='buddy-boulder' />
        <p>{t('login_footer')}</p>
      </div>
    </div>
  );
};

export default ForgotPassword;
