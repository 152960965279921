// Hooks
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLogged } from '../../redux/auth/hooks';
import { useCurrentUser } from '../../redux/user/hooks';
import { useSelectedRoom } from '../../redux/rooms/hooks';
import { useActiveSession } from '../../redux/sessions/hooks';
import { useBoulderValidationConditions } from '../../redux/boulders/hooks';

// Components
import { ModalContent } from '../../pages/Sector/Sector';
import ButtonIconIncr from '../../atoms/ButtonIconIncr/ButtonIconIncr';

// Utils
import './BoulderNob.scss';
import IconTypes from '../../types/IconTypes';
import Boulder from '../../utils/boulder.interface';
import BoulderNobItem from './partials/BoulderNobItem/BoulderNobItem';
import { trackEvent } from '../../providers/tracker';

interface Props {
  clap: number;
  color: string;
  colorRgba: string;
  boulder: Boulder;
  totalClaps: number;
  showModal: boolean;
  auditTemp?: string;
  disableNext: boolean;
  disablePrev: boolean;
  isPanelOpen: boolean;
  modalContent: ModalContent;

  onFail: () => void;
  hideTop: () => void;
  setPanel: () => void;
  nextBloc: () => void;
  prevBloc: () => void;
  invalidateBloc: () => void;
  onTop: (flash: boolean) => void;
  addClap: (value: number) => void;
  setModal: (key: string, initBreakpoint: number | null) => void;
}

const BoulderNob: React.FC<Props> = ({
  modalContent,
  setModal,
  onTop,
  invalidateBloc,
  isPanelOpen,
  addClap,
  color,
  colorRgba,
  clap,
  totalClaps = 0,
  boulder,
  hideTop,
  // audits,
  nextBloc,
  prevBloc,
  disableNext,
  disablePrev,
  auditTemp,
}) => {
  const { logged } = useLogged();
  const { room } = useSelectedRoom();
  const { currentUser } = useCurrentUser();
  const [_panel, setPanel] = useState<string>();
  const { activeSession } = useActiveSession();

  const { isFlashable, messageParams } = useBoulderValidationConditions(boulder?.id);

  const boulderStatus = useMemo(() => {
    if (auditTemp) return auditTemp;
    const index = activeSession?.sessionboulders?.findIndex(
      (boul) => boul?.boulderId === boulder?.id
    );
    return index || index === 0 ? activeSession?.sessionboulders[index]?.status : undefined;
  }, [activeSession?.sessionboulders, auditTemp, boulder?.id]);

  const colorHalfOpacity = useMemo(() => colorRgba?.replace('1)', '0.5)'), [colorRgba]);
  const locationStyle = useMemo(() => ({ backgroundColor: color }), [color]);
  const demoStyle = useMemo(
    () => ({ backgroundColor: modalContent.blocInfo ? colorHalfOpacity : '' }),
    [colorHalfOpacity, modalContent.blocInfo]
  );
  // const challengeStyle = useMemo(
  //   () => ({ backgroundColor: modalContent.blocDefi ? colorHalfOpacity : '' }),
  //   [colorHalfOpacity, modalContent.blocDefi]
  // );
  const panelStyle = useMemo(() => ({ backgroundColor: '' }), []);

  useEffect(() => {
    setPanel(undefined);
  }, [isPanelOpen]);

  // Handlers
  const handleClap = useCallback(
    (value) => {
      if (currentUser) {
        trackEvent('block_clap', { value, blockId: boulder?.id, sectorId: boulder?.id });
        addClap(value);
      }
    },
    [addClap, boulder?.id, currentUser]
  );

  const sendTop = useCallback(() => {
    onTop(false);
    setPanel('top');
  }, [onTop]);

  const sendFlash = useCallback(() => {
    if (boulderStatus === 'FLASH') {
      invalidateBloc();
    } else {
      onTop(true);
      setPanel('flash');
    }
  }, [boulderStatus, invalidateBloc, onTop]);

  const hideAlert = useCallback(
    (e) => {
      if (e.currentTarget === e.target) hideTop();
    },
    [hideTop]
  );

  return (
    <div className='action_nobs' onClick={hideAlert}>
      <div className='action_nobs_side'>
        <BoulderNobItem
          breakpoint={0.95}
          keyValue='blocInfo'
          className='small_nob'
          icon={IconTypes.INFO}
          onClick={setModal}
          style={demoStyle}
          disabled={!boulder?.mediaHelp && !boulder?.textHelp}
        />
        <BoulderNobItem
          keyValue='blocInfo'
          className='small_nob'
          icon={IconTypes.LEFT_OPEN}
          onClick={prevBloc}
          style={panelStyle}
          disabled={disablePrev}
        />
      </div>
      <div className='action_nobs_side'>
        {/* <BoulderNobItem
          keyValue='blocDefi'
          className='small_nob'
          icon={IconTypes.VIDEO}
          onClick={setModal}
          style={challengeStyle}
        /> */}
        <BoulderNobItem
          keyValue='blocValidation'
          className='small_nob'
          icon={IconTypes.RIGHT_OPEN}
          onClick={nextBloc}
          style={panelStyle}
          disabled={disableNext}
        />
        <div className='auditAndPoint'>
          <ButtonIconIncr
            disabled={!logged}
            value={totalClaps}
            myValue={clap}
            color={colorRgba}
            onChange={handleClap}
          />
          {boulderStatus !== 'FLASH' && boulderStatus !== 'TOP' && (
            <BoulderNobItem
              id='top'
              keyValue='blocValidation'
              className='top_nob'
              icon={IconTypes.TICK}
              onClick={sendTop}
              disabled={logged ? boulderStatus === 'TOP' || !room?.id : false}
              messageParams={messageParams}
            />
          )}
          {(boulderStatus === 'TOP' || boulderStatus === 'FLASH') && (
            <BoulderNobItem
              keyValue='blocValidation'
              className='thumb_nob'
              style={locationStyle}
              icon={IconTypes.LIKE}
              onClick={invalidateBloc}
            />
          )}
          <BoulderNobItem
            id='flash'
            keyValue='blocValidation'
            className='flash_nob'
            icon={IconTypes.BLOCS}
            onClick={sendFlash}
            messageParams={messageParams}
            style={boulderStatus === 'FLASH' ? locationStyle : undefined}
            disabled={
              boulderStatus === 'FAIL' ||
              boulderStatus === 'TOP' ||
              !room?.id ||
              messageParams.status === 'TOP' ||
              (!isFlashable && boulderStatus !== 'FLASH')
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BoulderNob;
