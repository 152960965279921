import { client } from '../../App';
import { actions as SessionsActions } from './redux';
import { actions as AuditsActions } from '../audits/redux';
import { all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import {
  GetPeriodicSessions,
  GetUserVisit,
  GetActiveSession,
  CreateSession,
  GetSessionById,
  GetDailySessions,
} from '../../GraphQL/GetSessions';
export default class SessionsSagas {
  static *loadPeriodicActivity({ payload }) {
    try {
      yield put(SessionsActions.setFetching(true));
      const { dateStart, dateEnd, roomId, isHome } = payload;
      let result;
      if (roomId) {
        result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetPeriodicSessions,
          variables: { roomId, dateStart, dateEnd },
        });
      } else {
        result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetPeriodicSessions,
          variables: {
            dateStart: dateStart,
            dateEnd: dateEnd,
          },
        });
      }
      if (result?.data) {
        if (isHome) yield put(SessionsActions.addActivity(result.data.sessions));
        else yield put(SessionsActions.addCoachActivity(result.data.sessions));
        yield put(SessionsActions.setFullLoaded(true));
      }
      yield put(SessionsActions.setFetching(false));
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadSemesterActivity({ payload }) {
    try {
      yield put(SessionsActions.setFetchingSemester(true));
      const { dateStart, dateEnd, roomId } = payload;
      let result;
      if (roomId) {
        result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetPeriodicSessions,
          variables: { roomId, dateStart, dateEnd },
        });
      } else {
        result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetPeriodicSessions,
          variables: {
            dateStart: dateStart,
            dateEnd: dateEnd,
          },
        });
      }
      if (result?.data) {
        yield put(SessionsActions.addSemesterActivity(result.data.sessions));
        yield put(SessionsActions.setFullLoadedSemester(true));
      }
      yield put(SessionsActions.setFetchingSemester(false));
    } catch (err) {
      console.warn(err);
      yield put(SessionsActions.setFetchingSemester(false));
      Sentry.captureException(err);
    }
  }

  static *loadDailySession({ payload }) {
    try {
      const { roomId, startDate, dateEnd } = payload;
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetDailySessions,
        variables: { roomId, dateStart: startDate, dateEnd: dateEnd },
      });
      if (result?.data) {
        const session = result.data.sessions.find((session) => {
          return new Date(session.startDate).toDateString() === startDate;
        });
        yield put(SessionsActions.addDailySession(session?.id));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadUserVisites({ payload }) {
    try {
      const { startDate, endDate, roomId } = payload;
      const result = yield client.query({
        fetchPolicy: 'network-only',
        query: GetUserVisit,
        variables: {
          startDate,
          endDate,
          roomId,
        },
      });
      if (result?.data) {
        yield put(SessionsActions.addUserVisites(result.data.qrCodeFlashHistoryByDate));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loadActiveSession({ payload }) {
    try {
      const { roomId } = payload;
      if (roomId) {
        const result = yield client.query({
          fetchPolicy: 'network-only',
          query: GetActiveSession,
          variables: {
            roomId,
          },
        });
        if (result?.data) {
          yield put(SessionsActions.addActiveSession(result.data.activesession));
        }
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *createSession({ payload }) {
    try {
      const { roomId, startDate, load } = payload;
      const result = yield client.mutate({
        mutation: CreateSession,
        fetchPolicy: 'network-only',
        variables: {
          roomId,
          startDate,
        },
      });
      if (result) {
        if (!startDate) {
          yield put(SessionsActions.addActiveSession(result.data.createSession));
          if (load) {
            yield put(
              AuditsActions.sendSingleValidation(
                load.boulderId,
                load.roomId,
                load.isCoaching,
                load.status
              )
            );
          }
        }
        if (startDate) {
          yield put(SessionsActions.addSession(result.data.createSession));
          if (load) {
            yield put(
              AuditsActions.sendMultipleValidation(
                load.roomId,
                load.boulders,
                startDate,
                result.data.createSession.id
              )
            );
          }
        }
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *getSessionById({ payload }) {
    try {
      const { id } = payload;
      const result = yield client.mutate({
        fetchPolicy: 'network-only',
        mutation: GetSessionById,
        variables: { id },
      });
      if (result?.data) {
        yield put(SessionsActions.addSession(result.data.session));
      }
    } catch (err) {
      console.warn(err);
      Sentry.captureException(err);
    }
  }

  static *loop() {
    yield all([
      takeEvery(
        SessionsActions.requestPeriodicActivity.getType(),
        SessionsSagas.loadPeriodicActivity
      ),
      takeLatest(
        SessionsActions.requestSemesterActivity.getType(),
        SessionsSagas.loadSemesterActivity
      ),
      takeLatest(SessionsActions.requestDailySession.getType(), SessionsSagas.loadDailySession),
      takeLatest(SessionsActions.requestUserVisites.getType(), SessionsSagas.loadUserVisites),
      takeLatest(SessionsActions.requestActiveSession.getType(), SessionsSagas.loadActiveSession),
      takeLatest(SessionsActions.createSession.getType(), SessionsSagas.createSession),
      takeLatest(SessionsActions.getSessionById.getType(), SessionsSagas.getSessionById),
    ]);
  }
}
