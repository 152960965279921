import { createAction } from 'redux-act';

export const initialState = {
  tagText: '',
  tagStyleIndex: 0,
};

export const actions = {
  setTagText: createAction('Editor: set text', (text) => ({ text })),
  setTagStyleIndex: createAction('Editor: set style index', (styleIndex) => ({
    styleIndex,
  })),
};

export const reducers = {
  [actions.setTagText]: (state, { text }) => state.setIn(['tagText'], text),
  [actions.setTagStyleIndex]: (state, { styleIndex }) => state.setIn(['tagStyleIndex'], styleIndex),
};

export const selectors = {
  tagText: (state) => state.editor.tagText,
  tagStyleIndex: (state) => state.editor.tagStyleIndex,
};
