import { IonIcon } from '@ionic/react';
import { square } from 'ionicons/icons';
import { useCallback } from 'react';

import './VideoFooter.scss';

interface Props {
  capturing: boolean;
  permission: string;
  onStart?: (ev: any) => void;
  onStop?: (ev: any) => void;
}

const VideoFooter: React.FC<Props> = ({ capturing, permission, onStart, onStop }) => {
  const handleClick = useCallback(
    (ev: any) => {
      if (permission !== 'denied') !capturing ? onStart?.(ev) : onStop?.(ev);
    },
    [capturing, onStart, onStop, permission]
  );
  return (
    <div id='VideoFooter'>
      <div
        className={`button ${capturing ? 'square' : 'camera'} ${
          permission === 'denied' && 'disabled'
        }`}
      >
        {!!capturing && (
          <button onClick={handleClick} className='square'>
            <IonIcon className='square' icon={square} />
          </button>
        )}
        {!capturing && <button className='camera' onClick={handleClick} />}
      </div>
    </div>
  );
};

export default VideoFooter;
