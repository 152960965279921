export enum PriceType {
  FULL_PRICE = 'full-price',
  REDUCED_PRICE = 'reduced-price',
  CHILD_PRICE = 'child-price',
}

export enum AccessType {
  SUBSCRIPTION = 'subscription',
  TICKET_BOOK = 'ticket-book',
}

export type DefaultAccess = {
  title: string;
};

export type AccessDto = {
  customer: number;
  subscription_name: string;
  start_date: string;
  end_date: string;
  commitment_end: string;
  sessions: number;
  remaining_sessions: number;
};

export const PriceTypeOrder = {
  [PriceType.FULL_PRICE]: 0,
  [PriceType.REDUCED_PRICE]: 1,
  [PriceType.CHILD_PRICE]: 2,
};

export const AccessTypeOrder = {
  [AccessType.SUBSCRIPTION]: 0,
  [AccessType.TICKET_BOOK]: 1,
};
