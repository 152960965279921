import { gql } from '@apollo/client';

export const GetCoaching = gql`
  query GetCoaching($volume: Int!, $time: Float!, $roomId: Int!) {
    coaching(volume: $volume, time: $time, roomId: $roomId) {
      id
      createDate
      color {
        id
        name
      }
      wall {
        name
        id
      }
      room {
        id
        name
      }
      sector {
        plan
        name
      }
      picture
      coefficient {
        coefficient
      }
    }
  }
`;

export const CreateSession = gql`
  mutation CreateSession($volume: Int!, $roomId: Int!, $boulderIds: [Int]!) {
    createSession(
      createSessionData: { volume: $volume, roomId: $roomId, boulderIds: $boulderIds }
    ) {
      id
      sessionboulders {
        boulderId
        status
      }
    }
  }
`;

export const getSession = gql`
  query getSession($id: Int!) {
    session(id: $id) {
      id
      sessionboulders {
        boulderId
        status
      }
    }
  }
`;

export const sendValidation = gql`
  mutation sendValidation($sessionId: Int!, $boulderId: Int!) {
    validateSessionboulder(
      validateSessionboulderData: { sessionId: $sessionId, boulderId: $boulderId }
    ) {
      id
    }
  }
`;
export const terminateSession = gql`
  mutation terminateSession($id: Int!, $date: DateTime) {
    updateSession(updateSessionData: { id: $id, endDate: $date }) {
      id
    }
  }
`;

export const AddSessionsBoulder = gql`
  mutation AddSessionsBoulder(
    $sessionId: Int!
    $boulderId: Int!
    $iscoaching: Boolean!
    $isvalidated: Boolean!
  ) {
    createSessionboulder(
      createSessionboulderData: {
        sessionId: $sessionId
        boulderId: $boulderId
        iscoaching: $iscoaching
        isvalidated: $isvalidated
      }
    ) {
      id
    }
  }
`;
